
import React from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';

export default function BreadCrumbDemo() {
    const home = { icon: 'pi pi-home' }

    const items = [
        { label: 'Dashboard' }
    ];

    return (
        <div>
            <div className="card border-0">
                <BreadCrumb  model={items} home={home} />
            </div>
        </div>
    )
}


