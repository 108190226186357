import React, { useEffect, useState } from "react";
import WingList from "./data/WingList";
import SocietyHeaderView from "../../components/societyHeaderView";
import { BreadCrumb } from "primereact/breadcrumb";
import WingNofResdnt from "./data/WingNofResdnt";
import { MdApartment, MdOutlineRemoveRedEye } from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";

//new

import axios from "axios";
import { useParams } from "react-router-dom";
export default function ManageWingsResdt(props) {
  const [userData, setUserData] = useState([]);
  const { state } = props.location;
  const { image, society, data, id } = state;
  const dataToPass = { image: image, society: society, id: id };
  console.log("state------------>", state);
  const home = { icon: "pi pi-home" };

  //new

  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const token = localStorage.getItem("token");
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(10);
  const [ID, setIDE] = useState("");
  const [residentData, setResidentData] = useState({
    first_name: "",
    last_name: "",
    phone_primary: "",
    email: "",
    status: "",
    type: "",
    society_id: id,
  });
  // const dispatch = useDispatch();

  const [userID, setuserId] = useState("");

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);
  const paramData = useParams();
  console.log(paramData);

  const items = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "Manage Wings" },
  ];

  function handleGetData() {
    setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}listOfUserByWing`,
      headers: { Authorization: "Bearer " + token },
      params: {
        society_id: paramData.society_id,
        wing_id: paramData.id,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.count);
        // set(response.data.data);
        console.log("response.data.data", response.data.data);
        setUserData(response.data.data);
        setIsLoading(false);
        setIDE(response.data.resident_id);
        setentry(response.data.data.length + offset);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //pagination
  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // if (isLoading) {
  //   return <Loading />;
  // }
  useEffect(() => {
    if (paramData.id) {
      handleGetData();
    }
  }, [id, paramData]);

  return (
    <div>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
      </div>
      <div className="header">
        <SocietyHeaderView
          imageUrl={image}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>
      <div className="card p-3">
        <div className="table-body">
          <>
            <div className="table_header mb-3">
              <div className="d-flex flex-wrap align-items-center">
                <button
                  onClick={() => window.history.back()}
                  className="btn me-2 btn-sm btn-primary rounded-4 "
                >
                  Back
                </button>
                <h5 className="mb-0 fs-6 d-flex align-item-center gap-2">
                  <MdApartment /> All Wings List
                </h5>
              </div>
            </div>
            {userData.length > 0 ? (
              <div>
                <div className="table-responsive">
                  <table className="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">FIRST NAME</th>
                        <th scope="col">LAST NAME</th>
                        <th scope="col">APARTMENT NUMBER</th>
                        <th scope="col">MOBILE NO.</th>
                        <th scope="col">RESIDENT TYPE</th>
                        <th scope="col">RESIDENT SINCE</th>
                        <th scope="col">STATUS</th>
                        <th scope="col">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.length > 0 &&
                        userData.map((data, idx) => (
                          <tr>
                            <th scope="row">{idx + 1}</th>

                            <td>
                              <div className="text-uppercase">{`${data.first_name}`}</div>
                            </td>
                            <td>
                              <div className="text-uppercase">{`${data.last_name}`}</div>
                            </td>
                            <td>
                            {/* {console.log(data?.wingandapartment[0]?.apartment_id[0].appartmentNumber)} */}
                              <div className="text-uppercase ">
                                {`${data?.wingandapartment[0]?.wing_id?.name}-${data?.wingandapartment[0]?.apartment_id[0].appartmentNumber}`}
                              </div>
                            </td>
                            <td>
                              <div className="text-uppercase ">
                                {data.phone_primary}
                              </div>
                            </td>
                            <td>
                              <div className="text-uppercase">{`${data?.type}`}</div>
                            </td>
                            <td>{data.createdAt.split("T")[0]}</td>
                            <td>
                              <div className="">{data.status}</div>
                            </td>
                            <td>
                              <div className="action_btn">
                                <div>
                                  <Link
                                    to={{
                                      pathname: `/view-resident/${data._id}`,
                                      state: dataToPass,
                                    }}
                                  >
                                    <MdOutlineRemoveRedEye />
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                {/* pagination */}
                <div className="table-footer d-md-flex justify-content-md-between align-items-center">
            <p className="mb-0">
              Showing {offsetentry + 1} to {entry} of{" "}
              <span id="total-entries">{totalItems}</span> entries
            </p>
            <nav>
              <ul className="pagination pagination-md">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>

                {pageNumbers.map((pageNumber) => {
                  // Define the minimum and maximum page numbers to display
                  const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                  const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                  if (pageNumber >= minPage && pageNumber <= maxPage) {
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                <li className="page-item">
                  <button className="page-link" onClick={nextPage}>
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          </div>
              </div>
            ) : (
              <h4>No data available</h4>
            )}
          </>
        </div>
      </div>
    </div>
  );
}
