import React from 'react'
import VisitingHelpsList from './data/VisitingHelpsList'

export default function ManageVisitingHelp() {
    return (
        <div>
            <div className='card p-3'>
                <div className='table-body'>
                    <VisitingHelpsList />
                </div>
            </div>
        </div>
    )
}
