import React from 'react'
import SocietyHeader from '../../components/societyHeader'
import SocietyHeaderView from '../../components/societyHeaderView'
import logo1 from "../../assets/images/hamptom.png"
import { BreadCrumb } from 'primereact/breadcrumb'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Ledgers from './data/ledgers'

export default function ManageLedgers(props) {
    const { state } = props.location;
    console.log("manageledger",props)
    // const { image, society, id } = state;
    const {society, image} = JSON.parse(localStorage.getItem("society_info"))
    const home = { icon: 'pi pi-home' }

    const items = [
        { label: 'Dashboard' },
        { label: 'Manage Society' },
        { label: 'Manage Ledgers' }
    ];


    return (
        <div>
            <div className='d-flex align-items-center bg-white rounded-2'>
                <BreadCrumb model={items} home={home} />
            </div>
            <div className='header'>
                <SocietyHeaderView
                    imageUrl={image}
                    societyName={society}
                    defaultRouter="/dashboard" />
            </div>
            <div className='card p-3'>

                <div className='table-body'>
                    <Ledgers />
                </div>

            </div>

        </div>
    )
}
