// import React from "react";
// import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
// import logo1 from "../../assets/images/hamptom.png";
// import SocietyHeader from "../../components/societyHeader";
// import { BreadCrumb } from "primereact/breadcrumb";
// import SocietyHeaderView from "../../components/societyHeaderView";
// const AddTenant = (props) => {
//   const home = { icon: "pi pi-home" };
//   const { id } = useParams();
//   const {society, image} = JSON.parse(localStorage.getItem("society_info"))

//   const items = [
//     { label: "Dashboard" },
//     { label: "Manage Society" },
//     { label: "Add Tenant " },
//   ];

//   const initialValues = {
//     name: "",
//     mobileNumber: "",
//     email: "",
//     apartmentNumber: "",
//     familyMember: "",
//     rentAmount: "",
//     agreementStartDate: "",
//     agreementEndDate: "",
//     noObjectionCertificate: null,
//     policeVerification: null,
//     rentalAgreement: null,
//     identityProof: null,
//   };

//   const validationSchema = Yup.object().shape({
//     name: Yup.string().required("Name is required"),
//     mobileNumber: Yup.string().required("Mobile Number is required"),
//     email: Yup.string().email("Invalid email format").required("Email is required"),
//     apartmentNumber: Yup.string().required("Apartment Number is required"),
//     familyMember: Yup.number().required("Family Member is required"),
//     rentAmount: Yup.string().required("Rent Amount is required"),
//     agreementStartDate: Yup.date().required("Agreement Start Date is required"),
//     agreementEndDate: Yup.date().required("Agreement End Date is required"),
//     noObjectionCertificate: Yup.mixed().required("No Objection Certificate is required"),
//     policeVerification: Yup.mixed().required("Police Verification is required"),
//     rentalAgreement: Yup.mixed().required("Rental Agreement is required"),
//     identityProof: Yup.mixed().required("Identity Proof is required"),
//   });

//   return (
//     <>
//       <div className="d-flex align-items-center bg-white rounded-2">
//         <BreadCrumb model={items} home={home} />
//       </div>
//       <div className="header">
//         {/* <SocietyHeader
//           imageUrl={logo1}
//           societyName="The Hampton Homes"
//           defaultRouter="/dashboard"
//         /> */}
//            <SocietyHeaderView
//           imageUrl={decodeURIComponent(image)}
//           societyName={society}
//           defaultRouter="/dashboard"
//         />
//       </div>
//       <div className="card p-4">
//         <div className="row">
//           <div className="col-xl-10 mx-auto">
//             <div className="card border-0 shadow-lg p-2">
//               <div className="card-header bg-white border-0">
//                 <div className="row align-items-center">
//                   <div className="col-8">
//                     <h4 className="mb-0">Add Tenant Profile</h4>
//                   </div>
//                   {/* <div className="col-4 text-end">
//                   <a href="#!" className="btn btn-sm btn-primary">
//                     Edit
//                   </a>
//                 </div> */}
//                 </div>
//               </div>
//               <div className="card-body">
//                 <form>
//                   <h6 className="heading-small text-muted mb-4">
//                     Tenant information
//                   </h6>
//                   <div className="col-12 mb-3">
//                     <div className="avatar d-flex justify-content-center align-items-center flex-column m-auto">
//                       <img
//                         className="rounded-circle"
//                         src="https://staetic.squareyards.com/resources/images/ghaziabad/tn-projectflagship/tn-shree-shyam-city-project-flagship1-3136.jpg"
//                         alt=""
//                         style={{ height: "150px", width: "150px" }}
//                       />
//                       <input
//                         type="file"
//                         id="upload-img"
//                         style={{ display: "none" }}
//                         // onChange={handleFileUpload}
//                       />
//                       <label htmlFor="upload-img" className="upload-img">
//                         <svg
//                           stroke="currentColor"
//                           fill="currentColor"
//                           strokeWidth="0"
//                           viewBox="0 0 24 24"
//                           height="1em"
//                           width="1em"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path fill="none" d="M0 0h24v24H0z"></path>
//                           <path d="M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z"></path>
//                         </svg>
//                       </label>
//                     </div>
//                   </div>
//                   <div className="pl-lg-4">
//                     <div className="row">
//                       {/* <div className="col-lg-4">
//                         <label className="form-control-label" htmlFor="title">
//                           Select From Existing Residents
//                         </label>
//                         <select
//                           class="form-select"
//                           aria-label="Default select example"
//                         >
//                           <option selected>Select a member</option>
//                           <option value="1">Member one</option>
//                           <option value="2">Member two</option>
//                         </select>
//                       </div> */}
//                       <div className="col-lg-6">
//                         <div className="form-group focused mb-2">
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-firstName"
//                           >
//                             Name (needs to match documents)
//                           </label>
//                           <input
//                             type="text"
//                             id="input-firstName"
//                             className="form-control form-control-alternative"
//                             placeholder="Lucky"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-6">
//                         <div className="form-group focused">
//                           <label
//                             className="form-control-label"
//                             htmlFor="mobile-number"
//                           >
//                             Mobile Number
//                           </label>
//                           <input
//                             type="text"
//                             id="mobile-number"
//                             className="form-control form-control-alternative"
//                             placeholder="+91-98647-98765"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-4">
//                         <div className="form-group focused mb-2">
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-email"
//                           >
//                             Email ID
//                           </label>
//                           <input
//                             type="text"
//                             id="input-email"
//                             className="form-control form-control-alternative"
//                             placeholder="Lucky@gmail.com"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-4">
//                         <div className="form-group mb-2">
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-lastName"
//                           >
//                             Apartment Number
//                           </label>
//                           <input
//                             type="text"
//                             id="input-lastName"
//                             className="form-control form-control-alternative"
//                             placeholder="w-202"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-4">
//                         <div className="form-group focused mb-2">
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-designation"
//                           >
//                             Family Member
//                           </label>
//                           <input
//                             type="number"
//                             id="input-designation"
//                             className="form-control form-control-alternative"
//                             placeholder=""
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-4">
//                         <div className="form-group mb-2">
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-lastName"
//                           >
//                             Rent Amount
//                           </label>
//                           <input
//                             type="text"
//                             id="input-lastName"
//                             className="form-control form-control-alternative"
//                             placeholder="50,000"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-4">
//                         <div className="form-group mb-2">
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-lastName"
//                           >
//                             Agreement Start Date
//                           </label>
//                           <input
//                             type="date"
//                             id="input-lastName"
//                             className="form-control form-control-alternative"
//                             placeholder="50,000"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-4">
//                         <div className="form-group mb-2">
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-lastName"
//                           >
//                             Agreement End Date
//                           </label>
//                           <input
//                             type="date"
//                             id="input-lastName"
//                             className="form-control form-control-alternative"
//                             placeholder="50,000"
//                           />
//                         </div>
//                       </div>
//                       <h5 className="my-4">Upload Documents</h5>
//                       <div className="col-lg-6">
//                         <div className="form-group mb-2">
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-lastName"
//                           >
//                             No object certificate
//                           </label>
//                           <input
//                             type="file"
//                             id="input-lastName"
//                             className="form-control form-control-alternative"
//                             placeholder="50,000"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-6">
//                         <div className="form-group mb-2">
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-lastName"
//                           >
//                             Police Verification
//                           </label>
//                           <input
//                             type="file"
//                             id="input-lastName"
//                             className="form-control form-control-alternative"
//                             placeholder="50,000"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-6">
//                         <div className="form-group mb-2">
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-lastName"
//                           >
//                             Rental Agreement
//                           </label>
//                           <input
//                             type="file"
//                             id="input-lastName"
//                             className="form-control form-control-alternative"
//                             placeholder="50,000"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-6">
//                         <div className="form-group mb-2">
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-lastName"
//                           >
//                             Identity Proof
//                           </label>
//                           <input
//                             type="file"
//                             id="input-lastName"
//                             className="form-control form-control-alternative"
//                             placeholder="50,000"
//                           />
//                         </div>
//                       </div>

//                     </div>

//                   </div>
//                   <hr className="my-4" />
//                   {/* Address */}

//                   <hr className="my-4" />

//                   <div className="d-flex justify-content-center align-items-center gap-2">
//                     <Link onClick={() => window.history.back()} className="btn btn-outline-primary">
//                       Back
//                     </Link>
//                     <button className="btn btn-primary">Save</button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AddTenant;

import React, { useEffect, useState, memo, useRef } from "react";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo1 from "../../assets/images/hamptom.png";
import { BreadCrumb } from "primereact/breadcrumb";
import SocietyHeaderView from "../../components/societyHeaderView";
import { makeApiCall } from "../../utils/api";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddTenant = (props) => {
  const [uploadimage, setUploadimage] = useState("");
  const home = { icon: "pi pi-home" };
  const paramsid = useParams();
  const { society, image } = JSON.parse(localStorage.getItem("society_info"));
  const [ownerDatatrue, setOwnerdatatrue] = useState(false);
  const [ownerData, setOwnerdata] = useState([]);
  const [wingData, setWingdata] = useState([]);
  const [appartmentData, setAppartmentdata] = useState([]);

  const noObjectionCertificateRef = useRef(null);
  const policeVerificationRef = useRef(null);
  const rentalAgreementRef = useRef(null);
  const identityProofRef = useRef(null);

  const items = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "Add Tenant " },
  ];
  const Validation = Yup.object().shape({
    image: Yup.string().required("Image is required"),
    name: Yup.string().required("Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
      .required("Mobile Number is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    apartmentNumber: Yup.string().required("Apartment Number is required"),
    familyMember: Yup.number("Only Number is valid ").required(
      "Family Member is required"
    ),
    rentAmount: Yup.string().required("Rent Amount is required"),
    agreementStartDate: Yup.date().required("Agreement Start Date is required"),
    agreementEndDate: Yup.date().required("Agreement End Date is required"),
    noObjectionCertificate: Yup.string().required(
      "No Objection Certificate is required"
    ),
    policeVerification: Yup.string().required(
      "Police Verification is required"
    ),
    rentalAgreement: Yup.string().required("Rental Agreement is required"),
    identityProof: Yup.string().required("Identity Proof is required"),
    ownerList: Yup.string().required("Owner list is required"),
    wingList: Yup.string().required("Wing list is required"),
  });

  const formik = useFormik({
    initialValues: {
      image: "",
      name: "",
      last_name: "",
      mobileNumber: "",
      email: "",
      apartmentNumber: "",
      familyMember: "",
      rentAmount: "",
      agreementStartDate: "",
      agreementEndDate: "",
      noObjectionCertificate: "",
      policeVerification: "",
      rentalAgreement: "",
      identityProof: "",
      ownerList: "",
      wingList: "",
      // appartmentList: "",
    },
    validationSchema: Validation,
    // onSubmit: (values) => {
    //   console.log(values);
    //   // Handle form submission
    // },
  });
  async function transformFormikValues(formikValues) {
    const values = {
      society_id: paramsid.id,
      profile_image: formikValues.image,

      first_name: formikValues.name,
      last_name: formikValues.last_name,
      email: formikValues.email,
      phone_primary: formikValues.mobileNumber,
      role: "tenant",
      ownerId: formikValues.ownerList,
      tenant_family_member: formikValues.familyMember,
      agreement_start_date: formikValues.agreementStartDate,
      agreement_end_date: formikValues.agreementEndDate,
      rent_amount: parseInt(formikValues.rentAmount, 10),

      wingandapartment: [
        {
          wing_id: formikValues.wingList,
          apartment_id: [formikValues.apartmentNumber],
        },
      ],

      upload_document: [
        {
          type: "noc",
          file: formikValues.noObjectionCertificate,
          status: "pending",
        },
        {
          type: "police_verification",
          file: formikValues.policeVerification,
          status: "pending",
        },
        {
          type: "rental_agreement",
          file: formikValues.rentalAgreement,
          status: "pending",
        },
        {
          type: "id_proof",
          file: formikValues.identityProof,
          status: "pending",
        },
      ],
    };

    console.log(values);

    const response = await makeApiCall(
      "addTenant",
      "POST",
      values,
      {},
      true,
      false,
      "",
      false
    );
    if (response.code == 200) {
      if (noObjectionCertificateRef.current)
        noObjectionCertificateRef.current.value = "";
      if (policeVerificationRef.current)
        policeVerificationRef.current.value = "";
      if (rentalAgreementRef.current) rentalAgreementRef.current.value = "";
      if (identityProofRef.current) identityProofRef.current.value = "";
      formik.resetForm();
      Swal.fire("Response", response.message);
    }
    if (response.message == "User Already exists.") {
      Swal.fire("Response", response.message);
    }
    console.log(values);
  }

  // Example usage
  // const transformedData = transformFormikValues(formikValues);
  // console.log(transformedData);

  const resetALLform = () => {
    if (noObjectionCertificateRef.current)
      noObjectionCertificateRef.current.value = "";
    if (policeVerificationRef.current) policeVerificationRef.current.value = "";
    if (rentalAgreementRef.current) rentalAgreementRef.current.value = "";
    if (identityProofRef.current) identityProofRef.current.value = "";
    formik.resetForm();
  };
  const handleSignIn = async (data) => {
    formik.setTouched(
      Object.keys(formik.values).reduce((acc, fieldName) => {
        acc[fieldName] = true;
        return acc;
      }, {})
    );

    formik.validateForm().then(async (errors) => {
      console.log("errors", errors);

      if (Object.keys(errors).length === 0) {
        //const formData = new FormData();
        const values = formik.values;

        // formData.append("name", values.name);
        // formData.append("last_name", values.last_name);
        // formData.append("mobileNumber", values.mobileNumber);
        // formData.append("email", values.email);
        // formData.append("apartmentNumber", values.apartmentNumber);
        // formData.append("familyMember", values.familyMember);
        // formData.append("rentAmount", values.rentAmount);
        // formData.append("agreementStartDate", values.agreementStartDate);
        // formData.append("agreementEndDate", values.agreementEndDate);
        // formData.append("noObjectionCertificate", values.noObjectionCertificate);
        // formData.append("policeVerification", values.policeVerification);
        // formData.append("rentalAgreement", values.rentalAgreement);
        // formData.append("identityProof", values.identityProof);

        // Mock API call
        // Replace this with actual API call
        console.log("Form data to be submitted:", values);

        transformFormikValues(formik.values);
      } else {
        console.log("Validation errors", errors);
      }
    });
  };

  async function handleUploadImage(event, fieldName) {
    const formData = new FormData();

    formData.append("profile_image", event.currentTarget.files[0]);

    const uploadImage = await makeApiCall(
      "uploadImageToAWS",
      "POST",
      formData,
      {},
      true,
      false,
      "",
      false
    );
    if (uploadImage) {
      console.log("fieldName", fieldName, uploadImage.data);
      formik.setFieldValue(fieldName, uploadImage.data);
    }
  }

  useEffect(() => {
    console.log("Myvalue", formik.values);
  }, [formik.values]);

  const handleOwner = async () => {
    const params = {
      society_id: paramsid?.id,
    };
    //setAppartmentdata([])
    console.log(paramsid?.id);
    if (paramsid?.id) {
      const response = await makeApiCall(
        "getOwnerListbySociety",
        "GET",
        null,
        params,
        true,
        false,
        "",
        false
      );
      console.log(response);
      if (response?.data) {
        // formik.setFieldValue("wingList","")
        //  setAppartmentdata([])
        setOwnerdata(response?.data);
      }
    }
  };

  const handleWingChangeBYowner = async (value) => {
    console.log("hello hello", value);
    let ownername = value;
    formik.setFieldValue("ownerList", value);
    formik.setFieldValue("wingList", "");
    setWingdata([])
    formik.setFieldValue("apartmentNumber", "");

    //.split(" ").pop().join()
    console.log(ownername);

    if (ownername && value) {
      const params = {
        _id: ownername,
      };

      const response = await makeApiCall(
        "getWingByOwner",
        "GET",
        null,
        params,
        true,
        false,
        "",
        false
      );
      console.log(response.data);
      if (response.data) {
        setWingdata(response.data);
      }
    }
  };

  const handleAppartment = async (value) => {
    console.log(formik?.values?.ownerList);
    console.log(formik?.values?.wingList);

    formik.setFieldValue("wingList", value);
    formik.setFieldValue("apartmentNumber", "");
    setAppartmentdata([]);
    if (formik?.values?.ownerList && value) {
      const params = {
        ownerId: formik?.values?.ownerList,
        wing_id: value,
      };
      const response = await makeApiCall(
        "getappartmentByOwner",
        "GET",
        null,
        params,
        true,
        false,
        "",
        false
      );
      console.log("appdata", response.data[0].wingandapartment[0].apartment_id);
      if (response.data[0].wingandapartment[0].apartment_id) {
        setAppartmentdata(response.data[0].wingandapartment[0].apartment_id);
      } else {
        setAppartmentdata([]);
      }
    }
  };

  // useEffect(()=>{
  //   if(ownerData,formik?.values?.ownerList){
  //     handleWingChangeBYowner()
  //   }

  // },[ownerData,formik?.values?.ownerList])

  // useEffect(()=>{
  //     if(formik?.values?.wingList){
  //     handleAppartment()
  //     }
  // },[formik?.values?.wingList])
  function getNumbersFromString(str) {
    // Use a regular expression to match all numbers in the string
    const numbers = str.match(/\d+/g);

    // Convert the matched numbers to an array of numbers
    return numbers ? numbers.map(Number) : [];
  }

  useEffect(() => {
    handleOwner();
  }, []);

  return (
    <>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
      </div>
      <div className="header">
        <SocietyHeaderView
          imageUrl={decodeURIComponent(image)}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>
      <div className="card p-4">
        <div className="row">
          <div className="col-xl-10 mx-auto">
            <div className="card border-0 shadow-lg p-2">
              <div className="card-header bg-white border-0">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h4 className="mb-0">Add Tenant Profile</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                  <h6 className="heading-small text-muted mb-4">
                    Tenant information
                  </h6>
                  <div className="col-12 mb-3">
                    <div className="avatar d-flex justify-content-center align-items-center flex-column m-auto">
                      <img
                        className="rounded-circle"
                        src={formik.values.image ? formik.values.image : ""}
                        alt=""
                        style={{ height: "150px", width: "150px" }}
                      />
                      {Boolean(formik.touched.image && formik.errors.image) && (
                        <small className="text-danger">
                          {formik.errors.image}
                        </small>
                      )}
                      <input
                        type="file"
                        id="upload-img"
                        name="image"
                        style={{ display: "none" }}
                        onChange={(event) => handleUploadImage(event, "image")}
                      />
                      <label htmlFor="upload-img" className="upload-img">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z"></path>
                        </svg>
                      </label>
                    </div>
                  </div>
                  <div className="pl-lg-4">
                    <div className="row">
                      <div className="col-6 mb-3">
                        <label htmlFor="name" className="form-label">
                          Name (needs to match documents)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          {...formik.getFieldProps("name")}
                        />
                        {Boolean(formik.touched.name && formik.errors.name) && (
                          <small className="text-danger">
                            {formik.errors.name}
                          </small>
                        )}
                      </div>
                      <div className="col-6 mb-3">
                        <label htmlFor="last_name" className="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="last_name"
                          {...formik.getFieldProps("last_name")}
                        />
                        {Boolean(
                          formik.touched.last_name && formik.errors.last_name
                        ) && (
                          <small className="text-danger">
                            {formik.errors.last_name}
                          </small>
                        )}
                      </div>
                      <div className="col-6 mb-3">
                        <label htmlFor="mobileNumber" className="form-label">
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="mobileNumber"
                          {...formik.getFieldProps("mobileNumber")}
                        />
                        {Boolean(
                          formik.touched.mobileNumber &&
                            formik.errors.mobileNumber
                        ) && (
                          <small className="text-danger">
                            {formik.errors.mobileNumber}
                          </small>
                        )}
                      </div>
                      <div className="col-6 mb-3">
                        <label htmlFor="email" className="form-label">
                          Email ID
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          {...formik.getFieldProps("email")}
                        />
                        {Boolean(
                          formik.touched.email && formik.errors.email
                        ) && (
                          <small className="text-danger">
                            {formik.errors.email}
                          </small>
                        )}
                      </div>

                      {/* <div className="col-6 mb-3">
                        <label htmlFor="apartmentNumber" className="form-label">
                          Apartment Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="apartmentNumber"
                          {...formik.getFieldProps("apartmentNumber")}
                        />
                        {Boolean(formik.touched.apartmentNumber && formik.errors.apartmentNumber) && (
                          <small className="text-danger">{formik.errors.apartmentNumber}</small>
                        )}
                      </div> */}

                      <div className="col-6 mb-3">
                        <label htmlFor="ownerList" className="form-label">
                          OWNER LIST
                        </label>
                        <select
                          className="form-control"
                          name="ownerList"
                          //onChange={handleappOwner}
                          onChange={(event) => {
                            handleWingChangeBYowner(event.target.value);
                            setAppartmentdata([]);
                          }}

                          //{...formik.getFieldProps("ownerList")}
                        >
                          <option value="" label="Select apartment number" />
                          {ownerData.length > 0 &&
                            ownerData.map((ele, index) => (
                              <>
                                <option
                                  value={ele._id}
                                  label={`${ele.first_name} ${ele.last_name}`}
                                />
                              </>
                            ))}
                        </select>

                        {Boolean(
                          formik.touched.ownerList && formik.errors.ownerList
                        ) && (
                          <small className="text-danger">
                            {formik.errors.ownerList}
                          </small>
                        )}
                      </div>

                      <div className="col-6 mb-3">
                        <label htmlFor="wingList" className="form-label">
                          Wing List
                        </label>
                        <select
                          className="form-control"
                          name="wingList"
                          value={formik.values.wingList}
                          onChange={(e) => {
                            handleAppartment(e.target.value);
                          }}
                        >
                          <option value="" label="Select wing" defaultValue />
                          {wingData.map((ele, index) => (
                            <>
                              // {console.log(ele.wingandapartment)}
                              {ele.wingandapartment.map((ele) => (
                                <>
                                  {console.log(ele.wing_id?.id)}
                                  <option
                                    value={ele?.wing_id?.id}
                                    label={ele?.wing_id?.name}
                                  />
                                </>
                              ))}
                              {/* <option value={ele?.wingandapartment?.wing_id?.id} label={ele?.wingandapartment?.wing_id?.name} /> */}
                            </>
                          ))}
                          {/*     
     <option value="A" label="A" />
    <option value="B" label="B" />
    <option value="C" label="C" />
    <option value="D" label="D" />  */}
                        </select>
                        {Boolean(
                          formik.touched.wingList && formik.errors.wingList
                        ) && (
                          <small className="text-danger">
                            {formik.errors.wingList}
                          </small>
                        )}
                      </div>

                      <div className="col-6 mb-3">
                        <label htmlFor="apartmentNumber" className="form-label">
                          Apartment Number
                        </label>
                        <select
                          className="form-control"
                          name="apartmentNumber"
                          {...formik.getFieldProps("apartmentNumber")}
                        >
                          <option value="" label="Select Appartment" />
                          {appartmentData.map((ele) => (
                            <>
                              <option
                                value={ele.id}
                                label={ele.appartmentNumber}
                              />
                            </>
                          ))}
                          {/* <option value="" label="Select apartment number" /> */}
                        </select>
                        {Boolean(
                          formik.touched.apartmentNumber &&
                            formik.errors.apartmentNumber
                        ) && (
                          <small className="text-danger">
                            {formik.errors.apartmentNumber}
                          </small>
                        )}
                      </div>

                      {/* <div className="col-6 mb-3">
  <label htmlFor="wingList" className="form-label">
    Wing List
  </label>
  <select
    className="form-control"
    name="wingList"
    {...formik.getFieldProps("wingList")}
  >
    <option value="" label="Select wing" />
    <option value="A" label="A" />
    <option value="B" label="B" />
    <option value="C" label="C" />
    <option value="D" label="D" />

  </select>
  {Boolean(formik.touched.wingList && formik.errors.wingList) && (
    <small className="text-danger">{formik.errors.wingList}</small>
  )}
</div> */}

                      <div className="col-6 mb-3">
                        <label htmlFor="familyMember" className="form-label">
                          Family Members Count
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="familyMember"
                          value={formik.values.familyMember}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              formik.setFieldValue(
                                "familyMember",
                                e.target.value
                              );
                              // formik.setFieldTouched("familyMember", true);
                            }
                          }}
                        />
                        {Boolean(
                          formik.touched.familyMember &&
                            formik.errors.familyMember
                        ) && (
                          <small className="text-danger">
                            {formik.errors.familyMember}
                          </small>
                        )}
                      </div>
                      <div className="col-6 mb-3">
                        <label htmlFor="rentAmount" className="form-label">
                          Rent Amount
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="rentAmount"
                          {...formik.getFieldProps("rentAmount")}
                        />
                        {Boolean(
                          formik.touched.rentAmount && formik.errors.rentAmount
                        ) && (
                          <small className="text-danger">
                            {formik.errors.rentAmount}
                          </small>
                        )}
                      </div>
                      {/* <div className="col-6 mb-3">
                        <label htmlFor="agreementStartDate" className="form-label">
                          Agreement Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          style={{ width: '500px', height: '40px' }} 
                          name="agreementStartDate"
                          {...formik.getFieldProps("agreementStartDate")}
                        />
                        {Boolean(formik.touched.agreementStartDate && formik.errors.agreementStartDate) && (
                          <small className="text-danger">{formik.errors.agreementStartDate}</small>
                        )}
                      </div> */}

                      <div className="col-6 mb-3">
                        <label
                          htmlFor="agreementStartDate"
                          className="form-label"
                        >
                          Agreement Start Date
                        </label>

                        <DatePicker
                          selected={formik.values.agreementStartDate}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) =>
                            formik.setFieldValue("agreementStartDate", date)
                          }
                          className="form-control custom-datepicker"
                          style={{ width: "150%", height: "40px" }}
                          name="agreementStartDate"
                        />
                        {Boolean(
                          formik.touched.agreementStartDate &&
                            formik.errors.agreementStartDate
                        ) && (
                          <small className="text-danger">
                            {formik.errors.agreementStartDate}
                          </small>
                        )}
                      </div>

                      <div className="col-6 mb-3">
                        <label
                          htmlFor="agreementEndDate"
                          className="form-label"
                        >
                          Agreement End Date
                        </label>
                        <DatePicker
                          selected={formik.values.agreementEndDate}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) =>
                            formik.setFieldValue("agreementEndDate", date)
                          }
                          className="form-control custom-datepicker"
                          style={{ width: "500px", height: "40px" }}
                          name="agreementEndDate"
                        />
                        {/* <input
                          type="date"
                          className="form-control"
                          name="agreementEndDate"
                          {...formik.getFieldProps("agreementEndDate")}
                        /> */}
                        {Boolean(
                          formik.touched.agreementEndDate &&
                            formik.errors.agreementEndDate
                        ) && (
                          <small className="text-danger">
                            {formik.errors.agreementEndDate}
                          </small>
                        )}
                      </div>
                      <h5 className="my-4">Upload Documents</h5>
                      <div className="col-lg-6">
                        <div className="form-group mb-2">
                          <label
                            className="form-control-label"
                            htmlFor="input-noObjectionCertificate"
                          >
                            No Objection Certificate
                          </label>
                          <input
                            type="file"
                            ref={noObjectionCertificateRef}
                            id="input-noObjectionCertificate"
                            name="noObjectionCertificate"
                            className="form-control form-control-alternative"
                            //value={formik.values.noObjectionCertificate}
                            onChange={(event) =>
                              handleUploadImage(event, "noObjectionCertificate")
                            }
                            // onChange={(event) => formik.setFieldValue("noObjectionCertificate", event.target.files[0])}
                          />
                          {Boolean(
                            formik.touched.noObjectionCertificate &&
                              formik.errors.noObjectionCertificate
                          ) && (
                            <small className="text-danger">
                              {formik.errors.noObjectionCertificate}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-2">
                          <label
                            className="form-control-label"
                            htmlFor="input-policeVerification"
                          >
                            Police Verification
                          </label>
                          <input
                            type="file"
                            id="input-policeVerification"
                            ref={policeVerificationRef}
                            name="policeVerification"
                            className="form-control form-control-alternative"
                            onChange={(event) =>
                              handleUploadImage(event, "policeVerification")
                            }
                            //onChange={(event) => formik.setFieldValue("policeVerification", event.target.files[0])}
                          />
                          {Boolean(
                            formik.touched.policeVerification &&
                              formik.errors.policeVerification
                          ) && (
                            <small className="text-danger">
                              {formik.errors.policeVerification}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-2">
                          <label
                            className="form-control-label"
                            htmlFor="input-rentalAgreement"
                          >
                            Rental Agreement
                          </label>
                          <input
                            type="file"
                            id="input-rentalAgreement"
                            ref={rentalAgreementRef}
                            name="rentalAgreement"
                            className="form-control form-control-alternative"
                            onChange={(event) =>
                              handleUploadImage(event, "rentalAgreement")
                            }
                            //onChange={(event) => formik.setFieldValue("rentalAgreement", event.target.files[0])}
                          />
                          {Boolean(
                            formik.touched.rentalAgreement &&
                              formik.errors.rentalAgreement
                          ) && (
                            <small className="text-danger">
                              {formik.errors.rentalAgreement}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-2">
                          <label
                            className="form-control-label"
                            htmlFor="input-identityProof"
                          >
                            Identity Proof
                          </label>
                          <input
                            type="file"
                            id="input-identityProof"
                            ref={identityProofRef}
                            name="identityProof"
                            className="form-control form-control-alternative"
                            onChange={(event) =>
                              handleUploadImage(event, "identityProof")
                            }
                            //onChange={(event) => formik.setFieldValue("identityProof", event.target.files[0])}
                          />
                          {Boolean(
                            formik.touched.identityProof &&
                              formik.errors.identityProof
                          ) && (
                            <small className="text-danger">
                              {formik.errors.identityProof}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <div className="d-flex justify-content-center align-items-center gap-2">
                    <Link
                      onClick={() => {
                        resetALLform();
                        window.history.back();
                      }}
                      className="btn btn-outline-primary"
                    >
                      Back
                    </Link>
                    <button
                      onClick={handleSignIn}
                      type="submit"
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(AddTenant);
