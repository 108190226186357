import React, { useEffect ,useState} from "react";
import ResidentsList from "./data/ResidentsList";
import logo1 from "../../assets/images/hamptom.png";
import SocietyHeader from "../../components/societyHeader";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SocietyHeaderView from "../../components/societyHeaderView";
import RecyclebinList from "./data/RecyclebinList";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { useDispatch, useSelector } from 'react-redux';
export default function RecycleBin(props) {
  const { id } = useParams();
  const {society, image} = JSON.parse(localStorage.getItem("society_info"))
  const home = { icon: "pi pi-home" };


  const items = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "Residents List" },
  ];

  return (
    <div>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
      </div>
      <div className="header">
        <SocietyHeaderView
          imageUrl={decodeURIComponent(image)}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>
      <div className="card p-3">
        <div className="table-body">
          <RecyclebinList id={id}/>
        </div>
      </div>
    </div>
  );
}
