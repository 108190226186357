import React from 'react'
import CompaniesList from './data/CompaniesList'

export default function ManageDelieveryCharges() {
    return (
        <div>
            <div className='card p-3'>
                <div className='table-body'>
                    <CompaniesList />
                </div>
            </div>
        </div>
    )
}
