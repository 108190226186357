import React from "react";
import UserProfile from "../../components/userProfile";
import avatar from "../../assets/images/avatar.jpg";
import banner from "../../assets/images/Debit.png";
import doc from "../../assets/images/Debit.png";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SocietyHeader from "../../components/societyHeader";
import logo1 from "../../assets/images/hamptom.png";
import AllFamily from "../../components/AllFamily";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import SocietyHeaderView from "../../components/societyHeaderView";
import Allparking from "../../components/Allparking";

export default function ViewTenant(props) {

  console.log(props.location)
  const { id, societyId } = useParams();
  const {society, image} = JSON.parse(localStorage.getItem("society_info"))
  
  const { state } = props.location;
  const uploadDocumentLink = state && state.uploadDocumentLink;

  const history = useHistory();
  const [data, setdata] = useState([]);
  const token = localStorage.getItem("token");
  const home = { icon: "pi pi-home" };

  const items = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "View Tenant Profile" },
  ];
  useEffect(() => {
    datafetch();
  }, []);

  const datafetch = () => {
    // setIsLoading(true);
    // const offset = (currentPage - 1) * pageSize;
    // const limit = pageSize;

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getTenant`,
      headers: { Authorization: "Bearer " + token },
      // params: { limit, offset }
      params: { id: id, society_id: societyId },
    };

    axios
      .request(config)
      .then((response) => {
        // setTotalItems(response.data.count);
        console.log(response.data);
        setdata(response.data);
        // setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //

  return (
    <div>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
        <button
          onClick={() => history.goBack()}
          className="btn ms-auto me-2 btn-sm btn-primary rounded-4"
        >
          Back
        </button>
      </div>
      <div className="header">
        {/* <SocietyHeader
          imageUrl={logo1}
          societyName="The Hampton Homes"
          defaultRouter="/dashboard" /> */}
        <SocietyHeaderView
          imageUrl={decodeURIComponent(image)}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>
      <div className="card shadow-lg p-4">
        <div className="row">
          <div className="col-12 col-md-12 col-xl-12">
            
            {data.data &&
              data.data.map((userData, index) => (
                <UserProfile
                  banner={banner}
                  avatar={userData.profile_image}
                  firstName={userData.first_name}
                  lastName={userData.last_name}
                  email={userData.email}
                  phone_primary={userData.phone_primary}
                  // apartmentnumber={userData.wingsAndApartments
                  //   .map((wingApartment) => {
                  //     return wingApartment.apartments
                  //       .map((apartment) => apartment.appartmentNumber)
                  //       .join(", ");
                  //   })
                  //   .join(", ")}
                  apartmentnumber={userData.wingsAndApartments.map((wingApartment) => {
                    const wingName = wingApartment.wing.name;
                    const apartmentNumbers = wingApartment.apartments.map((apartment) => apartment.appartmentNumber).join(", ");
                    return `${wingName} ${apartmentNumbers}`;
                }).join(", ")}
                  landnumber={userData.landNumber}
                  address={userData.address}
                  image={doc}
                  ranking={userData.ranking}
                  role={userData.role}
                  link="#"
                  name2={userData.name2}
                  title={userData.title}
                />


              ))}
            {/* <UserProfile
              banner={banner}
              avatar={avatar}
              name="John Doe"
              email="john@example.com"
              number="9876543210"
              apartmentnumber="D-123"
              landnumber="91-11-1234 5678"
              address="Fort Rd, Khejer Para, Manak Chowk, Amar Sagar Pol, "
              image={doc}
              ranking='2'
              link='#'
              name2="Judith A. Hayes"
              title='Owner'


            /> */}
          </div>
          

          {data.data &&
              data.data.map((userData, index) => (
                <div className="row">
                <div className="col-xl-6 col-md-6 col-sm-12">
      
                {console.log("userdata",userData)}
                  <Allparking
                   parking={userData?.parking_details} 
                   />
      
                </div>
                </div> 
      
                // <UserProfile
                //   banner={banner}
                //   avatar={userData.profile_image}
                //   firstName={userData.first_name}
                //   lastName={userData.last_name}
                //   email={userData.email}
                //   phone_primary={userData.phone_primary}/>

              ))}





{/* 
        <div className="row">
          <div className="col-xl-6 col-md-6 col-sm-12">

          
            <Allparking
             parking={userData.parking_details} 
             />

          </div>
          </div> */}

          
          <div className="">
                <h5 className="">Uploaded Documents:</h5>
                <br/>
          <div className="d-flex align-items-center gap-3">
  {uploadDocumentLink && uploadDocumentLink.length > 0 ? (
    uploadDocumentLink.map((link, index) => (
      <a
        key={link._id} // Assuming _id is unique
        href={link.file} // Accessing the 'file' property
        className="btn btn-sm btn-primary mb-2"
        download
        target="_blank" // Open link in a new tab
      >
        Download {link.type} File
      </a>
    ))
  ) : (
    <span className="error-message" style={{color:"red"}}>No upload documents available</span>
  )}
</div>
</div>


          {/* <div className='col-12 col-md-6 col-xl-5 '>
            <AllFamily />
          </div> */}
        </div>
      </div>
    </div>
  );
}
