import React from 'react';

export default function VotingList(props) {
    const { avatar, name, apartment, date, time, description, media, likes, comments, share, ...rest } = props;

    return (

        <div className="card-body p-2">
            <div className="d-flex">
                <img
                    src={avatar}
                    alt="User Avatar"
                    className="rounded"
                    style={{ width: '48px', height: '48px', marginRight: '20px' }}
                />
                <div className="media-body">
                    <h6 className='mb-2'>{name}</h6>
                    <div className="d-flex align-items-center">
                        <p className="text-muted mb-0">{apartment}</p>
                        <span className="badge bg-light text-muted ms-2">{date}</span>
                    </div>
                </div>
            </div>
        </div>

    );
}
