
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';

import { CgClose } from 'react-icons/cg';



export default function ParkingDetails(props) {
  const formik = props.formik;
  // const [numberOfParkings, setNumberOfParkings] = useState(6);
  // const [parkings, setParkings] = useState([]);

  // const generateInitialParking = (count) => {
  //   return Array.from({ length: count }, (_, index) => ({
  //     name: `Parking ${index + 1}`
  //   }))
  // }


  // useEffect(() => {
  //   setParkings(generateInitialParking(numberOfParkings));
  // }, [numberOfParkings]);


  // const handleAddParkingClose = (parkingIndex) => {
  //   const updatedParkings = parkings.filter((_, index) => index !== parkingIndex);
  //   setParkings(updatedParkings);
  // };



  const handleNumberChange = (event) => {
    
    const newNumber = parseInt(event.target.value, 10) || 0;
    const currentparkCount = formik.values.park.length;
    formik.setFieldValue("number", newNumber);
    formik.setFieldTouched("number", true);
    // If the new number is greater than the current number of boxes, add boxes
    if (newNumber > currentparkCount) {
      const additionalpark = newNumber - currentparkCount;
      const newpark = [...formik.values.park];

      for (let i = 0; i < additionalpark; i++) {
        newpark.push({ name: "" ,area : ""});
      }

      formik.setFieldValue("park", newpark);
    } else if (newNumber < currentparkCount) {
      // If the new number is less than the current number of boxes, remove boxes
      const removedpark = currentparkCount - newNumber;
      const newpark = formik.values.park.slice(0, - removedpark);

      formik.setFieldValue("park", newpark);
    }

    // Update the number field
    formik.handleChange(event);
  };




  const handleAddWingClose = (index) => {
    const newpark = [...formik.values.park];
    console.log(index)
    newpark.splice(index, 1);
    formik.setFieldValue("park", newpark);
    formik.setFieldValue("number", formik.values.number - 1);

    const touchpark = [...formik.touched.park]
    touchpark.splice(index, 1);

    formik.setFieldTouched(`park`, touchpark);
    // formik.setFieldTouched(`wings.${index}.name`, false);
    // formik.setFieldTouched(`wings.${index}.contact_number`, false);
    // formik.setFieldError(`wings.${index}.name`, undefined);
    // formik.setFieldError(`wings.${index}.contact_number`, undefined);
  };


  return (
    <div className="p-d-flex p-flex-column">
      <div className="p-fluid w-50 m-auto">
        <div className="p-field">
          <label htmlFor="totalParkingSlots">Total Parking Slots</label>
          <div className="p-inputgroup">
            <InputText
              id="totalParkingSlots"
              type="number"
              // value={numberOfParkings}
              // onChange={(e) => setNumberOfParkings(e.target.value)}
              onChange={handleNumberChange}
              onBlur={formik.handleBlur}
              value={formik.values.number}
            />
          </div>
          {Boolean(formik.touched.number && formik.errors.number) && (
                <small className="p-error">{formik.errors.number}</small>
            )}
        </div>
      </div>

      <div className="row mb-4">
        {formik.values.park.map((parking, parkingIndex) => (
          <div className='col-12 col-md-4 col-sm-6 col-xl-4 mb-0 p-2'>
            <div className="card border-0 shadow-lg p-3"
              key={parkingIndex}
            // title={`Parking ${parkingIndex + 1}`}

            >
              <h4 className="fw-semi-bold fs-6 ">{parking.name}</h4>
              <CgClose
                className="position-absolute"
                style={{ fontSize: '20px', fontWeight: '800', top: '10px', right: '10px', cursor: 'pointer' }}
                // onClick={() => handleAddParkingClose(parkingIndex)}
                onClick={() => handleAddWingClose(parkingIndex)}
              />

              <div className="p-grid p-mr-1 p-2">
                <div className="p-field p-mb-0">
                  <label className="text-muted" htmlFor={`parkingName${parkingIndex}`}>Parking Name :</label>
                  <InputText className='w-100'
                    id={`areaPerSqft${parkingIndex}`}
                    name={`park.${parkingIndex}.name`}
                    placeholder="Parking Name"
                    {...formik.getFieldProps(`park.${parkingIndex}.name`)}
                  />
                   {Boolean(formik.touched?.park && formik.touched?.park?.[parkingIndex]?.name && formik.errors?.park?.[parkingIndex] && formik.errors?.park?.[parkingIndex]?.name ) && (
                          <small className="p-error">{formik.errors?.park?.[parkingIndex]?.name}</small>
                      )}
                      {console.log(parkingIndex, formik.errors?.park?.[parkingIndex]?.name)}
                </div>

                <div className="p-field p-mb-0">
                  <label htmlFor={`areaPerSqft${parkingIndex}`}>Area per sqft :</label>
                  <InputText className='w-100'
                    id={`areaPerSqft${parkingIndex}`}
                    placeholder="Area per sqft"
                    name={`park.${parkingIndex}.area`}
                    {...formik.getFieldProps(`park.${parkingIndex}.area`)}
               
                  />
                   {Boolean(formik.touched?.park && formik.touched?.park?.[parkingIndex]?.area && formik.errors?.park?.[parkingIndex] && formik.errors?.park?.[parkingIndex]?.area ) && (
                          <small className="p-error">{formik.errors?.park?.[parkingIndex]?.area}</small>
                      )}
                      {console.log(parkingIndex, formik.errors?.park?.[parkingIndex]?.area)}
              
                </div>

              </div>
            </div>

          </div>

        ))}
      </div>


    </div>
  );
}
