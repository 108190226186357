import React, { useEffect, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import PostList from "./data/PostList";
import AnnouncementList from "./data/AnnouncementList";
import PollList from "./data/PollList";
import DiscussionList from "./data/DiscussionList";
import SocietyHeader from "../../components/societyHeader";
import logo1 from "../../assets/images/hamptom.png";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom/cjs/react-router-dom";
import TableReportedComments from "./data/TableReportedComments";
import TableReportePost from "./data/TableReportePost";
import SocietyHeaderView from "../../components/societyHeaderView";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { listAllPost } from "../../utils/asyncFun";

export default function CommunityManagement(props) {
  const { id } = useParams();
  const {society, image} = JSON.parse(localStorage.getItem("society_info"))

  const history = useHistory();
  const home = { icon: "pi pi-home" };

  const items = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "Manage Community" },
  ];

  const params = useParams();
  const [activeTab, setActiveTab] = useState([
    {
      index: 0,
      type: "post",
      active: true
    },
    {
        index: 1,
        type: "announcement",
        active: false
    },
    {
        index: 2,
        type: "poll",
        active: false
    },
    {
        index: 3,
        type: "reported_post",
        active: false
    },
    {
        index: 4,
        type: "comment",
        active: false
    },
  ]);

  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");

  // Redux-
  const dispatch = useDispatch();
  const { post, isLoading, isError } = useSelector((store) => store.data);

  useEffect(() => {
    const activeType = activeTab.find(tab => tab.active)?.type;
    dispatch(
      listAllPost({
        method: "GET",
        url: activeType === "comment" ? `listReportedComment?society_id=${params?.id}&type=${activeType}&offset=${offset}&searchText=${search}` : activeType === "reported_post" ? `listReportedPost?society_id=${params?.id}&type=post&offset=${offset}&searchText=${search}` : `listAllPost?societyId=${params?.id}&type=${activeType}&offset=${offset}`,
        data: "",
      })
    );
  }, [activeTab, offset, search]);

  const handlePageChange = (pageVal) => {
    setOffset((pageVal-1)*5)
  }
  const handleSearch = (val) => {
    setSearch(val)
  }

  return (
    <>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
      </div>

      <div className="header">
        <SocietyHeaderView
          imageUrl={decodeURIComponent(image)}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>

      <div className="card border-0 shadow-lg p-2">
        <div className="p-3 bg-body">
          <button
            onClick={() => history.goBack()}
            className="btn btn-sm btn-primary rounded-4 "
          >
            Back
          </button>
        </div>
        <TabView
          activeIndex={activeTab.findIndex(tab => tab.active)}
          onTabChange={(e) => {
            const newActiveTab = activeTab.map((tab, index) => ({
              ...tab,
              active: index === e.index
            }));
            setActiveTab(newActiveTab);
            setOffset(0)
            setSearch("")
          }}
        >
          <TabPanel header="Post List">
            <PostList post={post} id={params.id} societyId={id} image={image} society={society} onPageChange={handlePageChange} offset={offset} />
          </TabPanel>

          <TabPanel header="Announcement List">
            <AnnouncementList post={post} id={params.id} image={image} society={society} onPageChange={handlePageChange} offset={offset}/>
          </TabPanel>

          <TabPanel header="Poll List">
            <PollList post={post} id={params.id} image={image} society={society} onPageChange={handlePageChange} offset={offset} />
          </TabPanel>

          <TabPanel header="Reported Post">
            <TableReportePost post={post} id={params.id} image={image} society={society} onPageChange={handlePageChange} onSearch={handleSearch} offset={offset}/>
          </TabPanel>

          <TabPanel header="Reported Comments">
            <TableReportedComments post={post} id={params.id} image={image} society={society} onPageChange={handlePageChange} onSearch={handleSearch} offset={offset}/>
          </TabPanel>

        </TabView>
      </div>
    </>
  );
}
