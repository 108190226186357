import React from 'react'
import ManageApartmentType from './data/ManageApartmentType'

export default function ApartmentType() {
    return (
        <div>
            <div className='card p-3'>
                <div className='table-body'>
                    <ManageApartmentType />
                </div>
            </div>
        </div>
    )
}
