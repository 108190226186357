import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import TablePreAprvGuest from "./components/TablePreAprvGuest";
import TablePreAprvDelievery from "./components/TablePreAprvDelievery";
import TablePreAprvVisiting from "./components/TablePreAprvVisiting";
import TablePreAprvGuestP from "./components/TablePreAprvGuestP";
import TableGateEntry from "./components/TableGateEntry";

export default function AllRequestType(props) {
  // console.log(props);

  console.log(props);
  const { id } = props;
  return (
    <div className="card border-0 shadow-lg">
      <TabView>

        <TabPanel header="Pre Approve Guests">
          <TablePreAprvGuest id={id} />
        </TabPanel>
        
        <TabPanel header="Pre Approve Deliveries">
          <TablePreAprvDelievery id={id} />
        </TabPanel>

        <TabPanel header="Pre Approve Visiting Help">
          <TablePreAprvVisiting id={id} />
        </TabPanel>

        <TabPanel header="Gate Entry">
          <TableGateEntry id={id} />
        </TabPanel>


        {/* <TabPanel header="Pre Approve Guest Parking">
          <TablePreAprvGuestP />
        </TabPanel> */}

      </TabView>
    </div>
  );
}
