import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';



export default function WingList() {


    const [cities, setCities] = useState([]);

    const onCityChange = (e) => {
        let selectedCities = [...cities];
        if (e.checked)
            selectedCities.push(e.value);
        else
            selectedCities.splice(selectedCities.indexOf(e.value), 1);

        setCities(selectedCities);
    }

    return (
        <div
            className='card border-0 shadow-lg p-4' >
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: '30px' }}>
                <i
                    className="pi pi-check-circle"
                    style={{ fontSize: '25px' }}
                />
                <span style={{ marginLeft: '20px', fontSize: 'lg', fontWeight: '700' }}>
                    Wings List
                </span>
            </div>

            <div >
                <div className="p-col-12">
                    <Checkbox inputId="cb1" value="A Wing" onChange={onCityChange} checked={cities.includes('A Wing')}></Checkbox>
                    <label htmlFor="cb1" className="p-checkbox-label p-ml-2">A Wing</label>
                </div>
                <div className="p-col-12">
                    <Checkbox inputId="cb2" value="B Wing" onChange={onCityChange} checked={cities.includes('B Wing')}></Checkbox>
                    <label htmlFor="cb2" className="p-checkbox-label p-ml-2">B Wing</label>
                </div>
                <div className="p-col-12">
                    <Checkbox inputId="cb3" value="C Wing" onChange={onCityChange} checked={cities.includes('C Wing')}></Checkbox>
                    <label htmlFor="cb3" className="p-checkbox-label p-ml-2">C Wing</label>
                </div>
                <div className="p-col-12">
                    <Checkbox inputId="cb4" value="D Wing" onChange={onCityChange} checked={cities.includes('D Wing')}></Checkbox>
                    <label htmlFor="cb4" className="p-checkbox-label p-ml-2">D Wing</label>
                </div>


                {/* View Selected List Button */}
                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                    <Link to="/admin/apartment-lists">
                        <Button className="custom_btn" label="View Selected List" />
                    </Link>
                </div>
            </div>
        </div>
    )
}