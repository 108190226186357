import React, { useEffect, useState } from 'react'
import SocietyData from './components/SocietyData';
import Wings from './components/Wings';
import Parkingdetails from './components/ParkingDetails';
import PlatformSettings from './components/PlatformSetting';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getsocietydetail } from '../../utils/asyncFun';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

export default function AddSociety() {

    const [currentStep, setCurrentStep] = useState(1);

      //SocietyData validation 
      const societyDataSchema = Yup.object().shape({
        image : Yup.mixed()
        .required('please select an image')  
        .test('fileType', 'invalid file type', (value) => {
          if (value) {
            const allowedFileTypes = ['image/jpeg', 'image/png']; 
            return allowedFileTypes.includes(value.type);
          }
          return true; // Allow empty files
        })
        .test('fileSize', 'file size is too large', (value) => {
          if (value) {
            const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
            return value.size <= maxSizeInBytes;
          }
          return true; // Allow empty files
        }),
        title: Yup.string().required("first name is required"),
        first_name: Yup.string().required("first name is required"),
        last_name:Yup.string().required("last name is required"),
        email: Yup.string()
        .email('invalid email address')
        .required('email is required'),
        phone_number: Yup.string()
        .required('phone number is required')
        .test('phone_number', 'invalid phone number', (value) => {
          const phoneRegex = /^[0-9]{10}$/; 
          return phoneRegex.test(value);
        }),
        status :Yup.string().required("status is required"),
        society_name :Yup.string().required("society name is required"),
        society_address :Yup.string().required("society address is required")
    });

    const societyDataFormik = useFormik({
        initialValues: {
            image :"",
            title : "",
            first_name: "",
            last_name:"",
            email :"",
            phone_number :"",
            status :"",
            society_name :"",
            society_address :""
        },
        validationSchema : societyDataSchema
    });



    const wingListSchema = Yup.object().shape({
        number: Yup.number().required("wing number is required").min(1, "minimum 1 wing is required"),
        wings: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required("name is required"),
                contact_number: Yup.string()
                    .test('is-valid-phone-number', 'invalid phone number', function (value) {
                    if (this.parent.contact_number && this.parent.contact_number.trim().length > 0) {
                        return /^[0-9]{10}$/.test(value);
                    }
                    return true; // No validation if contact_number is not present or empty
                    })
                    .notRequired(),
                })
                ),
    });

    const wingListFormik = useFormik({
        initialValues: {
            number: 6,
            wings: [
                {
                    name : "",
                    contact_number :""
                },
                {
                    name : "",
                    contact_number :""
                },
                {
                    name : "",
                    contact_number :""
                },
                {
                    name : "",
                    contact_number :""
                },
                {
                    name : "",
                    contact_number :""
                },
                {
                    name : "",
                    contact_number :""
                },
            ],
        },
        validationSchema: wingListSchema
    });


    const parkingSchema = Yup.object().shape({
        number: Yup.number().required("park number is required").min(1, "minimum 1 wing is required"),
        park: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required("Parking is required"),
                area: Yup.number().required("Area is required"),
                    // .test('is-valid-phone-number', 'invalid phone number', function (value) {
                    // if (this.parent.contact_number && this.parent.contact_number.trim().length > 0) {
                    //     return /^[0-9]{10}$/.test(value);
                    // }
                    // return true; // No validation if contact_number is not present or empty
                    // })
                    // .notRequired(),
                })
                ),
    });

    const parkingFormik= useFormik({
        initialValues: {
            number: 6,
            park: [
                {
                    name : "",
                    area :""
                },
                {
                    name : "",
                    area :""
                },
                {
                    name : "",
                    area :""
                },
                {
                    name : "",
                    area :""
                },
                {
                    name : "",
                    area :""
                },
                {
                    name : "",
                    area :""
                },
            ],
        },
        validationSchema: parkingSchema
    });


    const platformSchema = Yup.object().shape({
    
                fee: Yup.number().required("Platform fee is required"),
  
                    // .test('is-valid-phone-number', 'invalid phone number', function (value) {
                    // if (this.parent.contact_number && this.parent.contact_number.trim().length > 0) {
                    //     return /^[0-9]{10}$/.test(value);
                    // }
                    // return true; // No validation if contact_number is not present or empty
                    // })
                    // .notRequired(),
                });
   

    const platformFormik = useFormik({
        initialValues: {
            fee :"",
          
        },
        validationSchema : platformSchema
    });




    const handleNext = () => {

        // setCurrentStep(currentStep + 1);
        console.log("working")
        var currentFormik ;
        if(currentStep === 1 ){
            currentFormik = societyDataFormik
            currentFormik.setTouched(
                Object.keys(currentFormik.values).reduce((acc , fieldName) => {
                    acc[fieldName] = true
                    return acc
                },
                {}
                )
            )
    
        }
        else if (currentStep ===2 ){
            currentFormik = wingListFormik
            currentFormik.setTouched(
                Object.keys(currentFormik.values).reduce((acc , fieldName) => {
                
                    if(fieldName === "wings"){
                        const wingsTouch = []
                        console.log('currentFormik.values',currentFormik.values)
                        for (let index = 0; index < currentFormik.values.wings.length; index++) {
                            wingsTouch.push({
                                name : true,
                                contact_number : true
                            })
                        }

                        acc[fieldName] = wingsTouch
                    }else {
                        acc[fieldName] = true
                    }
                    return acc
                },
                {}
                )
            )
        }else if(currentStep === 3){

            currentFormik = parkingFormik
            currentFormik.setTouched(
                Object.keys(currentFormik.values).reduce((acc , fieldName) => {
                
                    if(fieldName === "park"){
                        const parkTouch = []
                        console.log('currentFormik.values',currentFormik.values)
                        for (let index = 0; index < currentFormik.values.park.length; index++) {
                            parkTouch.push({
                                name : true,
                                area : true
                            })
                        }

                        acc[fieldName] = parkTouch
                    }else {
                        acc[fieldName] = true
                    }
                    return acc
                },
                {}
                )
            )


            
        }


        currentFormik.validateForm().then((errors) => {
          // Check if there are any validation errors
          console.log("errors" , errors)
          if (Object.keys(errors).length === 0) {
            // If no errors, proceed to the next step
            setCurrentStep(currentStep + 1);
          }
          
        console.log("values" , currentFormik.value)
        });
        
        
    };


    const handleBack = () => {
        setCurrentStep(currentStep - 1);
    };


    // Redux code-
    const {id} = useParams();
    const dispatch = useDispatch();
    const stores = useSelector((store) => store);

    useEffect(() => {
        dispatch(getsocietydetail({ method: "GET", url: `getsocietydetail?_id=${id}`, data: "" }));
    }, []);

    const {getsocietydetaildata, isLoading, isError} = stores.data

    return (
        <div className='card p-4'>

            {currentStep === 1 && (

                <SocietyData  formik={societyDataFormik} getsocietydetaildata={getsocietydetaildata}/>
            )}

            {currentStep === 2 && (
                <Wings  formik = {wingListFormik}/>

            )}

            {currentStep === 3 && (
                <Parkingdetails formik={parkingFormik} />

            )}


            {currentStep === 4 && (
                <PlatformSettings  formik={platformFormik}/>

            )}

{/* 
            <div className='d-flex align-items-center justify-content-end gap-2'>

                {currentStep > 1 && (
                    <button className='btn btn-primary' onClick={handleBack}>
                        Back
                    </button>
                )}
                {currentStep <= 3? (
                    <button className='btn btn-outline-primary' rounded={3} onClick={handleNext}>
                        Next
                    </button>
                ) : (
                    <button className='btn btn-outlined-primary' variant="brand" rounded={3}  >
                        Finish
                    </button>
                )}
            </div> */}


        </div>
    )
}
