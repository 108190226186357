import React from "react";
import {
  MdApartment,
  MdDeleteOutline,
  MdOutlineEdit,
  MdLocalParking,
} from "react-icons/md";
import { FaCar } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Loading from "../../ManageApp/data/Loading";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";

import { useFormik } from "formik";
import * as Yup from "yup";
import { classNames } from "primereact/utils";

const Parking = (props) => {
  const history = useHistory();
  const [data, setdata] = useState([]);
  const [visible, setVisible] = useState(false);
  const { occupyparking, unoccupyparking, id, counter } = props;

  const token = localStorage.getItem("token");
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // const [visible, setVisible] = useState(false);

  const [IDE, setID] = useState("");
  const [editslot, setslot] = useState("");
  const [editstatus, setstatus] = useState("");

  //   const [occupyparking,setoccupyparking]=useState("");
  //  const[unoccupyparking,setunoccupyparking]=useState("");

  // --fil-Upload-
  const [uploadedFiles, setUploadedFiles] = useState([]);
  //   const token = localStorage.getItem("token");
  const [file, setFile] = useState();
  const [file2, setFile2] = useState();

  const [selectedFile, setSelectedFile] = useState(null);

  // State to control the visibility of the button
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const [visibleRight, setVisibleRight] = useState(false);

  const [numeratorStr, denominatorStr] = counter
    .toString()
    .split("/")
    .map(Number);

  // Convert numerator and denominator strings to numbers
  const numerat = parseInt(numeratorStr);
  const denominat = parseInt(denominatorStr);
  const [numerator, setNumerator] = useState(numerat);
  const [denominator, setDenominator] = useState(denominat);

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);

  //     useEffect(() => {
  //         datafetch();
  //        }, []);

  //     function datafetch(){
  //         // // data fetching here
  //         // let config = {
  //         //     method: 'get',
  //         //     maxBodyLength: Infinity,
  //         //     url: `${process.env.REACT_APP_BASE_API}getwings`,
  //         //     headers: { }
  //         //   };
  //         //   axios.request(config)
  //         //   .then((response) => {
  //         //     // console.log(JSON.stringify(response.data));
  //         //     console.log(response.data);
  //         //     setdata(response.data);

  //         //   })
  //         //   .catch((error) => {
  //         //     console.log(error);
  //         //   });

  //           const baseURL = `${process.env.REACT_APP_BASE_API}getwings`; // The base URL

  // // Define query parameters
  // const myParams = {

  //     society_id:id,
  // };

  // // Send the GET request with the query parameters using the `params` option
  // axios
  //     .get(baseURL, { params: myParams })
  //     .then((response) => {
  //         console.log(id);
  //         console.log(response.data);
  //         setdata(response.data);
  //         // console.log(data)

  //     })
  //     .catch((error) => {
  //         console.error(error);
  //     });
  // }

  useEffect(() => {
    datafetch();
  }, [currentPage]); // Fetch data whenever currentPage changes

  const datafetch = () => {
    // setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getParkingAcctoSocity`,
      headers: { Authorization: "Bearer " + token },
      params: { limit, offset, society_id: id },
    };

    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.count);
        console.log(response.data);
        setdata(response.data);
        setIsLoading(false);
        console.log(id);
        console.log(response.data.data.length);
        setentry(response.data.data.length + offset);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const validationSchema = Yup.object().shape({
    slotname: Yup.string().required("Slot name is required"),
    status: Yup.string().required("Status is required"),
  });

  const formik = useFormik({
    initialValues: {
      slotname: "",
      status: "vacant",
    },
    validationSchema: validationSchema,
  });

  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const deleteconfirm = (ID) => {
    console.log("delete");
    console.log(ID);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_API}deleteParkingSlot/` + ID,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        };
        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            datafetch();
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    });
  };

  const [displayResponsive, setDisplayResponsive] = useState(false);

  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const renderFooter = (name) => {
    return (
      <div>
        {/* <Button
          label="No"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => onHide(name)}
          autoFocus
        /> */}
        <Button
          label="Close"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          autoFocus
          className="p-button-text"
        />
      </div>
    );
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile2(event.target.files[0]);
    // Get the first file from the selected files array
    setSelectedFile(file); // Update the state with the selected file
    setIsDisabled(!isDisabled);
    setIsButtonVisible(true);
  };
  const clearSelectedFile = () => {
    console.log(file2);
    setSelectedFile(null); // Reset the state to null
    setIsDisabled(!isDisabled);
    setIsButtonVisible(false);
  };
  const [isDisabled, setIsDisabled] = useState(true); // Initially disabled

  function handleSubmit() {
    // event.preventDefault();
    // const url = `${process.env.REACT_APP_BASE_API}uploadappartment`;
    const url = `${process.env.REACT_APP_BASE_API}uploadParkingCsv`;

    const formData = new FormData();
    formData.append("society_id", id);
    formData.append("parking_csv", file2);
    // formData.append('fileName', file.name);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response.data);
        // alert("file Uploaded success!");

        setSelectedFile(null); // Reset the state to null
        setIsDisabled(!isDisabled);
        setIsButtonVisible(false);
        datafetch();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "CSV Upload Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        onHide("displayResponsive");
      })
      .catch((error) => {
        //handle error
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Network error",
        });
        onHide("displayResponsive");
      });
  }

  const handleOnClick = () => {
    console.log("working");
    formik.setTouched(
      Object.keys(formik.values).reduce((acc, fieldName) => {
        acc[fieldName] = true;
        return acc;
      }, {})
    );
    formik.validateForm().then((errors) => {
      // Check if there are any validation errors
      console.log("errors", errors);
      if (Object.keys(errors).length === 0) {
        // If no errors, proceed to the next step
        console.log("values", formik.values);
        const payload = formik.values;
        console.log(payload);
        let formData = new FormData();
        formData.append("slotName", payload.slotname);
        formData.append("status", payload.status);
        formData.append("society_id", id);

        axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_API}addParkingSlot`,
          data: formData, // you are sending body instead
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            console.log(response);

            let status = response.data.data.status;
            // Output the numerator and denominator
            console.log("Numerator:", numerator);
            console.log("Denominator:", denominator);

            if (status === "vacant") {
              // Parking slot is vacant, perform actions accordingly
              console.log("Parking slot is vacant");
              // numerator+=1;
              setNumerator(numerator + 1);

              // You can update the UI or perform other operations for a vacant slot
            } else if (status === "occupy") {
              // Parking slot is occupied, perform actions accordingly
              console.log("Parking slot is occupied");
              setDenominator(denominator + 1);
              // denominator+=1;
              // You can update the UI or perform other operations for an occupied slot
            }
            formik.resetForm()

            setVisibleRight(false);
            datafetch();
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Parking added successfully",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((error) => {
            console.log("error", error);
            setVisibleRight(false);
            Swal.fire("Error", "Failed to add data", "error");
          });
      }
    });
  };

  const editPost = (data) => {
    console.log(data);
    // setID(val.id);
    // settype(val.type);
    // setques(val.question);
    // setanswer(val.answer);

    // setVisible(true);
    if (data) {
      setID(data._id);
      setstatus(data.status);
      setslot(data.slotName);

      setVisible(true);
      editformik.setValues({
        editslot: data.slotName,
        editstatus: data.status,
      });
    } else {
      console.error("ID not found in data object:", data);
    }
  };

  // Define Yup schema for edit form validation
  const editValidationSchema = Yup.object().shape({
    editstatus: Yup.string().required("Type is required"),
    editslot: Yup.string().required("Slot name is required"),
  });

  // Initialize Formik for edit form
  const editformik = useFormik({
    initialValues: {
      editstatus: editstatus,
      editslot: editslot,
    },
    validationSchema: editValidationSchema,
    // onSubmit: confirmAndHandleEdit,
    onSubmit: handleOnEdit, // assuming handleOnEdit is your submit handler function
  });

  function handleOnEdit() {
    setVisible(false);
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        // Proceed with the edit action
        executeEdit();
      } else if (result.isDenied) {
        // Do nothing or provide feedback
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  function executeEdit() {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_API}updateParkingSlot?_id=${IDE}`,
        {
          slotName: editformik.values.editslot,
          status: editformik.values.editstatus,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setVisible(false);
        datafetch();
        Swal.fire("Saved!", "", "success");
      })
      .catch((error) => {
        console.error(error);
        Swal.fire("Error", "Failed to update", "error");
      });
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Sidebar
        className="right-sidebar"
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
      >
        <h5>Edit Parking Slot</h5>
        <div class="sidebar-main">
          <form onSubmit={editformik.handleSubmit}>
            <div class="mb-3">
              <label for="chargeName" name="edittype" class="form-label">
                {" "}
                Edit Status
                <select
                  name="editstatus"
                  class="form-control"
                  //  className={classNames({
                  //             "input-invalid": Boolean(formik.touched.type && formik.errors.type),
                  //         }, "form-control")}
                  //         {...formik.getFieldProps("type")}
                  className={classNames(
                    {
                      "input-invalid": Boolean(
                        editformik.touched.editstatus &&
                          editformik.errors.editstatus
                      ),
                    },
                    "form-control"
                  )}
                  autocomplete="off"
                  value={editformik.values.editstatus}
                  // value={edittype}
                  // onChange={(e) => settype(e.target.value)}
                  onChange={editformik.handleChange}
                  onBlur={editformik.handleBlur}
                  disabled
                >
                  {" "}
                  <option value="">Select Status</option>
                  <option value="occupy">occupy</option>
                  <option value="vacant">vacant</option>
                </select>
                {/* {Boolean(formik.touched.type && formik.errors.type) && (
                                <small className="p-error">{formik.errors.type}</small>
                            )} */}
                {editformik.touched.editstatus &&
                  editformik.errors.editstatus && (
                    <small className="p-error">
                      {editformik.errors.editstatus}
                    </small>
                  )}
              </label>
              <br />

              <label for="chargeName" class="form-label">
                Edit Slot Name{" "}
              </label>
              <input
                type="text"
                class="form-control"
                id="chargeName"
                name="editslot"
                placeholder="Enter Slot name"
                className={classNames(
                  {
                    "input-invalid": Boolean(
                      editformik.touched.editslot && editformik.errors.editslot
                    ),
                  },
                  "form-control"
                )}
                //  className={classNames({
                //         "input-invalid": Boolean(formik.touched.question && formik.errors.question),
                //     }, "form-control")}
                //     {...formik.getFieldProps("question")}
                autocomplete="off"
                // value={editques}
                // onChange={(e) => setques(e.target.value)}
                // autoComplete="off"
                value={editformik.values.editslot}
                onChange={editformik.handleChange}
                onBlur={editformik.handleBlur}
              />
              {editformik.touched.editslot && editformik.errors.editslot && (
                <small className="p-error">{editformik.errors.editslot}</small>
              )}
              {/* {Boolean(formik.touched.question && formik.errors.question) && (
                                <small className="p-error">{formik.errors.question}</small>
              )} */}
            </div>

            <div className="d-flex footer gap-2 align-items-center">
              <button type="submit" class="btn btn-primary rounded-4">
                Edit
              </button>
            </div>
          </form>
        </div>
      </Sidebar>

      <Sidebar
        className="right-sidebar"
        visible={visibleRight}
        position="right"
        onHide={() => {setVisibleRight(false);formik.resetForm()}}
      >
        <h5>Add Parking Slot</h5>
        <div className="sidebar-main">
          <form>
            <div class="mb-3">
              <label for="chargeName" name="type" class="form-label">
                {" "}
                Status

                {console.log("formik.values.status",formik.values)}
                <select
                  name="type"
                  class="form-control"
                  className={classNames(
                    {
                      "input-invalid": Boolean(
                        formik.touched.status && formik.errors.status
                      ),
                    },
                    "form-control"
                  )}
                  {...formik.getFieldProps("status")}
                >
                  {/* <option value="">Select Status</option> */}
                  <option value="vacant">vacant</option> 
                  {/* <option value="occupy" >occupy</option> */}
                </select>
                {Boolean(formik.touched.status && formik.errors.status) && (
                  <small className="p-error">{formik.errors.status}</small>
                )}
              </label>
              <br />
            </div>

            <div class="mb-3">
              <label htmlFor="chargeName" class="form-label">
                Parking Slot Name
              </label>
              <input
                type="text"
                id="chargeName"
                autoComplete="off"
                placeholder="Enter slot name"
                className={classNames(
                  {
                    "input-invalid": Boolean(
                      formik.touched.slotname && formik.errors.slotname
                    ),
                  },
                  "form-control"
                )}
                name="slotname"
                {...formik.getFieldProps("slotname")}
              />
              {Boolean(formik.touched.slotname && formik.errors.slotname) && (
                <small className="p-error">{formik.errors.slotname}</small>
              )}
            </div>
          </form>
        </div>

        <div className="d-flex footer gap-2 align-items-center">
          <button
            type="submit"
            class="btn btn-primary rounded-4"
            onClick={handleOnClick}
          >
            Submit
          </button>
        </div>
      </Sidebar>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center">
          {/* <Link to= ""
                    // "/manage-society" 
                    className='btn me-2 btn-sm btn-primary rounded-4 '>Back</Link> */}
          <button
            onClick={() =>
              history.goBack({
                data: {
                  occupyparking: numerator,
                  unoccupyparking: denominator,
                },
              })
            }
            className="btn me-2 btn-sm btn-primary rounded-4 "
          >
            Back
          </button>
          <h5 className="mb-0 fs-6 d-flex align-item-center gap-2">
            <FaCar /> All Parking Slots
          </h5>
        </div>

        <div className="ms-auto d-flex align-items-center gap-2">
          <div>
            <Button
              label="Upload Parking CSV"
              className="p-button-sm p-button-rounded"
              icon="pi pi-external-link"
              onClick={() => onClick("displayResponsive")}
            />

            {/* <Toast visible={toastVisible} severity={toastSeverity} summary={toastSummary} detail={toastDetail} /> */}
            <Dialog
              header="Upload Parking CSV"
              visible={displayResponsive}
              onHide={() => onHide("displayResponsive")}
              breakpoints={{ "960px": "75vw" }}
              style={{ width: "50vw" }}
              footer={renderFooter("displayResponsive")}
            >
              <div>
                {/* <a href="" className='btn btn-sm btn-primary d-block ms-auto mb-2' style={{width: '30%'}} download>Download Society Import Sample CSV</a> */}
                {/* <FileUpload
                name="doc"
                url={`${process.env.REACT_APP_BASE_API}uploadappartment`}
                onUpload={onUpload}
                onClick={fileHandler}
                accept="pdf/*"
                maxFileSize={1000000}  // 1 MB
              />        
                <div>
                {uploadedFiles.length > 0 && (
                  <div>
                    <ul>
                      {uploadedFiles.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div> */}

                {/* <form onSubmit={handleSubmit}> */}
                {/* <h3> File Upload</h3> */}

                <a
                  // href="https://betazone.promaticstechnologies.com/r-community-rest-apis/public/apartment-sample-csv.csv"
                  href={`${process.env.REACT_APP_BASE_IMAGE_URL}r-community-rest-apis/public/parkingcsv.csv`}
                  className="btn btn-sm btn-primary d-block ms-auto mb-2"
                  style={{ width: "50%" }}
                  download
                >
                  Download Parking Slot Import Sample CSV
                </a>

                <div className="p-fileupload p-fileupload-advanced p-component">
                  <div className="p-fileupload-buttonbar">
                    <label
                      for="upload-apartment"
                      className="p-button p-fileupload-choose p-component "
                      tabIndex={0}
                    >
                      {!selectedFile && ( // Render the file input if no file is selected
                        <input
                          className="upload-apartment-csv"
                          type="file"
                          name="apartment_csv"
                          accept=".csv"
                          // className='btn btn-primary rounded-5 text-capitalize'
                          // onChange={handleChange}
                          id="upload-apartment"
                          onChange={handleFileChange}
                        />
                      )}

                      <span className="p-button-icon p-button-icon-left p-clickable pi pi-fw pi-plus"></span>
                      <span className="p-button-label p-clickable">choose</span>
                    </label>
                    <button
                      // className="p-button p-component p-disabled"
                      className="p-button p-component"
                      disabled={isDisabled}
                      onClick={handleSubmit}
                    >
                      <span class="p-button-icon p-c p-button-icon-left pi pi-upload"></span>
                      <span class="p-button-label p-c">Upload</span>
                    </button>
                    <button
                      type="button"
                      // class="p-button p-component p-disabled"
                      class="p-button p-component"
                      disabled={isDisabled}
                      onClick={clearSelectedFile}
                    >
                      <span class="p-button-icon p-c p-button-icon-left pi pi-times"></span>
                      <span class="p-button-label p-c">Cancel</span>
                    </button>
                  </div>
                  <div className="p-fileupload-content">
                    <div className="p-fileupload-files">
                      <div className="p-fileupload-row">
                        {/* <div class="p-fileupload-filename">appartment2.csv</div>
                        <div>154 B</div> */}
                        <div class="p-fileupload-filename">
                          {selectedFile && <p> {selectedFile.name}</p>}
                        </div>

                        <div>
                          {isButtonVisible && (
                            <button
                              type="button"
                              class="p-button p-component p-button-icon-only"
                              onClick={clearSelectedFile}
                            >
                              <span class="p-button-icon p-c pi pi-times"></span>
                              <span class="p-button-label p-c">&nbsp;</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </form> */}
              </div>
            </Dialog>
          </div>
          <button
            onClick={() => setVisibleRight(true)}
            className="btn btn-primary rounded-5"
            style={{ marginRight: "10px" }}
          >
            Add New
          </button>
        </div>
        <br />
      </div>
      {/* 
            <Sidebar className='right-sidebar' visible={visible} position="right" onHide={() => setVisible(false)}>
                <h5>Edit Visitor</h5>
                <div className='sidebar-main'>
                   
                <form onSubmit={editformik.handleSubmit}>

<div className="mb-3">
    <label htmlFor="chargeName" className="form-label">Visitor Name</label>
    <input
        type="text"
        id="chargeName"
        name="editname"
        placeholder="Enter Visitor Name" 
        className={classNames({
            "input-invalid": Boolean(editformik.touched.editname && editformik.errors.editname),
        }, "form-control")}
        // className={classNames(
        //     "form-control",
        //     { "border-red": editformik.touched.edittitle && editformik.errors.edittitle }
        // )}
         
        autoComplete="off"
        value={editformik.values.editname}
        onChange={editformik.handleChange}
        onBlur={editformik.handleBlur}
    />

{editformik.touched.editname && editformik.errors.editname && (
        <small className="p-error">{editformik.errors.editname}</small>
    )}
</div>




</form>
                </div>

                <div className='d-flex footer gap-2 align-items-center'>
                    <button type="submit" class="btn btn-primary rounded-4" onClick={handleOnEdit}>Edit</button>
                </div>
            </Sidebar> */}
      {data.data && data.data.length > 0 ? (
        <div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col" width="5%"><input class="form-check-input" type="checkbox"/></th>
                  <th scope="col">#</th>
                  <th scope="col">SLOT NAME</th>
                  <th scope="col">VEHICLE TYPE</th>
                  <th scope="col">USER TYPE</th>
                  <th scope="col">VEHICLE NUMBER</th>
                  <th scope="col">ADDRESS</th>
                  <th scope="col">STATUS</th>
                  <th scope="col">ACTION</th>

                  {/* <th scope="col">CONTACT NO</th> */}
                  {/* <th scope="col">ACTION</th> */}
                </tr>
              </thead>
              <tbody>
                {data.data &&
                  data.data.map((data, idx) => (
                    <tr>
                      <th><input class="form-check-input" type="checkbox"/></th>
                      <th scope="row">
                        {(currentPage - 1) * pageSize + idx + 1}
                      </th>
                      {/* <td>
                    <div className="text-uppercase">-</div>
                  </td> */}
                      <td>{data?.slotName || "N/A"}</td>
                      <td>{data?.type || "N/A"}</td>
                      <td>{data?.resident_id?.role || "N/A"}</td>
                      <td>{data?.vehicle_number || "N/A"}</td>
                      <td>{data?.wing_id?.name ? `${data?.wing_id?.name}-${data?.apartment_id?.appartmentNumber}` : "N/A"}</td>
                      <td>{data?.status == "occupy" ? "Occupied":"Vacant"}</td>
                      <td>
                        {/* <button onClick={() => editPost(data)}  style={{ border: 'none', background: 'none', cursor: 'pointer' }}  ><MdOutlineEdit /></button>
                    <button onClick={() => deleteconfirm(data._id)}   style={{ border: 'none', background: 'none', cursor: 'pointer' }}><MdDeleteOutline /></button></td> */}
                        {/* <td>-</td> */}
                        <button
                          onClick={() => editPost(data)}
                          style={{
                            border: "none",
                            background: "none",
                            cursor: "pointer",
                            margin: "0",
                            paddingRight: "0px",
                          }}
                        >
                          <MdOutlineEdit />
                        </button>
                        <button
                          onClick={() => deleteconfirm(data._id)}
                          style={{
                            paddingLeft: "0px",
                            border: "none",
                            background: "none",
                            cursor: "pointer",
                            margin: "0",
                          }}
                        >
                          <MdDeleteOutline />
                        </button>
                      </td>
                    </tr>
                  ))}
                {/* <td>
                                1
                                </td>
                            <td>
                                101
                            </td>
                            <td>
                                vacant
                            </td> */}

                {/* <tr>
                            <th scope="row">1</th>
                            <td ><div className='text-uppercase'>Wing (A)</div></td>
                            <td>200</td>
                            <td>400</td>
                            <td>+91-87652-87654</td>
                            <td><div className='action_btn'>
                                <Link to="/add-society"  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr> */}
                {/* <tr>
                            <th scope="row">1</th>
                            <td ><div className='text-uppercase'>Wing (A)</div></td>
                            <td>200</td>
                            <td>400</td>
                            <td>+91-87652-87654</td>
                            <td><div className='action_btn'>
                                <Link to="/add-society"  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td ><div className='text-uppercase'>Wing (B)</div></td>
                            <td>250</td>
                            <td>240</td>
                            <td>+91-87652-87654</td>
                            <td><div className='action_btn'>
                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td ><div className='text-uppercase'>Wing (C)</div></td>
                            <td>400</td>
                            <td>100</td>
                            <td>+91-87652-87654</td>
                            <td><div className='action_btn'>
                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td ><div className='text-uppercase'>Wing (D)</div></td>
                            <td>350</td>
                            <td>210</td>
                            <td>+91-87652-87654</td>
                            <td><div className='action_btn'>
                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td ><div className='text-uppercase'>Wing (A)</div></td>
                            <td>100</td>
                            <td>120</td>
                            <td>+91-87652-87654</td>
                            <td><div className='action_btn'>
                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr> */}
              </tbody>
            </table>
          </div>
          <div className="table-footer d-md-flex justify-content-md-between align-items-center">
            <p className="mb-0">
              Showing {offsetentry + 1} to {entry} of{" "}
              <span id="total-entries">{totalItems}</span> entries
            </p>
            <nav>
              <ul className="pagination pagination-md">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>

                {pageNumbers.map((pageNumber) => {
                  // Define the minimum and maximum page numbers to display
                  const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                  const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                  if (pageNumber >= minPage && pageNumber <= maxPage) {
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                <li className="page-item">
                  <button className="page-link" onClick={nextPage}>
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <h4>No data available</h4>
      )}
      {/* <div className="table-footer d-md-flex align-items-center justify-content-md-between"> */}
      {/* <p className='mb-0'>Showing 1 to 5 of <span id='total-entries'>57</span>  entries</p>
                <ul className="pagination  pagination-md">
                    <li class="page-item"><Link className="page-link" aria-label="Previous">&laquo;</Link></li>
                    <li className="page-item"><Link className="page-link" >1</Link></li>
                    <li className="page-item active"><Link className="page-link " href="#">2</Link></li>
                    <li className="page-item"><Link className="page-link" >3</Link></li>
                    <li className="page-item"><Link className="page-link" >4</Link></li>
                    <li className="page-item"><Link className="page-link" >5</Link></li>
                    <li className="page-item"><Link className="page-link" >6</Link></li>
                    <li className="page-item"><Link className="page-link" >7</Link></li>
                    <li className="page-item"><Link className="page-link" >8</Link></li>
                    <li className="page-item"><Link className="page-link" >9</Link></li>
                    <li className="page-item"><Link className="page-link" aria-label="Next">&raquo;</Link></li>
                </ul> */}
      {/* </div> */}
      {/* <nav>
        <ul className="pagination justify-content-center">
          <li className="page-item">
            <button
              className="page-link"
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          </li>
          {pageNumbers.map((pageNumber) => (
            <li
              key={pageNumber}
              className={`page-item ${
                currentPage === pageNumber ? "active" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(pageNumber)}
              >
                {pageNumber}
              </button>
            </li>
          ))}
         
          <li className="page-item">
            <button
              className="page-link"
              onClick={nextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </li>

          
        </ul>
      </nav> */}
      {/* {pageNumbers.map(pgNumber => (
                        <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}>
                            <button className='page-link' onClick={() => setCurrentPage(pgNumber)}>
                                {pgNumber}
                            </button>
                        </li>
                    ))} */}
      {/* disabled={currentPage === nPages} */}
    </>
  );
};

export default Parking;
