import React from 'react';
import { MdApartment, MdDeleteOutline, MdOutlineAdd, MdOutlineEdit } from "react-icons/md";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loading from '../../ManageApp/data/Loading';
import { Sidebar } from 'primereact/sidebar';
import { makeApiCall } from '../../../utils/api';
import moment from 'moment';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';


const Ledgers = (props) => {
    // console.log(props)
    const history = useHistory();
    const [ledgerData, setLedgerData] = useState([]);
    const [visible, setVisible] = useState(false);
    // const { id } = props;

    const token = localStorage.getItem('token');
    const [totalItems, setTotalItems] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [pageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1);


    const [offsetentry, setoffsetentry] = useState(0);
    const [entry, setentry] = useState(0);
    const [visibleRight, setVisibleRight] = useState(false);
    const [visibleRight2, setVisibleRight2] = useState(false);

    const { id } = useParams()


    const location = useLocation();
  
    // Use URLSearchParams to extract the query parameters
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');

    console.log(type)


    // pagination

    const nextPage = () => {
        const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const totalPages = Math.ceil(totalItems / pageSize);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    async function getAmenityBooking(currentPage) {
        console.log(currentPage)
        const offset = (currentPage - 1) * pageSize;
        const limit = pageSize;
        setoffsetentry(offset);
        console.log(limit, offset)
        const params = {
            apartment_id: id,
            offset: offset,
            limit: limit,
        };

        let api=""

        if(type=="rent"){
            api="listAllRentPaymentHistory"
        }else{
            api="listAllMaintenancePaymentHistory"
        }

        const response = await makeApiCall(
            api,
            "GET",
            null,
            params,
            true,
            false,
            "",
            true
        )
        if (response.data.length > 0) {
            console.log("hello")
            console.log(response)
            setLedgerData(response?.data)
            setTotalItems(response?.count)
        }
    }

    useEffect(() => {
        // console.log(currentPage)
        getAmenityBooking(currentPage);
        // setbill(response?.data ?? []);
        setIsLoading(false);
    }, [currentPage]);


    const updateFinance = () => {
        setVisibleRight(true)
    }

    const apiLedgers = async () => {
        const params = {
            apartment_id: id
        }


        let api=""

        if(type=="rent"){
            api="listAllRentPaymentHistory"
        }else{
            api="listAllMaintenancePaymentHistory"
        }
        const response = await makeApiCall(
            api,
            "GET",
            null,
            params,
            true,
            false,
            "",
            true
        )
        if (response.data.length > 0) {
            console.log("hello")
            console.log(response)
            setLedgerData(response?.data)
            setTotalItems(response?.count)
        }
    }




    useEffect(() => {
        apiLedgers()

    }, [])

    const handelDownload = async (id) => {
        console.log(id)
        const params = {
            dataId: id
        }

        const file = await makeApiCall(
            "generatePaymentInvoice",
            "GET",
            null,
            params,
            true
        )

        console.log(file)
        if (file) {
            window.open(file.downloadLink)
        }
    }




    return (
        <>

            <Sidebar className='right-sidebar' visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                <h5>Update Bill</h5>
                <div class="sidebar-main mt-3">
                    <form>
                        <div class="mb-3">
                            <label for="chargeName" class="form-label">Apartment Floor</label>
                            <input type="text" className='form-control' placeholder='Enter Apartment Floor' />
                        </div>

                        <div class="mb-3">
                            <label for="chargeName" class="form-label">Apartment Number</label>
                            <input type="text" className='form-control' placeholder='Enter Apartment Number' />
                        </div>

                        <div class="mb-3">
                            <label for="chargeName" class="form-label">Apartment Status</label>
                            <select className='form-control'>
                                <option value="option 1">Select </option>
                                <option value="option 2">Vacant</option>
                                <option value="option 3">Occupied</option>
                            </select>
                        </div>

                    </form>
                </div>
                <div className='d-flex footer gap-2 align-items-center'>
                    <button type="submit" class="btn btn-primary rounded-4">Add</button>
                </div>
            </Sidebar>

            <div className='table_header d-flex mb-3'>
                <div className='d-flex flex-wrap align-items-center'>
                    {/* <Link to= ""
                    // "/manage-society" 
                    className='btn me-2 btn-sm btn-primary rounded-4 '>Back</Link> */}
                    <button onClick={() => history.goBack()} className='btn me-2 btn-sm btn-primary rounded-4 '>Back</button>
                    <h5 className='mb-0 fs-6 d-flex align-item-center gap-2'><MdApartment /> Finance</h5>
                </div>

            </div>

            <div>
                <div className='table-responsive'>
                    <table className="table table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">TRANSACTION NUMBER</th>
                                <th scope="col">DATE</th>
                                <th scope="col">APARTMENT NUMBER</th>
                                <th scope="col">AMOUNT</th>
                                <th scope="col">PAYMENT TYPE</th>
                                <th scope="col">BILL PERIOD</th>
                                <th scope="col">REMARK</th>
                                <th scope="col">ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                         

                            {ledgerData?.map((ele, index) => (
                                <tr>

                                    <th scope="row">{index + 1}</th>
                                    <td >{ele?.payment_mode=="Online"? ele?.razor_pay_payment_id:ele?.transaction_id}</td>
                                    <td >{moment(ele.updatedAt).format('DD-MM-YYYY')}</td>
                                    <td>{`${ele.wing_id.name} - ${ele.apartment_id?.appartmentNumber}`}</td>
                                    <td>{`₹ ${ele.amount}`}</td>
                                    <td>{ele?.payment_mode}</td>
                                    {type == "rent"?  <td>{`${ele?.bill_id?.month_rent} ${moment(ele.updatedAt).format('YYYY')}`}</td>:  <td>{`${ele?.bill_id?.month_bill} ${moment(ele.updatedAt).format('YYYY')}`}</td>}
                                 
                                    {ele?.remark ? 
                                    <td>{`${ele?.remark}`}</td>:""}
                                    <td><div className='action_btn'>
                                        <div className="d-flex">
                                            <button className='btn me-2 btn-sm btn-primary rounded-4' onClick={() => handelDownload(ele?._id)}>Download</button>
                                        </div>
                                    </div></td>
                                </tr>
                            ))}
                            {/* <tr>
                                <th scope="row">1</th>
                                <td >#100045632</td>
                                <td >01 Jan 2024</td>
                                <td>A-101</td>
                                <td>₹ 2000</td>
                                <td>Offline</td>
                                <td>Jan 2024</td>
                                <td><div className='action_btn'>
                                    <div className="d-flex">
                                        <button className='btn me-2 btn-sm btn-primary rounded-4'>Download</button>

                                    </div>
                                </div></td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td >#100045632</td>
                                <td >01 Jan 2024</td>
                                <td>A-101</td>
                                <td>₹ 2000</td>
                                <td>Offline</td>
                                <td>Jan 2024</td>
                                <td><div className='action_btn'>
                                    <div className="d-flex">
                                        <button className='btn me-2 btn-sm btn-primary rounded-4'>Download</button>

                                    </div>
                                </div></td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td >#100045632</td>
                                <td >01 Jan 2024</td>
                                <td>A-101</td>
                                <td>₹ 2000</td>
                                <td>Offline</td>
                                <td>Jan 2024</td>
                                <td><div className='action_btn'>
                                    <div className="d-flex">
                                        <button className='btn me-2 btn-sm btn-primary rounded-4'>Download</button>

                                    </div>
                                </div></td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td >#100045632</td>
                                <td >01 Jan 2024</td>
                                <td>A-101</td>
                                <td>₹ 2000</td>
                                <td>Offline</td>
                                <td>Jan 2024</td>
                                <td><div className='action_btn'>
                                    <div className="d-flex">
                                        <button className='btn me-2 btn-sm btn-primary rounded-4'>Download</button>

                                    </div>
                                </div></td>
                            </tr> */}
                            {/* <tr>
                                <th scope="row">1</th>
                                <td >#100045632</td>
                                <td >01 Jan 2024</td>
                                <td>A-101</td>
                                <td>₹ 2000</td>
                                <td>Offline</td>
                                <td>Jan 2024</td>
                                <td><div className='action_btn'>
                                    <div className="d-flex">
                                        <button className='btn me-2 btn-sm btn-primary rounded-4'>Download</button>

                                    </div>
                                </div></td> */}
                            {/* </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td >#100045632</td>
                                <td >01 Jan 2024</td>
                                <td>A-101</td>
                                <td>₹ 2000</td>
                                <td>Offline</td>
                                <td>Jan 2024</td>
                                <td><div className='action_btn'>
                                    <div className="d-flex">
                                        <button className='btn me-2 btn-sm btn-primary rounded-4'>Download</button>

                                    </div>
                                </div></td>
                            </tr> */}
                        </tbody>

                    </table>
                </div>



                <div className="table-footer d-md-flex justify-content-md-between align-items-center">
                    <p className="mb-0">
                        Showing {offsetentry + 1} to {offsetentry + ledgerData.length} of{" "}
                        <span id="total-entries">{totalItems}</span> entries
                    </p>
                    <nav>
                        <ul className="pagination pagination-md">
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={prevPage}
                                    disabled={currentPage === 1}
                                >
                                    &laquo;
                                </button>
                            </li>

                            {pageNumbers.map((pageNumber) => {
                                // Define the minimum and maximum page numbers to display
                                const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                                const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                                if (pageNumber >= minPage && pageNumber <= maxPage) {
                                    return (
                                        <li
                                            key={pageNumber}
                                            className={`page-item ${currentPage === pageNumber ? "active" : ""
                                                }`}
                                        >
                                            <button
                                                className="page-link"
                                                onClick={() => setCurrentPage(pageNumber)}
                                            >
                                                {pageNumber}
                                            </button>
                                        </li>
                                    );
                                }
                                return null;
                            })}
                            <li className="page-item">
                                <button className="page-link" onClick={nextPage}>
                                    &raquo;
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>

            </div>


        </>


    );
};

export default Ledgers;

