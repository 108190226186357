import axios from "axios";
import moment from "moment";
import "moment-duration-format";
import Swal from "sweetalert2";

export const makeApiCall = async (
  endpoint,
  method = "GET",
  body = {},
  queryParams = {},
  authentication = false,
  formData = false,
  message,
  toggleMSg = true,
  errormsg = true
) => {
  try {
    const config = {
      method: method.toUpperCase(),
      url: `${process.env.REACT_APP_BASE_API}${endpoint}`,
      params: queryParams,
      data: body,
      headers: {},
    };

    if (formData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }

    if (authentication) {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "token"
      )}`;
    }

    const response = await axios(config);

    console.log("response1", response.data);

    if (method != "GET" && toggleMSg) {
      Swal.fire(message, "", "success");
    }

    if (
      response.data.code === 400 ||
      response.data.code === 409 ||
      response.data.code === 403 ||
      response.data.code === 401 ||
      response.data.code === 402
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response.data.message,
      });
    }

    return response.data;
  } catch (error) {
    console.log("error",error?.response)
    if (error?.response?.data === "Unauthorized") {
      localStorage.removeItem("token");
    }

    if (errormsg) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Network Error",
      });
    }
  }
};
