import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="d-flex flex-md-row flex-column justify-content-center justify-content-md-between w-100 p-2 px-4 mt-3">
      <p className="mb-0 fs-6 text-md-start text-center">
        {/* © 2024 */}
        {"©" + new Date().getFullYear()}
        {/* {console.log(new Date().getFullYear())} */}
        <span className="chakra-text css-1ckssb9">
          {" "}
          R-Community. All Rights Reserved.
        </span>
      </p>

      {/* <ul className="list-unstyled d-flex justify-content-md-start justify-content-center gap-md-4 gap-2 mb-0">
                <li className="nav-item">
                    <Link className="nav-link fs-6" to="/support">
                        Support
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link fs-6" to="/license">
                        License 
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link fs-6" to="/terms-of-use">
                        Terms of Use
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link fs-6" to="/blog">
                        Blog
                    </Link>
                </li>
            </ul> */}
    </div>
  );
}
