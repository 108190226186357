import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useParams } from 'react-router-dom'; 
import "../assets/css/login.css";
import axios from 'axios';
import Swal from 'sweetalert2';
import { useState } from "react";
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// import { Password } from "primereact/password";



import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Link } from "react-router-dom";
export default function ConfirmPassword() {
  const [showPassword, setShowPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
  const { id } = useParams();
  console.log("ID from URL:", id);




  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const validationSchema = Yup.object().shape({

    // name: Yup.string().required("name is required"),
    // email: Yup.string().email('invalid email address') .required('email is required'),

    // first_name: Yup.string().required("First Name is required"),
    // last_name: Yup.string().required("Last Name is required"),

    password: Yup.string().matches(passwordRules, { message: "Please create a stronger password" }).required("Required"),
  confirmpassword: Yup
    .string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
    // aboutme: Yup.string().required("About me is required"),
    // image : Yup.mixed()
    // .required('please select an image')  
    // .test('fileType', 'invalid file type', (value) => {
    // if (value) {
    //   const allowedFileTypes = ['image/jpeg', 'image/png','image/jpg']; 
    //   return allowedFileTypes.includes(value.type);
    // }
    // return true; // Allow empty files
    // })
    // .test('fileSize', 'file size is too large', (value) => {
    // if (value) {
    //   const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    //   return value.size <= maxSizeInBytes;
    // }
    // return true; // Allow empty files
    // }),
     

  
});




const formik = useFormik({
    initialValues: {
        // name:"",
        // email:"",
        // first_name:"",
        // last_name:"",
        password:"",
        confirmpassword:"",
        // aboutme:"",
        // image :"", 


    },
    validationSchema : validationSchema
});

// const handleOnClick = () => {
//   console.log("working")       
//   formik.setTouched(
//       Object.keys(formik.values).reduce((acc , fieldName) => {
//           acc[fieldName] = true
//           return acc;
//       },
//       {}
//       )
//   )
//   formik.validateForm().then((errors) => {
//     // Check if there are any validation errors
//     console.log("errors" , errors)
//     if (Object.keys(errors).length === 0) {
//       // If no errors, proceed to the next step
//       console.log("values" , formik.values)
//       const payload=formik.values;
//       console.log(payload)
   
//       let data = new FormData();
// // data.append('_id', '658bc9f068b695042ab18219');
// // data.append('name',data.name );
// // data.append('email', 'DEFG');
// // data.append('last_name', 'DEFG');


// data.append('password', payload.password);
// // data.append('password', payload.confirmpassword);


// let config = {
//   method: 'post',
//   maxBodyLength: Infinity,
//   url: `${process.env.REACT_APP_BASE_API}changePassword`,
//   headers: { 
//     'Authorization': 'Bearer '+ token
//   },
//   data : data
// };

// axios.request(config)
// .then((response) => {
//   console.log(JSON.stringify(response.data));
  
// })
// .catch((error) => {
//   console.log(error);
//   Swal.fire({
//     icon: "error",
//     title: "Oops...",
//     text: "Something went wrong!",

//   });
// });

  
//     }       
  
  
// })




// };

  //   const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  // const ForgotSchema = Yup.object().shape({

  //   password: Yup.string().matches(passwordRules, { message: "Please create a stronger password" }).required("Required"),
  // confirmpassword: Yup
  //   .string()
  //   .oneOf([Yup.ref("password"), null], "Passwords must match")
  //   .required("Required"),
  // });

  // const formik = useFormik({
  //   initialValues: {
  //       password:"",
  //       confirmpassword:"",
      
  //   },
  //   validationSchema: ForgotSchema,
  //   onSubmit: (data) => {
  //     console.log(data);
  //     const requestData = {
  //       id: id,
  //       newPassword: data.password
  //   };
  //     axios({
  //       method: 'post',
  //       url: `${process.env.REACT_APP_BASE_API}reset-password`,
  //       requestData, // you are sending body instead
  //       headers: {
  //       //  'Authorization': `bearer ${token}`,
  //       'Content-Type': 'application/json'
  //       }, 
  //     }) .then((response) => {
  //       console.log(response);
        
  //     //   formik.setValues({
  //     //       title:"",
  //     //       carpet_area: "",
  //     //       super_builtup_area:"",
  //     //       configuration:"",
  //     //       floor:"",
  //     //  })
  //     //  setVisibleRight(false);
  //      Swal.fire({
  //       position: "Center",
  //       icon: "success",
  //       title: "Success",
  //       showConfirmButton: false,
  //       timer: 1500
  //     });
  //         //  datafetch();
  //      })
  //       .catch((error) => {
  //        console.log("error",error)
  //       //  setVisibleRight(false);
  //     //    formik.setValues({
  //     //       title:"",
  //     //       carpet_area: "",
  //     //       super_builtup_area:"",
  //     //       configuration:"",
  //     //       floor:"",
  //     //  })
  //        Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: "Something went wrong!",
    
  //         });
  //      })

  //     setTimeout(() => {
  //       formik.setSubmitting(false);
  //     }, 2000);
  //   },
  // });

  // const { errors, touched, isSubmitting, handleSubmit } = formik;
  const handleOnClick = () => {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed to save changes
        formik.setTouched(
          Object.keys(formik.values).reduce((acc, fieldName) => {
            acc[fieldName] = true;
            return acc;
          }, {})
        );
  
        formik.validateForm().then((errors) => {
          if (Object.keys(errors).length === 0) {
            const payload = {
              id:id,
              newPassword: formik.values.password
            };
  console.log(payload)
            axios
              .post(`${process.env.REACT_APP_BASE_API}reset-password`, payload, {
                headers: {
              
                }
              })
              .then((response) => {
                console.log(response.data);
                Swal.fire("Password Changed", "", "success");
              
              })
              .catch((error) => {
                console.log(error);
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!"
                });
              });
          }
        });
      } else if (result.isDenied) {
        // User denied to save changes
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  
  return (
    <div className="form-box">
      <div className="fullHeight p-ai-center p-d-flex p-jc-center">
        <div className="shadow card m-3 px-3 py-4 px-sm-4 py-sm-5">
          <h4 className="text-center">Forgot Password</h4>
          {/* <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit} className="p-fluid">
            <div className="p-field">
                <span className="p-float-label">
                  <InputText
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": Boolean(touched.password && errors.password),
                    })}
                  />
                  <label
                    htmlFor="name"
                    className={classNames({
                      "p-error": Boolean(touched.password && errors.password),
                    })}
                  >
                  Password
                  </label>
                </span>
                {Boolean(touched.password && errors.password) && (
                  <small className="p-error">{formik.errors["password"]}</small>
                )}
              </div>

              <div className="p-field">
                <span className="p-float-label">
                  <InputText
                    id="email"
                    name="confirmpassword"
                    value={formik.values.confirmpassword}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": Boolean(touched.confirmpassword && errors.confirmpassword),
                    })}
                  />
                  <label
                    htmlFor="name"
                    className={classNames({
                      "p-error": Boolean(touched.confirmpassword && errors.confirmpassword),
                    })}
                  >
                   Confirm Password
                  </label>
                </span>
                {Boolean(touched.confirmpassword && errors.confirmpassword) && (
                  <small className="p-error">{formik.errors["confirmpassword"]}</small>
                )}
              </div>
              

              
              <div className="submitBtnBox">
        
                <Button
                  type="submit"
                  label="Submit"
                  iconPos="right"
                  loading={isSubmitting}
                  className="mt-4 submitBtn"
                  disabled={isSubmitting}
                />
              </div>
        
            </Form>
          </FormikProvider> */}

{/* 
        <div className="form-group">
            <label for="password">Password:</label>
            <input type="password" id="password" name="password"/>
        </div>
        <div className="form-group">
            <label for="confirmPassword">Confirm Password:</label>
            <input type="password" id="confirmPassword" name="confirmPassword"/>
        </div>
        <button type="submit">Submit</button> */}
    
    <div className="card-body">
                  <form>
                  {/* <hr className="my-4" /> */}
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                
                    </h6>
                    <div className="pl-lg-4">
                      <div className="row">
                        <div className="col-lg-6">
                          {/* <div className="form-group focused">
                            <label
                              className="form-control-label"
                              htmlFor="input-password"
                            >
                              Password
                            </label>
                            <input
                              type="password"
                              id="input-password"
                              name="password"
                              
                              // className="form-control form-control-alternative"
                              placeholder="Enter password"
                            // defaultValue=""
                            className={classNames({
                              "input-invalid": Boolean(formik.touched.password && formik.errors.password),
                          }, "form-control form-control-alternative")}
                          {...formik.getFieldProps("password")}
                        //   readOnly={isReadonly}
                           />


                  {Boolean(formik.touched.password && formik.errors.password) && (
                              <small className="p-error">{formik.errors.password}</small>
                          )}

                          </div> */}
                          <div className="form-group focused">
    <label className="form-control-label" htmlFor="input-password">
        Password
    </label>
    <div className="input-group">
         <input
            type={showPassword ? "text" : "password"}
            id="input-password"
            name="password"
            placeholder="Enter password"
            className={classNames({
                "input-invalid": Boolean(formik.touched.password && formik.errors.password),
            }, "form-control form-control-alternative")}
            {...formik.getFieldProps("password")}
        /> 
     
        <div className="input-group-append">
            <span className="view-pass"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
            >
                <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
            </span>
        </div>
    </div>
    {Boolean(formik.touched.password && formik.errors.password) && (
        <small className="p-error">{formik.errors.password}</small>
    )}
</div>

                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="confirm-password"
                            >
                              Confirm Password
                            </label>
                            <div className="input-group">
                            <input
                               type={showConfirmPassword ? "text" : "password"}
                              // type="password"
                              id="confirm-password"
                              name="confirmpassword"
                              
                              // className="form-control form-control-alternative"
                              placeholder="Confirm password"
                              className={classNames({
                                "input-invalid": Boolean(formik.touched.confirmpassword && formik.errors.confirmpassword),
                            }, "form-control form-control-alternative")}
                            {...formik.getFieldProps("confirmpassword")}
                            // readOnly={isReadonly}
                              />

<div className="input-group-append">
            <span className="view-pass"
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
                <i className={`fa ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
            </span>
        </div>
</div>

                    {Boolean(formik.touched.confirmpassword && formik.errors.confirmpassword) && (
                                <small className="p-error">{formik.errors.confirmpassword}</small>
                            )}

                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <hr className="my-4" /> */}
             
                    {/* Description */}


                {/*  */}
                  </form>
                  <br/>
                  <div>
                  <button type="submit" className="btn btn-primary rounded-4"  onClick={handleOnClick} >Submit</button>
                  </div>
                </div>
        </div>
      </div>
    </div>
  );
}
