import React, { useState, useEffect } from "react";
import user from "../../../assets/images/avatar.jpg";
import {
  MdApartment,
  MdDeleteOutline,
  MdManageAccounts,
  MdOutlineEdit,
} from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { SelectButton } from "primereact/selectbutton";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { LazyLoadImage } from "react-lazy-load-image-component";
import No_image_available from "../../../assets/images/avatar.jpg";
import Loading from "../../ManageApp/data/Loading";

import BankDetails from "../../../components/Dialog/BankDetails";

const apIcon = {
  fontSize: "20px",
};

const ManagementList = (props) => {
  const history = useHistory();
  const [selectedRole, setSelectedRole] = useState("");
  const [value1, setValue1] = useState("Unblock");
  const [value2, setValue2] = useState("Unblock");
  const [value3, setValue3] = useState("Unblock");
  const options = ["Unblock", "Block"];
  const [data, setdata] = useState([]);

  const { id, society, image } = props;
  const token = localStorage.getItem("token");
  const [totalItems, setTotalItems] = useState(0);
  //    const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);

  useEffect(() => {
    datafetch();
  }, [currentPage, searchQuery, selectedRole]);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
    // datafetch();
  };
  
  const datafetch = () => {
    // setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getManagmentAccordindSociety`,
      headers: { Authorization: "Bearer " + token },
      params: {
        search: searchQuery,
        limit,
        offset,
        society_id: id,
        role: selectedRole,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.count);
        console.log(response.data);
        setdata(response.data);
        console.log(response.data.data.length);
        setentry(response.data.data.length + offset);
        console.log(id);
        console.log(selectedRole);
        setIsLoading(false);
        const initialOptions = {};
        response.data.data.forEach((user) => {
          initialOptions[user._id] = user.status; // Assuming status field from the API response
        });
        setSelectedOptions(initialOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const editPost = (val, Id) => {
    console.log(val);
    // setValue1(val);
    console.log(Id);
    let formData = new FormData();
    formData.append("_id", Id);
    formData.append("status", val);
    console.log("formData", formData);
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "multipart/form-data",
      },
    };
    axios
      .patch(
        `${process.env.REACT_APP_BASE_API}updateStatusOfSocietyManagment`,
        formData,
        config
      )
      .then((response) => {
        console.log(response.data);
        //   window.location.reload(false);
        console.log(response.data);

        datafetch();
      })
      .catch((error) => console.error(error));
    setSelectedOptions((prevState) => ({
      ...prevState,
      [Id]: val,
    }));
  };

  const deleteconfirm = (ID) => {
    console.log("delete");
    console.log(ID);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_API}deleteManagement/` + ID,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        };
        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            Swal.fire({
              title: "Deleted!",
              text: "SUCCESS",
              icon: "success",
            });
            datafetch();
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    });
  };


  if (isLoading) {
    return <Loading />;
  }


  return (
    <>
      <div className="table_header mb-3">
        <div className="d-flex flex-wrap ">
          <div className="d-flex flex-wrap align-items-center">
            {/* <Link to="/" className='btn ms-auto me-2 btn-sm btn-primary rounded-4 '>Back</Link> */}
            <button
              onClick={() => window.history.back()}
              className="btn me-2 btn-primary rounded-4 "
            >
              Back
            </button>
            <h4 className="mb-0 fs-5 d-flex align-items-center gap-2">
              <MdManageAccounts /> Management List
            </h4>
          </div>
          <div className="ms-auto d-flex align-items-center gap-2">
            <div className="ms-auto d-flex align-items-center gap-2">
              <div className="search_box ">
                <input
                  placeholder="Search"
                  className="chakra-input "
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <div className="search_icon "></div>
              </div>
            </div>
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={handleRoleChange}
              value={selectedRole}
            >
              {/* selected */}
              <option value="">All Role</option>
              <option value="admin">Admin</option>
              <option value="Management">Management</option>
              {/* <option value="Security">Security</option> */}
            </select>
            <Link className="w-100" to={ {pathname: `/add-management/${id}`, state: props.state} }>
              <button className="btn btn-primary rounded-5 ">Add New</button>
            </Link>
          </div>
        </div>
      </div>

      {data && data.data.length > 0 ? (
        <div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">USER INFO</th>
                  <th scope="col">PHONE</th>
                  {/* <th scope="col">LANDLINE</th> */}
                  <th scope="col">ROLE</th>
                  <th scope="col">OWNER</th>
                  <th scope="col">DESIGNATION</th>
                  {/* <th scope="col">APARTMENTS</th> */}
                  {/* <th scope="col">BANK DETAILS</th> */}
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.data &&
                  data.data.map((data, idx) => (
                    <tr>
                      <th scope="row">
                        {(currentPage - 1) * pageSize + idx + 1}
                      </th>
                      <td>
                        <div className="user_info d-flex  align-items-center">
                          {/* <img src={data.profile_image} alt="user" className='avatar' /> */}
                          {data.profile_image ? (
                            <LazyLoadImage
                              src={data.profile_image}
                              alt="Profile"
                            />
                          ) : (
                            <LazyLoadImage
                              src={No_image_available}
                              alt="No rofile image"
                            />
                          )}
                          <div className="ms-3">
                            <strong>
                              <span className="txt-head">
                                {data.first_name + " " + data.last_name}
                              </span>
                            </strong>
                            <p className="txt-bottom mb-0">{data.email}</p>
                          </div>
                        </div>
                      </td>
                      <td>{data.mobile_number}</td>
                      {/* <td>-</td> */}
                      <td>{data.role}</td>

                      <td>
                        <SelectButton
                          className="btn-small"
                          value={selectedOptions[data._id] || "Unblock"}
                          options={options}
                          onChange={(e) => editPost(e.value, data._id)}
                        />
                      </td>

                      <td>
                        {data.designation ? (
                          data.designation
                        ) : (
                          <i
                            className="pi pi-times"
                            style={{ color: "red" }}
                          ></i>
                        )}
                      </td>
                      {/* <td>
                        <MdApartment style={apIcon} /> {data.appartmentCount}
                      </td> */}

                      {/* <td>
                        {data.bank_details.length > 0 ? (
                          <BankDetails bankDetails={data.bank_details} />
                        ) : (
                          <i
                            className="pi pi-times"
                            style={{ color: "red" }}
                          ></i>
                        )}
                      </td> */}

                      <td>
                        <div className="action_btn">
                          <span
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <Link to={ {pathname: `/update-management/${data._id}`, state: props.state} }><MdOutlineEdit /></Link>
                          </span>
                          <button
                            onClick={() => deleteconfirm(data._id)}
                            style={{
                              border: "none",
                              background: "none",
                              cursor: "pointer",
                            }}
                          >
                            <MdDeleteOutline />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                {/* <tr>
                            <th scope="row">1</th>
                            <td><div className='user_info d-flex  align-items-center'>

                                <img src={user} alt="user" className='avatar' />
                                <div className='ms-3'>
                                    <strong><span className='txt-head'>John Sons</span></strong>
                                    <p className='txt-bottom mb-0'>JohnSons13@gmail.com</p>
                                </div>

                            </div></td>
                            <td>987654310</td>
                            <td>91-11-1234 5678</td>
                            <td>Management</td>
                            <td> <SelectButton className='btn-small' value={value1} options={options} onChange={(e) => setValue1(e.value)} /></td>
                            <td>Admin</td>
                            <td><MdApartment style={apIcon} /> 200</td>

                            <td><div className='action_btn'>

                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td><div className='user_info d-flex  align-items-center'>

                                <img src={user} alt="user" className='avatar' />
                                <div className='ms-3'>
                                    <strong><span className='txt-head'>John Sons</span></strong>
                                    <p className='txt-bottom mb-0'>JohnSons13@gmail.com</p>
                                </div>

                            </div></td>
                            <td>987654310</td>
                            <td>91-11-1234 5678</td>
                            <td>Security</td>
                            <td> <SelectButton className='btn-small' value={value2} options={options} onChange={(e) => setValue2(e.value)} /></td>
                            <td>Gate Security</td>
                            <td> <MdApartment style={apIcon} /> 200</td>

                            <td><div className='action_btn'>

                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td><div className='user_info d-flex  align-items-center'> 

                                <img src={user} alt="user" className='avatar' />
                                <div className='ms-3'>
                                    <strong><span className='txt-head'>John Sons</span></strong>
                                    <p className='txt-bottom mb-0'>JohnSons13@gmail.com</p>
                                </div>

                            </div></td>
                            <td>987654310</td>
                            <td>91-11-1234 5678</td>
                            <td>Security</td>
                            <td> <SelectButton className='btn-small' value={value3} options={options} onChange={(e) => setValue3(e.value)} /></td>
                            <td>Deputy Security</td>
                            <td><MdApartment style={apIcon} /> 200</td>

                            <td><div className='action_btn'>

                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr> */}
              </tbody>
            </table>
          </div>
          <div className="table-footer d-md-flex justify-content-md-between align-items-center">
            <p className="mb-0">
              Showing {offsetentry + 1} to {entry} of{" "}
              <span id="total-entries">{totalItems}</span> entries
            </p>
            <nav>
              <ul className="pagination pagination-md">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>

                {pageNumbers.map((pageNumber) => {
                  // Define the minimum and maximum page numbers to display
                  const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                  const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                  if (pageNumber >= minPage && pageNumber <= maxPage) {
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                <li className="page-item">
                  <button className="page-link" onClick={nextPage}>
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <h4>No data available</h4>
      )}
      {/* <div className="table-footer"> */}
      {/* <ul className="pagination m-sm-auto pagination-md justify-content-end">
                    <li className="page-item"><Link className="page-link" href="#" aria-label="Previous">&laquo;</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">1</Link></li>
                    <li className="page-item active"><Link className="page-link " href="#">2</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">3</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">4</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">5</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">6</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">7</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">8</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">9</Link></li>
                    <li className="page-item"><Link className="page-link" href="#" aria-label="Next">&raquo;</Link></li>
                </ul> */}
      {/* </div> */}
      {/* <nav>
                <ul className='pagination justify-content-center'>
                    <li className="page-item">
                        <button className="page-link" onClick={prevPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                    </li>
                    {pageNumbers.map((pageNumber) => (
    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
      <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>
        {pageNumber}
      </button>
    </li>
  ))}

<li className="page-item">
                        <button className="page-link" onClick={nextPage} >
                            Next
                        </button>
                    </li>
                    </ul>
            </nav> */}
      {/* {pageNumbers.map(pgNumber => (
                        <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}>
                            <button className='page-link' onClick={() => setCurrentPage(pgNumber)}>
                                {pgNumber}
                            </button>
                        </li>
                    ))} */}

      {/* disabled={currentPage === nPages} */}
    </>
  );
};

export default ManagementList;
