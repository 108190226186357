import React from "react";
import { Link } from "react-router-dom";
import { CgArrowLeft } from "react-icons/cg";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import "primereact/resources/primereact.min.css";
import { useState } from "react";
import axios from "axios";

import { CgSearch } from "react-icons/cg";
import BankDetails from "./Dialog/BankDetails";

const SocietyHeaderView = ({ societyName, imageUrl, defaultRouter }) => {
  return (
    <div className="card p-3 mb-2">
      <div className="row align-items-center">
        <div className="col-md-4">
          <div className="card-images society_logo-img d-flex justify-content-md-start justify-content-center">
            <img className="img-fluid" src={imageUrl} alt="society logo" />
          </div>
        </div>
        <div className="col-md-4">
          <div className="card-title my-3 my-md-0 fs-4 text-decoration-underline fw-semibold text-center">
            {societyName}
          </div>
        </div>
        {/* <div className="col-md-2">
          <BankDetails />
        </div> */}

        <div className="col-md-4">
          <div className="text-md-end text-center">
            <Link
              to={defaultRouter}
              className="btn btn-primary rounded-5 text-capitalize"
            >
              <CgArrowLeft /> Back to all Societies
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocietyHeaderView;
