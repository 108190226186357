import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { postMethod } from "../api";

import { loginUrl } from "../constant";

import Swal from 'sweetalert2';

const initialState = {
  value: {
    isLogged: false,
    loginData: null,
  },
};

export const authenticateUser = createAsyncThunk(
  "authentication/user",
  async (data, thunkAPI) => {
//     const response = await postMethod(loginUrl, data);
// //    console.log("RESPONSE IS----", response);
//     return response;
try {
  const response = await postMethod(loginUrl, data);
  return response;
} catch (error) {
  // Handle the error appropriately
  Swal.fire({
    icon: 'error',
    title: 'Authentication Failed',
    text: 'Wrong password or Check Email or Network error' // You can customize the error message here
  });
  return thunkAPI.rejectWithValue(error.message); // or return a custom error object
}
  }
);

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    updateUser: (state, action) => {
     // console.log("ACTION ISSS----", action.payload, " Normal action is--", action);
   
      state.value = {
        isLogged: true,
        loginData: action.payload,
      };
    },
  },
  extraReducers: {
    [authenticateUser.fulfilled]: (state, action) => {
      state.isLogged = true;
      state.loginData = action.payload;
    console.log("Action Payload is----", action.payload.data);
    console.log("Payload is----", action.payload.data.token);
    if(action.payload.data.token){
    localStorage.setItem("token", action.payload.data.token);
   }
  
    },
    [authenticateUser.rejected]: (state, action) => {
      state.isLogged = false;
      state.loginData = null;
    },
    [authenticateUser.pending]: (state, action) => {
      state.isLogged = false;
      state.loginData = null;
    },
  },
});

export const { updateUser } = authenticationSlice.actions;

export default authenticationSlice.reducer;
