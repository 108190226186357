import React, { useState,useEffect } from 'react';
import user from "../../../assets/images/avatar.jpg"
import { MdDeleteOutline, MdLocalParking, MdPerson2 } from "react-icons/md";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { MdOutlineEdit } from "react-icons/md";
import { Sidebar } from 'primereact/sidebar';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { classNames } from "primereact/utils";
import axios from 'axios';
import Swal from 'sweetalert2'




const ChargesList = () => {
    const [visibleRight, setVisibleRight] = useState(false);
    const [visible, setVisible] = useState(false);
    const [data, setdata] = useState([]);
    function datafetch() {
        // data fetching here
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_API}getcharges`,
            headers: {}
        };
        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));
                console.log(response.data);
                setdata(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {

        datafetch();

    }, []);

    // //Addcharge validation
    const validationSchema = Yup.object().shape({
        
        chargeName: Yup.string().required("charge name is required"),
        charge:Yup.string().required("category is required"),
        amount:Yup.number().required("amount is required"),

        editchargeName: Yup.string().required("charge name is required"),
        editCharge:Yup.string().required("category is required"),
        editAmount:Yup.number().required("amount is required"),


    });

    const formik = useFormik({
        initialValues: {
            chargeName :"",
            charge: "",
            amount:"",
           
        },

        initialeditValues: {
            editchargeName:"",
            editCharge: "",
            editAmount:"",
           
        },
        validationSchema : validationSchema
    });


    
    const handleOnClick = () => {
        console.log("working")
        formik.setTouched(
            Object.keys(formik.values).reduce((acc , fieldName) => {
                acc[fieldName] = true
                return acc
            },
            {}
            )
        )
        formik.validateForm().then((errors) => {
          // Check if there are any validation errors
          console.log("errors" , errors)
          if (Object.keys(errors).length === 0) {
            // If no errors, proceed to the next step
            console.log("values" , formik.values)
        const payload = formik.values 
        console.log("payload" ,payload)
       
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_API}addCharges`,
            data: payload, // you are sending body instead
            headers: {
             // 'Authorization': `bearer ${token}`,
            'Content-Type': 'application/json'
            }, 
          }) .then((response) => {
            console.log(response);
            formik.setValues({
                chargeName :"",
                charge: "",
                amount:"",
           })
            datafetch();
            })
        .catch((error) => {
           console.log("error",error)
          })
        

          }       
        




         
        });
        
        
        
    };

    const deleteconfirm = (ID) => {
        console.log("delete");
        console.log(ID);

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"

        }).then((result) => {
            if (result.isConfirmed) {
                let config = {
                    method: 'delete',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_BASE_API}delete/charges/` + ID,
                    headers: { 'Content-Type': 'application/json' }
                };
                axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data));
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success"
                        });
                        datafetch();

                    }).catch((error) => {
                        console.log(error);
                    });


            }
        });
    }


    const handleOnClickUpdate = () => {
        console.log("working")
        
    };



    return (
        <>
            <Sidebar className='right-sidebar' visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>


                <h5>Add New Charge</h5>
                <div class="sidebar-main">
                    <form >
                        <div class="mb-3">
                            <label for="chargeName" class="form-label">Charge Name</label>
                            <input type="text"  name="chargeName" class="form-control" id="chargeName" 
                                 placeholder="Enter charge name"
                                 className={classNames({
                                    "input-invalid": Boolean(formik.touched.chargeName && formik.errors.chargeName),
                                }, "form-control")}
                                {...formik.getFieldProps("chargeName")}  />

                             {Boolean(formik.touched.chargeName && formik.errors.chargeName) && (
                                <small className="p-error">{formik.errors.chargeName}</small>
                             )}

                          
                        </div>
                        <div class="mb-3">
                            <label for="category" class="form-label">Category</label>
                            <input type="text" name="charge" class="form-control" id="category" placeholder="Enter category"   className={classNames({
                                    "input-invalid": Boolean(formik.touched.charge && formik.errors.charge),
                                }, "form-control")}
                                {...formik.getFieldProps("charge")}  />

              {Boolean(formik.touched.charge && formik.errors.charge) && (
                                <small className="p-error">{formik.errors.charge}</small>
                            )}
                        </div>
                        <div class="mb-3">
                            <label for="amount" class="form-label">Amount</label>
                            <input type="number" name="amount" class="form-control" id="amount" placeholder="Enter amount"    className={classNames({
                                    "input-invalid": Boolean(formik.touched.amount && formik.errors.amount),
                                }, "form-control")}
                                {...formik.getFieldProps("amount")}  />

              {Boolean(formik.touched.amount && formik.errors.amount) && (
                                <small className="p-error">{formik.errors.amount}</small>
                            )}
                        </div>
                        
                    </form>
                </div>
                <div className='d-flex footer gap-2 align-items-center'>
                    <button type="submit" class="btn btn-primary rounded-4" onClick={handleOnClick}>Submit</button>
                </div>
                
            </Sidebar>


            <Sidebar className='right-sidebar' visible={visible} position="right" onHide={() => setVisible(false)}>


                <h5>Edit Charge</h5>
                <div class="sidebar-main">
                    <form >
                        <div class="mb-3">
                        
                            <label for="chargeName" class="form-label">Charge Name</label>
                            <input type="text"  name="editchargeName" class="form-control" id="editchargeName" 
                                 placeholder="Enter charge name"
                                 className={classNames({
                                    "input-invalid": Boolean(formik.touched.editchargeName && formik.errors.editchargeName),
                                }, "form-control")}
                                {...formik.getFieldProps("editchargeName")}  value={data.name} />

                             {Boolean(formik.touched.editchargeName && formik.errors.editchargeName) && (
                                <small className="p-error">{formik.errors.editchargeName}</small>
                             )}

                          
                        </div>
                        <div class="mb-3">
                            <label for="category" class="form-label">Category</label>
                            <input type="text" name="editCharge" class="form-control" id="category" placeholder="Enter category"   className={classNames({
                                    "input-invalid": Boolean(formik.touched.editCharge && formik.errors.editCharge),
                                }, "form-control")}
                                {...formik.getFieldProps("editCharge")}  />

              {Boolean(formik.touched.editCharge && formik.errors.editCharge) && (
                                <small className="p-error">{formik.errors.editCharge}</small>
                            )}
                        </div>
                        <div class="mb-3">
                            <label for="amount" class="form-label">Amount</label>
                            <input type="number" name="editAmount" class="form-control" id="editAmount" placeholder="Enter editAmount"    className={classNames({
                                    "input-invalid": Boolean(formik.touched.editAmount && formik.errors.editAmount),
                                }, "form-control")}
                                {...formik.getFieldProps("editAmount")}  />

              {Boolean(formik.touched.editAmount && formik.errors.editAmount) && (
                                <small className="p-error">{formik.errors.editAmount}</small>
                            )}
                        </div>
                        
                    </form>
                </div>
                <div className='d-flex footer gap-2 align-items-center'>
                    <button type="submit" class="btn btn-primary rounded-4" onClick={handleOnClickUpdate}>Update</button>
                </div>
                
            </Sidebar>

            <div className='table_header mb-3'>
                <div className='d-flex flex-wrap align-items-center'>
                    <h4 className='mb-0 fw-semi-bold fs-5'>All Charges List</h4>
                    <div className='ms-auto d-flex align-items-center gap-2'>
                        <button onClick={() => setVisibleRight(true)} className='btn btn-sm btn-primary rounded-4'>Add New</button>
                    </div>

                </div>
            </div>
            <div className='table-responsive'>
                <table className="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">CHARGES NAME</th>
                            <th scope="col">CATEGORY</th>
                            <th scope="col">AMOUNT</th>
                            <th scope="col">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>

                    {data.data && data.data.map((data, idx) => (
                            <tr>
                                <th scope="row">{idx + 1}</th>
                                <td>{data.chargeName}</td>
                                <td>{data.charge}</td>
                                <td>{data.amount}</td>

                                <td><div className='action_btn'>
                                    <Link onClick={() => setVisible(true)}  ><MdOutlineEdit /></Link>
                                    <Link onClick={() => deleteconfirm(data._id)}><MdDeleteOutline /></Link>
                                   
                                </div></td>
                            </tr>
                        ))}

                        {/* <tr>
                            <th scope="row">1</th>
                            <td>Water</td>
                            <td>Utilities</td>
                            <td>&#8377; 400</td>
                            <td><div className='action_btn'>

                                <Link onClick={() => setVisibleRight(true)}  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Electricity</td>
                            <td>Utilities</td>
                            <td>&#8377; 400</td>
                            <td><div className='action_btn'>

                                <Link onClick={() => setVisibleRight(true)} ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Parking 4 Wheeler</td>
                            <td>Maintenance</td>
                            <td>&#8377; 400</td>
                            <td><div className='action_btn'>

                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Parking 2 Wheeler</td>
                            <td>Maintenance</td>
                            <td>&#8377; 400</td>
                            <td><div className='action_btn'>

                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>Property tax</td>
                            <td>Taxes</td>
                            <td>&#8377; 400</td>
                            <td><div className='action_btn'>

                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr> */}

                    </tbody>

                </table>
            </div>
            <div className="table-footer d-md-flex justify-content-md-between align-items-center">
                <p className='mb-0'>Showing 1 to 10 of <span id='total-entries'>57</span>  entries</p>
                <ul class="pagination pagination-md ">
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Previous">&laquo;</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">1</Link></li>
                    <li class="page-item active"><Link class="page-link " href="#">2</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">3</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">4</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">5</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">6</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">7</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">8</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">9</Link></li>
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Next">&raquo;</Link></li>
                </ul>
            </div>

        </>


    );
};

export default ChargesList;
