import React, { useState, useEffect } from "react";
import { MdDeleteOutline, MdReply } from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Sidebar } from "primereact/sidebar";
import axios from "axios";
import Loading from "../../ManageApp/data/Loading";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { classNames } from "primereact/utils";

const ContactUsList = () => {
  const [visibleRight, setVisibleRight] = useState(false);
  const [data, setdata] = useState([]);
  const [id, setid] = useState("");
  const [email, setemail] = useState("");
  const [replymsg, setreplymsg] = useState("");

  // const [replyMessage, setReplyMessage] = useState('');

  const [msg, setmsg] = useState("");

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  //

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);

  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(10);

  const token = localStorage.getItem("token");
  useEffect(() => {
    datafetch();
  }, [currentPage]); // Fetch data whenever currentPage changes

  const datafetch = () => {
    // setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getcontactus`,
      headers: { Authorization: "Bearer " + token },
      params: { limit, offset },
    };

    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.count);
         console.log(response.data.data);
        setdata(response.data.data);
        setIsLoading(false);
        // console.log(response.data.data.length);
        setentry(response.data.data.length + offset);
      })
      .catch((error) => {
        // console.log(error);
        Swal.fire("Error", "Failed to fetch data", "error");
      });
  };

  const validationSchema = Yup.object().shape({
    reply: Yup.string().required("reply is required"),
  });

  const formik = useFormik({
    initialValues: {
      reply: "",
    },
    validationSchema: validationSchema,
  });

  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  //

  const deletePost = (ID) => {
    console.log("delete");
    console.log(ID);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_API}deleteContactUs/` + ID,
          headers: { "Content-Type": "application/json" },
        };
        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            datafetch();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const replyPost = (ID, message, email, reply) => {
    setVisibleRight(true);
    setmsg(message);
    setid(ID);
    setemail(email);
    setreplymsg(reply);
  };

  const replyMSG = () => {
    console.log("working");
    formik.setTouched(
      Object.keys(formik.values).reduce((acc, fieldName) => {
        acc[fieldName] = true;
        return acc;
      }, {})
    );
    formik.validateForm().then((errors) => {
      // Check if there are any validation errors
      console.log("errors", errors);
      if (Object.keys(errors).length === 0) {
        // If no errors, proceed to the next step
        console.log("values", formik.values);
        const payload = formik.values;
        console.log("payload", payload);

        let formData = new FormData();
        formData.append("_id", id);
        formData.append("reply", payload.reply);
        formData.append("email", email);

        console.log("formData", formData);

        const config = {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        };

        axios
          .patch(`${process.env.REACT_APP_BASE_API}reply`, formData, config)
          .then((response) => {
            console.log(response.data);
            console.log(response.data);
            datafetch();
            setVisibleRight(false);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Reply successful",
              showConfirmButton: false,
              timer: 1500,
            });
            formik.setValues({
              reply: "",
            });
          })
          .catch((error) => {
            // console.error(error)
            console.log(error);
            setVisibleRight(false);
            Swal.fire("Error", "Failed to reply changes", "error");
          });
      }
    });
  };
  // const replyMSG=()=>{
  //     console.log("working")
  //     formik.setTouched(
  //         Object.keys(formik.values).reduce((acc , fieldName) => {
  //             acc[fieldName] = true
  //             return acc
  //         },
  //         {}
  //         )
  //     )
  //     formik.validateForm().then((errors) => {
  //       // Check if there are any validation errors
  //       console.log("errors" , errors)
  //  if (Object.keys(errors).length === 0) {

  //     let formData = new FormData();
  //     formData.append('_id',id);
  //     formData.append('reply',replyMessage);
  //     formData.append('email',email);

  //     console.log("formData", formData)

  //     const config = {
  //         headers: {  'Authorization': 'Bearer ' + token,'content-type': 'multipart/form-data' }
  //     }

  //     axios.patch(`${process.env.REACT_APP_BASE_API}reply`, formData, config)
  //         .then(response => {console.log(response.data)
  //         console.log(response.data);
  //         })
  //         .catch(error => console.error(error));
  //     }
  // }
  // }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Sidebar
        className="right-sidebar"
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h5>All Contact List</h5>
        <div class="sidebar-main">
          <form>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Message
              </label>
              {/* disabled */}
              <textarea
                type="text"
                value={msg}
                class="form-control"
                id="chargeName"
                placeholder="Message Here"
              />
            </div>
            {typeof replymsg !== "undefined" && (
              <div class="mb-3">
                <label for="chargeName" class="form-label">
                  Replied
                </label>
                {/* disabled */}
                <textarea
                  type="text"
                  value={replymsg}
                  class="form-control"
                  id="chargeName"
                  placeholder="Message Here"
                  readOnly
                />
              </div>
            )}

            {/* <button className='btn btn-primary btn-sm'> Reply</button> */}
            <div class="my-3">
              <label for="amount" class="form-label">
                Write Reply Here
              </label>
              <textarea
                type="text"
                class="form-control"
                name="reply"
                id="chargeName"
                className={classNames(
                  {
                    "input-invalid": Boolean(
                      formik.touched.reply && formik.errors.reply
                    ),
                  },
                  "form-control"
                )}
                {...formik.getFieldProps("reply")}
                placeholder="Message Here"
                //  onChange={(e) => setReplyMessage(e.target.value)}
              />

              {Boolean(formik.touched.reply && formik.errors.reply) && (
                <small className="p-error">{formik.errors.reply}</small>
              )}
            </div>
          </form>
        </div>
        <div className="d-flex footer gap-2 align-items-center">
          <button
            type="submit"
            class="btn btn-primary rounded-4"
            onClick={replyMSG}
          >
            Reply
          </button>
        </div>
      </Sidebar>

      <div className="table_header mb-3">
        <div className="d-flex flex-wrap align-items-center">
          <h4 className="mb-0 fw-semi-bold fs-5">All Contact Us List</h4>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">NAME</th>
              <th scope="col">EMAIL</th>
              <th scope="col">MOBILE NUMBER</th>
              <th scope="col">SUBJECT</th>
              <th scope="col">MESSAGE</th>
              <th scope="col">REPLY</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {console.log(data)}
            {Object.keys(data).length>0 &&
              data.map((data, idx) => (
                <>
                {data?.user_id ?
                <tr>
                  <th scope="row">{(currentPage - 1) * pageSize + idx + 1}</th>
                  <td>
                    {data?.user_id.first_name + " " + data?.user_id.last_name}
                  </td>
           
                  <td>{data?.user_id.email}</td>
                  <td>{data?.user_id.phone_primary}</td>
                  <td>{data?.subject}</td>
                  
                  <td>
                    {" "}
                    <p className="text-truncate" style={{ maxWidth: "300px" }}>
                      {data.message}{" "}
                    </p>
                  </td>
                  <td>{data?.reply}</td>

                  <td>
                    <div className="action_btn">
                    {/* setVisibleRight(true)  */}
                      <Link
                        onClick={() =>
                          replyPost(
                            data?._id,
                            data?.message,
                            data?.user_id.email,
                          )
                        }
                      >
                        <MdReply />
                      </Link>
                      <Link onClick={() => deletePost(data?.user_id._id)}>
                        <MdDeleteOutline />
                      </Link>
                    </div>
                  </td>
                </tr>
                :data?.society_admin_id ?
                <>
                  <tr>
                  <th scope="row">{(currentPage - 1) * pageSize + idx + 1}</th>
             
           
                    <td>{data?.society_admin_id?.first_name + " " + data?.society_admin_id?.last_name}</td> 
                  <td>{data?.society_admin_id?.email}</td>
                  <td>{data?.society_admin_id?.mobile_number}</td>
                  <td>{data?.subject}</td>
                  
                  <td>
                    {" "}
                    <p className="text-truncate" style={{ maxWidth: "300px" }}>
                      {data.message}{" "}
                    </p>
                  </td>
                  <td>{data?.reply}</td>

                  <td>
                    <div className="action_btn">
                   {/* // setVisibleRight(true)  */}
                      <Link
                        onClick={() =>
                          replyPost(
                            data?.society_admin_id?.first_name,
                            data?._id,
                            data?.message,
                            data?.society_admin_id?.email,
                          )
                        }
                      >
                        <MdReply />
                      </Link>
                      <Link onClick={() => deletePost(data?.society_admin_id?._id)}>
                        <MdDeleteOutline />
                      </Link>
                    </div>
                  </td>
                </tr>
                </>:""}
                </>
              ))}
               

            {/* <tr>
                            <th scope="row">1</th>
                            <td>Amar Singh</td>
                            <td>AmarSingh@gmail.com</td>
                            <td>9876543210</td>
                            <td> <p className='text-truncate' style={{ maxWidth: '300px' }} >Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corrupti porro consectetur at fuga quae ut repellat </p></td>
                            <td><div className='action_btn'>
                                <Link onClick={() => setVisibleRight(true)}  ><MdReply /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Amar Singh</td>
                            <td>AmarSingh@gmail.com</td>
                            <td>9876543210</td>
                            <td> <p className='text-truncate' style={{ maxWidth: '300px' }} >Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corrupti porro consectetur at fuga quae ut repellat </p></td>
                            <td><div className='action_btn'>
                                <Link onClick={() => setVisibleRight(true)}  ><MdReply /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Amar Singh</td>
                            <td>AmarSingh@gmail.com</td>
                            <td>9876543210</td>
                            <td> <p className='text-truncate' style={{ maxWidth: '300px' }} >Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corrupti porro consectetur at fuga quae ut repellat </p></td>
                            <td><div className='action_btn'>
                                <Link onClick={() => setVisibleRight(true)}  ><MdReply /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr> */}
          </tbody>
        </table>
      </div>
      <div className="table-footer d-md-flex justify-content-md-between align-items-center">
        <p className="mb-0">
          Showing {offsetentry + 1} to {entry} of{" "}
          <span id="total-entries">{totalItems}</span> entries
        </p>
        <nav>
          <ul className="pagination pagination-md">
            <li className="page-item">
              <button
                className="page-link"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                &laquo;
              </button>
            </li>

            {pageNumbers.map((pageNumber) => {
              // Define the minimum and maximum page numbers to display
              const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
              const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

              if (pageNumber >= minPage && pageNumber <= maxPage) {
                return (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      currentPage === pageNumber ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                );
              }
              return null;
            })}
            <li className="page-item">
              <button className="page-link" onClick={nextPage}>
                &raquo;
              </button>
            </li>
          </ul>
        </nav>
      </div>

      {/* <div className="table-footer d-md-flex justify-content-md-between align-items-center"> */}
      {/* <p className='mb-0'>Showing 1 to 10 of <span id='total-entries'>57</span>  entries</p>
                <ul class="pagination pagination-md ">
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Previous">&laquo;</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">1</Link></li>
                    <li class="page-item active"><Link class="page-link " href="#">2</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">3</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">4</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">5</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">6</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">7</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">8</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">9</Link></li>
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Next">&raquo;</Link></li>
                </ul> */}
      {/* </div> */}
      {/* <nav>
                <ul className='pagination justify-content-center'>
                    <li className="page-item">
                        <button className="page-link" onClick={prevPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                    </li>
                   
                     {pageNumbers.map((pageNumber) => (
    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
      <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>
        {pageNumber}
      </button>
    </li>
  ))}
                
                    <li className="page-item">
                        <button className="page-link" onClick={nextPage}  disabled={currentPage === totalPages} >
                            Next
                        </button>
                    </li>

                  
                </ul>
            </nav> */}
      {/* {pageNumbers.map(pgNumber => (
                        <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}>
                            <button className='page-link' onClick={() => setCurrentPage(pgNumber)}>
                                {pgNumber}
                            </button>
                        </li>
                    ))} */}
      {/* disabled={currentPage === nPages} */}
    </>
  );
};

export default ContactUsList;
