import React, { useState, useEffect } from "react";
import MiniStatistic from "../components/ministatistic";
import { VscGraph } from "react-icons/vsc";
import slogo1 from "../assets/images/slogo1.jpg";
import slogo2 from "../assets/images/slogo2.jpg";
import slogo3 from "../assets/images/slogo3.jpg";
import { Dropdown } from "primereact/dropdown";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { CgSearch } from "react-icons/cg";
import BreadCrumbDemo from "../components/breadcrumbdemo";
import axios from "axios";
import Swal from "sweetalert2";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Loading from "./ManageApp/data/Loading";
import { Toast } from "primereact/toast";

export default function Dashboard() {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // --fil-Upload-
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [entry, setentry] = useState(0);

  const [societyCount, setsocietyCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [posts, setPosts] = useState({ data: [] });
  const [data, setdata] = useState();
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize] = useState(6);

  // const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const [offsetentry, setoffsetentry] = useState(0);

  const onUpload = (event) => {
    setUploadedFiles(event.files);
  };
  const token = localStorage.getItem("token");

  //
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [file2, setFile2] = useState();
  // State to control the visibility of the button
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  //

  // --Dialog--

  const [displayResponsive, setDisplayResponsive] = useState(false);

  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="Close"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
        {/* <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
        <Button label="Yes" icon="pi pi-check" onClick={() => onHide(name)} autoFocus /> */}
      </div>
    );
  };

  //
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile2(event.target.files[0]);
    // Get the first file from the selected files array
    setSelectedFile(file); // Update the state with the selected file
    setIsDisabled(!isDisabled);
    setIsButtonVisible(true);
  };

  const clearSelectedFile = () => {
    console.log(file2);
    setSelectedFile(null); // Reset the state to null
    setIsDisabled(!isDisabled);
    setIsButtonVisible(false);
  };

  function handleSubmit() {
    // event.preventDefault();
    const url = `${process.env.REACT_APP_BASE_API}uploadsociety`;
    const formData = new FormData();
    // formData.append("society_id", id);
    formData.append("doc", file2);
    // formData.append('fileName', file.name);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response.data);
        // alert("file Uploaded success!");
        setSelectedFile(null); // Reset the state to null
        setIsDisabled(!isDisabled);
        setIsButtonVisible(false);
        datafetch();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "CSV Upload Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        onHide("displayResponsive");
      })
      .catch((error) => {
        //handle error
        console.log(error.message);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Email or Mobile number already exist or Network error",
        });
        onHide("displayResponsive");
      });
  }

  //

  useEffect(() => {
    datafetch();
  }, [currentPage, searchQuery]);

  const datafetch = () => {
    // setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    setoffsetentry(offset);
    const limit = pageSize;

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getsociety`,
      headers: { Authorization: "Bearer " + token },
      params: { search: searchQuery, limit, offset },
    };

    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.count);
        setsocietyCount(response.data.count);
        console.log(response.data);
        // setdata(response.data);
        setSearchResults(response.data.results);
        // setTotalPages(Math.ceil(response.data.totalCount / pageSize));
        setIsLoading(false);
        console.log(response.data.data.length)
        setentry(response.data.data.length + offset)


        setPosts(response.data);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred while fetching data from the server!",
        });
      });
  };

  const handleSearch = () => {
    setCurrentPage(1); // Reset to the first page when performing a new search
    datafetch(); // Fetch data based on the new search query
  };

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_BASE_API}getsociety` )
  //      .then((response) => response.json())
  //      .then((data) => {
  //         console.log(data);
  //         setPosts(data);
  //         // setIsLoading(false);

  //      })
  //      .catch((err) => {
  //         console.log(err.message);
  //      });
  // }, []);

  // const handleSearch = () => {
  //   const filtered = posts.data.filter((post) =>
  //     post.society_name.toLowerCase().includes(searchQuery.toLowerCase())
  //   );
  //   setFilteredPosts(filtered);
  // };

  // const fileHandler = (event) => {
  //   const formData = new FormData();
  //   formData.append("csvFile", event.target.files[0]);
  //   const config = {
  //     headers: {
  //       "content-type": "multipart/form-data",
  //     },
  //   };
  //   axios({
  //     method: "post",
  //     url: `${process.env.REACT_APP_BASE_API}uploadsociety`,
  //     data: formData,
  //     headers: { "Content-Type": "multipart/form-data" },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       console.log(response);

  //       Swal.fire({
  //         position: "center",
  //         icon: "success",
  //         title: "CSV Upload Successfully",
  //         showConfirmButton: false,
  //         timer: 1500
  //       });
  //       onHide("displayResponsive");
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log(response);
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Oops...',
  //         text: 'An error occurred while uploading CSV to the server!',
  //     });
  //     onHide("displayResponsive");
  //     });
  // };

  const renderPosts = () => {
    const dataToMap = searchQuery ? filteredPosts : posts.data;
    return dataToMap.map((post) => (
      <div key={post._id} className="col-12 col-sm-6 col-xl-4">
        <MiniStatistic post={post} />
      </div>
    ));
  };

  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  //

  // Example data for MiniStatistic
  // const miniStatProps1 = {
  //   media: slogo2,
  //   name: 'The Hampton Society ',
  //   apartments: '200',
  //   wings: '08',
  //   dynamicroute: '/manage-society'
  // };

  // const miniStatProps2 = {
  //   media: slogo3,
  //   name: 'Ek Noor Welfare Society',
  //   apartments: '200',
  //   wings: '08',
  //   dynamicroute: '/manage-society'

  // };

  // const miniStatProps5 = {
  //   media: slogo1,
  //   name: 'Kerala Dinesh Beedi workers\' Central Co-op Society.',
  //   apartments: '200',
  //   wings: '08',
  //   dynamicroute: '/manage-society'
  // };

  // const miniStatProps3 = {
  //   media: slogo2,
  //   name: 'Eksaar Shiksha Abhiyan Society',
  //   apartments: '200',
  //   wings: '08',
  //   dynamicroute: '/manage-society'
  // };

  // const miniStatProps6 = {
  //   media: slogo1,
  //   name: 'Indian Farmers Fertilizer Cooperative Limited (IFFCO)',
  //   apartments: '200',
  //   wings: '08',
  //   dynamicroute: '/manage-society'
  // };

  // const miniStatProps4 = {
  //   media: slogo1,
  //   name: 'Guru Angad Dev Sewa Society',
  //   apartments: '200',
  //   wings: '08',
  //   dynamicroute: '/manage-society'
  // };

  // if (isLoading) {
  //   return <Loading />;
  // }
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Define the number of page numbers to display at a time
  const maxPageNumbers = 6; // You can adjust this number as per your requirement

  // Calculate the starting index for page numbers
  const startPageIndex = Math.max(
    0,
    currentPage - Math.floor(maxPageNumbers / 2)
  );
  const endPageIndex = Math.min(
    startPageIndex + maxPageNumbers - 1,
    totalPages - 1
  );

  // Get the subset of page numbers to display
  const visiblePageNumbers = pageNumbers.slice(
    startPageIndex,
    endPageIndex + 1
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="main-content">
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumbDemo />
        <div className="ms-auto" style={{ marginRight: "10px" }}>
          <h6>{`Total Societies: ${societyCount}`}</h6>
        </div>
      </div>
      <div className="card p-3">
        <div className="container">
          <div className="d-flex mb-4 flex-wrap align-items-center">
            <h4 className="mb-0 fw-semi-bold fs-5">Dashboard</h4>

            <div className="ms-auto d-flex align-items-center flex-wrap gap-2">
              {/* 
              <Button label="Upload CSV" className='p-button-sm p-button-rounded' icon="pi pi-external-link" onClick={() => onClick('displayResponsive')} /> */}
              {/* <div className="col-md-3"> */}

              <div>
                <Button
                  label="Upload CSV"
                  className="p-button-sm p-button-rounded"
                  icon="pi pi-external-link"
                  onClick={() => onClick("displayResponsive")}
                  style={{ marginRight: "10px" }}
                />
                {/* <Toast visible={toastVisible} severity={toastSeverity} summary={toastSummary} detail={toastDetail} /> */}
                <Dialog
                  header="Upload Society"
                  visible={displayResponsive}
                  onHide={() => onHide("displayResponsive")}
                  breakpoints={{ "960px": "75vw" }}
                  style={{ width: "50vw" }}
                  footer={renderFooter("displayResponsive")}
                >
                  <div>
                    {/* <a href="" className='btn btn-sm btn-primary d-block ms-auto mb-2' style={{width: '30%'}} download>Download Society Import Sample CSV</a> */}
                    {/* <FileUpload
                name="doc"
                url={`${process.env.REACT_APP_BASE_API}uploadappartment`}
                onUpload={onUpload}
                onClick={fileHandler}
                accept="pdf/*"
                maxFileSize={1000000}  // 1 MB
              />        
                <div>
                {uploadedFiles.length > 0 && (
                  <div>
                    <ul>
                      {uploadedFiles.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div> */}

                    {/* <form onSubmit={handleSubmit}> */}
                    {/* <h3> File Upload</h3> */}

                    <a
                      href={`${process.env.REACT_APP_BASE_IMAGE_URL}r-community-rest-apis/public/society-onboard-sample-csv.csv`}
                      className="btn btn-sm btn-primary d-block ms-auto mb-2"
                      style={{ width: "50%" }}
                      download
                    >
                      Download Society Import Sample CSV
                    </a>

                    <div className="p-fileupload p-fileupload-advanced p-component">
                      <div className="p-fileupload-buttonbar">
                        <label
                          for="upload-apartment"
                          className="p-button p-fileupload-choose p-component "
                          tabIndex={0}
                        >
                          {!selectedFile && ( // Render the file input if no file is selected
                            <input
                              className="upload-apartment-csv"
                              type="file"
                              name="doc"
                              accept=".csv"
                              // className='btn btn-primary rounded-5 text-capitalize'
                              // onChange={handleChange}
                              id="upload-apartment"
                              onChange={handleFileChange}
                            />
                          )}

                          <span className="p-button-icon p-button-icon-left p-clickable pi pi-fw pi-plus"></span>
                          <span className="p-button-label p-clickable">
                            choose
                          </span>
                        </label>
                        <button
                          // className="p-button p-component p-disabled"
                          className="p-button p-component"
                          disabled={isDisabled}
                          onClick={handleSubmit}
                        >
                          <span className="p-button-icon p-c p-button-icon-left pi pi-upload"></span>
                          <span className="p-button-label p-c">Upload</span>
                        </button>
                        <button
                          type="button"
                          // className="p-button p-component p-disabled"
                          className="p-button p-component"
                          disabled={isDisabled}
                          onClick={clearSelectedFile}
                        >
                          <span className="p-button-icon p-c p-button-icon-left pi pi-times"></span>
                          <span className="p-button-label p-c">Cancel</span>
                        </button>
                      </div>
                      <div className="p-fileupload-content">
                        <div className="p-fileupload-files">
                          <div className="p-fileupload-row">
                            {/* <div className="p-fileupload-filename">appartment2.csv</div>
                        <div>154 B</div> */}
                            <div className="p-fileupload-filename">
                              {selectedFile && <p> {selectedFile.name}</p>}
                            </div>

                            <div>
                              {isButtonVisible && (
                                <button
                                  type="button"
                                  className="p-button p-component p-button-icon-only"
                                  onClick={clearSelectedFile}
                                >
                                  <span className="p-button-icon p-c pi pi-times"></span>
                                  <span className="p-button-label p-c">&nbsp;</span>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                </Dialog>
              </div>
              
            </div>

            <div className="search_box position-relative">
              <input
                placeholder="Search"
                className="form-control rounded-5 "
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <span className="search-icon">
                <CgSearch onClick={handleSearch} />
              </span>
            </div>
          </div>

          {/* <Dialog header="Upload CSV File " visible={displayResponsive} onHide={() => onHide('displayResponsive')} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={renderFooter('displayResponsive')}>
            <div>
             <a href="https://betazone.promaticstechnologies.com/r-community-rest-apis/public/society-onboard-sample-csv.csv" className='btn btn-sm btn-primary d-block ms-auto mb-2' style={{width: '50%'}} download>Download Society Import Sample CSV</a>
              <FileUpload
                name="doc"
                url={`${process.env.REACT_APP_BASE_API}uploadsociety`}
                
                onUpload={onUpload}
                onClick={fileHandler}
                accept= ".csv"
                maxFileSize={1000000}  // 1 MB
              />
                     
                           <div>
                {uploadedFiles.length > 0 && (
                  <div>
                    <ul>
                      {uploadedFiles.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </Dialog> */}

          {/*           
          <input
          type="file"
          name="file" 
          id="exampleFile" 
          onChange={(e) => fileHandler(e)}/>
       */}

          <div className="row">
            {posts.data &&
              posts.data.map((posts) => (
                <div className="col-12 col-sm-6 col-xl-4 ">
                  <MiniStatistic post={posts} />
                </div>
              ))}
            {/* {renderPosts()} */}

            {/* <div className='col-12 col-sm-6 col-xl-4'>
              <MiniStatistic {...miniStatProps2} />
            </div>
            <div className='col-12 col-sm-6 col-xl-4'>
              <MiniStatistic {...miniStatProps3} />
            </div>
            <div className='col-12 col-sm-6 col-xl-4'>
              <MiniStatistic {...miniStatProps4} />
            </div>
            <div className='col-12 col-sm-6 col-xl-4'>
              <MiniStatistic {...miniStatProps5} />
            </div> */}
            <div className="table-footer d-md-flex justify-content-md-between align-items-center">
              <p className='mb-0'>Showing {offsetentry + 1} to {entry} of <span id='total-entries'>{totalItems}</span>  entries</p>
              <nav>
                <ul className="pagination pagination-md">
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      &laquo;
                    </button>
                  </li>
                  {pageNumbers.map((pageNumber) => {
                    // Define the minimum and maximum page numbers to display
                    const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                    const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed


                    if (pageNumber >= minPage && pageNumber <= maxPage) {
                      return (
                        <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                          <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>
                            {pageNumber}
                          </button>
                        </li>
                      );
                    }
                    return null;
                  })}
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      &raquo;
                    </button>
                  </li>
                </ul>
              </nav>



            </div>
            {/* <nav>
              <ul className="pagination justify-content-center">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>
                {pageNumbers.map((pgNumber) => (
                  <li
                    key={pgNumber}
                    className={`page-item ${
                      currentPage === pgNumber ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(pgNumber)}
                    >
                      {pgNumber}
                    </button>
                  </li>
                ))}

                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav> */}
          </div>
        </div>
      </div>

      {/* <button className="page-link" onClick={prevPage} disabled={currentPage === 1}>
                            Previous
                        </button> */}

      {/* {pageNumbers.map((pageNumber) => (
    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
      <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>
        {pageNumber}
      </button>
    </li>
  ))} */}

      {/* {visiblePageNumbers.map((pageNumber) => (
            <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
              <button className='page-link' onClick={() => handlePageChange(pageNumber)}>
                {pageNumber}
              </button>
            </li>
          ))} */}

      {/* <button className="page-link" onClick={nextPage}  disabled={currentPage === totalPages} >
                            Next
                        </button> */}

      {/* disabled={currentPage === nPages} */}
    </div>
  );
}
