import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { makeApiCall } from "../../utils/api";
import { getApartmentDetail } from "../../utils/asyncFun";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
import Swal from "sweetalert2";
import axios from "axios";

const AddResidents = ({
  header,
  showDialog,
  setShowDialog,
  society_id,
  datafetch,
}) => {
  const [wing, setWing] = useState([]);

  const validationSchema = Yup.object().shape({
    society_id: Yup.string(),
    wings: Yup.array().of(
      Yup.object().shape({
        wing_id: Yup.string().required("Wing is required"),
        apartment_id: Yup.array()
          .min(1, "Appartment is required")
          .required("Appartment is required"),
        appartment_list: Yup.array(),
        wing_list: Yup.array(),
      })
    ),
    profile_image: Yup.string(),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    phone_primary: Yup.string()
      .required("Mobile number is required")
      .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
    email: Yup.string().required("Email is required")
      // .required("Email is required")
      .email("Invalid email address"),
    // status: Yup.string(),
    type: Yup.string().required("Type is required"),
  });

  const formik = useFormik({
    initialValues: {
      society_id: society_id,
      wings: [
        {
          wing_id: "",
          apartment_id: [],
          appartment_list: [],
          wing_list: [],
        },
      ],
      profile_image: "",
      first_name: "",
      last_name: "",
      phone_primary: "",
      email: "",
      // status: "pending",
      type: "resident",
    },
    validationSchema: validationSchema,
  });

  const restoreResident=async()=>{
    console.log("makeapi")
    const response = await makeApiCall(
      "restoreFromRecycleBin",
      "PATCH",
      {
        userId: response?.userId,
      },
      {},
      true
    )

    if(response){
      Swal.fire(
        response?.message
      );
    }
  }

  const handleSave = async () => {
    console.log(
      "cis",
      Object.keys(formik.values).reduce((acc, fieldName) => {
        if (fieldName == "wings") {
          const wings = formik.values.wings.map(() => {
            return { wing_id: true, apartment_id: true };
          });
          console.log("wings", wings);
          acc[fieldName] = wings;
        } else {
          acc[fieldName] = true;
        }
        return acc;
      }, {})
    );

    formik.setTouched(
      Object.keys(formik.values).reduce((acc, fieldName) => {
        if (fieldName == "wings") {
          const wings = formik.values.wings.map(() => {
            return { wing_id: true, apartment_id: true };
          });
          acc[fieldName] = wings;
        } else {
          acc[fieldName] = true;
        }
        return acc;
      }, {})
    );

    formik.validateForm().then(async (errors) => {
      // Check if there are any validation errors
      console.log("touched", formik.touched);
      console.log("errors", errors);
      if (Object.keys(errors).length === 0) {
        const wingsData = formik.values.wings;

        // wings.forEach(item => {
        //   if (!result[item.wing_id]) {
        //     result[item.wing_id] = { wing_id: item.wing_id, apartment_id: [] };
        //   }
        //   result[item.wing_id].apartment_id.push(item.apartment_id);
        // });

        // const finalResult = Object.values(result);
        // console.log("finalResult", finalResult)

        const wingandapartment = [];

        for (let index = 0; index < wingsData.length; index++) {
          const wing = wingsData[index];
          const apartment_id = wing.apartment_id.map((ele) => ele.value);
          wingandapartment.push({
            wing_id: wing.wing_id,
            apartment_id: apartment_id,
          });
        }

        const data = {
          society_id: formik.values.society_id,
          first_name: formik.values.first_name,
          last_name: formik.values.last_name,
          email: formik.values.email,
          phone_primary: formik.values.phone_primary,
          profile_image: formik.values.profile_image,
          // status: formik.values.status,
          type: formik.values.type,
          profile_image: formik.values.profile_image,
          wingandapartment: wingandapartment,
          role: "owner",
        };

        console.log("data", data);

        // const response = await makeApiCall(
        //   "addResident",
        //   "POST",
        //   data,
        //   {},
        //   true
        // );
        // let response = await axios.post(
        //   'https://api.rcommunity.app/a1/admin/addResident',
        //   data,
        //   {
        //     headers: {
        //       'Authorization': `Bearer ${localStorage.getItem("token")}`,
        //     },
        //   }
        // );

        // response=response.data

        try {
          let response = await axios.post(
            'https://api.rcommunity.app/a1/admin/addResident',
            data,
            {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
        
          response = response.data;
          console.log('Response:', response);
          console.log("ch",response)
          if (response?.is_trashed) {
            onHide();
            
            Swal.fire({
              title: "Are you sure?",
              text: "This user already exists in the recycle bin. Do you want to restore?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Restore",
              cancelButtonText: "Cancel",
            }).then((result) => {
              if (result.isConfirmed) {
                console.log("Restore confirmed. Making API call...");
                // restoreResident();
                axios({
                  method: 'PATCH',
                  url: 'https://api.rcommunity.app/a1/admin/restoreFromRecycleBin',
                  headers: {
                    'Authorization':`Bearer ${localStorage.getItem("token")}`,
                  },
                  data: {
                    userId:response.userId ,
                  },
                })
                  .then((response) => {
                     datafetch();
                     console.log()
                    Swal.fire('Restored!', response?.data?.message, 'success');
                  })
                  .catch((error) => {
                    Swal.fire('Error!', 'There was a problem restoring the item.', 'error');
                  });
              }
            });
            // datafetch();
  
            return
          }
  
          if (response.code==400) {
            onHide();
            datafetch();
            Swal.fire("Response", response.message);
            onHide();
            datafetch();
          }
       
          if (response.code == 200 && response?.is_trashed) {
            formik.resetForm();
            Swal.fire("Response", response.message);
            onHide();
            datafetch();
          }
        } catch (error) {
          // console.error('Error details:', error.response?.data || error.message);
          // Swal.fire('Error!', 'There was a problem adding the resident.', 'error');
          onHide();

          
          Swal.fire(error.response?.data?.message);

          return
        }
        


      }
    });
  };

  const onHide = () => {
    setShowDialog(false);
    formik.resetForm();
  };

  async function getWings() {
    const params = {
      society_id: society_id,
    };

    const wingResponse = await makeApiCall(
      "getwings",
      "GET",
      null,
      params,
      true
    );

    if (wingResponse) {
      setWing(wingResponse.data);
      formik.setFieldValue(`wings.0.wing_list`, wingResponse.data);
    }
  }

  useEffect(() => {
    if (showDialog) {
      console.log("wing ran");
      getWings();
    }
  }, [showDialog]);

  // async function getApartmentList(wing_id) {
  //   const params = {
  //     wing_id: wing_id,
  //   };

  //   const wingResponse = await makeApiCall(
  //     "getappartment",
  //     "GET",
  //     null,
  //     params,
  //     true
  //   );

  //   if (wingResponse) {
  //     return wingResponse?.data ?? []
  //   }

  //   return []
  // }

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
        <Button
          label="Yes"
          type="button"
          icon="pi pi-check"
          onClick={handleSave}
          // onClick = { addNewWingAndAppartment}
          autoFocus
        />
      </div>
    );
  };

  function addNewWingAndAppartment() {
    const newWings = [...formik.values.wings];
    const selectedWings = newWings.map((ele) => ele.wing_id);
    const filterdWing = wing.filter(
      (element) => !selectedWings.includes(element._id)
    );

    newWings.push({
      wing_id: "",
      apartment_id: "",
      appartment_list: [],
      wing_list: filterdWing,
    });
    formik.setFieldValue("wings", newWings);
  }

  const deleteWings = (index) => {
    const newWings = [...formik.values.wings];
    console.log("before removing", newWings);
    newWings.splice(index, 1);

    console.log("after newWings", newWings);

    formik.setFieldValue("wings", newWings);

    const touchWings = [...formik.touched.wings];
    touchWings.splice(index, 1);
    formik.setFieldTouched(`wings`, touchWings);
  };

  async function handleUploadImage(event) {
    if (!event?.currentTarget?.files[0]) return;
    const formData = new FormData();
    formData.append("profile_image", event.currentTarget.files[0]);

    const uploadImage = await makeApiCall(
      "uploadImageToAWS",
      "POST",
      formData,
      {},
      true,
      false,
      "",
      false
    );

    if (uploadImage) {
      formik.setFieldTouched("profile_image", true);
      formik.setFieldValue("profile_image", uploadImage?.data);
    }
  }

  async function handleChangeWing(wing_id, index) {
    const wings = formik.values.wings;
    const currentSelectedwings = wings.map((ele, i) => {
      return ele.wing_id;
    });
    for (let i = 0; i < wings.length; i++) {
      const singlewing = wings[i];
      if (i === index) continue;
      const filteredWing = wing.filter(
        (ele) =>
          !currentSelectedwings.includes(ele._id) ||
          singlewing.wing_id === ele._id
      );
      singlewing.wing_list = filteredWing;
      wings[i] = singlewing;
    }
    formik.setFieldValue(`wings`, wings);

    if (!wing_id) {
      formik.setFieldValue(`wings.${index}.appartment_list`, []);
      formik.setFieldValue(`wings.${index}.apartment_id`, []);
      return;
    }

    const params = {
      wing_id: wing_id,
    };

    const appartmentResponse = await makeApiCall(
      "getappartmentList",
      "GET",
      null,
      params,
      true
    );
    if (appartmentResponse) {
      if (appartmentResponse?.data) {
        formik.setFieldValue(
          `wings.${index}.appartment_list`,
          appartmentResponse?.data
        );
        formik.setFieldValue(`wings.${index}.apartment_id`, []);
      }
    }
  }
  console.log("formikall", formik);

  return (
    <>
      {/* <Button label="Open Dialog" onClick={() => setShowDialog(true)} /> */}
      <Dialog
        header={header}
        visible={showDialog}
        style={{ width: "50vw" }}
        footer={renderFooter()}
        onHide={onHide}
      >
        <div className="resdient form_Cntner">
          <form>
            <div className="row">
              <div className="col-12 mb-3">
                <div className="avatar d-flex justify-content-center align-items-center flex-column m-auto">
                  {formik?.values?.profile_image ? (
                    <img
                      className="rounded-circle"
                      alt=""
                      src={formik?.values?.profile_image}
                      style={{ height: 100, width: 100, objectFit: "contain" }}
                    />
                  ) : (
                    <img
                      className="rounded-circle"
                      alt=""
                      src=""
                      style={{ height: 100, width: 100 }}
                    />
                  )}

                  <input
                    type="file"
                    id="upload-img"
                    onChange={handleUploadImage}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="upload-img" className="upload-img">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth={0}
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z" />
                    </svg>
                  </label>
                </div>
              </div>
              <div className="col-6 mb-3">
                <label for="chargeName" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="first_name"
                  {...formik.getFieldProps("first_name")}
                />
                {Boolean(
                  formik.touched.first_name && formik.errors.first_name
                ) && (
                  <small className="text-danger">
                    {formik.errors.first_name}
                  </small>
                )}
              </div>
              <div className="col-6 mb-3">
                <label for="chargeName" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="last_name"
                  {...formik.getFieldProps("last_name")}
                />
                {Boolean(
                  formik.touched.last_name && formik.errors.last_name
                ) && (
                  <small className="text-danger">
                    {formik.errors.last_name}
                  </small>
                )}
              </div>
              <div className="col-6 mb-3">
                <label for="chargeName" className="form-label">
                  Mobile Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="phone_primary"
                  {...formik.getFieldProps("phone_primary")}
                />
                {Boolean(
                  formik.touched.phone_primary && formik.errors.phone_primary
                ) && (
                  <small className="text-danger">
                    {formik.errors.phone_primary}
                  </small>
                )}
              </div>
              <div className="col-6 mb-3">
                <label for="chargeName" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {Boolean(formik.touched.email && formik.errors.email) && (
                  <small className="text-danger">{formik.errors.email}</small>
                )}
              </div>
              <div className="col-6 mb-3">
                <label for="chargeName" className="form-label">
                  Resident Type
                </label>
                <select
                  className="form-control"
                  name="type"
                  {...formik.getFieldProps("type")}
                  disabled
                >
                  <option value="">Select Type</option>
                  <option value="resident">Resident</option>
                  {/* <option value="managememt">Management</option> */}
                </select>
                {Boolean(formik.touched.type && formik.errors.type) && (
                  <small className="text-danger">{formik.errors.type}</small>
                )}
              </div>
              {/* <div className="col-6 mb-3">
                <label for="chargeName" className="form-label">
                  Resident Status
                </label>
                <select
                  className="form-control"
                  name="status"
                  {...formik.getFieldProps("status")}
                >
                  <option value="" selected>
                    Select Status
                  </option>
                  <option value="pending">Pending</option>
                  <option value="approve">Approve</option>
                  <option value="disapprove">Disapprove</option>
                </select>
                {Boolean(formik.touched.status && formik.errors.status) && (
                  <small className="text-danger">{formik.errors.status}</small>
                )}
              </div> */}
            </div>
            {formik.values.wings.map((element, index) => (
              <div className="row" key={index}>
                {console.log("element", element)}
                <div className="col-6 mb-3">
                  <div className="append_items">
                    <label for="chargeName" className="form-label">
                      Wing
                    </label>
                    <div className="d-flex">
                      <select
                        className="form-select"
                        name="wing"
                        // value={formik.values[`wings.${index}.wing_id`]}
                        onChange={(e) => {
                          handleChangeWing(e.target.value, index);
                          formik.setFieldValue(
                            `wings.${index}.wing_id`,
                            e.target.value
                          );
                        }}
                        // {...formik.getFieldProps(`wings.${index}.wing_id`)}
                      >
                        <option value="" selected>
                          Select Wing
                        </option>
                        {element.wing_list.map((ele, index) => (
                          <option value={ele._id} key={index}>
                            {ele.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {Boolean(
                    formik.touched.wings?.[index]?.wing_id &&
                      formik?.errors?.wings?.[index]?.wing_id
                  ) && (
                    <small className="text-danger">
                      {formik?.errors?.wings?.[index]?.wing_id}
                    </small>
                  )}
                </div>
                <div className="col-6 mb-3">
                  <div className="append_items">
                    <label for="chargeName" className="form-label">
                      Apartment
                    </label>
                    <div className="d-flex gap-1 aline-item-start">
                      <div style={{ flex: "1" }}>
                        <Select
                          value={formik.values.wings[index].apartment_id}
                          isMulti
                          name="colors"
                          classNamePrefix="select"
                          options={element.appartment_list.map((ele, index) => {
                            return {
                              value: ele._id,
                              label: ele.appartmentNumber,
                            };
                          })}
                          styles={{
                            container: (provided) => ({
                              ...provided,
                              width: "100%", // Set width to 100%
                            }),
                          }}
                          onChange={(selectedOption) => {
                            formik.setFieldValue(
                              `wings.${index}.apartment_id`,
                              selectedOption
                            );
                          }}
                        />
                      </div>
                      {index !== 0 ? (
                        <Button
                          type="button"
                          icon="pi pi-trash"
                          onClick={() => deleteWings(index)}
                          autoFocus
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {Boolean(
                      formik.touched.wings?.[index]?.apartment_id &&
                        formik?.errors?.wings?.[index]?.apartment_id
                    ) && (
                      <small className="text-danger">
                        {formik?.errors?.wings?.[index]?.apartment_id}
                      </small>
                    )}
                  </div>
                </div>
              </div>
            ))}

            <div className="add_more-btn d-grid justify-content-end">
              <Button
                type="button"
                label="Add More"
                icon="pi pi-plus"
                autoFocus
                onClick={addNewWingAndAppartment}
              />
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default AddResidents;
