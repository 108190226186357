import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MdApartment, MdHouse } from "react-icons/md";
import { TiEdit } from "react-icons/ti";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Button } from "primereact/button";

export default function MiniStatistic(props) {
  // const { media, name, apartments, wings, dynamicroute } = props;
  const {
    profile_image,
    society_name,
    address,
    appartmentCount,
    wingCount,
    _id,
    resident_count,
    management_Count,
    security_Count,
    OccupiedappartmentCount,
    UnOccupiedappartmentCount,
    countfortenant,
    OccupiedParkingSlotCount,
    UnOccupiedParkingSlotCount,
  } = props.post;

  const dataToPass = {
    image: profile_image,
    society: society_name,
    count: wingCount,
    id: _id,
    resident: resident_count,
    management: management_Count,
    security: security_Count,
    occupy: OccupiedappartmentCount,
    unoccupy: UnOccupiedappartmentCount,
    tenant: countfortenant,
    occupyparking: OccupiedParkingSlotCount,
    unoccupyparking: UnOccupiedParkingSlotCount,
  };

  const societyData = {
    image: profile_image,
    society: society_name,
    id: _id,
  };

  return (
    <div className="card p-2 society-card rounded-4 border-0 shadow-lg mb-4">
      {/* <img className="card-img-top rounded-4 " src={profile_image} alt="Card cap" loading="lazy"  /> */}

      <LazyLoadImage
        className="card-img-top rounded-4"
   
        src={profile_image}
       
        alt="Card cap"
      />
      <div className="card-body px-1 pb-0 ">
        <h6 className="card-title">{society_name}</h6>
        <p className="card-text text-muted mb-2">{address}</p>
        <div className="d-flex align-items-center gap-3 flex-wrap mb-2">
          <p className="card-text mb-0 d-flex align-items-center gap-1">
            <MdHouse /> Apartments{" "}
            <span className="text-muted">{appartmentCount}</span>{" "}
          </p>
          <p className="card-text mb-0 d-flex align-items-center gap-1">
            <MdApartment /> Wings{" "}
            <span className="text-muted">{wingCount}</span>{" "}
          </p>
          <p className="card-text mb-0 d-flex align-items-center gap-1">
            <TiEdit /> <Link to={`/update-society/${_id}`}>Edit</Link>
          </p>
        </div>

        {OccupiedParkingSlotCount === 0 && UnOccupiedParkingSlotCount === 0 ? (
          <div className="">
            <p style={{ color: "red", fontSize: "12px" }}>
              Parking Slots Pending
            </p>
          </div>
        ) : null}

        <div
          className="d-flex g-2"
          onClick={() =>
            localStorage.setItem("society_info", JSON.stringify(societyData))
          }
        >
          <Link
            to={{ pathname: `/manage-society/${_id}` }}
            className="btn btn-primary w-100  rounded-4"
          >
            Manage Society
          </Link>
        </div>
      </div>
    </div>
  );
}
