import { Card } from 'primereact/card'
import React from 'react'
import Banner from './components/Banner'
import banner from "../../assets/images/Debit.png"
import avatar from "../../assets/images/avatar.jpg"
import WingList from './components/WingList'
import AmenityList from './components/AmenityList'
import AllRequest from '../AllRequest'
import SubAdmintable from './components/SubAdmintable'
import BreadCrumbDemo from '../../components/breadcrumbdemo'

export default function ViewSociety() {
  return (

    <div className='main-content'>
      <BreadCrumbDemo />
      <div className='card p-4'>
        <Banner
          banner={banner}
          avatar={avatar}
          name='Adela Parkson'
          job='Society Manager'
          posts='05'
          apartments='25'
          amenities='08'
          occupancy="200"
        />

        <div className='row '>
          <div className='col-12 col-md-4 col-lg-4  col-xl-4 mb-4'>
            <WingList />
          </div>
          <div className='col-12 col-md-8 col-lg-8  col-xl-8 mb-4'>
            <AmenityList />
          </div>
        </div>
        <div className='request_list mb-4'>
          <AllRequest />
        </div>
        <div className='Admin_list'>
          <SubAdmintable />
        </div>
      </div>
    </div>

  )
}
