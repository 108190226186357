import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { useDispatch, useSelector } from "react-redux";
import { closeSideBar } from "../library/store/sidebar";
import Logo1 from "../assets/images/logo2.svg";
import "../assets/css/menu.css";
import { MdApartment, MdContactEmergency, MdContactSupport, MdDashboard, MdOutlineContactEmergency, MdPayment, MdPerson, MdPolicy, MdPrivacyTip } from "react-icons/md";
import { SiSociety6 } from "react-icons/si";
import { FaBuilding, FaList, FaMoneyBill, FaQuora } from "react-icons/fa";
import { TbReplaceFilled } from "react-icons/tb";
import { CgList } from "react-icons/cg";
import { FcContacts } from "react-icons/fc";


export default function SideBar() {
  const [drawerVisible, setDrawerVisible] = useState();
  const drawerState = useSelector((state) => state.sidebar.value);
  const dispatch = useDispatch();

  useEffect(() => {
    setDrawerVisible(drawerState);
  }, [drawerState]);

  return (
    <div
      className="p-col-fixed p-0 p-d-none p-d-lg-block h-100 sidebarWrapper"
      style={{ width: "200px" }}
    >
      {/* side drawer for mobile */}
      <Sidebar
        visible={drawerVisible}
        onHide={() => {
          dispatch(closeSideBar());
        }}
      >
        {menuContent}
      </Sidebar>

      {/* normal sidebar */}
      <div className="menuSidebar p-d-none p-d-lg-flex h-100">
        {menuContent}
      </div>
    </div>
  );
}

const menus = [
  {
    name: "Dashboard",
    route: "/dashboard",
    icon: <MdDashboard />,
    iconType: "component",
  },
  // {
  //   name: "View Profile",
  //   route: "/view-society",
  //   icon: <SiSociety6 />,
  //   iconType: "component",
  // },

  // {
  //   name: "Manage Society",
  //   route: "/all-owners",
  //   icon: <MdPerson />,
  //   iconType: "component",
  // },

  // {
  //   name: "Manage Resident",
  //   route: "/all-tenants",
  //   icon: <MdPerson />,
  //   iconType: "component",
  // },
  {
    name:"Manage Apartment Type",
    route:"/manage-apartment-type",
    icon: <MdPerson />,
    iconType: "component",

  },


/*   {
    name: "Manage Payments",
    route: "/payment-management",
    icon: <MdPayment />,
    iconType: "component",
  }, */

  // {
  //   name: "Add Charges ",
  //   route: "/manage-charges",
  //   icon: <FaMoneyBill />,
  //   iconType: "component",
  // },

  // {
  //   name: "Manage Delievery Companies",
  //   route: "/manage-delievery-companies",
  //   icon: <FaBuilding />,
  //   iconType: "component",
  // },

  {
    name: "Manage Amenities",
    route: "/add-amenities",
    icon: <TbReplaceFilled />,
    iconType: "component",
  },

  // {
  //   name: "Manage Emergency Contacts",
  //   route: "/manage-contact-list",
  //   icon: <MdContactEmergency />,
  //   iconType: "component",
  // },

  {
    name: "Visiting Helps Categories",
    route: "/manage-visiting-list",
    icon: <MdPerson />,
    iconType: "component",
  },
  {
    name: "How It Works",
    route: "/how-it-works",
    icon:  <CgList />,
    iconType: "component",
  },

  {
    name: "About Us",
    route: "/about-us",
    icon: <CgList />,
    iconType: "component",
  },



  {
    name: "Contact Us",
    route: "/contact-us",
    icon: <MdContactSupport />,
    iconType: "component",
  },

/*   {
    name: "Manage Apartments",
    route: "/apartment-list",
    icon: <MdApartment />,
    iconType: "component",
  }, */



  {
    name: "Faq Management",
    route: "/manage-faq",
    icon: <FaQuora />,
    iconType: "component",
  },

  {
    name: "Data & Privacy For Community",
    route: "/data-and-privacy",
    icon: <MdPrivacyTip />,
    iconType: "component",
  },

  {
    name: "Terms & Condition",
    route: "/terms-and-conditon",
    icon: <FaList />,
    iconType: "component",
  },

  {
    name: "Privacy and Policy",
    route: "/privacy-policy",
    icon: <MdPolicy />,
    iconType: "component",
  },
 


];

const menuContent = (
  <div className="menus w-100 p-3" >
    <div className="mb-4 d-flex border-bottom border-light pb-2">
      <img src={Logo1} alt="Logo" style={{ height: 'auto', width: '120px', margin: 'auto' }} className="img img-fluid logo" />
    </div>
    <div className="menulist">
      {menus.map((item, index) => (
        <NavLink
          key={index}
          to={item.route}
          className="p-d-flex gap-2 p-ai-center"
          activeClassName="active"
        >
          {item.iconType == "component" && item.icon}
          <span className="ml-2 menuText">{item.name}</span>
        </NavLink>
      ))}


    </div>


  </div>
);
