import React,{useState,useEffect} from "react";
import AllDocsList from "./AllDocsList";
import image from "../assets/images/Dunzo.png";
import { set } from "firebase/database";

const AllDocs = (props) => {

  const [userData,setuserData]=useState([])

  //console.log(props?.data?.upload_document)

  useEffect(()=>{

    setuserData(props?.data?.upload_document)

    console.log({userData})

  },[props])




  return (
    <>
      <div className="card shadow-lg border-0 p-3" mb={{ base: "0px" }}>
        <div className="d-flex align-items-center">
          <h5 className="">All Docs List</h5>
        </div>
        <ul className="doc_listing">
          {userData?userData?.map((ele)=>(<>
          
            <AllDocsList
            image={image}
            UserDocument={ele?.file}
            Download="Download"
            
            Document={ele.type === "noc" ? "Noc" : ele.type === "Driving_Licence" ? "Driving Licence" :ele.type=="rental_agreement" ? "Rental Agreement":ele.type=="id_proof"?"Id Proof":ele.type=="police_verification" ? "Police Verification":" "}
          
          />
          </>)):""}
 
        </ul>
      </div>
    </>
  );
};

export default AllDocs;





    {/* <AllDocsList
            image={image}
            Download="Download"
            Document="Driving Licence "
          /> */}
          {/* <AllDocsList
            image={image}
            Download="Download"
            Document="Driving Licence "
          /> */}
          {/* <AllDocsList
            image={image}
            Download="Download"
            Document="Driving Licence "
          />
          <AllDocsList
            image={image}
            Download="Download"
            Document="Driving Licence"
          /> */}