import React from "react";
import No_image_available from "../assets/images/avatar.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";


const Family = (props) => {
  const { title, number, email, image, relation } = props;


  return (
    <>
      <div className="card border-0 shadow-sm " style={{ padding: "5px 10px" }}>
        <div className="d-flex align-items-center flex-column flex-sm-row">
          <LazyLoadImage
            // src={image}
            src={image ? image : No_image_available}
            alt="Document"
            className="rounded mr-md-4"
            style={{ width: "60px", height: "60px" }}
          />
          <div className="ms-3 mt-2">
            <h6 className="fw-semi-bold mb-1">{title} <span className="ms-3 badge bg-secondary ">{relation}</span></h6>
             <p className="fw-semi-bold  text-secondary mb-0">{number}</p>
             <p className="fw-normal  text-secondary mb-0">{email}</p>
          </div>
        
        </div>
      </div>

    </>
  );
};

export default Family;
