import React from "react";
import BookedAmenitiesList from "./components/BookedAmenitiesList";
import SocietyHeaderView from "../../components/societyHeaderView";
import { BreadCrumb } from "primereact/breadcrumb";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const BookedAmenities = () => {
    const { id } = useParams();
    const {society, image} = JSON.parse(localStorage.getItem("society_info"))

    const home = { icon: "pi pi-home" };

    const items = [
      { label: "Dashboard" },
      { label: "Manage Society" },
      { label: "All Amenites " },
    ];

  return (
    <div>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
      </div>

      <div className="header">
        <SocietyHeaderView
          imageUrl={decodeURIComponent(image)}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>
      <div className="card p-3">
        <div className="table-body">
          <BookedAmenitiesList
            imageUrl={decodeURIComponent(image)}
            societyName={society}
            id={id}
          />
        </div>
      </div>
    </div>
  );
};

export default BookedAmenities;
