// import React from "react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import No_image_available from "../assets/images/avatar.jpg";

export default function VistingDetail(props) {
  const {
    type,
    issue,
    issue_Description,
    request_date,
    status,
    ticket_id,
    allow_entry_type,
    approved_date,
    delivery_company_name,
    date_of_entry,
    date_of_exit,
    guest_name,
    time_of_entry,
    time_of_exit,
    all_day, 
    approved_days,
    helper_name,
    visitor_name,

expected_time
  } = props;


  function convertTimeToAMPM(time24) {
    // Split the time string into hours and minutes
    const [hours, minutes] = time24.split(':');
    
    // Convert hours to AM/PM format
    let hoursAMPM = hours % 12 || 12;
    
    // Determine if it's AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    
    // Construct the AM/PM time string
    const time12 = `${hoursAMPM}:${minutes} ${ampm}`;
    
    return time12;
}
  return (
    <div>
      <div className="text-primary font-size-1xl font-weight-700">
        Request Type: {type ? type : "N/A"}
      </div>

      <div>
        <div>
          <div>{issue ? `Issue: ${issue}` : ""}</div>
          <div>
            {issue_Description ? `Issue Description: ${issue_Description}` : ""}
          </div>
          <div> {request_date ? `Date: ${request_date}` : ""}</div>
          <div> {status ? `Status: ${status}` : ""}</div>
          <div> {ticket_id ? `ticket_id: ${ticket_id}` : ""}</div>
          <div>
            {allow_entry_type ? ` Allow Entry Type: ${allow_entry_type}` : ""}
          </div>
          <div> {approved_date ? `Approved Date: ${new Date(approved_date).toISOString().split('T')[0] }` : ""}</div>
          <div> {delivery_company_name ? `Delivery Company Name: ${delivery_company_name}` : ""}
          </div>
          <div> {date_of_entry ? ` Date Of Entry: ${ new Date(date_of_entry).toISOString().split('T')[0]}` : ""}</div>
          <div> {date_of_exit ? `Date of Exit: ${new Date(date_of_exit).toISOString().split('T')[0]}` : ""}</div>
          <div> {guest_name ? ` Guest Name: ${guest_name}` : ""}</div>
          <div> {time_of_entry ? ` Time of Entry: ${convertTimeToAMPM(time_of_entry)}` : ""}</div>
          <div> {time_of_exit ? `Time of Exit: ${convertTimeToAMPM(time_of_exit)}` : ""}</div>
          <div> {all_day ? ` All Day: ${all_day}` : ""}</div>
          <div> {visitor_name ? `Visitor Name: ${visitor_name}` : ""}</div>
          <div> {helper_name ? ` Helper Name: ${helper_name}` : ""}</div>
          <div> {expected_time ? ` Expected Time: ${convertTimeToAMPM(expected_time)}` : ""}</div>
          <div>
  {approved_days ? (
    <>
      Approved Days:{" "}
      {approved_days.map((days, index) => (
        <span key={index}>{days} </span>
      ))}
    </>
  ) : (
    ""
  )}
</div>
        </div>
      </div>
    </div>
  );
}
