import React from 'react'
import WingList from './data/WingList'
import SocietyHeader from '../../components/societyHeader'
import SocietyHeaderView from '../../components/societyHeaderView'
import logo1 from "../../assets/images/hamptom.png"
import { BreadCrumb } from 'primereact/breadcrumb'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

export default function ManageWings(props) {
    const { id } = useParams();
    const { society, image } = JSON.parse(localStorage.getItem("society_info"))
    const home = { icon: 'pi pi-home' }

    const items = [
        { label: 'Dashboard' },
        { label: 'Manage Society' },
        { label: 'Manage Wings' }
    ];

    const dataToPass = { image, society, id, };

    return (
        <div>
            <div className='d-flex align-items-center bg-white rounded-2'>
                <BreadCrumb model={items} home={home} />
            </div>
            <div className='header'>
                <SocietyHeaderView
                    imageUrl={decodeURIComponent(image)}
                    societyName={society}
                    defaultRouter="/dashboard" />
            </div>
            <div className='card p-3'>

                <div className='table-body'>
                    <WingList id={id} dataToPass={dataToPass} />
                </div>

            </div>

        </div>
    )
}
