import React, { useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import No_image_available from "../assets/images/avatar.jpg";

export default function ParkingList(props) {

    const [isBankDetailsVisible, setIsBankDetailsVisible] = useState(false);

    const handleOnClick = () => {
        setIsBankDetailsVisible(!isBankDetailsVisible);
    };

    const { name, number, apartmentnumber, image, BN, AHN, AC, IFSC } = props;

    console.log("v",name)
    return (
        <div className="card w-100 border-0 shadow-sm mb-1" style={{ padding: "5px 10px" }}>
            <div className="d-flex align-items-center flex-column flex-sm-row">
               
                
                <LazyLoadImage
        
            src={image ? image : No_image_available}
            alt="Document"
            className="rounded mr-md-4"
            style={{ width: "60px", height: "60px" }}
          />
                <div className="ms-3 mt-2">
                    <h6 className="font-weight-bold mb-1">{name}</h6>
                    <p className="font-weight-bold mb-0">{number}</p>

                 
                </div>
            </div>
        </div>
    )
}
