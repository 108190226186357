import React from "react";
import UserProfile from "../../components/userProfile";
import avatar from "../../assets/images/avatar.jpg";
import banner from "../../assets/images/Debit.png";
import doc from "../../assets/images/Debit.png";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SocietyHeader from "../../components/societyHeader";
import logo1 from "../../assets/images/hamptom.png";
import AllFamily from "../../components/AllFamily";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import SocietyHeaderView from "../../components/societyHeaderView";
import No_image_available from "../../../src/assets/images/avatar.jpg";
import VistingDetail from "../../components/vistingDetail";

export default function ViewVisting(props) {
  console.log(props);
  let { id } = useParams();
  console.log(id);
  // let { society_id } = useParams();
  // let { id, society_id, imageUrl, societyName } = useParams();
  // console.log('imageUrl:', imageUrl);
  // console.log('societyName:', societyName);
  const { state } = props.location;
  const image = state && state.image;
  const society = state && state.society;
  const society_id = state && state.society_id;
  const uploadDocumentLink = state && state.uploadDocumentLink;

  const history = useHistory();
  const [data, setdata] = useState([]);
  const [user, setuser] = useState("");
  const token = localStorage.getItem("token");
  const home = { icon: "pi pi-home" };

  const items = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "View Vistings" },
  ];
  useEffect(() => {
    datafetch();
  }, []);

  const datafetch = () => {
    // setIsLoading(true);
    // const offset = (currentPage - 1) * pageSize;
    // const limit = pageSize;

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}listAllRequests`,
      headers: { Authorization: "Bearer " + token },
      // params: { limit, offset }
      params: { id: id, society_id: society_id },
    };

    axios
      .request(config)
      .then((response) => {
        // setTotalItems(response.data.count);
        console.log(response.data.data.allData);
        setdata(response.data.data.allData);
        setuser(response.data.data.allData.user_details);
        // setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //
  console.log(user);
  console.log(data);
  return (
    <div>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
        <button
          onClick={() => history.goBack()}
          className="btn ms-auto me-2 btn-sm btn-primary rounded-4"
        >
          Back
        </button>
      </div>
      <div className="header">
        {/* <SocietyHeader
          imageUrl={logo1}
          societyName="The Hampton Homes"
          defaultRouter="/dashboard" /> */}
        <SocietyHeaderView
          imageUrl={image}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>
      <div className="card shadow-lg p-4">
        <div className="row">
          <div className="col-12 col-md-12 col-xl-12">
            {data &&
              data.map((userData, index) => (
                <UserProfile
                  banner={banner}
                  avatar={
                    userData.user_details.profile_image
                      ? userData.user_details.profile_image
                      : No_image_available
                  }
                  firstName={userData.user_details.first_name}
                  lastName={userData.user_details.last_name}
                  email={userData.user_details.email}
                  phone_primary={userData.user_details.phone_primary}
                  apartmentnumber={
                    userData.apartment_details.appartmentNumber +
                    "-" +
                    userData.wing.name
                  }
                  //   landnumber={userData.landNumber}
                  //   address={userData.address}
                  //   image={doc}
                  //   ranking={userData.ranking}
                  //   link="#"
                  //   name2={userData.name2}
                  //   title={userData.title}
                />
              ))}
            {/* <UserProfile
              banner={banner}
              avatar={avatar}
            //   name={data.user_details.
            //     first_name}
              email="john@example.com"
              number="9876543210"
              apartmentnumber="D-123"
              landnumber="91-11-1234 5678"
              address="Fort Rd, Khejer Para, Manak Chowk, Amar Sagar Pol, "
              image={doc}
              ranking='2'
              link='#'
              name2="Judith A. Hayes"
              title='Owner'


            /> */}
          </div>

          <div></div>
        </div>
      </div>

      <div className="card shadow-lg p-4">
        <div className="row">
          <div className="col-12 col-md-12 col-xl-12">
            {data &&
              data.map((userData, index) => (
                <VistingDetail
                  type={userData.type}
                  issue={userData.issue}
                  issue_Description={userData.issue_Description}
                  request_date={userData.request_date}
                  status={userData.status}
                  ticket_id={userData.ticket_id}
                  approved_date={userData.approved_date}
                  delivery_company_name={userData.delivery_company_name}
                  date_of_entry={userData.date_of_entry}
                  date_of_exit={userData.date_of_exit}
                  guest_name={userData.guest_name}
                  time_of_entry={userData.time_of_entry}
                  time_of_exit={userData.time_of_exit}
                  allow_entry_type={userData.allow_entry_type}
                  all_day={userData.all_day}
                  approved_days
                  ={userData.approved_days
                  }
                  helper_name={userData.helper_name
                  }

visitor_name={userData.
    visitor_name
    }

    expected_time={userData.
        expected_time}
  


                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
