// import React from 'react'
// import { Link } from 'react-router-dom/cjs/react-router-dom'
// import TenantList from './tenantList'
// import doc from "../assets/images/Debit.png"
// import { CgList } from "react-icons/cg";

// const collapaseIcon = {
//     fontSize: '20px'
// }

// export default function AllTenants() {

//     return (
//         <div className="card shadow-lg p-4 border-0 w-100">

//             <div className="d-flex align-items-center">
//                 <h5 lassName="">All Tenants List</h5>
//                 <Link className="ms-auto" ><CgList style={collapaseIcon} /></Link>
//             </div>
//             <TenantList
//                 image={doc}
//                 name='Akshay Kumar'
//                 number='9876543210'
//                 apartmentnumber='A 105' />
//             {/* <TenantList
//                 image={doc}
//                 name='Akshay Kumar'
//                 number='9876543210'
//                 apartmentnumber='A 105' />
//             <TenantList
//                 image={doc}
//                 name='Akshay Kumar'
//                 number='9876543210'
//                 apartmentnumber='A 105' /> */}
//         </div>
//     )
// }


import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import TenantList from './tenantList'
import doc from "../assets/images/Debit.png"
import { CgList } from "react-icons/cg";

const collapaseIcon = {
  fontSize: '20px'
}

export default function AllTenants(props) {
  const { tenant } = props;
  
  return (
  
    <div className="card shadow-lg p-4 border-0 w-100">
      <div className="d-flex align-items-center">
        <h5 className="">All Tenants List</h5>
      </div>
      {tenant && tenant.length > 0 ? (
        tenant.map((member, index) => {
          if (member.role === "tenant") {
            console.log("Tenant data:", tenant);
            return (
              <TenantList
                key={index}
                image={member.profile_image}
                number={member.phone_primary}
                email={member.email}
                name={`${member.first_name} ${member.last_name}`}
                relation={member.Relation}
              />
            );
          } else {
            return null; // Skip rendering if the role is not tenant
          }
        })
      ) : (
        <span className="error-message" style={{ color: "red" }}>No Tenants Added</span>
      )}
    </div>
  )
}
