import {
  MdApartment,
  MdBookOnline,
  MdDeleteOutline,
  MdOutlineEdit,
  MdOutlineRemoveRedEye,
  MdSecurity,
} from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../../assets/images/Debit.png";
import { Menu } from "primereact/menu";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { deleteAmenity, showAllAmenityList } from "../../../utils/asyncFun";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

const AmenitiesList = ({ imageUrl, societyName, id, data, onPageChange }) => {
  const dataToPass = { imageUrl: imageUrl, society: societyName, id: id };
  const history = useHistory();

  const menu = useRef(null);
  const items = [
    {
      label: "2 More Breaks",
      items: [
        {
          label: "04:00 05:00",
          icon: "pi pi-clock",
        },
        {
          label: "08:00 09:00",
          icon: "pi pi-clock",
        },
      ],
    },
  ];

  const [page, setPage] = useState(1);
  const { totalCount } = data;
  const totalPages = Math.ceil(totalCount / 5);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    onPageChange(pageNumber);
  };

  const dispatch = useDispatch();

  const handleDelete = (e, Id) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteAmenity({ method: "DELETE", url: `deleteAmenity?dataId=${Id}`, data: "" }))
        .then(() => {
          dispatch(showAllAmenityList({method: "GET", url: `showAllAmenityList?society_id=${id}`, data: ""}))
          Swal.fire("Deleted!", "", "success")
        });
      }
    });
  };

  return (
    <>
      <div className="table_header mb-3">
        <div className="d-flex flex-wrap align-items-center">
          {/* <Link to="/manage-society" className='btn me-2 btn-sm btn-primary rounded-4 '>Back</Link> */}
          <button
            onClick={() => history.goBack()}
            className="btn me-2 btn-sm btn-primary rounded-4 "
          >
            Back
          </button>
          <h5 className="mb-0 fs-6 d-flex align-item-center gap-2">
            <MdBookOnline /> All Amenities List
          </h5>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">INFO</th>
              <th scope="col">DESCRIPTION</th>
              <th scope="col">BOOKING LIMITS</th>
              <th scope="col">AVAILABILITY</th>
              <th scope="col">BREAK TIME</th>
              <th scope="col">STATUS</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((el, i) => (
              <tr>
                <th scope="row">{i + 1}</th>
                <td>
                  <div className="user_info d-flex  align-items-center">
                    <img
                      src={el?.amenity_id?.image}
                      alt="logo"
                      className="avatar"
                    />
                    <div className="ms-3">
                      <strong>
                        <span className="txt-head">
                          {el?.amenity_id?.amenity}
                        </span>
                      </strong>
                    </div>
                  </div>
                </td>
                <td>
                  <p className="text-truncate" style={{ maxWidth: "200px" }}>
                    {el?.description}
                  </p>
                </td>
                <td>{el?.advanceBookingNoLimit ? "Unlimit" : `${el?.advanceBookingLimit} Days`}</td>
                <td>{el?.amenity_type == "all_day" ? "All days" : el?.weekly_AllowedDays?.map((day) => `${day.charAt(0).toUpperCase() + day.slice(1)} `)}</td>
                <td>
                  {el?.break_time?.map((e) => <><span>{`${e?.break_startTime} - ${e?.break_endTime}`}</span><br/></>)}
                  {/* <div className="bagde-container">
                    <Menu model={items} popup ref={menu} id="popup_menu" />
                    <button
                      className="btn btn-sm p-shadow-2"
                      label="Show"
                      icon="pi pi-bars"
                      onClick={(event) => menu.current.toggle(event)}
                      aria-controls="popup_menu"
                      aria-haspopup
                    >
                      + <span className="counter">2</span> More
                    </button>
                  </div> */}
                </td>
                <td>
                  <div className="">
                    <i
                      className="pi pi-check"
                      style={{ color: "slateblue" }}
                    ></i>
                    {el?.status}
                  </div>
                </td>
                <td>
                  <div className="action_btn">
                    <Link
                      to={{
                        pathname: `/view-amenity/${el?._id}/${id}`,
                        state: dataToPass,
                      }}
                    >
                      <MdOutlineRemoveRedEye />
                    </Link>
                    <button
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                      onClick={(e) => handleDelete(e, el?._id)}
                    >
                      <MdDeleteOutline />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="table-footer d-md-flex justify-content-md-between align-items-center">
        <p className="mb-0">
          Showing {totalCount > 0 ? 1 : 0} to {Math.min(10, totalCount)} of{" "}
          {totalCount} entries
        </p>
        <ul className="pagination pagination-md">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(
            (pageNumber) => (
              <li
                key={pageNumber}
                className={`page-item ${pageNumber === page ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            )
          )}
        </ul>
      </div>
    </>
  );
};

export default AmenitiesList;
