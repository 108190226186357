// import React from "react";
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import No_image_available from "../assets/images/avatar.jpg"
import defaultImages from "../assets/images/avatar.jpg";


export default function UserProfile(props) {
  const { role, banner, avatar=defaultImages, type, firstName, lastName, email, number, BN, AHN, AC, IFSC, apartmentnumber, landnumber, userType, id, phone_primary } = props;

  return (
    <div className="card shadow-lg border-0 p-3 mb-2" >
      <div
        className="bg"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          borderRadius: "16px",
          height: "131px",
          width: "100%",
        }}
      />
      <div className="d-flex justify-content-around align-items-center text-center flex-column">
        <div>
          <img
            src={avatar}
            alt="Avatar"
            className="mx-auto rounded-circle"
            style={{ height: "87px", width: "87px", objectFit: "cover", marginTop: "-43px", marginLeft: "40px" }}
          />
          <h6 className="fw-semi-bold mb-0  mt-2">{firstName + " " + lastName} <span className="badge bg-secondary">{userType}</span></h6>
          <p className="text-color-secondary font-size-sm">{email}</p>
        </div>
        <div>
          <div className="d-flex w-max-content mx-auto gap-3">
         
            <div className="mx-auto me-60px text-center">
              <p className="text-primary font-size-1xl font-weight-700">M-Number</p>
              <p className="text-secondary font-size-sm font-weight-400">
                {/* {number} */}
                {phone_primary}</p>
            </div>
            <div className="mx-auto me-60px text-center">
              <p className="text-primary font-size-1xl font-weight-700">Apartment Number</p>
              <p className="text-secondary font-size-sm font-weight-400">{apartmentnumber}</p>
            </div>
            <div className="mx-auto me-60px text-center">
              <p className="text-primary font-size-1xl font-weight-700">Role</p>
              <p className="text-secondary font-size-sm font-weight-400">
              
                {role}</p>
            </div>
            {/* <div className="mx-auto text-center">
              <p className="text-primary font-size-1xl font-weight-700">Landline Number</p>
              <p className="text-secondary font-size-sm font-weight-400">{landnumber}</p>
            </div> */}
          </div>
        </div>


      </div>

      {/* <div className="bank-data">
        <h6 className="">Bank Details</h6>
        <div className="card p-2 shadow-lg border-0">
          <div className="d-flex align-items-center gap-2">
            <p className="text-primary font-size-1xl mb-2 font-weight-700">Bank Name : </p>
            <p className="text-secondary mb-2 font-weight-400">{BN}</p>
          </div>
          <div className="d-flex align-items-center gap-2">
            <p className="text-primary  mb-2 font-weight-700">Account Holder Name : </p>
            <p className="text-secondary mb-2 font-weight-400">{AHN}</p>
          </div>
          <div className="d-flex align-items-center gap-2">
            <p className="text-primary mb-2 font-weight-700">Account Number : </p>
            <p className="text-secondary mb-2 font-weight-400">{AC}</p>
          </div>
          <div className="d-flex align-items-center gap-2">
            <p className="text-primary mb-2 font-weight-700">IFSC Code : </p>
            <p className="text-secondary mb-2 font-weight-400">{IFSC}</p>
          </div>
        </div>
      </div> */}


    </div>


  );
}
