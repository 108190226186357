import React, { useState } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from "primereact/utils";


export default function PlatformSettings(props) {

    const formik = props.formik;
    const [service, setService] = useState(false);
    const [parking, setParking] = useState(false);
    const [water, setWater] = useState(false);
    const [insurance, setInsurance] = useState(false);
    const [property, setProperty] = useState(false);
    const [sinking, setSinking] = useState(false);
    const [occupancy, setOccupancy] = useState(false);
    const [others, setOthers] = useState(false);

    const [isEditing, setIsEditing] = useState(false);
    const [platformFee, setPlatformFee] = useState('455');

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };

    const handleInputChange = (event) => {
        setPlatformFee(event.target.value);
    };

    return (
        <div className='card p-4 border-0 shadow-sm'
            header="Platform settings"
            style={{ padding: '0px', marginBottom: '30px', boxShadow: '0px 18px 40px rgba(112, 144, 176, 0.12)' }}

        >
            <div style={{ fontWeight: '500', fontSize: '20px', marginBottom: '10px' }}>Maintenance Bill Particulars:</div>
            <div style={{ paddingTop: '1.5px', paddingBottom: '2px', paddingLeft: '2px', lineHeight: '1.25' }}>

                {[
                    { state: service, setter: setService, label: 'Service Fee' },
                    { state: parking, setter: setParking, label: 'Parking Fee' },
                    { state: water, setter: setWater, label: 'Water Inlet' },
                    { state: insurance, setter: setInsurance, label: 'Insurance' },
                    { state: property, setter: setProperty, label: 'Property Tax' },
                    { state: sinking, setter: setSinking, label: 'Sinking Funds' },
                    { state: occupancy, setter: setOccupancy, label: 'Non Occupancy Charge:' },
                    { state: others, setter: setOthers, label: 'Others' },
                ].map((item, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', paddingTop: '2px', marginBottom: '5px' }}>
                        <div style={{ marginTop: '0.25px' }}>
                            <InputSwitch checked={item.state} onChange={() => item.setter(!item.state)} />
                        </div>
                        <div style={{ width: '80%', marginLeft: '5px' }}>
                            <div style={{ color: item.state ? '#1D1E5E' : '#A0AEC0', fontSize: '16px', fontWeight: '500' }}>
                                {item.label}
                            </div>
                        </div>
                    </div>
                ))}
                <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0px' }}>
                    <div style={{ width: '30%', marginRight: '2px' }}>
                        <InputText type="number" value={platformFee} onChange={handleInputChange} name="fee" placeholder="Platform fee" className={classNames({
                                                        "input-invalid": Boolean(formik.touched.fee && formik.errors.fee),
                                                    }, "form-control form-control-alternative")} />
                                                      {Boolean(formik.touched.fee && formik.errors.fee) && (
                                                    <small className="p-error">{formik.errors.fee}</small>
                                                )}
                    </div>
                    <div className="p-my-3" onClick={handleEditClick}>
                        <Button
                            label="edit"
                            icon="pi pi-pencil"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
