import React, { useRef, useState, useEffect, createRef } from "react";
import { Menu } from "primereact/menu";
import { MdBookOnline, MdFilter1 } from "react-icons/md";
import { makeApiCall } from '../../../utils/api'
import mement from 'moment'
import { SiMongoose } from "react-icons/si";
import { convertTime } from '../../../utils/globalFun'

import { useHistory } from "react-router-dom";
import moment from "moment";
const BookedAmenitiesList = ({ id }) => {
  const history = useHistory();
  const [bookedAmenities, setBookedAmenities] = useState([])
  const [amenityType, setAmenityType] = useState([])
  const [bookStatus, setBookStatus] = useState("")
  const [type, setType] = useState("")
  const [date, setDate] = useState("")

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);
  const [offsetentry, setoffsetentry] = useState(0);

  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }


  const menuRefs = useRef([1, 2, 3, 4, 5].map(() => createRef()));

  async function getAmenityType() {
    const params = {
      society_id: id,
    };
    console.log("params", params)

    const appartmentResponse = await makeApiCall(
      "getAmenityName",
      "GET",
      null,
      params,
      true
    );

    if (appartmentResponse) {
      setAmenityType(appartmentResponse?.data ?? [])
    }
  }


  async function getAmenityBooking(currentPage) {
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);

    const params = {
      society_id: id,
      amenity_type: type,
      ...(bookStatus ? { status: bookStatus } : {}),
      ...(date ? { date: date } : {}),
      offset: offset,
      limit: limit
    };

    console.log("params", params)

    const response = await makeApiCall(
      "showAllAmenityBooking",
      "GET",
      null,
      params,
      true
    );

    if (response) {
      setBookedAmenities(response?.data ?? [])
      setTotalItems(response.count);
      // setentry(response.data.length + offset);
    }
  }


  useEffect(() => {
    getAmenityType()
    getAmenityBooking(1)
    setCurrentPage(1)
  }, [type, bookStatus, date])

  useEffect(() => {
    getAmenityBooking(currentPage)
  }, [currentPage])



  // useEffect(() => {
  //   menuRefs.current = bookedAmenities.map(() => createRef());
  // }, [bookedAmenities]);


  function generateMenuItems(bookingList, index) {
    if (bookingList && Array.isArray(bookingList)) {
      return bookingList.map((element) => ({
        label: <span className="badge badge-secondary">
          {`${convertTime(element.startTime)} to ${convertTime(element.endTime)}`}
        </span>,
        index: index,
      }));
    } else {
      return [];
    }
  }


  return (
    <>
      <div className="table_header mb-3">
        <div className="d-flex lign-items-center">
          <div className="d-flex align-items-center">
            <button className="btn me-2 btn-sm btn-primary rounded-4 " onClick={()=> history.goBack()}>
              Back
            </button>
            <h5 className="mb-0 fs-6 d-flex align-item-center gap-2">
              <MdBookOnline /> All Amenities List
            </h5>
          </div>
          <div className="ms-auto d-flex align-items-center gap-2">
            <div className="filteCon">
              <h5 className="mb-0 text-nowrap fs-6 d-flex align-item-center gap-2">
                <MdFilter1 /> filter by:
              </h5>
            </div>
            {console.log("amenityType", amenityType)}
            <select
              className="form-select"
              aria-label="Default select example"
              onClick={(e) => setType(e.target.value)}
            >
              {/* selected */}
              <option value="" selected >Select Amenity</option>
              {/* {console.log("element.appartment_list", element.appartment_list)} */}
              {
                amenityType.length !== 0 && amenityType.map((ele, index) => <option value={ele.amenity} key={index}>{ele.amenity}</option>)
              }
            </select>
            <select
              className="form-select"
              aria-label="Default select example"
              onClick={(e) => setBookStatus(e.target.value)}
            >
              {/* selected */}
              <option value="">Select Status</option>
              <option value="booked">Booked</option>
              <option value="cancelled">Cancelled</option>
            </select>
            <input type="date" onChange={(e) => setDate(e.target.value)} className="form-control" />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">USERNAME</th>
              {/* <th scope="col">TIME</th> */}
              <th scope="col">APARTMENT ID</th>
              <th scope="col">MOBILE NO</th>
              <th scope="col">BOOKING DATE</th>
              <th scope="col">SLOTS</th>
              <th scope="col">AMENITY NAME</th>
              <th scope="col">AMENITY TYPE</th>
              <th scope="col">STATUS</th>
            </tr>
          </thead>
          <tbody>
            {
              bookedAmenities.length !== 0 && bookedAmenities.map((element, idx) => (
                <tr key={idx}>
                  <td scope="col">{(currentPage - 1) * pageSize + idx + 1}</td>
                  <td scope="col">{element?.user_id?.first_name}</td>
                  {/* <td scope="col">12:00 AM</td> */}
                  <td scope="col">{`${element?.wing_id?.name} - ${element?.apartment_id?.appartmentNumber} `} </td>
                  <td scope="col">{element?.user_id?.phone_primary}</td>
                  <td scope="col">{moment(element?.booking_date).format("DD-MM-YYYY")}</td>
                  {/* <td scope="col"><span className="badge badge-secondary">9:00 AM to 9:30 AM</span></td> */}
                  <td style={{ overflowX: "scroll" }}>
                    {element.all_day ?
                      <div className="bagde-container">
                        <span className="badge badge-secondary">
                          Full Day
                        </span>
                      </div>
                      :
                      element.booking_time &&
                        element.booking_time.length > 0 ? (
                        <div>
                          <div className="bagde-container">
                            {
                              <span className="badge badge-secondary">
                                {`${convertTime(element.booking_time[0].startTime)} to ${convertTime(element.booking_time[0].endTime)}`}
                              </span>
                            }
                            {/* {
                              element.booking_time.map(element => (
                                <span className="badge badge-secondary">
                                  {`${convertTime(element.startTime)} to ${convertTime(element.endTime)}`}
                                </span>
                              ))
                            } */}
                          </div>
                          {element.booking_time.length > 1 && (
                            <div className="bagde-container">
                              {
                                console.log(`menuRefs.current[${idx}]`, menuRefs.current[idx])
                              }
                              <Menu
                                model={generateMenuItems(
                                  element.booking_time.slice(1),
                                  idx
                                )}
                                popup
                                ref={menuRefs.current[idx]}
                                id={`popup_menu_${idx}`}
                              />
                              <button
                                className="btn btn-sm p-shadow-2"
                                label="Show"
                                icon="pi pi-bars"
                                onClick={(event) => {
                                  menuRefs?.current[idx]?.current?.toggle(
                                    event
                                  );
                                }
                                }
                                aria-controls="popup_menu"
                                aria-haspopup="true"
                              >
                                <span className="counter">
                                  {element.booking_time.length - 1} +More
                                </span>{" "}
                              </button>
                            </div>
                          )}
                        </div>
                      ) : (
                        <span>
                          <i
                            className="pi pi-times"
                            style={{ color: "red" }}
                          ></i>
                        </span>
                      )}
                  </td>
                  <td scope="col">{element?.amenity_timing_id?.name}</td>
                  <td scope="col">{element?.amenity_timing_id?.amenity_id?.amenity}</td>
                  <td scope="col">{element?.status === "booked" ? <span className="badge badge-success">Booked</span> : <span className="badge badge-danger">Cancelled</span>}</td>
                </tr>
              ))
            }
            {/* <tr>
              <td scope="col">1</td>
              <td scope="col">Bhiswa</td>
              <td scope="col">12:00 AM</td>
              <td scope="col">B1 201</td>
              <td scope="col">9876543210</td>
              <td scope="col">21/10/2024</td>
              <td scope="col"><span className="badge badge-secondary">9:00 AM to 9:30 AM</span></td>
              <td scope="col">Swimming pool</td>
              <td scope="col"><span className="badge badge-success">Booked</span></td>
            </tr>
            <tr>
              <td scope="col">1</td>
              <td scope="col">Bhiswa</td>
              <td scope="col">12:00 AM</td>
              <td scope="col">B1 201</td>
              <td scope="col">9876543210</td>
              <td scope="col">21/10/2024</td>
              <td scope="col"><span className="badge badge-secondary">9:00 AM to 9:30 AM</span></td>
              <td scope="col">Swimming pool</td>
              <td scope="col"><span className="badge badge-success">Booked</span></td>
            </tr> */}
          </tbody>
        </table>
      </div>
      {
        bookedAmenities.length !== 0 ?
          <div className="table-footer d-md-flex justify-content-md-between align-items-center">
            <p className="mb-0">
              Showing {offsetentry + 1} to {offsetentry + bookedAmenities.length} of{" "}
              <span id="total-entries">{totalItems}</span> entries
            </p>
            <nav>
              <ul className="pagination pagination-md">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>


                {pageNumbers.map((pageNumber) => {
                  // Define the minimum and maximum page numbers to display
                  const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                  const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                  if (pageNumber >= minPage && pageNumber <= maxPage) {
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${currentPage === pageNumber ? "active" : ""
                          }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                <li className="page-item">
                  <button className="page-link" onClick={nextPage}>
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          : ""
      }
    </>
  );
};

export default BookedAmenitiesList;
