// AvatarContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { simpleCommonFun } from '../utils/globalFun';

const AvatarContext = createContext();

export const AvatarProvider = ({ children }) => {
  const [avatarUrl, setAvatarUrl] = useState('');

  const data = simpleCommonFun({method: "GET", url: "getsocietydetail", data: {}})

  useEffect(() => {

    console.log('data--------->', data);
    
  }, [])

  return (
    <AvatarContext.Provider value={{ avatarUrl, setAvatarUrl }}>
      {children}
    </AvatarContext.Provider>
  );
};

export const useAvatar = () => useContext(AvatarContext);
