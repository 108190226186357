import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from 'moment';
import 'moment-duration-format';
import Swal from "sweetalert2";

// Uni fun-
export const uniFun = (type) => {
    return createAsyncThunk(type, async ({ method, url, data, message = "Saved!" }) => {
        console.log("message", message)
        try {
            const res = await axios({
                method,
                url: `${process.env.REACT_APP_BASE_API}${url}`,
                data,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (method != "GET") {
                Swal.fire(message, "", "success")
            }
            if (res.data.code === 409 || res.data.code === 403 || res.data.code === 401) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: res.data.message,
                });
            }
            return res.data
        }
        catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Network Error",
            });
        }
    })
}

// Get time difference-
export const getTimeDifference = (dateTime) => {
    const postTime = moment(dateTime);
    const duration = moment.duration(moment().diff(postTime));
    const totalSeconds = duration.asSeconds();

    if (totalSeconds < 60) {
        return `${Math.floor(totalSeconds)} sec`;
    } else if (totalSeconds < 3600) {
        return `${Math.floor(totalSeconds / 60)} min`;
    } else if (totalSeconds < 86400) {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        return `${hours} h ${minutes} min`;
    } else {
        const days = Math.floor(totalSeconds / 86400);
        return `${days} day${days > 1 ? 's' : ''}`;
    }
};

// Simple common function-
export const simpleCommonFun = async ({ method, url, data }) => {
    try {
        const result = await axios({
            method,
            url: `${process.env.REACT_APP_BASE_API}${url}`,
            data,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
        return result.data
    }
    catch (error) {
        console.log(error);
    }
}

export const convertTime = (time24) => {
    if(!time24) return ""
    const [hours, minutes, seconds] = time24.split(':').map(Number);
    const amPM = (hours < 12) ? 'AM' : 'PM';
    const hours12 = (hours % 12) || 12;
    return `${hours12 == 0 ? "00" : hours12}:${minutes == 0 ? "00" : minutes}${seconds ? `:${seconds == 0 ? "00" : seconds}`: ''} ${amPM}`;
}