import React from 'react'
import EmergencyContactList from './data/EmergencyContactList'

export default function ManageContactList() {
    return (
        <div>
            <div className='card p-3'>
                <div className='table-body'>
                    <EmergencyContactList />
                </div>
            </div>

        </div>
    )
}
