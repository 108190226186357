import React, { useState } from "react";
import user from "../../../assets/images/avatar.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { CgCalendarDates, CgTime } from "react-icons/cg";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import Loading from "../../ManageApp/data/Loading";

const ComplaintsList = (props) => {
  const { id } = props;
  console.log(id);
  const history = useHistory();
  const [data, setdata] = useState([]);
  const token = localStorage.getItem("token");

  const [totalItems, setTotalItems] = useState(0);
  //    const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);

  useEffect(() => {
    datafetch();
  }, [currentPage, searchQuery]);

  const datafetch = () => {
    // setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getcomplaintlist`,
      headers: { Authorization: "Bearer " + token },
      params: { society_id: id, offset, limit, search: searchQuery },
      // params: {search: searchQuery,limit,offset,society_id:id, role: selectedRole }
    };
    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.count);
        console.log(response.data);
        setdata(response.data);
        // console.log(response.data.data.length)
        setentry(response.data.data.length + offset);
        //         console.log(id);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="table_header mb-3">
        <div className="d-flex flex-wrap align-items-center">
          <button
            onClick={() => history.goBack()}
            className="btn me-2 btn-sm btn-primary rounded-4 "
          >
            Back
          </button>
          <h4 className="fw-semi-bold fs-5 mb-0">General Complaints List</h4>
          <div className="ms-auto d-flex align-items-center gap-2">
            <div class="search_box ">
              <input
                placeholder="Search"
                class="chakra-input "
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {data && data.data.length > 0 ? (
        <div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">MADE BY</th>
                  <th scope="col">APARTMENT NO</th>
                  <th scope="col">ISSUE</th>
                  <th scope="col">DETAILS</th>
                  <th scope="col">REQUESTED DATE</th>
                  <th scope="col">RESOLVED DATE</th>
                  <th scope="col">STATUS</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.data &&
                  data.data.map((data, idx) => (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>
                        {data.user_id.first_name + " " + data.user_id.last_name}
                      </td>
                      <td>{data.apartment_id.appartmentNumber+"-"+data.wing_details.name
}</td>
                      <td>{data.issue}</td>
                      <td>{data.issue_Description}</td>
                      <td>
                        {" "}
                        <p>
                          {" "}
                          <CgCalendarDates /> {data.request_date}
                          {/* <span className='time'><CgTime /> 10:00 AM</span>  */}
                        </p>
                      </td>
                      <td>
                        {" "}
                        {data.resolved_date ? (
                          <div>
                            <CgCalendarDates />{" "}
                            {
                                 new Date(data.resolved_date)
                                .toISOString()
                                .split("T")[0]
                            }
                          </div>
                        ) : (
                          "-"
                        )}{" "}
                      </td>
                      <td>{data.status}</td>
                    </tr>
                  ))}

                {/* 
                        <tr>
                            <td>1</td>
                            <td>Ram Kishan</td>
                            <td>A 101</td>
                            <td>Lift</td>
                            <td>Lift door isn’t closing properly</td>
                            <td> <p> <CgCalendarDates /> 05-08-2024 <span className='time'><CgTime /> 10:00 AM</span> </p></td>
                            <td> - </td>
                            <td><h6 className='badge bg-danger'><i className="pi pi-check" style={{ color: '#fff' }}></i>open</h6></td>

                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Ram Kishan</td>
                            <td>A 101</td>
                            <td>Lift</td>
                            <td>Lift door isn’t closing properly</td>
                            <td> <p> <CgCalendarDates /> 05-08-2024 <span className='time'><CgTime /> 10:00 AM</span> </p></td>
                            <td> - </td>
                            <td><h6 className='badge bg-secondary'><i className="pi pi-check" style={{ color: '#fff' }}></i>In Progress</h6></td>

                        </tr>

                        <tr>
                            <td>1</td>
                            <td>Ram Kishan</td>
                            <td>A 101</td>
                            <td>Lift</td>
                            <td>Lift door isn’t closing properly</td>
                            <td> <p> <CgCalendarDates /> 05-08-2024 <span className='time'><CgTime /> 10:00 AM</span> </p></td>
                            <td> - </td>
                            <td><h6 className='badge bg-primary'><i className="pi pi-check" style={{ color: '#fff' }}></i>Overdue</h6></td>

                        </tr> */}
              </tbody>
            </table>
          </div>
          {/* <div className="table-footer d-md-flex justify-content-md-between align-items-center">
                <p className='mb-0'>Showing 1 to 10 of <span id='total-entries'>57</span>  entries</p>
                <ul class="pagination pagination-md ">
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Previous">&laquo;</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">1</Link></li>
                    <li class="page-item active"><Link class="page-link " href="#">2</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">3</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">4</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">5</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">6</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">7</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">8</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">9</Link></li>
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Next">&raquo;</Link></li>
                </ul>
            </div> */}
          <div className="table-footer d-md-flex justify-content-md-between align-items-center">
            <p className="mb-0">
              Showing {offsetentry + 1} to {entry} of{" "}
              <span id="total-entries">{totalItems}</span> entries
            </p>
            <nav>
              <ul className="pagination pagination-md">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>

                {pageNumbers.map((pageNumber) => {
                  // Define the minimum and maximum page numbers to display
                  const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                  const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                  if (pageNumber >= minPage && pageNumber <= maxPage) {
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                <li className="page-item">
                  <button className="page-link" onClick={nextPage}>
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <h4>No data available</h4>
      )}
    </>
  );
};

export default ComplaintsList;
