import React from 'react'
import PostList from '../CommunityManagement/data/PostList'
import FaqList from './data/FaqList'

export default function FaqManage() {
  return (
    <div>
    <div className='card p-3'>

        <div className='table-body'>
            <FaqList />
        </div>

    </div>

</div>
  )
}
