import React, { useState, useEffect, useRef } from "react";
import { MdDeleteForever, MdDeleteOutline, MdOutlineEdit, MdUndo } from "react-icons/md";
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import Swal from "sweetalert2";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { listAllTrash, undoFromTrash } from '../../../utils/asyncFun';
const RecyclebinList = ({ id }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [offsetentry, setoffsetentry] = useState(0);
  const [selectedCity1, setSelectedCity1] = useState("");

  // --swal--

  const data = useSelector((store) => store?.data?.trashList);
  const trashList = data?.data ?? [];
  const totalItems = data?.count ?? 0

  function getTrashList() {
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);
    dispatch(listAllTrash({ method: "GET", url: `listAllTrash?society_id=${id}&type=${selectedCity1}&limit=${limit}&offset=${offset}`, data: "" }))
  }
  useEffect(() => {
    getTrashList()
  }, [currentPage, selectedCity1])


  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  
  for (let i = 1; i <= totalPages; i++) {

    pageNumbers.push(i);

  }



  const deletePost = (_id) => {
    console.log("delete");
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      console.log("result", result)
      if (result.isConfirmed) {
        dispatch(undoFromTrash({ method: "DELETE", url: `deleteFromTrash?_id=${_id}`, data: "" , message : "Deleted!"}))
          .then(() => {
            getTrashList()
          })
      }
    });
  };

  const undo = (_id) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You want to revert it!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, revert it!",
    }).then((result) => {
      console.log("result", result)
      if (result.isConfirmed) {
        dispatch(undoFromTrash({ method: "DELETE", url: `undoFromTrash?_id=${_id}`, data: "" , message : "Reverted!"}))
          .then(() => {
            getTrashList()
          })
      }
    });
  };



  const types = [
    { name: "All", value: "", code: "NY" },
    { name: "Residents", value: "user", code: "RM" },
    { name: "Wings/Unit", value: "wing", code: "LDN" },
    { name: "Total Security Guard", value: "Total Security Guard", code: "NY" },
    { name: "Request", value: "Request", code: "IST" },
    { name: "Gate Control", value: "Gate Control", code: "PRS" },
    { name: "Management", value: "Management", code: "PRS" },
    { name: "Tenants", value: "Tenants", code: "PRS" },
    { name: "Vacant Units", value: "Vacant Units", code: "PRS" },
    { name: "Parking Slots", value: "Parking Slots", code: "PRS" },
    { name: "Communities", value: "Communities", code: "PRS" },
    { name: "Amenities", value: "Amenities", code: "PRS" },
    // { name: "Finance", value: "Finance", code: "PRS" },
    // { name: "Maintenance Bills", value: "Maintenance Bills", code: "PRS" },
  ];

  const onCityChange = (e) => {
    setSelectedCity1(e?.target?.value);
    setCurrentPage(1)
    console.log("e>>>>>>>>>>>>", e?.target?.value)
  };

  return (
    <>
      <div className="table_header mb-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <button
            onClick={() => history.goBack()}
            className="btn me-2 btn-sm btn-primary rounded-4">
            Back
          </button>
          <h5 className="mb-0 fs-6 d-flex align-items-center gap-2">
            <MdDeleteForever /> Recycle Bin List
          </h5>
        </div>
        <div className="ms-auto d-flex align-items-center gap-2">
          <div className="search_box ">
            <Dropdown
              value={selectedCity1}
              options={types}
              onChange={onCityChange}
              optionLabel="name"
              placeholder="Select a type"
            />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">TYPE</th>
              <th scope="col">NAME</th>
              <th scope="col">DELETED DATE</th>
              <th scope="col">DELETED BY</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {
              trashList && trashList.length !== 0 ?

                trashList.map((element, index) => (
                  <tr key={index}>
                    <td>{(currentPage - 1) * pageSize + index + 1}</td>
                    <td>{element.type}</td>
                    <td>{element.name}</td>
                    <td>{moment(element.createdAt).format("DD/MM/YYYY")}</td>
                    <td>{`${element?.deletedBy_id?.first_name} ${element?.deletedBy_id?.last_name}`}</td>
                    <td>
                      <div className="action_btn cstm-actns-bttns">
                        <span>
                          <MdUndo onClick={() => undo(element._id)} />
                        </span>
                        <span onClick={() => deletePost(element._id)}
                        >
                          <MdDeleteOutline />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
                :
                ""
            }
          </tbody>
        </table>
      </div>
      {
        trashList.length !== 0 ?
          <div className="table-footer d-md-flex justify-content-md-between align-items-center">
            <p className="mb-0">
              Showing {offsetentry + 1} to {offsetentry + trashList.length} of{" "}
              <span id="total-entries">{totalItems}</span> entries
            </p>
            <nav>
              <ul className="pagination pagination-md">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>

                {pageNumbers.map((pageNumber) => {
                  // Define the minimum and maximum page numbers to display
                  const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                  const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                  if (pageNumber >= minPage && pageNumber <= maxPage) {
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${currentPage === pageNumber ? "active" : ""
                          }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                <li className="page-item">
                  <button className="page-link" onClick={nextPage}>
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          : ""
      }
    </>
  );
};

export default RecyclebinList;
