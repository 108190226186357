import React from "react";
import { FcComments, FcLike, FcShare } from "react-icons/fc";
import MediaCarousel from "./Mediacarousel";


export default function Post(props) {
    const { avatar, name, apartment, date, time, description, likes, comments, share, images, ...rest } = props;

    return (
        <div className="card border-0 shadow-lg mb-4">
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <img 
                            src={avatar}
                            alt="User Avatar"
                            className="rounded-circle mr-3"
                            style={{ width: '48px', height: '48px' }}
                        />
                        <div>
                            <h6 className=" ms-3 mb-1">{name}</h6>

                            <div className=" ms-3 d-flex align-items-center">
                                <p className="text-muted mb-0">{apartment}</p>
                                <span style={{display:"block", marginRight:"0.2rem"}} className="text-secondary mr-2">
                                    {date}
                                </span>
                                <span className="text-secondary">
                                    {time}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>

                <div className="my-2">
                    <p className="text-secondary mb-0">{description}</p>
                </div>


        <div>
        <MediaCarousel mediaUrls={images}/>
        </div>


                <div className="d-flex justify-content-center align-items-center gap-2 w-100 overflow-auto mb-2">
                    
                          {/* // }
                        // images?.map((el) =>(<>
                        // <img src={el} alt={el} className="img-fluid" />
                        // </>))

                    // } */}
                </div>

                <div class="container mt-3">
                    <div class="d-flex justify-content-between flex-wrap">
                        <button class="btn shadow-lg" >

                            <FcLike /> Like {likes}
                        </button>
                        <button class="btn shadow-lg" >

                            <FcComments /> Comment {comments}
                        </button>
                        {/* <button class="btn shadow-lg" >

                            <FcShare /> Share {share}
                        </button> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
