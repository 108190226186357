import React, { useEffect, useState, useRef } from "react";
import Poll from "./components/Poll";
import team1 from "../../assets/images/Debit.png";
import { Menu } from "primereact/menu";
import VotingList from "./components/VotingList";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom";
import SocietyHeader from "../../components/societyHeader";
import SocietyHeaderView from "../../components/societyHeaderView"

import logo1 from "../../assets/images/hamptom.png";
import { useDispatch, useSelector } from "react-redux";
import { detailOfPost, deleteCommentAndReply } from "../../utils/asyncFun";
import { FcClock } from "react-icons/fc";
import { getTimeDifference } from "../../utils/globalFun";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { HiDotsVertical } from "react-icons/hi";

import moment from "moment";

export default function ViewAnnouncement() {
  const home = { icon: "pi pi-home" };
  const [payload, setPayload] = useState({ _id: "", type: "" })
  const menu = useRef([]);
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);

  const [voterData, setvoterData] = useState([]);



  function handleVoterData(data) {
    setvoterData(data)

  }


  const itemss = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "View Announcement" },
  ];

  const items = [
    {
      label: "Options",
      items: [
        {
          label: "Delete",
          icon: "pi pi-times",
          command: () => {
            handleClick(payload)
            toast.current.show({
              severity: "warn",
              summary: "Delete",
              detail: "Data Deleted",
              life: 3000,
            });
          },
        },
      ],
    },
  ];

  const avatar = "https://source.unsplash.com/random/200x200?sig=1";

  // Development-
  const { id, societyId } = useParams();
  const { society, image } = JSON.parse(localStorage.getItem("society_info"))
  const dispatch = useDispatch();
  const stores = useSelector((store) => store.data.detailOfPost);

  let totalVotes = 0

  if (stores?.poll_option) {
    totalVotes = stores.poll_option.reduce(
      (accumulator, currentValue) => accumulator + currentValue?.votes ?? 0,
      0,
    );
    console.log('totalVotes', totalVotes)
  }


  function calculatePercentage(total, value) {
    return (value / total) * 100
  }


  useEffect(() => {
    dispatch(
      detailOfPost({
        method: "GET",
        url: `detailOfPost?_id=${id}`,
        data: "",
      })
    );
  }, []);


  const handleClick = (data) => {
    dispatch(
      deleteCommentAndReply({
        method: "POST",
        url: `deleteCommentAndReply`,
        data,
      })
    )
      .then(() => {
        dispatch(
          detailOfPost({
            method: "GET",
            url: `detailOfPost?_id=${id}`,
            data: "",
          })
        )
      })
  }


  return (
    <>
          
          <Dialog header="Voter" visible={visible} style={{ width: '25vw' }} onHide={() => setVisible(false)}>
        <div className="m-0 h-100">

          {voterData.length>0?voterData
            ?.map((e) => (
              <div key={e.id} className="card border-0 shadow-sm mb-2">
                <VotingList
                  avatar={e.profile_image || team1}
                  name={`${e.first_name} ${e.last_name}`}
                />
              </div>
            )):<>
            <h5>Voter not exist</h5>
            </>}

        </div>
      </Dialog>

      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={itemss} home={home} />
        {/* <Link
          to="/community-management/society"
          className="btn ms-auto me-2 btn-sm btn-primary rounded-4 "
        >
          Back
        </Link> */}
        <button
        className="btn ms-auto me-2 btn-sm btn-primary rounded-4 "
        onClick={()=>{ window.history.back()}}
        >
          Back
        </button>
      </div>

      <div className="header">
        <SocietyHeaderView
          imageUrl={decodeURIComponent(image)}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>

      <div className="container">
        <div className="card p-4 shadow-lg border-0">
          <div className="row mb-4">
            <div className="col">
              <h4 className="mb-0">Announcement</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8 col-lg-6 col-xl-6">
              {/* Assuming Poll is a custom component */}
              <Poll
                avatar={stores?.societyAdmin_id?.profile_image ? stores?.societyAdmin_id?.profile_image : team1}
                name={`${stores?.societyAdmin_id?.first_name} ${stores?.societyAdmin_id?.last_name}`}
                date={`${moment(stores?.societyAdmin_id?.createdAt)?.format("DD MMM YYYY")}`}
                time={moment(stores?.societyAdmin_id?.createdAt)?.format("hh : mm A")}
                title={stores?.title}
                description={stores?.description}
                comments={stores?.comment?.length || 0}
                likes={stores?.likes?.count || 0}
                meeting={stores?.is_meeting}
                pdf={stores?.fileAttachment}
                images={stores?.post_image}

              // VotingList="200"
              // comments="22"
              // share="20"
              />

              {/* console.log("attach",stores.fileAttachment) */}

            </div>

            {
              !stores?.is_meeting ?
                <div className="col-lg-6 col-md-12">
                  <div className="comments_container">
                    <ul className="comment-list list-unstyled">
                      {stores?.comment?.map((el, index) => (
                        <li className="p-2" key={index}>
                          <div className="d-flex justify-content-start align-items-center">
                            <img
                              src={el?.user_id?.profile_image ? el?.user_id?.profile_image : el?.societyAdmin_id?.profile_image ? el?.societyAdmin_id?.profile_image : avatar}
                              alt="Avatar"
                              className="me-3 rounded-circle"
                              style={{
                                height: "48px",
                                width: "48px",
                                marginRight: "20px",
                              }}
                            />
                            <div className="d-flex flex-column align-items-start">
                              <h6 className="text-dark font-weight-bold mb-0">
                                {el?.user_id ? `${el?.user_id?.first_name} ${el?.user_id?.last_name}` : `${el?.societyAdmin_id?.first_name} ${el?.societyAdmin_id?.last_name}`}
                              </h6>

                              <div className="d-flex align-items-center gap-2">
                                <p className="text-secondary m-0 me-3">
                                  {el?.apartment_id?.appartmentNumber}
                                </p>
                                <FcClock color="#6c757d" size="15" />
                                <p className="text-secondary m-0">
                                  {getTimeDifference(el?.createdAt)} ago
                                </p>
                              </div>
                            </div>
                            {
                              el.visible ?
                                <div className="ms-auto">
                                  <Toast ref={toast}></Toast>

                                  <Menu model={items} popup ref={menu} id="popup_menu" />
                                  <button
                                    className="btn shadow-sm"
                                    label="Show"
                                    icon="pi pi-bars"
                                    onClick={(event) => {
                                      menu.current.toggle(event);
                                      setPayload({ _id: el._id, type: "comment" })
                                    }}
                                    aria-controls="popup_menu"
                                    aria-haspopup
                                  >
                                    <HiDotsVertical />
                                  </button>
                                </div>
                                : <div className="ms-auto">
                                  <button class="btn shadow-lg text-danger" >
                                    Deleted
                                  </button>
                                </div>
                            }
                          </div>
                          <div className="my-2">
                            
                            <p className="text-dark font-weight-bold mt-2">
                              {el?.comment}
                              {/* <a href="!#" className="text-dark ms-2">
                          Read More
                        </a> */}
                            </p>
                          </div>
                          {el?.comment_reply?.map((el2) => (
                            <div className="ms-4 p-2">
                              <div className="d-flex justify-content-start align-items-center">
                                <img
                                  src={el?.user_id ? el?.user_id?.profile_image : el?.societyAdmin_id?.profile_image}
                                  alt="Avatar"
                                  className="me-3 rounded-circle"
                                  style={{
                                    height: "48px",
                                    width: "48px",
                                    marginRight: "20px",
                                  }}
                                />
                                <div className="d-flex flex-column align-items-start">
                                  <h6 className="text-dark font-weight-bold mb-0">
                                    {el?.user_id ? `${el?.user_id?.first_name} ${el?.user_id?.last_name}` : `${el?.societyAdmin_id?.first_name} ${el?.societyAdmin_id?.last_name}`}
                                  </h6>

                                  <div className="d-flex align-items-center gap-2">
                                    <p className="text-secondary m-0 me-3">{el2?.apartment_id?.appartmentNumber}</p>
                                    <FcClock color="#6c757d" size="15" />
                                    <p className="text-secondary m-0">{getTimeDifference(el2?.createdAt)} ago</p>
                                  </div>
                                </div>
                                {
                                  el.visible ?
                                    <div className="ms-auto">
                                      <Toast ref={toast}></Toast>

                                      <Menu
                                        model={items}
                                        popup
                                        ref={menu}
                                        id="popup_menu"
                                      />
                                      <button
                                        className="btn shadow-sm"
                                        label="Show"
                                        icon="pi pi-bars"
                                        onClick={(event) => {
                                          menu.current.toggle(event);
                                          setPayload({ _id: el2._id, type: "reply" })
                                        }}
                                        aria-controls="popup_menu"
                                        aria-haspopup
                                      >
                                        <HiDotsVertical />
                                      </button>
                                    </div>
                                    : 
                                    <div className="ms-auto">
                                      <button class="btn shadow-lg text-danger" >
                                        Deleted
                                      </button>
                                    </div>
                                }
                              </div>
                              <div className="my-2">
                                <p className="text-dark font-weight-bold mt-2">
                                  {el2?.reply}
                                  {/* <a href="!#" className="text-dark ms-2">
                              Read More
                            </a> */}
                                </p>
                              </div>
                            </div>
                          ))}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div> :
                ""
            }
          </div>

          {
            stores?.is_meeting ?
              <div className="row">
                <div className="col-md-8 col-lg-6 col-xl-6 mb-4">
                  <div className="card border-0 shadow-lg col-12">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="mt-2">
                          <p className="text-secondary mb-0">Meeting Date -{moment(stores?.meeting_date_time).utc().format("DD MMM YYYY")}</p>
                          <p className="text-secondary mb-0">Meeting Time - {moment(stores?.meeting_date_time).utc().format("hh : mm A")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              ""
          }
          {
            stores?.is_meeting ?
              <div className="row">
                <div className="col-md-8 col-lg-6 col-xl-6">
                  <div className="card border-0 shadow-lg col-12">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="mt-2">
                          <p className="text-secondary mb-0">Poll Question - {stores?.poll_question}</p>
                          <p className="text-secondary mb-0">Poll Expiry - {moment(stores?.poll_expiry_date).format("DD-MM-YYYY")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : ""
          }

          <div className="row mt-4">

            {stores?.poll_option?.map((el) => (
              <div className="col-md-6  col-lg-4 col-12">
                <div className="card shadow-lg border-0 p-3 bg-white mb-4 shadow">
                  <div className="row mb-2">
                    <div className="col">
                      <h6 className="font-weight-bold mb-2">Voting List</h6>
                    </div>
                    <div className="col text-right">
                      <div className="d-flex align-items-center justify-content-end">
                        <span className="cursor-pointer">...</span>
                        <span className="font-weight-bold ml-1">{el?.id}</span>
                      </div>
                    </div>
                  </div>

                  {/* //card */}

                  <div  onClick={() => { setVisible(true); handleVoterData(el?.voted_user_ids) }}
                   className="card mb-3 p-2">
                    <div className="voting d-flex align-items-center">
                      <div className="box box_one me-2">
                        <span className="font-weight-bold">{el?.id}</span>
                      </div>
                      <div className="ms-2 d-flex flex-column">
                        <span className="font-weight-bold">{el?.title}</span>
                        <div
                          className="progress mt-1"
                          style={{ height: "8px", width: "150px" }}
                        >
                  
                          <div
                            className="progress-bar "
                            role="progressbar"
                            style={{ width: `${calculatePercentage(totalVotes, el?.votes ?? 0)}%` }}
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div className="box box_two ms-auto">
                        <span className="font-weight-bold">{el?.votes}</span>
                      </div>
                    </div>
                  </div>
                  {el?.voted_user_ids?.map((e) => (
                    <div className="card border-0 shadow-sm mb-2">
                      <VotingList
                        avatar={e?.profile_image || team1}
                        name={`${e?.first_name} ${e?.last_name}`}
                      // apartment="D 005"
                      // date="2 July 2023"
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {/* <div className="col-md-6 col-lg-4 col-12">
              <div className="card shadow-lg border-0 p-3 bg-white mb-4 shadow">
                <div className="row mb-2">
                  <div className="col">
                    <h6 className="font-weight-bold mb-2">Voting List</h6>
                  </div>
                  <div className="col text-right">
                    <div className="d-flex align-items-center justify-content-end">
                      <span className="cursor-pointer">...</span>
                      <span className="font-weight-bold ml-1">B</span>
                    </div>
                  </div>
                </div>

                <div className="card mb-3 p-2">
                  <div className="voting d-flex align-items-center ">
                    <div className="box box_one me-2">
                      <span className="font-weight-bold">B</span>
                    </div>
                    <div className="ms-2 d-flex flex-column">
                      <span className="font-weight-bold">May be</span>
                      <div className="progress mt-1" style={{ height: '8px', width: '150px' }}>
                        <div className="progress-bar " role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div className="box box_two ms-auto">
                      <span className="font-weight-bold">70%</span>
                    </div>
                  </div>
                </div>

                <div className="card border-0 shadow-sm  mb-2">
                  <VotingList
                    avatar={team1}
                    name="John Mack"
                    apartment="D 005"
                    date="2 July 2023" />
                </div>
                <div className="card border-0 shadow-sm  mb-2">
                  <VotingList
                    avatar={team1}
                    name="John Mack"
                    apartment="D 005"
                    date="2 July 2023" />
                </div>
                <div className="card border-0 shadow-sm  mb-2">
                  <VotingList
                    avatar={team1}
                    name="John Mack"
                    apartment="D 005"
                    date="2 July 2023" />
                </div>
                <div className="card border-0 shadow-sm  mb-2">
                  <VotingList
                    avatar={team1}
                    name="John Mack"
                    apartment="D 005"
                    date="2 July 2023" />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-12">
              <div className="card shadow-lg border-0 p-3 bg-white mb-4 shadow">
                <div className="row mb-2">
                  <div className="col">
                    <h6 className="font-weight-bold mb-2">Voting List</h6>
                  </div>
                  <div className="col text-right ms-auto">
                    <div className="d-flex align-items-center justify-content-end">
                      <span className="cursor-pointer">...</span>
                      <span className="font-weight-bold ml-1">C</span>
                    </div>
                  </div>
                </div>

                <div className="card mb-3 p-2">
                  <div className="voting d-flex align-items-center">
                    <div className="box box_one me-2">
                      <span className="font-weight-bold">C</span>
                    </div>
                    <div className="ms-2 d-flex flex-column">
                      <span className="font-weight-bold">No</span>
                      <div className="progress mt-1" style={{ height: '8px', width: '150px' }}>
                        <div className="progress-bar " role="progressbar" style={{ width: '20%' }} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div className="box box_two ms-auto">
                      <span className="font-weight-bold">20%</span>
                    </div>
                  </div>
                </div>
                <div className="card border-0 shadow-sm  mb-2">
                  <VotingList
                    avatar={team1}
                    name="John Mack"
                    apartment="D 005"
                    date="2 July 2023" />
                </div>
                <div className="card border-0 shadow-sm  mb-2">
                  <VotingList
                    avatar={team1}
                    name="John Mack"
                    apartment="D 005"
                    date="2 July 2023" />
                </div>
                <div className="card border-0 shadow-sm  mb-2">
                  <VotingList
                    avatar={team1}
                    name="John Mack"
                    apartment="D 005"
                    date="2 July 2023" />
                </div>
                <div className="card border-0 shadow-sm  mb-2">
                  <VotingList
                    avatar={team1}
                    name="John Mack"
                    apartment="D 005"
                    date="2 July 2023" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
