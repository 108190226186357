import React from "react";
import axios from 'axios';
import Swal from 'sweetalert2';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import "../assets/css/login.css";

import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Link } from "react-router-dom";
export default function ForgotpasswordPage() {
  const ForgotSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgotSchema,
    onSubmit: (data) => {
      console.log(data);
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_API}sendForgetPasswordEmailForAdmin`,
        data: data, // you are sending body instead
        headers: {
        //  'Authorization': `bearer ${token}`,
        'Content-Type': 'application/json'
        }, 
      }) .then((response) => {
        console.log(response);
        
      //   formik.setValues({
      //       title:"",
      //       carpet_area: "",
      //       super_builtup_area:"",
      //       configuration:"",
      //       floor:"",
      //  })
      //  setVisibleRight(false);
       Swal.fire({
        position: "Center",
        icon: "success",
        title: "Success",
        showConfirmButton: false,
        timer: 1500
      });
          //  datafetch();
       })
        .catch((error) => {
         console.log("error",error)
        //  setVisibleRight(false);
      //    formik.setValues({
      //       title:"",
      //       carpet_area: "",
      //       super_builtup_area:"",
      //       configuration:"",
      //       floor:"",
      //  })
         Swal.fire({
            icon: "error",
            title: "Error",
            text: "Email does not exist or Network error",
    
          });
       })
      //  }       
    
    
//  })

      setTimeout(() => {
        formik.setSubmitting(false);
      }, 2000);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit } = formik;

  return (
    <div className="form-box">
      <div className="fullHeight p-ai-center p-d-flex p-jc-center">
        <div className="shadow card m-3 px-3 py-4 px-sm-4 py-sm-5">
          <h4 className="text-center">Forgot Password</h4>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit} className="p-fluid">
              <div className="p-field">
                <span className="p-float-label">
                  <InputText
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": Boolean(touched.email && errors.email),
                    })}
                  />
                  <label
                    htmlFor="name"
                    className={classNames({
                      "p-error": Boolean(touched.email && errors.email),
                    })}
                  >
                    Email ID*
                  </label>
                </span>
                {Boolean(touched.email && errors.email) && (
                  <small className="p-error">{formik.errors["email"]}</small>
                )}
              </div>

              
              <div className="submitBtnBox">
              {/* <Link to="/confirm-password"> */}
                <Button
                  type="submit"
                  label="Submit"
                  iconPos="right"
                  loading={isSubmitting}
                  className="mt-4 submitBtn"
                  disabled={isSubmitting}
                />
                {/* </Link> */}
              </div>
            
            </Form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
}
