import React from "react";
import LoginPage from "./containers/login";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PrivateRoute } from "./library/helper";

import ToastManager from "./components/toast";

// pages
import DashboardPage from "./containers/dashboard";
import ManageApartment from "./containers/ManageSociety/ManageApartment";
import ManageParking from "./containers/ManageSociety/ManageParking";

import RegisterPage from "./containers/register";
import ForgotpasswordPage from "./containers/forgotpassword";
import ChangepasswordPage from "./containers/changepassword";
import NotfoundPage from "./containers/notfound";
import UserProfilePage from "./containers/userprofile";
import AllTenants from "./containers/AllTenants";
import ViewTenant from "./containers/AllTenants/ViewTenant";
import CommunityManagement from "./containers/CommunityManagement";
import ViewPost from "./containers/CommunityManagement/ViewPost";
import ViewPoll from "./containers/CommunityManagement/ViewPoll";
import ViewAnnouncement from "./containers/CommunityManagement/ViewAnnouncement";
import ViewDiscussions from "./containers/CommunityManagement/ViewDiscussions";
import ManageSociety from "./containers/ManageSociety";
import AddSociety from "./containers/ManageSociety/AddSociety";
import ViewSociety from "./containers/ManageSociety/ViewSociety";
import ManageResidents from "./containers/ManageSociety/ManageResidents";
import ManageVisitors from "./containers/ManageSociety/ManageVisitors";
import ManageSecurity from "./containers/ManageSociety/ManageSecurity";
import Payments from "./containers/PaymentMaintenance/Payments";
import AddAnnouncement from "./containers/ManageSociety/AddAnnouncement";
import ManageWings from "./containers/ManageSociety/ManageWings";
import ManageAmenity from "./containers/ManageSociety/ManageAmenity";
import ViewAmenity from "./containers/ManageSociety/ViewAmenity";
import ManageCharges from "./containers/ManageApp/ManageCharges";
import ManageDelieveryCharges from "./containers/ManageApp/ManageDelieveryCharges";
import AddAmenities from "./containers/ManageApp/AddAmenities";
import ManageContactList from "./containers/ManageApp/ManageContactList";
import ManageVisitingHelp from "./containers/ManageApp/ManageVisitingHelp";
import ApartmentType from "./containers/ManageApp/ApartmentType";
import AboutUs from "./containers/ContentManage/AboutUs";
import DataAndPrivacy from "./containers/ContentManage/DataAndPrivacy";
import FaqManage from "./containers/ContentManage/FaqManage";
import PrivacyPolicy from "./containers/ContentManage/PrivacyPolicy";
import TermsCondition from "./containers/ContentManage/TermsCondition";
import AllManagement from "./containers/AllManagement";
import ViewResident from "./containers/ManageSociety/ViewResident";
import ContactUs from "./containers/Contactus";
import AddApartment from "./containers/AddApartment";
import ManageComplaints from "./containers/ManageSociety/ManageComplaints";
import ManageApartmentType from "./containers/ManageApp/data/ManageApartmentType";

import PasswordChange from "./containers/passwordchange";
import TermsAndCondition from "./containers/ContentManage/TermsCondition";
import ConfirmPassword from "./containers/confirmPassword";
import HowWorks from "./containers/ContentManage/HowWorks";

import Header from "./components/header";
import Userprofile from "./containers/userprofile";

import { AvatarProvider } from "./context/ AvatarContext";
import { SocietyProvider } from "./context/SocietyContext";

import ViewVisting from "./containers/AllRequest/ViewVisting";
import ManageVisitorstype from "./containers/ManageSociety/ManageVisitorstype";
import ManageWingsResdt from "./containers/ManageSociety/ManageWingsResdt";
import AddManagement from "./containers/ManageSociety/AddManagement";
import AddTenant from "./containers/AllTenants/AddTenant";
import UpdateManagement from "./containers/ManageSociety/UpdateManagement";
import ManageFinance from "./containers/ManageSociety/ManageFinance";
import ManageLedgers from "./containers/ManageSociety/ManageLedgers";
import ManageBills from "./containers/ManageSociety/ManageBills";
import RecycleBin from "./containers/ManageSociety/RecycleBin";
import BookedAmenities from "./containers/ManageSociety/BookedAmenities";


function App() {
  return (
    <AvatarProvider>
      <SocietyProvider>
        <>
          <Router>
            {/* <Header />  */}
            {/* <Userprofile/> */}
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/register" component={RegisterPage} />
              <Route exact path="/forgot-password" component={ForgotpasswordPage} />
              <Route exact path="/confirm-password/:id" component={ConfirmPassword} />
              <Route exact path="/change-password" component={ChangepasswordPage} />
              <PrivateRoute exact path="/dashboard" component={DashboardPage} />
              <PrivateRoute exact path="/user-profile" component={UserProfilePage} />
              <PrivateRoute exact path="/manage-society/:id" component={ManageSociety} />
              <PrivateRoute exact path="/all-managements/:id" component={AllManagement} />
              <PrivateRoute exact path="/all-tenants/:id" component={AllTenants} />
              <PrivateRoute exact path="/update-society/:id" component={AddSociety} />
              <PrivateRoute exact path="/view-society" component={ViewSociety} />
              {/* <PrivateRoute exact path="/view-tenant" component={ViewTenant} /> */}

              <PrivateRoute exact path="/view-tenant/:id/:societyId" component={ViewTenant} />
              <PrivateRoute exact path="/view-resident/:id" component={ViewResident} />
              <PrivateRoute exact path="/view-visting/:id" component={ViewVisting} />
              <PrivateRoute exact path="/community-management/:id" component={CommunityManagement} />
              <PrivateRoute exact path="/view-post/:id/:societyId" component={ViewPost} />
              <PrivateRoute exact path="/view-poll/:id/:societyId" component={ViewPoll} />
              <PrivateRoute exact path="/view-announcement/:id/:societyId" component={ViewAnnouncement} />
              <PrivateRoute exact path="/view-discussion" component={ViewDiscussions} />
              <PrivateRoute exact path="/manage-residents/:id" component={ManageResidents} />
              <PrivateRoute exact path="/manage-visitors/:id" component={ManageVisitors} />
              <PrivateRoute exact path="/manage-security/:id" component={ManageSecurity} />
              <PrivateRoute exact path="/manage-wings/:id" component={ManageWings} />
              <PrivateRoute exact path="/manage-apartment/:id" component={ManageApartment} />
              <PrivateRoute exact path="/parking-slots/:id/:counter" component={ManageParking} />
              <PrivateRoute exact path="/payment-management" component={Payments} />
              <PrivateRoute exact path="/add-announcement" component={AddAnnouncement} />
              <PrivateRoute exact path="/manage-amenity/:id" component={ManageAmenity} />
              <PrivateRoute exact path="/view-amenity/:id/:society_id" component={ViewAmenity} />
              <PrivateRoute exact path="/manage-charges" component={ManageCharges} />
              <PrivateRoute exact path="/manage-delievery-companies" component={ManageDelieveryCharges} />
              <PrivateRoute exact path="/add-amenities" component={AddAmenities} />
              <PrivateRoute exact path="/manage-contact-list" component={ManageContactList} />
              <PrivateRoute exact path="/manage-visiting-list" component={ManageVisitingHelp} />
              <PrivateRoute exact path="/manage-apartment-type" component={ApartmentType} />
              <PrivateRoute exact path="/about-us" component={AboutUs} />
              <PrivateRoute exact path="/how-it-works" component={HowWorks} />
              <PrivateRoute exact path="/data-and-privacy" component={DataAndPrivacy} />
              <PrivateRoute exact path="/manage-faq" component={FaqManage} />
              <PrivateRoute exact path="/privacy-policy" component={PrivacyPolicy} />
              <PrivateRoute exact path="/contact-us" component={ContactUs} />
              <PrivateRoute exact path="/apartment-list" component={AddApartment} />
              <PrivateRoute exact path="/manage-complaints/:id" component={ManageComplaints} />
              <PrivateRoute exact path="/passwordchange" component={PasswordChange} />
              <PrivateRoute exact path="/terms-and-conditon" component={TermsAndCondition} />
              <PrivateRoute exact path="/manage-visitors-type/:id" component={ManageVisitorstype} />
              <PrivateRoute exact path="/manage-wing-resident/:society_id/:id" component={ManageWingsResdt} />

              {/* --new-page-added-29-march-- */}

              <PrivateRoute exact path="/add-management/:id" component={AddManagement} />
              <PrivateRoute exact path="/update-management/:id" component={UpdateManagement} />
              <PrivateRoute exact path="/add-tenant/:id" component={AddTenant} />
              <PrivateRoute exact path="/manage-finance/:id" component={ManageFinance} />
              <PrivateRoute exact path="/manage-ledgers/:id" component={ManageLedgers} />
              <PrivateRoute exact path="/manage-bills/:id" component={ManageBills} />
              <PrivateRoute exact path="/recycle-bin/:id" component={RecycleBin} />


              {/* --new-page-added-06-may-2024-- */}

              <PrivateRoute exact path="/booked-amenities/:id" component={BookedAmenities} />




              <Route path="*" component={NotfoundPage} />
            </Switch>
          </Router>

          <ToastManager />
        </>
      </SocietyProvider>
    </AvatarProvider>
  );
}

export default App;
