import React from "react";
import avatar from "../assets/images/avatar.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { classNames } from "primereact/utils";
import { useState, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import { useAvatar } from "../context/ AvatarContext";
import Swal from "sweetalert2";

export default function Userprofile() {
  const { setAvatarUrl } = useAvatar();
  const [previewImage, setPreviewImage] = useState("");
  const history = useHistory();

  const [isReadonly, setIsReadonly] = useState(true);

  const [rows, setRows] = useState([]);
  const [editname, setname] = useState("");
  const [editfirst, setfirst_name] = useState("");
  const [editlast, setlast_name] = useState("");
  const [editemail, setemail] = useState("");
  const [editimage, setimage] = useState("");
  const [userdescription, setDescription] = useState("");
  const [IDE, setID] = useState("");

  const token = localStorage.getItem("token");
  useEffect(() => {
    fetchdata();
    console.log(fetchdata());
  }, []);

  function fetchdata() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getProfile`,

      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        // console.log(response.data);
        const results = response.data;
        // setRows(results.data.data);
        // console.log(rows);

        const value = response.data.data;

        console.log(value);
        setID(value.data.id);
        setname(value.data.name);
        setfirst_name(value.data.first_name);
        setlast_name(value.data.last_name);
        setDescription(value.data.description);
        setemail(value.data.email);
        setimage(value.data.image);
        setAvatarUrl(value.data.image);
        editformik.setValues({
          editname: value.data.name,
          editfirst: value.data.first_name,
          editlast: value.data.last_name,
          editemail: value.data.email,
          editimage: value.data.image,
        });
        // console.log(first_name);
        // console.log(image);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  }

  // const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("name is required"),
    email: Yup.string()
      .email("invalid email address")
      .required("email is required"),
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),

    //   password: Yup.string().matches(passwordRules, { message: "Please create a stronger password" }).required("Required"),
    // confirmpassword: Yup
    //   .string()
    //   .oneOf([Yup.ref("password"), null], "Passwords must match")
    //   .required("Required"),
    // aboutme: Yup.string().required("About me is required"),
    image: Yup.mixed()
      .required("please select an image")
      .test("fileType", "invalid file type", (value) => {
        if (value) {
          const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"];
          return allowedFileTypes.includes(value.type);
        }
        return true; // Allow empty files
      })
      .test("fileSize", "file size is too large", (value) => {
        if (value) {
          const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
          return value.size <= maxSizeInBytes;
        }
        return true; // Allow empty files
      }),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      first_name: "",
      last_name: "",
      image: "",

      // password:"",
      // confirmpassword:"",
      // aboutme:"",
    },
    validationSchema: validationSchema,
  });

  // const handleOnClick = () => {
  //   console.log("working")
  //   formik.setTouched(
  //     Object.keys(formik.values).reduce((acc, fieldName) => {
  //       acc[fieldName] = true
  //       return acc;
  //     },
  //       {}
  //     )
  //   )
  //   formik.validateForm().then((errors) => {
  //     // Check if there are any validation errors
  //     console.log("errors", errors)
  //     if (Object.keys(errors).length === 0) {
  //       // If no errors, proceed to the next step
  //       console.log("values", formik.values)
  //       const payload = formik.values;
  //       console.log(payload)
  //     }

  //   })

  // };

  function handleChangeImage(event) {
    const file = event.currentTarget.files[0];

    // Create a FileReader object
    const reader = new FileReader();

    // Define a function to execute when the file is read
    reader.onload = function (e) {
      // Set the preview image URL to the result of FileReader
      setPreviewImage(e.target.result);
    };

    // Read the file as a data URL
    reader.readAsDataURL(file);

    editformik.setFieldTouched("editimage", true);
    editformik.setFieldValue("editimage", event.currentTarget.files[0]);
  }

  const handleonEdit = () => {
    console.log("working");
    setIsReadonly((prevState) => !prevState);
  };

  const handle = () => {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Proceed with saving changes
        editformik.validateForm().then((errors) => {
          // Check if there are any validation errors
          if (Object.keys(errors).length === 0) {
            let formData = new FormData();
            formData.append("_id", IDE);
            formData.append("name", editformik.values.editname);
            formData.append("first_name", editformik.values.editfirst);
            formData.append("last_name", editformik.values.editlast);
            formData.append("email", editformik.values.editemail);

            if (editformik.values.editimage) {
              formData.append("image", editformik.values.editimage);
            } else {
              formData.append("image", editimage);
            }

            axios
              .patch(`${process.env.REACT_APP_BASE_API}updateADmin`, formData)
              .then((response) => {
                console.log(response.data);
                fetchdata();
                history.push("/dashboard");
                Swal.fire("Saved!", "", "success");
              })
              .catch((error) => {
                console.error(error);
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                });
              });
          }
        });
      } else if (result.isDenied) {
        // Don't save changes
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  //   const handle=()=>{
  //     // console.log(name);
  //     // console.log(email)
  //     // console.log(first_name)
  //     // console.log(last_name)
  //     // console.log(ID )
  //     console.log(editformik.values);
  //     editformik.validateForm().then((errors) => {
  //       // Check if there are any validation errors
  //       if (Object.keys(errors).length === 0) {
  //         let formData = new FormData();
  //         // formData.append('image',editimage);
  //         formData.append('_id',IDE);
  //         formData.append('name',editformik.values.editname);
  //         formData.append('first_name',editformik.values.editfirst);
  //         formData.append('last_name',editformik.values.editlast);
  //         formData.append('email',editformik.values.editemail);
  //         // formData.append('image',editformik.values.editimage);
  //         console.log("formData", formData)

  //         if (editformik.values.editimage) {
  //           // If there's a new image, append it to the form data
  //           formData.append('image', editformik.values.editimage);
  //         } else {
  //           // If editimage is empty, send the previous image URL to the backend
  //           formData.append('image', editimage); // Assuming editimage holds the previous image URL
  //         }
  // axios.patch(`${process.env.REACT_APP_BASE_API}updateADmin`,
  // //  {
  // //   _id:IDE,
  // //   name:editname,
  // //   first_name:editfirst,
  // //   last_name:editlast,
  // //   email:editemail,
  // // }
  // formData

  // )
  //   .then(response => {console.log(response.data)
  //     // window.location.reload(false);

  //     fetchdata();
  //     // window.location.reload();
  //     history.push('/dashboard');
  //   })
  //   .catch(error => {
  //     console.error(error);
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "Something went wrong!"
  //     });

  //   });
  // }
  //     })
  //   }

  //Define Yup schema for edit form validation
  const editValidationSchema = Yup.object().shape({
    editname: Yup.string().required("user name is required"),
    editfirst: Yup.string().required("first name is required"),
    editlast: Yup.string().required("last name is required"),
    editemail: Yup.string()
      .email("invalid email address")
      .required("email is required"),
    // editimage:Yup.mixed()
    // .required('please select an image')
    // .test('fileType', 'invalid file type', (value) => {
    //   if (value) {
    //     const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    //     return allowedFileTypes.includes(value.type);
    //   }
    //   return true; // Allow empty files
    // })
    // .test('fileSize', 'file size is too large', (value) => {
    //   if (value) {
    //     const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    //     return value.size <= maxSizeInBytes;
    //   }
    //   return true; // Allow empty files
    // }),
  });

  // Initialize Formik for edit form
  const editformik = useFormik({
    initialValues: {
      editname: editname,
      editfirst: editfirst,
      editlast: editlast,
      editemail: editemail,
      editimage: editimage,
    },
    validationSchema: editValidationSchema,
    // onSubmit: handleOnEdit, // assuming handleOnEdit is your submit handler function
  });

  return (
    <div className="card p-4">
      <div className="main-content">
        <div className="container-fluid mt-7">
          <div className="row">
            <div className="col-xl-4 order-xl-2 mb-5 mb-xl-0">
              <div className="card card-profile border-0 mt-5 shadow-lg">
                <div className="row ">
                  <div className="card-profile-image d-flex justify-content-center">
                    <div>
                      {/* <img
                        src={formik.values.image ? URL.createObjectURL(formik.values.image) : avatar} style={{ width: '100px', height: '100px', marginTop: '-50px' }} alt='user'
                        className="rounded-circle img-fluid"
                      />
                    </div>
                    <br />

                    {Boolean(formik.touched.image && formik.errors.image) && (
                      <small className="p-error">{formik.errors.image}</small>
                    )} */}
                      {/* <img src={formik.values.image ? URL.createObjectURL(formik.values.image) : avatar} style={{ width: '100px', height: '100px', marginTop: '-50px' }} alt='user'
                        className="rounded-circle img-fluid"
                      /> 
                  )} */}

                      {/* <img src={'https://betazone.promaticstechnologies.com/r-community-rest-apis/public/profile_image/'+ editimage} 
                 style={{ width: '100px', height: '100px', marginTop: '-50px' }} alt='user'
                 className="rounded-circle img-fluid"
                   /> */}
                      <img
                        src={
                          previewImage ||
                          `${process.env.REACT_APP_BASE_IMAGE_URL}r-community-rest-apis/public/profile_image/${editimage}`
                        }
                        style={{
                          width: "100px",
                          height: "100px",
                          marginTop: "-50px",
                        }}
                        alt="user"
                        className="rounded-circle img-fluid"
                      />
                    </div>
                    <br />
                    {Boolean(
                      editformik.touched.editimage &&
                        editformik.errors.editimage
                    ) && (
                      <small className="p-error">
                        {editformik.errors.editimage}
                      </small>
                    )}
                    {/* <div>
                                                      
                                 <label className='btn btn-sm btn-primary  rounded-4' htmlFor="upload-image">upload</label>
                            <input type="file" id='upload-image' style={{ display: 'none' }} onChange={handleChangeImage} />
                    </div> */}
                  </div>
                </div>
                {/* <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <button className='btn btn-primary' onClick={handleOnClickimage}>upload</button>
                </div> */}
                <div
                  className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
                  hidden={isReadonly}
                >
                  <label
                    className="btn btn-sm btn-primary  rounded-4"
                    htmlFor="upload-image"
                  >
                    upload
                  </label>
                  <input
                    type="file"
                    id="upload-image"
                    name="image"
                    style={{ display: "none" }}
                    onChange={handleChangeImage}
                  />
                </div>

                <div className="card-body">
                  <div className="card-title text-center text-muted">
{/* 
                    "I'm Jane Hong, and I recently graduated with an advanced
                    diploma from Smith secondary school. I'm seeking an
                    internship where I can apply my skills in content creation
                    and increase my experience in digital marketing."
                     */}
                     {userdescription}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 order-xl-1">
              <div className="card border-0 shadow-lg p-2">
                <div className="card-header bg-white border-0">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h3 className="mb-0">My account</h3>
                    </div>
                    <div className="col-4 text-end">
                      {/* <a href="#!" className="btn btn-sm btn-primary">
                        Edit
                      </a> */}
                      <button
                        type="submit"
                        className="btn btn-primary rounded-4"
                        onClick={handleonEdit}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {/* <form> */}
                  {/* onSubmit={editformik.handleSubmit} */}
                  <form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group focused mb-2">
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Username
                            </label>
                            {/* <input
                              type="text"
                              id="input-username"
                              name="username"
                              className="form-control form-control-alternative"
                              placeholder="Username"
                              defaultValue="lucky.jesse"
                            /> */}
                            {/* <input
                              type="text"
                              id="input-username"
                              name="name"
                              placeholder="name"
                              // defaultValue="lucky.jesse"
                              className={classNames({
                                "input-invalid": Boolean(formik.touched.name && formik.errors.name),
                              }, "form-control form-control-alternative")}
                              {...formik.getFieldProps("name")}

                              readOnly={isReadonly}
                              value={name}
                              
                               /> */}

                            {/* <form onSubmit={editformik.handleSubmit}>
<div className="mb-3">
    <label htmlFor="chargeName" className="form-label">Visitor Name</label>
    <input
        type="text"
        id="chargeName"
        name="editname"
        placeholder="Enter Visitor Name" 
        className={classNames({
            "input-invalid": Boolean(editformik.touched.editname && editformik.errors.editname),
        }, "form-control")}
        // className={classNames(
        //     "form-control",
        //     { "border-red": editformik.touched.edittitle && editformik.errors.edittitle }
        // )}
         
        autoComplete="off"
        value={editformik.values.editname}
        onChange={editformik.handleChange}
        onBlur={editformik.handleBlur}
    />

{editformik.touched.editname && editformik.errors.editname && (
        <small className="p-error">{editformik.errors.editname}</small>
    )}
</div>
</form> */}
                            <input
                              type="text"
                              id="input-username"
                              name="editname"
                              // className="form-control form-control-alternative"
                              placeholder="Username"
                              autocomplete="off"
                              className={classNames(
                                {
                                  "input-invalid": Boolean(
                                    editformik.touched.editname &&
                                      editformik.errors.editname
                                  ),
                                },
                                "form-control form-control-alternative"
                              )}
                              // defaultValue="lucky.jesse"
                              // className={classNames({
                              //   "input-invalid": Boolean( formik.errors.name),
                              // }, "form-control form-control-alternative")}
                              // {...formik.getFieldProps("name")}
                              readOnly={isReadonly}
                              // value={editname}
                              // onChange={(e) => setname(e.target.value)}
                              value={editformik.values.editname}
                              onChange={editformik.handleChange}
                              onBlur={editformik.handleBlur}
                            />

                            {/* {Boolean(formik.touched.name && formik.errors.name) && (
                              <small className="p-error">{formik.errors.name}</small>
                            )} */}
                            {editformik.touched.editname &&
                              editformik.errors.editname && (
                                <small className="p-error">
                                  {editformik.errors.editname}
                                </small>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group mb-2">
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <input
                              type="email"
                              id="input-email"
                              name="editemail"
                              autocomplete="off"
                              // className="form-control form-control-alternative"
                              placeholder="jesse@example.com"
                              className={classNames(
                                {
                                  "input-invalid": Boolean(
                                    editformik.touched.editemail &&
                                      editformik.errors.editemail
                                  ),
                                },
                                "form-control form-control-alternative"
                              )}
                              // className={classNames({
                              //   "input-invalid": Boolean(formik.touched.email && formik.errors.email),
                              // }, "form-control form-control-alternative")}
                              // {...formik.getFieldProps("email")}
                              readOnly={isReadonly}
                              // value={editemail}
                              // onChange={(e) => setemail(e.target.value)}
                              value={editformik.values.editemail}
                              onChange={editformik.handleChange}
                              onBlur={editformik.handleBlur}
                            />

                            {editformik.touched.editemail &&
                              editformik.errors.editemail && (
                                <small className="p-error">
                                  {editformik.errors.editemail}
                                </small>
                              )}

                            {/* {Boolean(formik.touched.email && formik.errors.email) && (
                              <small className="p-error">{formik.errors.email}</small>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group focused">
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              First name
                            </label>
                            <input
                              type="text"
                              id="input-first-name"
                              // name="first_name"
                              name="editfirst"
                              // className="form-control form-control-alternative"
                              placeholder="First name"
                              defaultValue="Lucky"
                              autocomplete="off"
                              // className={classNames({
                              //   "input-invalid": Boolean(formik.touched.first_name && formik.errors.first_name),
                              // }, "form-control form-control-alternative")}
                              // {...formik.getFieldProps("first_name")}

                              className={classNames(
                                {
                                  "input-invalid": Boolean(
                                    editformik.touched.editfirst &&
                                      editformik.errors.editfirst
                                  ),
                                },
                                "form-control form-control-alternative"
                              )}
                              readOnly={isReadonly}
                              // value={editfirst}
                              // onChange={(e) => setfirst_name(e.target.value)}
                              value={editformik.values.editfirst}
                              onChange={editformik.handleChange}
                              onBlur={editformik.handleBlur}
                            />

                            {editformik.touched.editfirst &&
                              editformik.errors.editfirst && (
                                <small className="p-error">
                                  {editformik.errors.editfirst}
                                </small>
                              )}

                            {/* {Boolean(formik.touched.first_name && formik.errors.first_name) && (
                              <small className="p-error">{formik.errors.first_name}</small>
                            )} */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group focused">
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>
                            <input
                              type="text"
                              id="input-last-name"
                              // name="last_name"
                              name="editlast"
                              // className="form-control form-control-alternative"
                              placeholder="Last name"
                              defaultValue="Jesse"
                              autocomplete="off"
                              className={classNames(
                                {
                                  "input-invalid": Boolean(
                                    editformik.touched.editlast &&
                                      editformik.errors.editlast
                                  ),
                                },
                                "form-control form-control-alternative"
                              )}
                              // className={classNames({
                              //   "input-invalid": Boolean(formik.touched.last_name && formik.errors.last_name),
                              // }, "form-control form-control-alternative")}
                              // {...formik.getFieldProps("last_name")}
                              readOnly={isReadonly}
                              value={editformik.values.editlast}
                              onChange={editformik.handleChange}
                              onBlur={editformik.handleBlur}
                              // value={editlast}
                              // onChange={(e) => setlast_name(e.target.value)}
                            />

                            {editformik.touched.editlast &&
                              editformik.errors.editlast && (
                                <small className="p-error">
                                  {editformik.errors.editlast}
                                </small>
                              )}

                            {/* {Boolean(formik.touched.last_name && formik.errors.last_name) && (
                              <small className="p-error">{formik.errors.last_name}</small>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Description */}

                    {/*  */}
                  </form>
                  <br />
                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary rounded-4"
                      hidden={isReadonly}
                      onClick={handle}
                    >
                      Submit
                    </button>
                  </div>
                  {/* onClick={handleOnClick} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
