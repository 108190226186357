import React, { useState,useEffect} from 'react';
import { MdDeleteOutline } from "react-icons/md";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { MdOutlineEdit } from "react-icons/md";
import { Sidebar } from 'primereact/sidebar';
import Swal from 'sweetalert2'
import axios from 'axios';
import Loading from './Loading';
import { LazyLoadImage } from "react-lazy-load-image-component";



// ==Assets==

import logo1 from "../../../assets/images/amazon1.svg"
import logo2 from "../../../assets/images/flipkart.svg"
import logo3 from "../../../assets/images/Swiggy.svg"
import logo4 from "../../../assets/images/Zomato.svg"
import logo5 from "../../../assets/images/Zepto.svg"
import logo6 from "../../../assets/images/Dominoz.svg"
import logo7 from "../../../assets/images/bigbasket.svg"

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { classNames } from "primereact/utils";

const imgPreview = {
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    border: '1px dashed #b5b5b5',
    width: '100%'

}


const CompaniesList = () => {
    const [visibleRight, setVisibleRight] = useState(false);
    const [visible, setVisible] = useState(false);
    const [data, setdata] = useState([]);

    const [totalItems, setTotalItems] = useState(0);


    const [IDE, setID] = useState("");
    const [editname, setname] = useState("");
    const [editimage, setimage] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10); // Number of items per page

    const [check, setCheck] = useState(false);
    const [showImagePreview, setShowImagePreview] = useState(false);
    const [newImagePreview, setNewImagePreview] = useState(null);
    const [showimage, setshowimage] = useState("");
   
  const[offsetentry,setoffsetentry]=useState(0);
  const[entry,setentry]=useState(0);

 const [isLoading, setIsLoading] = useState(true);

 const token = localStorage.getItem('token');
 useEffect(() => {
    datafetch();
}, [currentPage]); // Fetch data whenever currentPage changes

const datafetch = () => {
    setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);
    
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API}getdelivery`,
        headers: { 'Authorization': 'Bearer ' + token },
        params: { limit, offset }
    };

    axios.request(config)
        .then((response) => {
            setTotalItems(response.data.count); 
            console.log(response.data)
            setdata(response.data);
            setIsLoading(false);
            console.log(response.data.data.length)
            setentry(response.data.data.length+offset)
        })
        .catch((error) => {
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'An error occurred while fetching data from the server!',
            });
        });
};

   
    // function datafetch() {
    //     // data fetching here
    //     let config = {
    //         method: 'get',
    //         maxBodyLength: Infinity,
    //         url: `${process.env.REACT_APP_BASE_API}getdelivery`,
    //         headers: { 'Authorization': 'Bearer ' + token}
    //     };
    //     axios.request(config)
    //         .then((response) => { 
    //             // console.log(JSON.stringify(response.data));
    //             console.log(response.data);
    //             setdata(response.data);
    //             console.log(data);
    //             setIsLoading(false);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }

    // useEffect(() => {

    //     datafetch();

    // }, []);

    const totalPages = Math.ceil(totalItems / pageSize);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    
    const nextPage = () => {
        const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
//

const validationSchema = Yup.object().shape({
    image : Yup.mixed()
    .required('please select an image')  
    .test('fileType', 'invalid file type', (value) => {
      if (value) {
        const allowedFileTypes = ['image/jpeg', 'image/png','image/jpg']; 
        return allowedFileTypes.includes(value.type);
      }
      return true; // Allow empty files
    })
    .test('fileSize', 'file size is too large', (value) => {
      if (value) {
        const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
        return value.size <= maxSizeInBytes;
      }
      return true; // Allow empty files
    }),
    company_name: Yup.string().required("company name is required"),
});

const formik = useFormik({
    initialValues: {
        image :"",
        company_name : ""
       
    },
    validationSchema : validationSchema
});

const handleUploadButtonClick = () => {
    // Logic to handle upload button click
    // setShowImagePreview(true); // Hide the image preview when upload button is clicked
};
const handleOnClick = () => {

    
    console.log("working")


    formik.setTouched(
        Object.keys(formik.values).reduce((acc , fieldName) => {
            acc[fieldName] = true
            return acc
        },
        {}
        )
    )


    formik.validateForm().then((errors) => {
      // Check if there are any validation errors
      console.log("errors" , errors)
      if (Object.keys(errors).length === 0) {
        // If no errors, proceed to the next step

      
      
    console.log("values" , formik.values)
    const payload = formik.values
    console.log("payload", payload)
    let formData = new FormData();
    formData.append('image', payload.image);
    formData.append('company_name', payload.company_name );






    console.log("formData", formData)

    const config = {
        headers: {  'Authorization': 'Bearer ' + token,
        'content-type': 'multipart/form-data' }

    }

    axios.post(`${process.env.REACT_APP_BASE_API}addDelivery`, formData, config)
        .then(response => {
            console.log(response);
            formik.setValues({
                image :"",
        company_name : ""
       
            })
            setVisibleRight(false);
             Swal.fire({
            position: "Center",
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1500
          });
            datafetch();
        })
        .catch(error => {
            console.log(error);
            setVisibleRight(false);
            formik.setValues({
                image :"",
        company_name : ""
       
            })
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
        
              });
        });
    }

    });

    
    
};

function handleChangeImage(event){
    formik.setFieldTouched('image', true);
    formik.setFieldValue('image', event.currentTarget.files[0]);
}


function handleChangeEditImage(event) {
    handleUploadButtonClick();
    editformik.setFieldTouched('editimage', true);
    editformik.setFieldValue('editimage', event.currentTarget.files[0]);
    const reader = new FileReader();
reader.onloadend = () => {
    setNewImagePreview(reader.result);
};
reader.readAsDataURL(event.currentTarget.files[0]);
setShowImagePreview(true);
setCheck(true);
}


const deleteconfirm = (ID) => {
    console.log("delete");
    console.log(ID);

    Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"

    }).then((result) => {
        if (result.isConfirmed) {
            let config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_API}delete/Delivery/` + ID,
                headers: {  'Authorization': 'Bearer ' + token,'Content-Type': 'application/json' }
            };
            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });
                    datafetch();

                }).catch((error) => {
                    console.log(error);
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                
                      });
                });
        }
    });
}




// function handleOnEdit(){ 
//     console.log(editname);
//     console.log(editimage);
//     console.log(editformik.values)
//     editformik.validateForm().then((errors) => {
//         // Check if there are any validation errors
//         if (Object.keys(errors).length === 0) {
//     let formData = new FormData();
//     formData.append('image',editimage);
//     formData.append('_id',IDE);
//     formData.append('company_name',editformik.values.editname);

//     console.log("formData", formData)

//     const config = {
//         headers: {  'Authorization': 'Bearer ' + token,'content-type': 'multipart/form-data' }

//     }

//     axios.patch(`${process.env.REACT_APP_BASE_API}updatedeliveryPartner`, formData, config
    
//     // {
//     //     _id:IDE,
//     //     company_name:editname,
//     //     image:editimage,    
//     //   }
      
      
//       )
//         .then(response => {console.log(response.data)
//         //   window.location.reload(false);
//         console.log(response.data);
//         setVisible(false);
//         datafetch();
//         })
//         .catch(error => console.error(error));

//     }
// })
// }

function handleOnEdit() {
    setVisible(false);
    Swal.fire({
        title: "Do you want to save the changes?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Save",
        denyButtonText: `Don't save`
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            editformik.validateForm().then((errors) => {
                // Check if there are any validation errors
                if (Object.keys(errors).length === 0) {
                    let formData = new FormData();
                    // formData.append('image', editformik.values.editimage);
                    formData.append('_id', IDE);
                    formData.append('company_name', editformik.values.editname);

                    console.log("formData", formData)

                    const config = {
                        headers: { 'Authorization': 'Bearer ' + token, 'content-type': 'multipart/form-data' }
                    }

        axios.patch(`${process.env.REACT_APP_BASE_API}updatedeliveryPartner`, formData, config)
                        .then(response => {
                            console.log(response.data);
                            setVisible(false);


                            setCheck(false);
                            editformik.resetForm();
                            setNewImagePreview(null);
                            datafetch();
                            Swal.fire("Saved!", "", "success");
                        })
                        .catch(error =>{
                            
                            console.error(error)
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                        
                              });
                        });
                }
            });
        } else if (result.isDenied) {
            datafetch();
            Swal.fire("Changes are not saved", "", "info");
        }
    });
}

const editPost=(val)=>{
    console.log(val);
    console.log(val.image);
    setID(val.id);
    setimage(val.image);
    setname(val.company_name)
    setshowimage(val.image);
    // setNewImagePreview(val.image)
  
    editformik.setValues({
        editimage: val.image,
        editname: val.company_name,
      
    });
    setVisible(true);          
}

// const handleChange=(e)=>{
//  setimage(e.target.files[0]);
// }


//Define Yup schema for edit form validation
const editValidationSchema = Yup.object().shape({
    editname: Yup.string().required('Company name is required'),
    editimage: Yup.mixed()
    .required('please select an image')  
    .test('fileType', 'invalid file type', (value) => {
      if (value) {
        const allowedFileTypes = ['image/jpeg', 'image/png','image/jpg']; 
        return allowedFileTypes.includes(value.type);
      }
      return true; // Allow empty files
    })
    .test('fileSize', 'file size is too large', (value) => {
      if (value) {
        const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
        return value.size <= maxSizeInBytes;
      }
      return true; // Allow empty files
    }),
    
});

// Initialize Formik for edit form
const editformik = useFormik({
    initialValues: {
        editname: editname,
        editimage: editimage,
        
    },
    validationSchema: editValidationSchema,
    onSubmit: handleOnEdit, // assuming handleOnEdit is your submit handler function
});

const handle = (event) => {
    editformik.setFieldTouched('editimage', true);
    editformik.setFieldValue('editimage', event.currentTarget.files[0]);
};

if (isLoading) {
    return <Loading />;
  }

    return (
        <>
            <Sidebar className='right-sidebar' visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                <h5>Add New Partner</h5>
                <div className='sidebar-main'>
                    <form>
                        <div className='mb-3 text-center'>
                            <div className='mb-2' style={imgPreview}>
                                <img src={formik.values.image ? URL.createObjectURL(formik.values.image) : logo1} style={{ maxWidth: '80px' }} alt="delivery partner logo" className='img-fluid' />
                          
                            </div>
                            
                            {Boolean(formik.touched.image && formik.errors.image) && (
                                    <small className="p-error">{formik.errors.image}</small>
                                )}
                                <br/>
                            <label className='btn btn-sm btn-primary rounded-4' htmlFor="upload-image">upload</label>
                            <input type="file" id='upload-image' style={{ display: 'none' }}  onChange={handleChangeImage} />

                        </div>
                        <div class="mb-3">
                            <label 
                            htmlFor="chargeName"
                            class="form-label"
                            >Company Name</label>
                            <input 
                            type="text" 
                            id="chargeName" 
                            autoComplete="off"
                            placeholder="Enter charge name" 
                            className={classNames({
                                "input-invalid": Boolean(formik.touched.company_name && formik.errors.company_name),
                            }, "form-control")}
                            name="company_name"
                            {...formik.getFieldProps("company_name")}
                            />
                             {Boolean(formik.touched.company_name && formik.errors.company_name) && (
                                <small className="p-error">{formik.errors.company_name}</small>
                            )}
                        </div>
                    </form>

                </div>

                <div className='d-flex footer gap-2 align-items-center'>
                    <button type="submit" class="btn btn-primary rounded-4" onClick={handleOnClick}>Submit</button>
                </div>
            </Sidebar>

            <Sidebar className='right-sidebar' visible={visible} position="right" onHide={() => setVisible(false)}>
                <h5>Edit Partner</h5>
                <div className='sidebar-main'>
                <form onSubmit={editformik.handleSubmit}>

                {/* <div className='mb-3 text-center'>
                            <div className='mb-2' style={imgPreview}>
                            {editformik.values.editimage && editformik.values.editimage instanceof File && (
    <img 
        // src={URL.createObjectURL(editformik.values.editimage)}
        src={editformik.values.editimage}
        style={{ maxWidth: '80px' }}
        alt="delivery partner logo"
        className='img-fluid'
    />
)}       </div>
                            
                            {Boolean(editformik.touched.editimage && editformik.errors.editimage) && (
                                    <small className="p-error">{editformik.errors.editimage}</small>
                                )}
                                <br/>
                            <label className='btn btn-sm btn-primary rounded-4' htmlFor="upload-image">upload</label>
                            <input type="file" id='upload-image' style={{ display: 'none' }}  onChange={handle} />

                        </div> */}
                {/* <div className="mb-3">
        <label htmlFor="editImage" className="form-label">Edit Image</label>
        <input
            type="file"
            id="editImage"
            name="editimage"
            className="form-control"
            onChange={handleChangeImage}
        />
        {editformik.touched.editimage && editformik.errors.editimage && (
            <small className="text-danger">{editformik.errors.editimage}</small>
        )}
    </div> */}

<div className='mb-3 text-center'>
                            <div className='mb-2' style={imgPreview}>
                                {/* <img src={editformik.values.editimage ? URL.createObjectURL(editformik.values.editimage) :logo2 } style={{ backgroundSize: 'cover' }} alt="delivery partner logo" className='img-fluid' /> */}

                                
                                {/* <img src={editformik.values.editimage } style={{ backgroundSize: 'cover' }} alt="delivery partner logo" className='img-fluid'   /> */}
                                {/* <img src={'https://betazone.promaticstechnologies.com/r-community-rest-apis/public/uploadcsv/'+ newImagePreview} style={{ backgroundSize: 'cover' }} alt="Preview" className='img-fluid' /> */}
                                {newImagePreview ? (
        <img src={newImagePreview} style={{ backgroundSize: 'cover' }} alt="Preview" className='img-fluid' />
    ) : (
        <img src={editformik.values.editimage} style={{ backgroundSize: 'cover' }} alt="delivery partner logo" className='img-fluid' />
    )}
{/* 
{showImagePreview ? (
        newImagePreview ? (
            <img src={'https://betazone.promaticstechnologies.com/r-community-rest-apis/public/uploadcsv/'+newImagePreview} style={{ maxWidth: '80px' }} alt="Delivery Partner Logo" className='img-fluid' />
        ) : (
            <img src={editformik.values.editimage} style={{ maxWidth: '80px' }} alt="Delivery Partner Logo" className='img-fluid' />
        )
    ) : null} */}
                            </div>
                              

                            {Boolean(editformik.touched.editimage && editformik.errors.editimage) && (
                                <small className="p-error">{editformik.errors.editimage}</small>
                            )}

                            
                            <br />
                            <label className='btn btn-sm btn-primary  rounded-4' htmlFor="upload-image">upload</label>
                            <input type="file" id='upload-image' style={{ display: 'none' }} onChange={handleChangeEditImage} />

                        </div>   
                        <div className="mb-3">
                            <label htmlFor="chargeName" className="form-label">Name</label>
                            <input
                                type="text"
                                id="chargeName"
                                name="editname"
                                placeholder="Enter Company name"
                                className={classNames({
                                    "input-invalid": Boolean(editformik.touched.editname && editformik.errors.editname),
                                }, "form-control")}
                                // className={classNames(
                                //     "form-control",
                                //     { "border-red": editformik.touched.edittitle && editformik.errors.edittitle }
                                // )}
                                 
                                autoComplete="off"
                                value={editformik.values.editname}
                                onChange={editformik.handleChange}
                                onBlur={editformik.handleBlur}
                            />

                       {editformik.touched.editname && editformik.errors.editname && (
                                <small className="p-error">{editformik.errors.editname}</small>
                            )}
                        </div>

                      

           
                        {/* Add similar blocks for other form fields */}
                        {/* <div className="d-flex footer gap-2 align-items-center">
                            <button type="submit" className="btn btn-primary rounded-4">Edit</button>
                        </div> */}
                    </form>
                </div>

                <div className='d-flex footer gap-2 align-items-center'>
                    <button type="submit" class="btn btn-primary rounded-4" onClick={handleOnEdit}>Edit</button>
                </div>
            </Sidebar>


            <div className='table_header mb-3'>
                <div className='d-flex flex-wrap align-items-center'>
                    <h4 className='mb-0 fw-semi-bold fs-5'>All Delivery Partners List</h4>
                    <div className='ms-auto d-flex align-items-center gap-2'>
                        <button onClick={() => setVisibleRight(true)} className='btn btn-sm btn-primary rounded-4'>Add New</button>
                    </div>

                </div>
            </div>
{data.data && data.data.length>0 ?(
            <div>
            <div className='table-responsive'>
                <table className="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">LOGO</th>
                            <th scope="col">NAME</th>
                            <th scope="col">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>

                    {/* //data.data */}
                    {data.data && data.data.map((data, idx) => (
                            <tr>
                                {/* <th scope="row">{idx + 1}</th> */}
                                <th scope="row">{(currentPage - 1) * pageSize + idx + 1}</th>

                                <td>
                               
                                    {/* <LazyLoadImage src={'https://betazone.promaticstechnologies.com/r-community-rest-apis/public/uploadcsv/'+data.image} style={{ height: 25, width: 25 }} /> */}
                                    <LazyLoadImage src={data.image} style={{ height: 25, width: 25 }} />
                                    {/* <img src={data.image} style={{ height: 25, width: 25 }} /> */}
                                    </td>
                                <td>{data.company_name}</td>

                                <td><div className='action_btn'>
                                    <Link onClick={() => editPost(data)}  ><MdOutlineEdit /></Link>
                                    <Link onClick={() => deleteconfirm(data._id)}><MdDeleteOutline /></Link>
                                </div></td>
                            </tr>
                        ))}
                        {/* <tr>
                            <th scope="row">1</th>
                            <td><img src={logo1} alt="Partner 1 Logo" class="img-fluid" style={{ maxWidth: '50px' }} /></td>
                            <td>Amazon</td>
                            <td><div className='action_btn'>

                                <Link onClick={() => setVisibleRight(true)}  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td><img src={logo2} alt="Partner 1 Logo" class="img-fluid" style={{ maxWidth: '50px' }} /></td>
                            <td>Flipkart</td>
                            <td><div className='action_btn'>

                                <Link onClick={() => setVisibleRight(true)} ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td><img src={logo3} alt="Partner 1 Logo" class="img-fluid" style={{ maxWidth: '50px' }} /></td>
                            <td>Swiggy</td>
                            <td><div className='action_btn'>

                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td><img src={logo4} alt="Partner 1 Logo" class="img-fluid" style={{ maxWidth: '50px' }} /></td>
                            <td>Zomato</td>
                            <td><div className='action_btn'>

                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td><img src={logo5} alt="Partner 1 Logo" class="img-fluid" style={{ maxWidth: '50px' }} /></td>
                            <td>Zepto</td>
                            <td><div className='action_btn'>

                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td><img src={logo6} alt="Partner 1 Logo" class="img-fluid" style={{ maxWidth: '50px' }} /></td>
                            <td>Dominoz</td>
                            <td><div className='action_btn'>

                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td><img src={logo7} alt="Partner 1 Logo" class="img-fluid" style={{ maxWidth: '50px' }} /></td>
                            <td>Big Basket</td>
                            <td><div className='action_btn'>

                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr> */}

                    </tbody>

                </table>
            </div>
            <div className="table-footer d-md-flex justify-content-md-between align-items-center">
        <p className='mb-0'>Showing {offsetentry+1} to {entry} of <span id='total-entries'>{totalItems}</span>  entries</p>
        <nav>
              <ul className="pagination pagination-md">
              <li className="page-item">
            <button
              className="page-link"
              onClick={prevPage}
              disabled={currentPage === 1}
            >
               &laquo;
            </button>
          </li>
               
                {pageNumbers.map((pageNumber) => {
            // Define the minimum and maximum page numbers to display
            const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
            const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed


            if (pageNumber >= minPage && pageNumber <= maxPage) {
                return (
                    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                        <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>
                            {pageNumber}
                        </button>
                    </li>
                );
            }
            return null;
        })}
 <li className="page-item">
            <button className="page-link" onClick={nextPage}>
            &raquo;
            </button>
          </li>
                
              </ul>
            </nav>
      
          
   
      </div>
      </div>
      ):(<h4>No data available</h4>)}    
            {/* <div className="table-footer d-md-flex justify-content-md-between align-items-center"> */}
                {/* <p className='mb-0'>Showing 1 to 10 of <span id='total-entries'>57</span>  entries</p>
               <ul class="pagination pagination-md ">
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Previous">&laquo;</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">1</Link></li>
                    <li class="page-item active"><Link class="page-link " href="#">2</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">3</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">4</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">5</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">6</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">7</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">8</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">9</Link></li>
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Next">&raquo;</Link></li>
                </ul> 
                    */}
            {/* </div> */}

            {/* <div className="pagination-controls">
                <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
                <span>{currentPage}</span>
                <button onClick={nextPage}>Next</button>
            </div> */}

            {/* <nav>
                <ul className='pagination justify-content-center'>
                    <li className="page-item">
                        <button className="page-link" onClick={prevPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                    </li>
                   

{pageNumbers.map((pageNumber) => {
            // Define the minimum and maximum page numbers to display
            const minPage = Math.max(1, currentPage - 2); // Adjust this value as needed
            const maxPage = Math.min(totalPages, currentPage + 2); // Adjust this value as needed

            if (pageNumber >= minPage && pageNumber <= maxPage) {
                return (
                    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                        <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>
                            {pageNumber}
                        </button>
                    </li>
                );
            }
            return null;
        })}
                
                    <li className="page-item">
                        <button className="page-link" onClick={nextPage} disabled={currentPage === totalPages} >
                            Next
                        </button>
                    </li>

                    
                </ul>
            </nav> */}
          {/* {pageNumbers.map((pageNumber) => (
    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
      <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>
        {pageNumber}
      </button>
    </li>
  ))} */}
                    {/* {pageNumbers.map(pgNumber => (
                        <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}>
                            <button className='page-link' onClick={() => setCurrentPage(pgNumber)}>
                                {pgNumber}
                            </button>
                        </li>
                    ))} */}
                    {/* disabled={currentPage === nPages} */}
        </>


    );
};

export default CompaniesList;
