import React from "react";
import { Avatar } from "primereact/avatar";
import { MdDownload } from "react-icons/md";



export default function Banner(props) {
    const { banner, avatar, name, job, posts, apartments, amenities, occupancy } = props;

    return (
        <div className="mb-4">
            <div className="p-d-flex p-ai-center p-jc-center p-mb-4 p-p-0" >
                <img src={banner} alt="Banner" style={{ width: "100%", borderRadius: "16px", height: '150px' }} />
            </div>

            <div style={{ marginTop: '-50px' }}>
                <div className="d-flex align-items-center justify-content-around flex-wrap">
                    <div className="">
                        <div className="p-d-flex p-jc-center">
                            <Avatar image={avatar} shape="circle" size="xlarge" className="p-mb-2" />
                        </div>

                        <div className="p-d-flex p-jc-center p-flex-column">
                            <h4 className="p-text-center p-mt-3">{name}</h4>
                            <p className="p-text-center p-mb-4">{job}</p>
                        </div>
                    </div>

                    <div className="p-d-flex p-jc-center p-mb-4">
                        <div className="p-d-flex p-ai-center p-dir-col">
                            <h3>{posts}</h3>
                            <span>Total Wings</span>
                        </div>

                        <div className="p-d-flex p-ai-center p-dir-col p-mx-4">
                            <h3>{apartments}</h3>
                            <span>Total Apartments</span>
                        </div>

                        <div className="p-d-flex p-ai-center p-dir-col p-mx-4">
                            <h3>{amenities}</h3>
                            <span>Total Amenities</span>
                        </div>

                        <div className="p-d-flex p-ai-center p-dir-col">
                            <h3>{occupancy}</h3>
                            <span>Total Occupancy</span>
                        </div>
                    </div>

                </div>
                <div className="d-flex justify-content-around align-items-center flex-wrap">
                    <button className="btn btn-primary"> <MdDownload /> Download Info</button>
                    <div className="text">
                         <h5 className="innerTxt text-center"> NAV SARVOPRIYA COOP GROUP HOUSING SOCIETY LTD.</h5>
                         <p className="innerTxt text-center" > Address: G9XQ+2J6, Salarpur Khadar, Sector 102, Noida, Uttar Pradesh</p>
                    </div>

                </div>


            </div>


        </div>
    );
}
