import React from "react";
import { MdUpload } from "react-icons/md";
import avatar from "../../../assets/images/avatar.jpg";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  updatesocietydetail,
  uploadMediaToServer,
} from "../../../utils/asyncFun";
import { mutateSocietyData } from "../../../library/store/slice";
import Swal from "sweetalert2";

export default function SocietyData(props) {
  const formik = props.formik;
  const history = useHistory();

  const {
    profile_image,
    title,
    society_name,
    email,
    phone_primary,
    status,
    address,
  } = props.getsocietydetaildata;

  function handleChangeImage(event) {
    formik.setFieldTouched("image", true);
    formik.setFieldValue("image", event.currentTarget.files[0]);

    const formData = new FormData();
    formData.append("image", event.currentTarget.files[0]);
    formData.append("path", "profile_image");
    dispatch(
      uploadMediaToServer({
        method: "POST",
        url: "uploadMediaToServer",
        data: formData,
      })
    );
  }

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      updatesocietydetail({
        method: "PATCH",
        url: "updatesocietydetail",
        data: props.getsocietydetaildata,
      })
    ).then(() => {
      Swal.fire("Saved!", "", "success");
    });
  };

  return (
    <div className="container-fluid mt-7">
      <div className="row">
        <div className="col-8">
          <button
            onClick={() => history.goBack()}
            className="btn me-2 btn-sm btn-primary rounded-4"
          >
            Back
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-10 mx-auto">
          <div className="card border-0  p-2">
            <div className="card-header bg-white border-0">
              <div className="row align-items-center">
                <div className="col-8">
                  <h4 className="mb-0">Edit Society </h4>
                </div>
                {/* <div className="col-4 text-end">
                                    <a href="#!" className="btn btn-sm btn-primary">
                                        Edit
                                    </a>
                                </div> */}
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={(e) => handleSubmit(e)}>
                {/* <h6 className="heading-small text-muted mb-4">
                                    Society Manager information
                                </h6> */}
                <div className="pl-lg-4">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile">
                        <img src="" className="avaat" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="avatar d-flex justify-content-center align-items-center flex-column m-auto">
                      <img
                        style={{ height: "150px", width: "150px" }}
                        className="rounded-circle"
                        src={
                          profile_image?.includes("https")
                            ? profile_image
                            : `${
                                process.env.REACT_APP_BASE_IMAGE_URL +
                                "r-community-rest-apis/public/profile_image/" +
                                profile_image
                              }`
                        }
                        alt=""
                      />
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="upload-img"
                        onChange={handleChangeImage}
                      />
                      <label htmlFor="upload-img" className="upload-img">
                        <MdUpload />
                      </label>
                      {/* {Boolean(formik.touched.image && formik.errors.image) && (
                                                <small className="p-error">{formik.errors.image}</small>
                                            )} */}
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-lg-4">
                      <label className="form-control-label" htmlFor="title">
                        Title
                      </label>
                      <select
                        aria-label="Default select example"
                        className={classNames(
                          {
                            "input-invalid": Boolean(
                              formik.touched.title && formik.errors.title
                            ),
                          },
                          "form-select"
                        )}
                        name="title"
                        {...formik.getFieldProps("title")}
                        value={title}
                        onChange={(e) => {
                          dispatch(
                            mutateSocietyData({
                              field: "title",
                              value: e.target.value,
                            })
                          );
                        }}
                      >
                        <option selected>Select title</option>
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Dr">Dr</option>
                        <option value="Ms">Ms</option>
                      </select>
                    
                    </div> */}
                    <div className="col-lg-4">
                      <div className="form-group focused mb-2">
                        <label
                          className="form-control-label"
                          htmlFor="input-firstName"
                        >
                          Society Name
                        </label>
                        <input
                          type="text"
                          id="input-firstName"
                          className={classNames(
                            {
                              "input-invalid": Boolean(
                                formik.touched.first_name &&
                                  formik.errors.first_name
                              ),
                            },
                            "form-control form-control-alternative"
                          )}
                          placeholder="Lucky"
                          name="first_name"
                          {...formik.getFieldProps("first_name")}
                          value={society_name}
                          onChange={(e) => {
                            dispatch(
                              mutateSocietyData({
                                field: "society_name",
                                value: e.target.value,
                              })
                            );
                          }}
                        />
                        {/* {Boolean(formik.touched.first_name && formik.errors.first_name) && (
                                                    <small className="p-error">{formik.errors.first_name}</small>
                                                )} */}
                      </div>
                    </div>
                    {/* <div className="col-lg-4">
                                            <div className="form-group mb-2">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-lastName"
                                                >
                                                    Last Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="input-lastName"
                                                    placeholder="jesse"
                                                    className={classNames({
                                                        "input-invalid": Boolean(formik.touched.last_name && formik.errors.last_name),
                                                    }, "form-control form-control-alternative")}
                                                    name="last_name"
                                                    {...formik.getFieldProps("last_name")}
                                                />
                                                {Boolean(formik.touched.last_name && formik.errors.last_name) && (
                                                    <small className="p-error">{formik.errors.last_name}</small>
                                                )}
                                            </div>
                                        </div> */}
                    <div className="col-lg-4">
                      <label className="form-control-label" htmlFor="status">
                        Status
                      </label>
                      <select
                        aria-label="Default select example"
                        className={classNames(
                          {
                            "input-invalid": Boolean(
                              formik.touched.status && formik.errors.status
                            ),
                          },
                          "form-select"
                        )}
                        name="status"
                        {...formik.getFieldProps("status")}
                        value={status}
                        onChange={(e) => {
                          dispatch(
                            mutateSocietyData({
                              field: "status",
                              value: e.target.value,
                            })
                          );
                        }}
                      >
                        <option selected>Select Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">In Active</option>
                        <option value="blocked">Block</option>
                      </select>
                      {/* {Boolean(formik.touched.status && formik.errors.status) && (
                                                <small className="p-error">{formik.errors.status}</small>
                                            )} */}
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-lg-4">
                      <div className="form-group focused">
                        <label className="form-control-label" htmlFor="email">
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          placeholder="lucky@gmail.com"
                          className={classNames(
                            {
                              "input-invalid": Boolean(
                                formik.touched.email && formik.errors.email
                              ),
                            },
                            "form-control form-control-alternative"
                          )}
                          name="email"
                          {...formik.getFieldProps("email")}
                          value={email}
                          onChange={(e) => {
                            dispatch(
                              mutateSocietyData({
                                field: "email",
                                value: e.target.value,
                              })
                            );
                          }}
                        />
                     
                      </div>
                    </div> */}
                    {/* <div className="col-lg-4">
                      <div className="form-group focused">
                        <label
                          className="form-control-label"
                          htmlFor="mobile-number"
                        >
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          id="mobile-number"
                          placeholder="9864798765"
                          className={classNames(
                            {
                              "input-invalid": Boolean(
                                formik.touched.phone_number &&
                                  formik.errors.phone_number
                              ),
                            },
                            "form-control form-control-alternative"
                          )}
                          name="phone_number"
                          {...formik.getFieldProps("phone_number")}
                          value={phone_primary}
                          onChange={(e) => {
                            dispatch(
                              mutateSocietyData({
                                field: "phone_primary",
                                value: e.target.value,
                              })
                            );
                          }}
                        />
                     
                      </div>
                    </div> */}
                    {/* <div className='col-lg-4'>
                                            <label
                                                className="form-control-label"
                                                htmlFor="status"
                                            >
                                                Roll
                                            </label>
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Select Status</option>
                                                <option value="1">Manager</option>
                                            </select>
                                        </div> */}
                  </div>
                </div>
                {/* <hr className="my-4" /> */}
                {/* Address */}
                {/* <h6 className="heading-small text-muted mb-4">
                  Society Address
                </h6> */}
                <div className="pl-lg-4">
                  <div className="row">
                    {/* <div className="col-lg-12">
                                            <div className="form-group focused mb-2">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="resident-id"
                                                >
                                                    Society Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="resident-id"
                                                    placeholder="RES123"
                                                    className={classNames({
                                                        "input-invalid": Boolean(formik.touched.society_name && formik.errors.society_name),
                                                    }, "form-control form-control-alternative")}
                                                    name="society_name"
                                                    {...formik.getFieldProps("society_name")}
                                                />
                                                {Boolean(formik.touched.society_name && formik.errors.society_name) && (
                                                    <small className="p-error">{formik.errors.society_name}</small>
                                                )}
                                            </div>
                                        </div> */}
                    <div className="col-lg-12">
                      <div className="form-group mb-2">
                        <label
                          className="form-control-label"
                          htmlFor="block-name"
                        >
                          Society Address
                        </label>
                        <textarea
                          type="text"
                          id="block-name"
                          placeholder="A 151"
                          className={classNames(
                            {
                              "input-invalid": Boolean(
                                formik.touched.society_address &&
                                  formik.errors.society_address
                              ),
                            },
                            "form-control form-control-alternative"
                          )}
                          name="society_address"
                          {...formik.getFieldProps("society_address")}
                          value={address}
                          onChange={(e) => {
                            dispatch(
                              mutateSocietyData({
                                field: "address",
                                value: e.target.value,
                              })
                            );
                          }}
                        />
                        {/* {Boolean(formik.touched.society_address && formik.errors.society_address) && (
                                                    <small className="p-error">{formik.errors.society_address}</small>
                                                )}  */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <hr className="my-4" /> */}
                <button
                  type="submit"
                  className="btn btn-outline-primary mt-3"
                  rounded={3}
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
