import React from 'react'
import ChargesList from './data/ChargesList'

export default function ManageCharges() {
    return (
        <div>
            <div className='card p-3'>

                <div className='table-body'>
                    <ChargesList />
                </div>

            </div>

        </div>
    )
}
