import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import {
  MdApartment,
  MdDeleteOutline,
  MdOutlineAdd,
  MdOutlineEdit,
} from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
//import Swal from "sweetalert2";
import Loading from "../../ManageApp/data/Loading";
import { Sidebar } from "primereact/sidebar";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { makeApiCall } from "../../../utils/api";
import moment from "moment";
import Swal from "sweetalert2";
import Datecalender from "./Datecalender";
import { DatePicker, Space } from "antd";
// import { Button } from 'primereact/button';
// import { Dialog } from 'primereact/dialog';
// import { Avatar } from '@/components/lib/avatar/Avatar';

const { RangePicker } = DatePicker;

const Finance = (props) => {
  const history = useHistory();
  const [data, setdata] = useState([]);
  const [visible, setVisible] = useState(false);
  const { id } = props;

  const token = localStorage.getItem("token");
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [bankDetail, setBanckDetail] = useState([]);

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);
  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleRight2, setVisibleRight2] = useState(false);
  const paramsdata = useParams();
  const [appartmentData, setAppartment] = useState({});
  const [alldueData, setAllDue] = useState([]);
  const [buttonToggle, setButtonToggle] = useState("false");
  const [finanaceFilter, setfinanaceFilter] = useState({});
  const [selectedDate, setSelectedDate] = useState([]);
  const [maxmonth, setMaxmonth] = useState("");

  //modal
  // const [visible, setVisible] = useState(false);

  const validationSchema = Yup.object().shape({
    deductAmount: Yup.number("Amount should be number")
      .positive("Amount should be positive")
      .required("Amount is required"),
    genid: Yup.string().required("Transaction id is required"),
    changeDate: Yup.date().required("Date is required"),
    textArea: Yup.string().required("Remark is required"),
  });
  const formik = useFormik({
    initialValues: {
      deductAmount: "",
      genid: "",
      changeDate: "",
      textArea: "",
    },
    validationSchema: validationSchema,
  });

  const handleSignIn = async (data) => {
    const formData = new FormData();
    formik.setTouched(
      Object.keys(formik.values).reduce((acc, fieldName) => {
        acc[fieldName] = true;
        return acc;
      }, {})
    );
    formik.validateForm().then(async (errors) => {
      console.log("errors", errors);

      if (Object.keys(errors).length === 0) {
        const data = formik.values;
        console.log("values", formik.values);
        formData.append("apartment_id", appartmentData.apartment_id);
        formData.append("wing_id", appartmentData.wing_id);
        formData.append("cash_payment_date", formik.values.changeDate);
        formData.append("amount", formik.values.deductAmount);
        formData.append("transaction_id", formik.values.genid);
        formData.append("remark", formik.values.textArea);

        const params = {
          society_id: paramsdata.id,
        };
        const response = await makeApiCall(
          "payMaintenanceBillCash",
          "POST",
          formData,
          params,
          true
        );
        console.log(response);
        if (response) {
          formik.resetForm();
          financeapiCall();
        }
      }

      // formik.resetForm()
    });
  };
  // Disable future dates
  const disabledDate = (current) => {
    return current && current > new Date();
  };

  const apiAllDueCall = async () => {
    const params = {
      society_id: paramsdata.id,
    };

    const apiTranstionData = await makeApiCall(
      "generateTransactionId",
      "GET",
      null,
      params,
      true
    );
  };

  const apiTranstionid = async () => {
    const params = {
      society_id: paramsdata.id,
    };

    const apiTranstionData = await makeApiCall(
      "generateTransactionId",
      "GET",
      null,
      params,
      true
    );
    if (apiTranstionData) {
      console.log(apiTranstionData);
      formik.setFieldValue("genid", apiTranstionData?.transactionId);
      // setdata(apiFinanaceData.data);
      // setTotalItems(apiBillData?.count);
      // setbill(apiBillData?.data ?? [])
      // setIsLoading(false);
    }
  };

  const updateFinance = (data) => {
    if (Object.keys(data).length > 0) {
      setAppartment({
        ...appartmentData,
        ["apartment_id"]: data?.apartment_id?._id,
        ["wing_id"]: data?.wing_id?._id,
      });
    }

    setVisibleRight(true);
  };

  const allDues = async (data) => {
    const params = {
      apartment_id: data?.apartment_id?._id,
    };

    let api = "";

    if (buttonToggle) {
      api = "listAllDueMaintenanceBill";
    } else {
      api = "listAllDueRentBill";
    }
    console.log(api);
    const response = await makeApiCall(api, "GET", null, params, true);
    console.log("duedata", response.data);

    setVisibleRight2(true);
    // Swal.fire("No dues","All dues cleared")

    setAllDue(response.data);
  };

  async function financeapiCall() {
    const params = {
      society_id: paramsdata.id,
    };

    const apiFinanaceData = await makeApiCall(
      "getMaintenanceBillByWingAndApartment",
      "GET",
      null,
      params,
      true
    );
    if (apiFinanaceData) {
      console.log(apiFinanaceData.data);
      setdata(apiFinanaceData.data);
      // setTotalItems(apiBillData?.count);
      // setbill(apiBillData?.data ?? [])
      setIsLoading(false);
    }
  }

  useEffect(() => {
    financeapiCall();
  }, []);

  async function getAmenityBooking(currentPage) {
    console.log(currentPage);

    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);
    console.log(limit, offset);

    const params = {
      society_id: paramsdata.id,
      offset: offset,
      limit: limit,
    };

    //  let url=null
    //     if{!buttonToggle}{
    //       url=
    //     }
    //     console.log(params)

    //
    const response = await makeApiCall(
      !buttonToggle
        ? "getRentBillByWingAndApartment"
        : "getMaintenanceBillByWingAndApartment",
      "GET",
      null,
      params,
      true
    );
    console.log(response);
    if (response) {
      console.log(response.data);
      setTotalItems(response?.count ?? 6);
      setdata(response?.data ?? []);
      //   setBookedAmenities(response?.data ?? [])
      //   setTotalItems(response.count);
      // setentry(response.data.length + offset);
    }
  }

  useEffect(() => {
    // console.log(currentPage)
    getAmenityBooking(currentPage);
    // setbill(response?.data ?? []);
    setIsLoading(false);
  }, [currentPage]);

  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // if (isLoading) {
  //   return <Loading />;
  // }

  // const deletePost = () => {
  //   console.log("delete");

  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       let config = {
  //         method: "delete",
  //         maxBodyLength: Infinity,
  //       };
  //       axios
  //         .request(config)
  //         .then((response) => {
  //           console.log(JSON.stringify(response.data));
  //           Swal.fire({
  //             title: "Deleted!",
  //             text: "Your file has been deleted.",
  //             icon: "success",
  //           });
  //           datafetch();
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     }
  //   });
  // };
  const handleMaintnanceApi = async () => {
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);
    console.log(limit, offset);

    let params = {};
    let startDate = moment(finanaceFilter?.month, "YYYY-MM")
      .startOf("month")
      .format("YYYY-MM-DD");
    let endDate = moment(finanaceFilter?.month, "YYYY-MM")
      .endOf("month")
      .format("YYYY-MM-DD");

    console.log(startDate);
    console.log("start", finanaceFilter.startDate);
    console.log("start", finanaceFilter.endDate);
    if (finanaceFilter?.startDate && finanaceFilter.endDate) {
      params = {
        society_id: paramsdata.id,
        offset: offset,
        limit: limit,
        start_date: finanaceFilter.startDate,
        end_date: finanaceFilter.endDate,
      };
    } else if (finanaceFilter?.month) {
      params = {
        society_id: paramsdata.id,
        offset: offset,
        limit: limit,
        start_date: startDate,
        end_date: endDate,
      };
    } else {
      params = {
        society_id: paramsdata.id,
        offset: offset,
        limit: limit,
      };
    }

    // const params = {
    //   society_id: paramsdata.id,
    //   offset: offset,
    //   limit: limit,
    //   start_date:finanaceFilter.startDate,
    //   end_date: finanaceFilter.endDate

    // };

    const response = await makeApiCall(
      "getMaintenanceBillByWingAndApartment",
      "GET",
      null,
      params,
      true
    );
    console.log(response);
    if (response) {
      console.log(response.data);
      setTotalItems(response?.count ?? 6);
      setdata(response?.data ?? []);
      //   setBookedAmenities(response?.data ?? [])
      //   setTotalItems(response.count);
      // setentry(response.data.length + offset);
    }
  };

  const handleDatepicker = (dates, dateString) => {
    setSelectedDate(dates);

    setfinanaceFilter({
      ...finanaceFilter,
      ["startDate"]: dateString[0],
      ["endDate"]: dateString[1],
      ["month"]: "",
    });
    // !buttonToggle ? handleRentApi() : handleMaintnanceApi();
  };

  const handleMonth = (e) => {
    // setfinanaceFilter()
    setSelectedDate([]);
    setfinanaceFilter({
      ...finanaceFilter,
      ["month"]: e.target.value,
      ["startDate"]: "",
      ["endDate"]: "",
    });
    console.log(e.target.value);
    // buttonToggle ? handleRentApi(e) : handleMaintnanceApi(e);
  };

  const handleRentApi = async () => {
    console.log("handlerentapi");
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);
    console.log(limit, offset);

    let params = {};
    let startDate = moment(finanaceFilter?.month, "YYYY-MM")
      .startOf("month")
      .format("YYYY-MM-DD");
    let endDate = moment(finanaceFilter?.month, "YYYY-MM")
      .endOf("month")
      .format("YYYY-MM-DD");

    console.log(startDate);

    if (finanaceFilter?.startDate && finanaceFilter.endDate) {
      params = {
        society_id: paramsdata.id,
        offset: offset,
        limit: limit,
        start_date: finanaceFilter.startDate,
        end_date: finanaceFilter.endDate,
      };
    } else if (finanaceFilter?.month) {
      params = {
        society_id: paramsdata.id,
        offset: offset,
        limit: limit,
        start_date: startDate,
        end_date: endDate,
      };
    } else {
      params = {
        society_id: paramsdata.id,
        offset: offset,
        limit: limit,
      };
    }

    //     const params={}
    //     const startDate = moment(finanaceFilter?.month, 'YYYY-MM').startOf('month');
    // const endDate = moment(finanaceFilter?.month, 'YYYY-MM').endOf('month');

    // console.log(startDate)

    //     if(finanaceFilter?.month){

    //       params = {
    //        society_id: paramsdata.id,
    //        offset: offset,
    //        limit: limit,
    //        start_date:startDate,
    //        end_date:endDate

    //      };

    //      }else{

    //       params = {
    //        society_id: paramsdata.id,
    //        offset: offset,
    //        limit: limit,

    //      };

    //      }

    // const params = {
    //   society_id: paramsdata.id,
    //   offset: offset,
    //   limit: limit,
    // };

    const response = await makeApiCall(
      "getRentBillByWingAndApartment",
      "GET",
      null,
      params,
      true
    );
    console.log(response);
    if (response) {
      console.log(response.data);
      setTotalItems(response?.count ?? 6);
      setdata(response?.data ?? []);
      //   setBookedAmenities(response?.data ?? [])
      //   setTotalItems(response.count);
      // setentry(response.data.length + offset);
    }
  };
  //bank details
  const handleBankDetails = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}bankDetails`,
      headers: { Authorization: "Bearer " + token },
      params: { society_id: paramsdata.id },
    };

    axios
      .request(config)
      .then((response) => {
        // setTotalItems(response.data.count);
        console.log(response.data.data);
        setBanckDetail(response.data.data);
        // setIsLoading(false);
        // setIDE(response.data.resident_id);
        // setentry(response.data.data.length + offset);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      {/* <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" /> */}
      <h2 className="font-bold white-space-nowrap">Bank Details</h2>
    </div>
  );

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-check"
        onClick={() => setVisible(false)}
        autoFocus
      />
    </div>
  );

  useEffect(() => {
    handleBankDetails();
  }, []);
  useEffect(() => {
    // Get the current year and month
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = (today.getMonth() + 1).toString().padStart(2, "0");
    const maxMonth = `${currentYear}-${currentMonth}`;
    console.log(maxMonth);
    setMaxmonth(maxMonth);
  }, []);

  useEffect(() => {
    !buttonToggle ? handleRentApi() : handleMaintnanceApi();
  }, [finanaceFilter.month, finanaceFilter.endDate, buttonToggle]);

  // useEffect(()=>{
  //   !buttonToggle ? handleRentApi() : handleMaintnanceApi();
  // },[finanaceFilter.endDate])

  return (
    <>
      <Sidebar
        className="right-sidebar"
        visible={visibleRight}
        position="right"
        onHide={() => {
          setVisibleRight(false);
          formik.resetForm();
        }}
      >
        <h5>Update Bill</h5>
        <div class="sidebar-main mt-3">
          <form>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Deduct Amount
              </label>
              <input
                type="number"
                name="deductAmount"
                placeholder="₹ Enter Amount "
                className={
                  Boolean(
                    formik.touched.deductAmount && formik.errors.deductAmount
                  )
                    ? "border-danger form-control "
                    : "form-control"
                }
                {...formik.getFieldProps("deductAmount")}
              />
              {Boolean(
                formik.touched.deductAmount && formik.errors.deductAmount
              ) && (
                <small className="text-danger">
                  {formik.errors.deductAmount}
                </small>
              )}
            </div>

            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Transaction Id
              </label>
              <div className="position-relative">
                <input
                  type="text"
                  placeholder="Click on generate"
                  disabled
                  // className="form-control"
                  name="genid"
                  className={
                    Boolean(formik.touched.genid && formik.errors.genid)
                      ? "border-danger form-control "
                      : "form-control"
                  }
                  {...formik.getFieldProps("genid")}
                />
                {Boolean(formik.touched.genid && formik.errors.genid) && (
                  <small className="text-danger">{formik.errors.genid}</small>
                )}
                <span
                  onClick={() => {
                    apiTranstionid();
                  }}
                  className="generate"
                >
                  <a>Generate</a>
                </span>
                {/* {Boolean(formik.touched.genid && formik.errors.genid) && (
                        <small className="text-danger">{formik.errors.genid}</small>
                      )} */}
              </div>
            </div>

            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Date
              </label>
              <input
                type="date"
                placeholder="Select Date"
                name="changeDate"
                className={
                  Boolean(formik.touched.changeDate && formik.errors.changeDate)
                    ? "border-danger form-control "
                    : "form-control"
                }
                {...formik.getFieldProps("changeDate")}
              />
              {Boolean(
                formik.touched.changeDate && formik.errors.changeDate
              ) && (
                <small className="text-danger">
                  {formik.errors.changeDate}
                </small>
              )}
            </div>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Remark
              </label>
              <textarea
                type="text"
                placeholder="Write remark here"
                name="textArea"
                className={
                  Boolean(formik.touched.changeDate && formik.errors.textArea)
                    ? "border-danger form-control "
                    : "form-control"
                }
                {...formik.getFieldProps("textArea")}
              />
              {Boolean(formik.touched.textArea && formik.errors.textArea) && (
                <small className="text-danger">{formik.errors.textArea}</small>
              )}
            </div>
          </form>
        </div>
        <div className="d-flex footer gap-2 align-items-center">
          {/* {!buttonToggle? */}
          <button
            type="submit"
            onClick={() => {
              handleSignIn();
            }}
            class="btn btn-primary rounded-4"
          >
            Update
          </button>
        </div>
      </Sidebar>

      <Sidebar
        className="right-sidebar"
        visible={visibleRight2}
        position="right"
        onHide={() => setVisibleRight2(false)}
      >
        <h5>All Dues</h5>
        {console.log(alldueData)}
        <div class="sidebar-main mt-3">
          {alldueData.length > 0 &&
            alldueData.map((ele) => (
              <div className="all_dues mb-2">
                {/* <div class="sidebar-main mt-3">
          <div className="all_dues">
            <div className="card mb-3 shadow">
              <h4 className="status badge bg-success">paid</h4>
              <div className="card-body">
                <p className="card-title">Due Amount <span className="card-text">&#8377; 1200</span></p>
                <p className="card-title">Due Date <span className="card-text">21-10-2024</span></p>
                <p className="card-title">Ref Number <span className="card-text">REF66EEKJEION5</span></p>

              </div> */}
                {/* </div> */}
                <div className="card shadow">
                  <h4 className="status badge bg-warning">{ele.is_paid}</h4>
                  <div className="card-body">
                    <p className="card-title">
                      Due Amount{" "}
                      <span className="card-text">{`${ele.remaining_amount}`}</span>
                    </p>
                    <p className="card-title">
                      Due Date{" "}
                      <span className="card-text">
                        {moment(ele.due_date).format("DD-MM-YYYY")}
                      </span>
                    </p>
                    <p className="card-title">
                      Ref Number{" "}
                      <span className="card-text fs-11">#{ele.id}</span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </Sidebar>

      <div className="table_header d-flex mb-3 justify-content-between">
        <div className="d-flex flex-wrap align-items-center">
          {/* <Link to= ""
                    // "/manage-society" 
                    className='btn me-2 btn-sm btn-primary rounded-4 '>Back</Link> */}
          <button
            onClick={() => history.goBack()}
            className="btn me-2 btn-sm btn-primary rounded-4 "
          >
            Back
          </button>
          <h5 className="mb-0 fs-6 d-flex align-item-center gap-2">
            <MdApartment /> Finance
          </h5>
          <button
            onClick={() => {
              setButtonToggle(true);
              // handleMaintnanceApi()
            }}
            //style={{backgroundColor:{buttonToggle?"white":""}}}
            //className={`btn mx-3 btn-sm btn-white rounded-4 shadow-sm `}
            className={`btn mx-2 btn-sm ${
              buttonToggle ? "btn-primary" : "btn-white"
            } rounded-4 shadow-sm`}
            // className={`btn mx-3 btn-sm ${!buttonToggle ? "btn-white" : "btn-primary"} rounded-4 shadow-sm`}
          >
            Maintance
          </button>
          <button
            onClick={() => {
              setButtonToggle(false);
              //handleRentApi()
            }}
            //  className="btn  px-3 btn-sm btn-primary rounded-4 shadow-md"
            className={`btn btn-sm ${
              !buttonToggle ? "btn-primary" : "btn-white"
            } rounded-4 shadow-sm`}
          >
            Rent
          </button>
        </div>

        <div className="d-flex gap-3">
          <div className="card flex justify-content-center">
            <Button
              label="Bank Detail"
              icon="pi pi-external-link"
              onClick={() => setVisible(true)}
            />
            <Dialog
              visible={visible}
              modal
              header={headerElement}
              footer={footerContent}
              style={{ width: "25rem" }}
              onHide={() => {
                if (!visible) return;
                setVisible(false);
              }}
            >
              {/* <p className="m-0"> */}
              {bankDetail?.bank_name ? (
                <div style={cardStyle}>
                  <h2>Bank Details</h2>
                  <p>
                    <strong>Bank Name:</strong> {bankDetail?.bank_name}
                  </p>
                  <p>
                    <strong>Account Number:</strong>{" "}
                    {bankDetail?.account_number}
                  </p>
                  <p>
                    <strong>IFSC Code:</strong> {bankDetail?.ifsc_code}
                  </p>
                  <p>
                    <strong>Account Holder Name:</strong>{" "}
                    {bankDetail?.account_holder_name}
                  </p>
                </div>
              ) : (
                <p className="m-0">Not Available</p>
              )}

              {/* </p> */}
            </Dialog>
          </div>

          <input
            type="month"
            max={maxmonth}
            name="month"
            value={finanaceFilter?.month}
            onChange={(e) => {
              handleMonth(e);

              // setfinanaceFilter({ ...finanaceFilter, ["month"]: e.target.value, ["startDate"]: "", ["endDate"]: "" }); console.log(e.target.value);
              // buttonToggle ? handleRentApi(e) : handleMaintnanceApi(e);
            }}
            className="form-control w_200"
          />

          <Space direction="vertical" size={12}>
            <RangePicker
              picker="date"
              value={selectedDate}
              id={{
                start: "startInput",
                end: "endInput",
              }}
              onFocus={(_, info) => {
                console.log("Focus:", info.range);
              }}
              onBlur={(_, info) => {
                console.log("Blur:", info.range);
              }}
              onChange={(dates, dateString) => {
                handleDatepicker(dates, dateString);
                console.log(dates);
                console.log(dateString[0]);

                // setfinanaceFilter({ ...finanaceFilter, ["startDate"]: dateString[0], ["endDate"]: dateString[1], ["month"]: "" });
                // !buttonToggle ? handleRentApi() : handleMaintnanceApi();
              }}
              disabledDate={disabledDate}
            />
          </Space>
        </div>
        {console.log(finanaceFilter)}
      </div>

      <div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">WINGS</th>
                <th scope="col">APARTMENT NUMBER</th>
                <th scope="col">
                  {buttonToggle ? "MAINTAINANCE AMOUNT" : "RENT AMOUNT"}
                </th>
                <th scope="col">OPENING BALANCE</th>
                <th scope="col">CLOSING BALANCE</th>
                <th scope="col">DUE AMOUNT</th>
                {/* <th scope="col">BANK DETAILS</th> */}
                <th scope="col">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {console.log(data)}
              {Object.keys(data).length > 0 &&
                data.map((ele, index) => (
                  <>
                    <tr key={index}>
                      <th scope="row">
                        {(currentPage - 1) * pageSize + index + 1}
                      </th>
                      <td>
                        <div className="text-uppercase">
                          {ele?.wing_id?.name}
                        </div>
                      </td>
                      <td>{ele?.apartment_id?.appartmentNumber}</td>
                      <td>
                        {buttonToggle
                          ? `₹ ${ele?.maintenance_amount ?? ""}`
                          : `₹ ${ele?.rent_amount ?? ""}`}
                      </td>

                      {/* <td>{buttonToggle ? `₹ ${ele?.maintenance_amount}` ?? "" : `₹ ${ele?.rent_amount}`} ?? ""</td>
                       */}
                      <td>{`₹ ${ele?.openingBalance}`}</td>
                      <td>{`₹ ${ele?.closingBalance}`}</td>
                      <td>{`₹ ${ele?.remaining_amount}`}</td>
                      {/* <td>{`${ele?.remark}`}</td> */}
                      {/* <td>
                        {ele.bank_details.length > 0 ? (
                          <BankDetails bankDetails={data.bank_details} />
                        ) : (
                          <i
                            className="pi pi-times"
                            style={{ color: "red" }}
                          ></i>
                        )}
                      </td> */}
                      <td>
                        <div className="action_btn">
                          <div className="d-flex">
                            {buttonToggle ? (
                              <button
                                className="btn me-2 btn-sm btn-primary rounded-4"
                                onClick={() => {
                                  updateFinance(ele);
                                  // apiTranstionid()
                                }}
                              >
                                Update
                              </button>
                            ) : (
                              ""
                            )}

                            {buttonToggle ? (
                              <button className="btn me-2  btn-sm btn-primary rounded-4 ">
                                <Link
                                  className="text-decoration-none text-white"
                                  to={`/manage-ledgers/${ele?.apartment_id?._id}?type=maintain`}
                                >
                                  Ledgers
                                </Link>
                              </button>
                            ) : (
                              <button className="btn me-2  btn-sm btn-primary rounded-4 ">
                                <Link
                                  className="text-decoration-none text-white"
                                  to={`/manage-ledgers/${ele?.apartment_id?._id}?type=rent`}
                                >
                                  Ledgers
                                </Link>
                              </button>
                            )}

                            {ele?.remaining_amount > 0 && (
                              <button
                                className="btn p-auto me-2 btn-sm btn-primary rounded-4"
                                onClick={() => {
                                  allDues(ele);
                                }}
                              >
                                All Dues
                              </button>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                ))}

              {/* 
               <tr>
                <th scope="row">1</th>
                <td>
                  <div className="text-uppercase">Wing (A)</div>
                </td>
                <td>A-101</td>
                <td>₹ 10000</td>
                <td>₹ 2000</td>
                <td>
                  <div className="action_btn">
                    <div className="d-flex">
                      <button
                        className="btn me-2 btn-sm btn-primary rounded-4"
                        onClick={() => {
                          updateFinance();
                        }}
                      >
                        Update
                      </button>
                      <Link to="/manage-ledgers">
                        <button className="btn me-2 btn-sm btn-primary rounded-4 ">
                          Ledgers
                        </button>
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">1</th>
                <td>
                  <div className="text-uppercase">Wing (A)</div>
                </td>
                <td>A-101</td>
                <td>₹ 10000</td>
                <td>₹ 2000</td>
                <td>
                  <div className="action_btn">
                    <div className="d-flex">
                      <button
                        className="btn me-2 btn-sm btn-primary rounded-4"
                        onClick={() => {
                          updateFinance();
                        }}
                      >
                        Update
                      </button>
                      <Link to="/manage-ledgers">
                        <button className="btn me-2 btn-sm btn-primary rounded-4 ">
                          Ledgers
                        </button>
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">1</th>
                <td>
                  <div className="text-uppercase">Wing (A)</div>
                </td>
                <td>A-101</td>
                <td>₹ 10000</td>
                <td>₹ 2000</td>
                <td>
                  <div className="action_btn">
                    <div className="d-flex">
                      <button
                        className="btn me-2 btn-sm btn-primary rounded-4"
                        onClick={() => {
                          updateFinance();
                        }}
                      >
                        Update
                      </button>
                      <Link to="/manage-ledgers">
                        <button className="btn me-2 btn-sm btn-primary rounded-4 ">
                          Ledgers
                        </button>
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">1</th>
                <td>
                  <div className="text-uppercase">Wing (A)</div>
                </td>
                <td>A-101</td>
                <td>₹ 10000</td>
                <td>₹ 2000</td>
                <td>
                  <div className="action_btn">
                    <div className="d-flex">
                      <button
                        className="btn me-2 btn-sm btn-primary rounded-4"
                        onClick={() => {
                          updateFinance();
                        }}
                      >
                        Update
                      </button>
                      <Link to="/manage-ledgers">
                        <button className="btn me-2 btn-sm btn-primary rounded-4 ">
                          Ledgers
                        </button>
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">1</th>
                <td>
                  <div className="text-uppercase">Wing (A)</div>
                </td>
                <td>A-101</td>
                <td>₹ 10000</td>
                <td>₹ 2000</td>
                <td>
                  <div className="action_btn">
                    <div className="d-flex">
                      <button
                        className="btn me-2 btn-sm btn-primary rounded-4"
                        onClick={() => {
                          updateFinance();
                        }}
                      >
                        Update
                      </button>
                      <Link to="/manage-ledgers">
                        <button className="btn me-2 btn-sm btn-primary rounded-4 ">
                          Ledgers
                        </button>
                      </Link>
                    </div>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <div className="table-footer d-md-flex justify-content-md-between align-items-center">
          <p className="mb-0">
            Showing {offsetentry + 1} to {offsetentry + data.length} of{" "}
            <span id="total-entries">{totalItems}</span> entries
          </p>
          <nav>
            <ul className="pagination pagination-md">
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={prevPage}
                  disabled={currentPage === 1}
                >
                  &laquo;
                </button>
              </li>

              {pageNumbers.map((pageNumber) => {
                // Define the minimum and maximum page numbers to display
                const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                if (pageNumber >= minPage && pageNumber <= maxPage) {
                  return (
                    <li
                      key={pageNumber}
                      className={`page-item ${
                        currentPage === pageNumber ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    </li>
                  );
                }
                return null;
              })}
              <li className="page-item">
                <button className="page-link" onClick={nextPage}>
                  &raquo;
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

const cardStyle = {
  border: "1px solid #ddd",
  height: "100%",
  width: "100%",
  borderRadius: "8px",
  padding: "16px",
  maxWidth: "400px",
  margin: "16px auto",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
};
export default Finance;
