import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

export default function SocietyStat(props) {
    const { name, counter, value, dynamicRoute ,imageUrl,id,societyName, unoccupyparking, occupyparking} = props;
    // console.log(counter);
    // console.log(name);
    // console.log('dynamicRoute', dynamicRoute)
    // console.log(unoccupyparking)
    const dataToPass = { image: imageUrl,society:societyName,id:id , occupyparking: occupyparking,unoccupyparking:unoccupyparking ,counter:counter};
    return (
        <div className='card border-0 rounded-4 shadow-lg mb-4 p-3'>
            <div className='row'>
                <div className="col-12 p-0">
                    <div className='text-center mb-2'>
                        <h2 className="fw-2 text-primary mb-2">
                            {counter}
                        </h2>
                        <p className="text-muted fw-6" >
                            {name}
                        </p>
                    </div>
                </div>
                <div className="col-12 p-0">
                    {/* <div className='d-grid justify-content-center'>
                        <Link to={dynamicRoute}  className="btn btn-primary rounded-5 btn-sm">{value}</Link>
                    </div> */}

                      <div className='d-grid justify-content-center'>
                        <Link to={{ pathname: dynamicRoute, state: dataToPass } }  className="btn btn-primary rounded-5 btn-sm">{value}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
