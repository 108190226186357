import React, { useState } from "react";
import user from "../../../assets/images/avatar.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Sidebar } from "primereact/sidebar";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import team1 from "../../../assets/images/avatar.jpg";
import VotingList from "../components/VotingList";
import moment from "moment";

const TableReportePost = ({ post, id, onPageChange, onSearch, image, society }) => {
  const [visibleRight, setVisibleRight] = useState(false);
  const [displayBasic2, setDisplayBasic2] = useState(false);

  const dialogFuncMap = {
    displayBasic2: setDisplayBasic2,
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
        <Button
          label="OK"
          icon="pi pi-check"
          onClick={() => onHide(name)}
          autoFocus
        />
      </div>
    );
  };

  const [page, setPage] = useState(1);
  const totalCount = post?.totalCount ?? 0;
  console.log("tabler" + " " + totalCount)
  const totalPages = Math.ceil(totalCount / 5);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber)
    onPageChange(pageNumber);
  };

  const handleSearch = (e) => {
    onSearch(e.target.value);
  };

  return (
    <>
      <Sidebar
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h3>Right Sidebar</h3>
      </Sidebar>

      <div className="table_header mb-3">
        <div className="d-flex flex-wrap align-items-center">
          <h4 className="mb-0 fw-semi-bold fs-5"> Reported Post List</h4>
          <div className="ms-auto d-flex align-items-center gap-2">
            <div class="search_box ">
              <input placeholder="Search" class="chakra-input" onChange={(e) => handleSearch(e)} />
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">POST CONTENT</th>
              <th scope="col">AUTHOR</th>
              <th scope="col">POST CREATED</th>
              <th scope="col">STATUS</th>
              <th scope="col">REPORTERS</th>
              {/* <th scope="col">REMOVAL REASON</th> */}
              <th scope="col">DATE REPORTED</th>
              <th scope="col">REMOVED BY</th>
              <th scope="col">DATE REMOVAL</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {post?.data?.map((el, i) => (
              <tr key={i}>
                <td>{(page - 1) * 5 + i + 1}</td>
                <td>{el?.post_id?.title}</td>
                <td>{`${el?.post_id?.user_id?.first_name} ${el?.post_id?.user_id?.last_name}`}</td>
                <td>{moment(el?.post_id?.createdAt).format('YYYY-MM-DD HH:mm')} </td>
                <td>{el?.post_id?.status}</td>
                <td>
                  No of User <span className="badge bg-secondary">{el?.post_id?.reportCount}</span>
                </td>
                {/* <td>{el?.reason}</td> */}
                <td>{moment(el?.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                <td>{el?.post_id?.action_by?.first_name}</td>
                <td>{moment(el?.post_id?.updatedAt).format('YYYY-MM-DD HH:mm')}</td>
                <td>
                  <div className="action_btn">
                    <Link to={`${el?.post_id?.type === "post" ? "/view-post/" : `${el?.post_id?.type === "poll" ? "/view-poll/" : "view-announcement"}`}${el?.post_id?._id}/${id}`}>
                      <MdOutlineRemoveRedEye />
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="table-footer d-md-flex justify-content-md-between align-items-center">
        <p className="mb-0">
          Showing {totalCount > 0 ? 1 : 0} to {Math.min(10, totalCount)} of {totalCount} entries
        </p>
        <ul className="pagination pagination-md">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
            <li key={pageNumber} className={`page-item ${pageNumber === page ? "active" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* -Dialoge-Content- */}

      <Dialog
        header="Reported Users List"
        visible={displayBasic2}
        style={{ width: "50vw" }}
        footer={renderFooter("displayBasic2")}
        onHide={() => onHide("displayBasic2")}
      >
        <div className="card shadow-sm border-0 p-3 bg-white mb-4 shadow">

          {/* Assuming VotingList is a custom component */}
          <div className="card border-0 shadow-sm mb-2">
            <VotingList
              avatar={team1}
              name="John Mack"
              apartment="D 005"
              date="2 July 2023"
            />
          </div>
          <div className="card border-0 shadow-sm  mb-2">
            <VotingList
              avatar={team1}
              name="John Mack"
              apartment="D 005"
              date="2 July 2023"
            />
          </div>
          <div className="card border-0 shadow-sm  mb-2">
            <VotingList
              avatar={team1}
              name="John Mack"
              apartment="D 005"
              date="2 July 2023"
            />
          </div>
          <div className="card border-0 shadow-sm  mb-2">
            <VotingList
              avatar={team1}
              name="John Mack"
              apartment="D 005"
              date="2 July 2023"
            />
          </div>
        </div>
        <br />
      </Dialog>
    </>
  );
};

export default TableReportePost;
