import { createSlice } from "@reduxjs/toolkit";
import { handleAsyncThunkAction } from "../../utils/handleAsyncThunkFunc";
import { addManagements, addManagment, addUSers, addWings, deleteAmenity, deleteCommentAndReply, deletePost, deleteWings, detailOfPost, editManagment, editWings, getApartmentDetail, getManagment, getsocietydetail, listAllPost, reportedPostReasonList, resolveComplainRequest, resolveMaintenanceRequest, showAllAmenityList, showAllAmenityTimingDetail, updateApartmentDetail, updateStatusOfSocietyManagment, updateUser, updatesocietydetail, uploadMediaToServer , listAllTrash , undoFromTrash ,deleteFromTrash} from "../../utils/asyncFun";

// Initial state-
const initState = {
    getsocietydetaildata: {},
    isLoading: false,
    isError: false,
    post: {},
    detailOfPost: {},
    detail: {},
    amenity: {},
    reportedReason:{},
    trashList : {},
}

// Reducer function-
const allFunctions = createSlice({
    name: "r_community",
    initialState: initState,
    reducers: {
        mutateSocietyData: (state, action) => {
            const { field, value } = action.payload;
            state.getsocietydetaildata[field] = value;
        },
        mutateDetail: (state, action) => {
            const { field, value } = action.payload;
            state.detail[field] = value;
        }
    },
    extraReducers: (builder) => {
        handleAsyncThunkAction(builder, getsocietydetail, (state, payload) => {
            state.getsocietydetaildata = payload.result
        })
        handleAsyncThunkAction(builder, updatesocietydetail, (state) => {})
        handleAsyncThunkAction(builder, uploadMediaToServer, (state, payload) => {
            state.getsocietydetaildata.profile_image = `${process.env.REACT_APP_BASE_IMAGE_URL + "r-community-rest-apis/public/profile_image/"}${payload.data}`
            state.detail.profile_image = `${process.env.REACT_APP_BASE_IMAGE_URL + "r-community-rest-apis/public/profile_image/"}${payload.data}`
        })
        handleAsyncThunkAction(builder, listAllPost, (state, payload) => {
            state.post = payload
        })
        handleAsyncThunkAction(builder, detailOfPost, (state, payload) => {
            state.detailOfPost = payload.data
        })
        handleAsyncThunkAction(builder, deletePost, (state) => {})
        handleAsyncThunkAction(builder, deleteCommentAndReply, (state) => {})
        handleAsyncThunkAction(builder, addWings, (state) => {})
        handleAsyncThunkAction(builder, deleteWings, (state) => {})
        handleAsyncThunkAction(builder, editWings, (state) => {})
        handleAsyncThunkAction(builder, addManagment, (state) => {})
        handleAsyncThunkAction(builder, updateStatusOfSocietyManagment, (state) => {})
        handleAsyncThunkAction(builder, addUSers, (state) => {})
        handleAsyncThunkAction(builder, updateUser, (state) => {})
        handleAsyncThunkAction(builder, addManagements, (state) => {})
        handleAsyncThunkAction(builder, getManagment, (state, payload) => {
            state.detail = payload.data
        })
        handleAsyncThunkAction(builder, editManagment, (state) => {})
        handleAsyncThunkAction(builder, showAllAmenityList, (state, payload) => {
            state.amenity = payload
        })
        handleAsyncThunkAction(builder, showAllAmenityTimingDetail, (state, payload) => {
            state.detail = payload
        })
        handleAsyncThunkAction(builder, reportedPostReasonList, (state, payload) => {
            state.reportedReason = payload
        })
        handleAsyncThunkAction(builder, deleteAmenity, (state) => {})
        handleAsyncThunkAction(builder, resolveMaintenanceRequest, (state) => {})
        handleAsyncThunkAction(builder, resolveComplainRequest, (state) => {})
        handleAsyncThunkAction(builder, getApartmentDetail, (state, payload) => {
            state.detail = payload.data
        })
        handleAsyncThunkAction(builder, updateApartmentDetail, (state) => {})
        handleAsyncThunkAction(builder, listAllTrash, (state, payload) => {
            state.trashList = payload
        })
        handleAsyncThunkAction(builder, undoFromTrash, (state) => {})
        handleAsyncThunkAction(builder, deleteFromTrash, (state) => {})
    }
})

export const {mutateSocietyData, mutateDetail} = allFunctions.actions
export default allFunctions.reducer