// import React, { useEffect } from 'react';
// import Poll from './components/Poll';
// import team1 from "../../assets/images/Debit.png"
// import VotingList from './components/VotingList';
// import { BreadCrumb } from 'primereact/breadcrumb';
// import { Link } from 'react-router-dom/cjs/react-router-dom';
// import SocietyHeader from '../../components/societyHeader';
// import SocietyHeaderView from '../../components/societyHeaderView'
// import logo1 from "../../assets/images/hamptom.png"
// import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// import { useDispatch, useSelector } from 'react-redux';
// import { detailOfPost } from '../../utils/asyncFun';
// import moment from 'moment';

// export default function ViewPoll() {

//   const home = { icon: 'pi pi-home' }

//   const items = [
//     { label: 'Dashboard' },
//     { label: 'Manage Society' },
//     { label: 'View Poll' }
//   ];

//   // Development-
//   const { id, societyId } = useParams();
//   const { society, image } = JSON.parse(localStorage.getItem("society_info"))
//   const dispatch = useDispatch();
//   const data = useSelector((store) => store.data.detailOfPost);

//   useEffect(() => {
//     dispatch(
//       detailOfPost({
//         method: "GET",
//         url: `detailOfPost?_id=${id}`,
//         data: "",
//       })
//     );
//   }, []);

//   let totalVotes = 0

//   if (data?.poll_option) {
//     totalVotes = data.poll_option.reduce(
//       (accumulator, currentValue) => accumulator + currentValue?.votes ?? 0,
//       0,
//     );
//     console.log('totalVotes', totalVotes)
//   }

//   function calculatePercentage(total, value) {
//     return (value / total) * 100
//   }

//   return (
//     <div >

// <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
//   Launch demo modal
// </button>

// {/* <!-- Modal --> */}
// <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//   <div class="modal-dialog">
//     <div class="modal-content">
//       <div class="modal-header">
//         <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
//         <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//       </div>
//       <div class="modal-body">
//         ...
//       </div>
//       <div class="modal-footer">
//         <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//         <button type="button" class="btn btn-primary">Save changes</button>
//       </div>
//     </div>
//   </div>
// </div>

//       <div className='d-flex align-items-center bg-white rounded-2'>
//         <BreadCrumb model={items} home={home} />
//         <button onClick={() => (window.history.back())} className='btn ms-auto me-2 btn-sm btn-primary rounded-4 '>Back</button>
//       </div>

//       <div className='header'>
//         <SocietyHeaderView
//           imageUrl={decodeURIComponent(image)}
//           societyName={society}
//           defaultRouter="/dashboard" />
//       </div>

//       <div className="card p-4 shadow-lg border-0">
//         <div className="row mb-4">
//           <div className="col">
//             <h4 className="mb-0">Poll</h4>
//           </div>
//         </div>

//         <div className="row">
//           <div className="col-md-8 col-lg-6 col-xl-6">
//             {/* Assuming Poll is a custom component */}
//             <Poll
//               avatar={data?.user_id?.profile_image ? data?.user_id?.profile_image : data?.societyAdmin_id?.profile_image ? data?.societyAdmin_id?.profile_image : team1}
//               name={data?.user_id ? `${data?.user_id?.first_name} ${data?.user_id?.last_name}` : `${data?.societyAdmin_id?.first_name} ${data?.societyAdmin_id?.last_name}`}
//               // apartment="D 005"
//               date={moment(data?.createdAt).format("MMMM Do YYYY")}
//               time={moment(data?.createdAt).format("hh:mm A")}
//               title={data?.title}
//               // VotingList="200"
//               comments={data?.comment?.length || 0}
//               // share="20"
//               likes={data?.likes?.count || 0}
//               images={data?.post_image}
//               meeting={true}
//             />
//           </div>
//         </div>

//         {
//           data ?
//             <div className="row">
//               <div className="col-md-8 col-lg-6 col-xl-6">
//                 <div className="card border-0 shadow-lg col-12">
//                   <div className="card-body">
//                     <div className="d-flex align-items-center justify-content-between">
//                       <div className="mt-2">
//                         <p className="text-secondary mb-0">Poll Question - {data?.poll_question}</p>
//                         <p className="text-secondary mb-0">Poll Expiry - {moment(data?.poll_expiry_date).format("DD-MM-YYYY")}</p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             : ""
//         }

//         <div className="row mt-4">
//           {data?.poll_option?.map((el) => (
//             <div className="col-md-6  col-lg-4 col-12">
//               <div className="card shadow-lg border-0 p-3 bg-white mb-4 shadow">
//                 <div className="row mb-2">
//                   <div className="col">
//                     <h6 className="font-weight-bold mb-2">Voting List</h6>
//                   </div>
//                   <div className="col text-right">
//                     <div className="d-flex align-items-center justify-content-end">
//                       <span className="cursor-pointer">...</span>
//                       <span className="font-weight-bold ml-1">{el?.id}</span>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="card mb-3 p-2">
//                   <div className="voting d-flex align-items-center">
//                     <div className="box box_one me-2">
//                       <span className="font-weight-bold">{el?.id}</span>
//                     </div>
//                     <div className="ms-2 d-flex flex-column">
//                       <span className="font-weight-bold">{el?.title}</span>
//                       <div
//                         className="progress mt-1"
//                         style={{ height: "8px", width: "150px" }}
//                       >
//                         <div
//                           className="progress-bar "
//                           role="progressbar"
//                           style={{ width: `${calculatePercentage(totalVotes, el?.votes ?? 0)}%` }}
//                           aria-valuenow="50"
//                           aria-valuemin="0"
//                           aria-valuemax="100"
//                         ></div>
//                       </div>
//                     </div>
//                     <div className="box box_two ms-auto">
//                       <span className="font-weight-bold">{el?.votes}</span>
//                     </div>
//                   </div>
//                 </div>

//                 {/* {el?.voted_user_ids?.map((e) => (
//                   <div className="card border-0 shadow-sm mb-2">
//                     <VotingList
//                       avatar={e?.profile_image || team1}
//                       name={`${e?.first_name} ${e?.last_name}`}
//                     // apartment="D 005"
//                     // date="2 July 2023"
//                     />
//                   </div>
//                 ))} */}
//               </div>
//             </div>
//           ))}

//           {/* <div className="col-md-6 col-lg-4 col-12">
//             <div className="card shadow-lg border-0 p-3 bg-white mb-4 shadow">
//               <div className="row mb-2">
//                 <div className="col">
//                   <h6 className="font-weight-bold mb-2">Voting List</h6>
//                 </div>
//                 <div className="col text-right">
//                   <div className="d-flex align-items-center justify-content-end">
//                     <span className="cursor-pointer">...</span>
//                     <span className="font-weight-bold ml-1">B</span>
//                   </div>
//                 </div>
//               </div>

//               <div className="card mb-3 p-2">
//                 <div className="voting d-flex align-items-center ">
//                   <div className="box box_one me-2">
//                     <span className="font-weight-bold">B</span>
//                   </div>
//                   <div className="ms-2 d-flex flex-column">
//                     <span className="font-weight-bold">May be</span>
//                     <div className="progress mt-1" style={{ height: '8px', width: '150px' }}>
//                       <div className="progress-bar " role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
//                     </div>
//                   </div>
//                   <div className="box box_two ms-auto">
//                     <span className="font-weight-bold">70%</span>
//                   </div>
//                 </div>
//               </div>
//               <div className="card border-0 shadow-sm  mb-2">
//                 <VotingList
//                   avatar={team1}
//                   name="John Mack"
//                   apartment="D 005"
//                   date="2 July 2023" />
//               </div>
//               <div className="card border-0 shadow-sm  mb-2">
//                 <VotingList
//                   avatar={team1}
//                   name="John Mack"
//                   apartment="D 005"
//                   date="2 July 2023" />
//               </div>
//               <div className="card border-0 shadow-sm  mb-2">
//                 <VotingList
//                   avatar={team1}
//                   name="John Mack"
//                   apartment="D 005"
//                   date="2 July 2023" />
//               </div>
//               <div className="card border-0 shadow-sm  mb-2">
//                 <VotingList
//                   avatar={team1}
//                   name="John Mack"
//                   apartment="D 005"
//                   date="2 July 2023" />
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6 col-lg-4 col-12">
//             <div className="card shadow-lg border-0 p-3 bg-white mb-4 shadow">
//               <div className="row mb-2">
//                 <div className="col">
//                   <h6 className="font-weight-bold mb-2">Voting List</h6>
//                 </div>
//                 <div className="col text-right ms-auto">
//                   <div className="d-flex align-items-center justify-content-end">
//                     <span className="cursor-pointer">...</span>
//                     <span className="font-weight-bold ml-1">C</span>
//                   </div>
//                 </div>
//               </div>

//               <div className="card mb-3 p-2">
//                 <div className="voting d-flex align-items-center">
//                   <div className="box box_one me-2">
//                     <span className="font-weight-bold">C</span>
//                   </div>
//                   <div className="ms-2 d-flex flex-column">
//                     <span className="font-weight-bold">No</span>
//                     <div className="progress mt-1" style={{ height: '8px', width: '150px' }}>
//                       <div className="progress-bar " role="progressbar" style={{ width: '20%' }} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
//                     </div>
//                   </div>
//                   <div className="box box_two ms-auto">
//                     <span className="font-weight-bold">20%</span>
//                   </div>
//                 </div>
//               </div>
//               <div className="card border-0 shadow-sm  mb-2">
//                 <VotingList
//                   avatar={team1}
//                   name="John Mack"
//                   apartment="D 005"
//                   date="2 July 2023" />
//               </div>
//               <div className="card border-0 shadow-sm  mb-2">
//                 <VotingList
//                   avatar={team1}
//                   name="John Mack"
//                   apartment="D 005"
//                   date="2 July 2023" />
//               </div>
//               <div className="card border-0 shadow-sm  mb-2">
//                 <VotingList
//                   avatar={team1}
//                   name="John Mack"
//                   apartment="D 005"
//                   date="2 July 2023" />
//               </div>
//               <div className="card border-0 shadow-sm  mb-2">
//                 <VotingList
//                   avatar={team1}
//                   name="John Mack"
//                   apartment="D 005"
//                   date="2 July 2023" />
//               </div>
//             </div>
//           </div> */}
//         </div>
//       </div>
//     </div>

//   )
// }

import React, { useEffect, useState } from "react";
import Poll from "./components/Poll";
import team1 from "../../assets/images/Debit.png";
import VotingList from "./components/VotingList";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SocietyHeader from "../../components/societyHeader";
import SocietyHeaderView from "../../components/societyHeaderView";
import logo1 from "../../assets/images/hamptom.png";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { detailOfPost } from "../../utils/asyncFun";
import moment from "moment";
import { Dialog } from "primereact/dialog";

export default function ViewPoll() {
  const [visible, setVisible] = useState(false);

  const [voterData, setvoterData] = useState([]);

  const home = { icon: "pi pi-home" };

  const items = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "View Poll" },
  ];

  const { id, societyId } = useParams();
  const { society, image } = JSON.parse(localStorage.getItem("society_info"));
  const dispatch = useDispatch();
  const data = useSelector((store) => store.data.detailOfPost);

  useEffect(() => {
    dispatch(
      detailOfPost({
        method: "GET",
        url: `detailOfPost?_id=${id}`,
        data: "",
      })
    );
  }, [id, dispatch]);

  let totalVotes = 0;

  if (data?.poll_option) {
    totalVotes = data.poll_option.reduce(
      (accumulator, currentValue) => accumulator + currentValue?.votes ?? 0,
      0
    );
  }

  function calculatePercentage(total, value) {
    return (value / total) * 100;
  }

  function handleVoterData(data) {
    setvoterData(data);
  }

  return (
    <div>
      <Dialog
        header="Voter"
        visible={visible}
        style={{ width: "25vw" }}
        onHide={() => setVisible(false)}
      >
        <div className="m-0 h-100">
          {voterData.length > 0 ? (
            voterData?.map((e) => (
              <div key={e.id} className="card border-0 shadow-sm mb-2">
                <VotingList
                  avatar={e.profile_image || team1}
                  name={`${e.first_name} ${e.last_name}`}
                />
              </div>
            ))
          ) : (
            <>
              <h5>Voter not exist</h5>
            </>
          )}
        </div>
      </Dialog>

      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
        <button
          onClick={() => window.history.back()}
          className="btn ms-auto me-2 btn-sm btn-primary rounded-4"
        >
          Back
        </button>
      </div>

      <div className="header">
        <SocietyHeaderView
          imageUrl={decodeURIComponent(image)}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>

      <div className="card p-4 shadow-lg border-0">
        <div className="row mb-4">
          <div className="col">
            <h4 className="mb-0">Poll</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8 col-lg-6 col-xl-6">
            <Poll
              avatar={
                data?.user_id?.profile_image ||
                data?.societyAdmin_id?.profile_image ||
                logo1
              }
              name={
                data?.user_id
                  ? `${data?.user_id?.first_name} ${data?.user_id?.last_name}`
                  : `${data?.societyAdmin_id?.first_name} ${data?.societyAdmin_id?.last_name}`
              }
              date={moment(data?.createdAt).format("MMMM Do YYYY")}
              time={moment(data?.createdAt).format("hh:mm A")}
              title={data?.title}
              comments={data?.comment?.length || 0}
              likes={data?.likes?.count || 0}
              images={data?.post_image}
              meeting={true}
            />
          </div>
        </div>

        {data && (
          <div className="row">
            <div className="col-md-8 col-lg-6 col-xl-6">
              <div className="card border-0 shadow-lg col-12">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="mt-2">
                      <p className="text-secondary mb-0">
                        Poll Question - {data.poll_question}
                      </p>
                      <p className="text-secondary mb-0">
                        Poll Expiry -{" "}
                        {moment(data.poll_expiry_date).format("DD-MM-YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row mt-4">
          {data?.poll_option?.map((el) => (
            <div key={el.id} className="col-md-6 col-lg-4 col-12">
              <div className="card shadow-lg border-0 p-3 bg-white mb-4 shadow">
                <div className="row mb-2">
                  <div className="col">
                    <h6 className="font-weight-bold mb-2">Voting List</h6>
                  </div>
                  <div className="col text-right">
                    <div className="d-flex align-items-center justify-content-end">
                      <span className="cursor-pointer">...</span>
                      <span className="font-weight-bold ml-1">{el.id}</span>
                    </div>
                  </div>
                </div>

                <div
                  label="Show"
                  icon="pi pi-external-link"
                  onClick={() => {
                    setVisible(true);
                    handleVoterData(el?.voted_user_ids);
                  }}
                  className="cursor-pointer card mb-3 p-2"
                >
                  <div className="voting d-flex align-items-center">
                    <div className="box box_one me-2">
                      <span className="font-weight-bold">{el.id}</span>
                    </div>
                    <div className="ms-2 d-flex flex-column">
                      <span className="font-weight-bold">{el.title}</span>
                      <div
                        className="progress mt-1"
                        style={{ height: "8px", width: "150px" }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${calculatePercentage(
                              totalVotes,
                              el.votes ?? 0
                            )}%`,
                          }}
                          aria-valuenow={calculatePercentage(
                            totalVotes,
                            el.votes ?? 0
                          )}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div className="box box_two ms-auto">
                      <span className="font-weight-bold">{el.votes}</span>
                    </div>
                  </div>
                </div>

                {/*                 
                <Dialog
                  header="Header"
                  visible={visible}
                  style={{ width: '50vw' }}
                  onHide={() => setVisible(false)}
                >
                  <p className="m-0">
                    {console.log("myel", el.voted_user_ids)}

                    {el?.voted_user_ids
                      ?.map((e) => (
                        <div key={e.id} className="card border-0 shadow-sm mb-2">
                          <VotingList
                            avatar={e.profile_image || team1}
                            name={`${e.first_name} ${e.last_name}`}
                          />
                        </div>
                      ))}

                  </p>
                </Dialog> */}

                {/* Uncomment and adjust the following if you have VotingList component */}
                {/* {el.voted_user_ids?.map((e) => (
                  <div key={e.id} className="card border-0 shadow-sm mb-2">
                    <VotingList
                      avatar={e.profile_image || team1}
                      name={`${e.first_name} ${e.last_name}`}
                    />
                  </div>
                ))} */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
