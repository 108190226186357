import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

// Create the context
const SocietyContext = createContext();

// Create the provider component
export const SocietyProvider = ({ children }) => {
  const [societies, setSocieties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch society data from the API
    const fetchSocieties = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_API}getsociety`);
        setSocieties(response.data.results);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching society data:', error);
        setIsLoading(false);
      }
    };

    fetchSocieties();

    // Clean-up function
    return () => {
      // Any clean-up code if necessary
    };
  }, []);

  return (
    <SocietyContext.Provider value={{ societies, isLoading }}>
      {children}
    </SocietyContext.Provider>
  );
};

// Custom hook to consume the context
export const useSociety = () => {
  return useContext(SocietyContext);
};
