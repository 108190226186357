import React from 'react'
import ContactUsList from './data/ContactUsList'

export default function ContactUs() {
  return (
    <div className='card p-3'>
      <div className='table-body'>
        <ContactUsList />
      </div>
    </div>
  )
}
