import React, { useEffect } from 'react'
import image01 from "../../assets/images/tennis-court.jpg"
import image02 from "../../assets/images/gym.jpg"
import image03 from "../../assets/images/park.jpg"
import { BreadCrumb } from 'primereact/breadcrumb'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom'
import SocietyHeader from '../../components/societyHeader'
import logo1 from "../../assets/images/hamptom.png"
import { useHistory } from 'react-router-dom';
import SocietyHeaderView from '../../components/societyHeaderView'
import { useDispatch, useSelector } from 'react-redux'
import { showAllAmenityTimingDetail } from '../../utils/asyncFun'
import { convertTime } from "../../utils/globalFun"
import moment from 'moment';
export default function ViewAmenity(props) {


  const { state } = props.location || {};
  // console.log(state)

  //   const { state } = props.location.state || {};
  const image = state && state.imageUrl;
  const society = state && state.society;
  // const dayArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

  const dayObject = {
    "mon": "Monday",
    "tue": "Tuesday",
    "wed": "Wednesday",
    "thu": 'Thursday',
    "fri": "Friday",
    "sat": "Saturday",
    "sun": "Sunday"
  }

  console.log(image)
  console.log(society)
  // const { imageUrl, societyName ,id} = state || {};

  const history = useHistory();
  const home = { icon: 'pi pi-home' }

  const items = [
    { label: 'Dashboard' },
    { label: 'Manage Society' },
    { label: 'View Amenity ' }
  ];

  const dispatch = useDispatch();
  const stores = useSelector((store) => store?.data?.detail);
  const { id, society_id } = useParams();

  useEffect(() => {
    dispatch(showAllAmenityTimingDetail({ method: "GET", url: `showAllAmenityTimingDetail?_id=${id}&society_id=${society_id}`, data: "" }))
  }, []);


  return (
    <div>
      <div className='d-flex align-items-center bg-white rounded-2'>
        <BreadCrumb model={items} home={home} />
        {/* <Link to="/manage-amenity" className='btn ms-auto me-2 btn-sm btn-primary rounded-4 '>Back</Link> */}
        <button onClick={() => history.goBack()} className='btn ms-auto me-2 btn-sm btn-primary rounded-4  '>Back</button>
      </div>

      <div className='header'>
        {/* <SocietyHeader
          imageUrl={logo1}
          societyName="The Hampton Homes"
          defaultRouter="/dashboard" /> */}
        <SocietyHeaderView
          //  imageUrl={logo1}
          imageUrl={image}
          // societyName="The Hampton Homes"
          // societyName={societyName}
          societyName={society}

          defaultRouter="/dashboard" />
      </div>
      <div className='card p-4'>
        <div className="row">
          <div className="col-lg-11 mx-auto ">
            <div className='card shadow-lg border-0 p-4'>
              <div className="grid-item">
                <img src={stores?.data?.amenity_id?.image || image01} alt="pool 1" />
              </div>
              <div className='row'>
                <div className="col-md-6 col-lg-6 col-12">
                  <div className='address'>
                    <h5 className='title'>Amenity Name </h5>
                    <p className='text-muted'>{stores?.data?.name}</p>
                  </div>
                  <div className='address'>
                    <h5 className='title'>Description </h5>
                    <p className='text-muted'>{stores?.data?.description}</p>
                  </div>
                  <div className='rules_regulation'>
                    <h5 className='title'>Rules and Regulation</h5>
                    <ul className='list-unstyled'>
                      {
                        stores?.data?.rules?.map((el, index) => (
                          <li className='list-item' key={index}><span className='text-muted'><i className="pi pi-check"></i>{el}</span></li>
                        ))
                      }
                    </ul>
                  </div>
                  <div className='address'>
                    <h5 className='title'>Slot Limit </h5>
                    <p className='text-muted'>{stores?.data?.slot_No_Limit == false ? stores?.data?.slot_allowed : "No Limit"}</p>
                  </div>
                  <div className='address'>
                    <h5 className='title'>Booking Limit </h5>
                    <p className='text-muted'>{stores?.data?.advanceBookingNoLimit == false ? stores?.data?.advanceBookingLimit : "No Limit"}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-12">
                  <div className="card p-4 border-0 shadow-sm">
                    <h5 className='title'>Availability</h5>
                    {
                      stores?.data?.amenity_type === "all_day" ?
                        Object.values(dayObject).map((element, index) => (<div className='d-flex justify-content-between align-items-center' key={index}>
                          <span className='title'>{element}</span>
                          <span className='title text-muted'>{stores?.data?.availability_limit == true ? "No Limit" : `${convertTime(stores?.data?.availability_startTime)} - ${convertTime(stores?.data?.availability_endTime)}`}</span>
                        </div>)) :
                        stores?.data?.weekly_AllowedDays?.map((e, index) => <div className='d-flex justify-content-between align-items-center' key={index}>
                          <span className='title'>{dayObject[e]}</span>
                          <span className='title text-muted'>{stores?.data?.availability_limit == true ? "No Limit" : `${convertTime(stores?.data?.availability_startTime)} - ${convertTime(stores?.data?.availability_endTime)}`}</span>
                        </div>)
                    }
                  </div>
                  {
                    stores?.data?.break_time?.length !== 0 ?
                      <div className="card p-4 border-0 shadow-sm">
                        <h5 className='title'>Break Time</h5>
                        {
                          stores?.data?.break_time?.map((element, index) => <div className='d-flex justify-content-between align-items-center' key={index}>
                            <span className='title'></span>
                            <span className='title text-muted'>{`${convertTime(element?.break_startTime)} - ${convertTime(element?.break_endTime)}`}</span>
                          </div>)
                        }
                      </div>
                      :
                      ""
                  }
                  {
                    stores?.data?.break_time?.length !== 0 ?
                      <div className="card p-4 border-0 shadow-sm">
                        <h5 className='title'>Maintenance Time</h5>
                        {
                          stores?.data?.maintenance?.map((element, index) => <div className='d-flex justify-content-between align-items-center' key={index}>
                            <span className='title'>{moment(element?.maintenanceDate).format("DD-MM-YYYY")}</span>
                            <span className='title text-muted'>{`${convertTime(element?.maintenance_startTime)} - ${convertTime(element?.maintenance_endTime)}`}</span>
                          </div>)
                        }
                      </div>
                      :
                      ""
                  }
                </div>
              </div>
              {/* <div className='row'>
                <div className="col-md-6 col-lg-6 col-12">
                  <div className='address'>
                    <h5 className='title'>Description </h5>
                    <p className='text-muted'>{stores?.data?.description}</p>
                  </div>
                  <div className='rules_regulation'>
                    <h5 className='title'>Rules and Regulation</h5>
                    <ul className='list-unstyled'>
                      {
                        stores?.data?.rules?.map((el, index) => (
                          <li className='list-item' key={index}><span className='text-muted'><i className="pi pi-check"></i>{el}</span></li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-12">
                  <div className="card p-4 border-0 shadow-sm">
                    <h5 className='title'>Break Time</h5>
                    {
                      stores?.data?.amenity_type === "all_day" ?
                        Object.values(dayObject).map((element, index) => (<div className='d-flex justify-content-between align-items-center' key={index}>
                          <span className='title'>{element}</span>
                          <span className='title text-muted'>{stores?.data?.availability_limit == true ? "No Limit" : `${convertTime(stores?.data?.availability_startTime)} - ${convertTime(stores?.data?.availability_endTime)}`}</span>
                        </div>)) :
                        stores?.data?.weekly_AllowedDays?.map((e, index) => <div className='d-flex justify-content-between align-items-center' key={index}>
                          <span className='title'>{dayObject[e]}</span>
                          <span className='title text-muted'>{stores?.data?.availability_limit == true ? "No Limit" : `${convertTime(stores?.data?.availability_startTime)} - ${convertTime(stores?.data?.availability_endTime)}`}</span>
                        </div>)
                    }
                  </div>
                </div>
              </div> */}

            </div>
          </div>
          <div className='col-lg-6'>

          </div>
          <div className='col-lg-6'>

          </div>
        </div>

      </div>
    </div>
  )
}
