import { Card } from 'primereact/card';
import React, { useState } from 'react'
import Slider from 'react-slick';
import gym from "../../../assets/images/gym.jpg"
import pool from "../../../assets/images/pool.jpg"
import tennis from "../../../assets/images/tennis-court.jpg"
import park from "../../../assets/images/park.jpg"
import { Checkbox } from 'primereact/checkbox';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



export default function AmenityList() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        fade: true,
        slidesToScroll: 1,
    };

    const [cities, setCities] = useState([]);

    const onCityChange = (e) => {
        let selectedCities = [...cities];
        if (e.checked)
            selectedCities.push(e.value);
        else
            selectedCities.splice(selectedCities.indexOf(e.value), 1);

        setCities(selectedCities);
    }


    return (

        <div className='card shadow-lg border-0 p-4 h-100' >
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: '30px' }}>


                <i
                    className="pi pi-check-circle"
                    style={{ fontSize: '25px' }}
                />
                <span style={{ marginLeft: '20px', fontSize: 'lg', fontWeight: '700' }}>
                    Amenities List
                </span>
            </div>

            <div className='amenity-listing'>
                <Slider {...settings}>
                    <div className='item'>
                        <div className='card border-0'>
                            <div className='row'>
                                <div className="col-md-4">
                                    <img src={gym} alt="gym" className='' />
                                </div>
                                <div className="col-md-8">
                                    <div className='card-body ms-4'>
                                        <p className='nameInfo mb-0 '>Gym</p>
                                        <div className="my-2">
                                            <div className='d-flex p-0'>
                                                <Checkbox inputId="cb1" value="A Wing" onChange={onCityChange} checked={cities.includes('A Wing')}></Checkbox>
                                                <label htmlFor="cb1" className="p-checkbox-label p-ml-2">Status</label>
                                            </div>
                                        </div>
                                        <p className=' mb-0'>
                                            Apt. 739 348 Cleveland Corners, West Isabellatown, ID 16253
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='card border-0'>
                            <div className='row'>
                                <div className="col-md-4">
                                    <img src={pool} alt="gym" className='' />
                                </div>
                                <div className="col-md-8">
                                    <div className='card-body ms-4'>
                                        <p className='nameInfo mb-0 '>Gym</p>
                                        <div className="my-2">
                                            <div className='d-flex p-0'>
                                                <Checkbox inputId="cb1" value="A Wing" onChange={onCityChange} checked={cities.includes('A Wing')}></Checkbox>
                                                <label htmlFor="cb1" className="p-checkbox-label p-ml-2">Status</label>
                                            </div>
                                        </div>
                                        <p className=' mb-0'>
                                            Apt. 739 348 Cleveland Corners, West Isabellatown, ID 16253
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='card border-0'>
                            <div className='row'>
                                <div className="col-md-4">
                                    <img src={tennis} alt="gym" className='' />
                                </div>
                                <div className="col-md-8">
                                    <div className='card-body ms-4'>
                                        <p className='nameInfo mb-0 '>Gym</p>
                                        <div className="my-2">
                                            <div className='d-flex p-0'>
                                                <Checkbox inputId="cb1" value="A Wing" onChange={onCityChange} checked={cities.includes('A Wing')}></Checkbox>
                                                <label htmlFor="cb1" className="p-checkbox-label p-ml-2">Status</label>
                                            </div>
                                        </div>
                                        <p className=' mb-0'>
                                            Apt. 739 348 Cleveland Corners, West Isabellatown, ID 16253
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='card border-0'>
                            <div className='row'>
                                <div className="col-md-4">
                                    <img src={park} alt="gym" className='' />
                                </div>
                                <div className="col-md-8">
                                    <div className='card-body ms-4'>
                                        <p className='nameInfo mb-0 '>Gym</p>
                                        <div className="my-2">
                                            <div className='d-flex p-0'>
                                                <Checkbox inputId="cb1" value="A Wing" onChange={onCityChange} checked={cities.includes('A Wing')}></Checkbox>
                                                <label htmlFor="cb1" className="p-checkbox-label p-ml-2">Status</label>
                                            </div>
                                        </div>
                                        <p className=' mb-0'>
                                            Apt. 739 348 Cleveland Corners, West Isabellatown, ID 16253
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='card border-0'>
                            <div className='row'>
                                <div className="col-md-4">
                                    <img src={gym} alt="gym" className='' />
                                </div>
                                <div className="col-md-8">
                                    <div className='card-body ms-4'>
                                        <p className='nameInfo mb-0 '>Gym</p>
                                        <div className="my-2">
                                            <div className='d-flex p-0'>
                                                <Checkbox inputId="cb1" value="A Wing" onChange={onCityChange} checked={cities.includes('A Wing')}></Checkbox>
                                                <label htmlFor="cb1" className="p-checkbox-label p-ml-2">Status</label>
                                            </div>
                                        </div>
                                        <p className=' mb-0'>
                                            Apt. 739 348 Cleveland Corners, West Isabellatown, ID 16253
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
      
                </Slider>

            </div>

        </div>

    )
}
