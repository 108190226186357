import React, { useState,useEffect } from 'react';
import { MdDeleteOutline, } from "react-icons/md";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { MdOutlineEdit } from "react-icons/md";
import { Sidebar } from 'primereact/sidebar';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { classNames } from "primereact/utils";
import axios from 'axios';
import Swal from 'sweetalert2'
import Loading from './Loading';



const EmergencyContactList = () => {
    const [visibleRight, setVisibleRight] = useState(false);
    const [visible, setVisible] = useState(false);
    const [data, setdata] = useState([]);
    useEffect(() => {
        datafetch();
       }, []);
       
    const [IDE, setID] = useState("");
    const [editname, setname] = useState("");
    const [editnumber, setnumber] = useState("");
   

      //Pagination
      const [currentPage, setCurrentPage] = useState(1);
      const [recordsPerPage] = useState(10);
   //

   const [isLoading, setIsLoading] = useState(true);

//    const token = localStorage.getItem('token');
   const [totalItems, setTotalItems] = useState(0);
//    const [isLoading, setIsLoading] = useState(true);
   const [pageSize] = useState(10);


   const token = localStorage.getItem('token');
 

   

//     function datafetch(){

        
//         // data fetching here
//         let config = {
//             method: 'get',
//             maxBodyLength: Infinity,
//             url: `${process.env.REACT_APP_BASE_API}getEmergencyContact`,
//             headers: { 'Authorization': 'Bearer ' + token}
       
//           };    
//           axios.request(config)
//           .then((response) => {
//             // console.log(JSON.stringify(response.data));
//             // console.log(response.data);
//             setdata(response.data); 
//             const value = response.data.data;

//         //   console.log(value);
        
       
//          setIsLoading(false);
//         //  console.log(ID)
//           })
//           .catch((error) => {
//             console.log(error);
//           });
// }




// //pagination
//     // const array= Object.keys(data);
//     // console.log(array)
//     const val=data.data;
//     // console.log(val);

//     const array=[];
//     for(let key in val) {
//         array.push(val[key]);
//     }
//     // console.log(array);
//     const indexOfLastRecord = currentPage * recordsPerPage;
//     const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
//     const currentRecords = array.slice(indexOfFirstRecord, indexOfLastRecord);
//     const nPages = Math.ceil(array.length / recordsPerPage)


//     const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    

//     const goToNextPage = () => {
//             if(currentPage !== nPages) setCurrentPage(currentPage + 1)
//     }
//     const goToPrevPage = () => {
//         if(currentPage !== 1) setCurrentPage(currentPage - 1)
//     }
// //

useEffect(() => {
    datafetch();
}, [currentPage]); // Fetch data whenever currentPage changes

const datafetch = () => {
    setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API}getEmergencyContact`,
        headers: { 'Authorization': 'Bearer ' + token },
        params: { limit, offset }
    };

    axios.request(config)
        .then((response) => {
            setTotalItems(response.data.count); 
            console.log(response.data)
            setdata(response.data);
            setIsLoading(false);
        })
        .catch((error) => {
            console.log(error);
        });
};

const totalPages = Math.ceil(totalItems / pageSize);
const pageNumbers = [];
for (let i = 1; i <= totalPages; i++) {
  pageNumbers.push(i);
}
const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
};

const prevPage = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
};

    // //Addcharge validation
    const validationSchema = Yup.object().shape({
        
        contact_name: Yup.string().required("contact name is required"),
        contact_number:Yup.number().required("contact number is required"),

    });

    const formik = useFormik({
        initialValues: {
            contact_name :"",
            contact_number:"",
           
        },
        validationSchema : validationSchema
    });
    
    const handleOnClick = () => {
        console.log("working")
        formik.setTouched(
            Object.keys(formik.values).reduce((acc , fieldName) => {
                acc[fieldName] = true
                return acc
            },
            {}
            )
        )
        formik.validateForm().then((errors) => {
          // Check if there are any validation errors
          console.log("errors" , errors)
          if (Object.keys(errors).length === 0) {
            // If no errors, proceed to the next step
            console.log("values" , formik.values)
            const payload = formik.values 
    
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_API}addEmergencyContact`,
                data: payload, // you are sending body instead
                headers: {
                    'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
                }, 
              }) .then((response) => {
                console.log(response);
                formik.setValues({
                    contact_name :"",
                    contact_number:"",
               })
               setIsLoading(false);
               setVisibleRight(false);  
                datafetch();
                })
            .catch((error) => {
               console.log("error",error)
              })
          }       
       
        });
        
        
    };


        
const deletePost=(ID)=>
{
        console.log("delete");
        console.log(ID);

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
         
          }).then((result) => {
            if (result.isConfirmed) {
         let config = {
           method: 'delete',
           maxBodyLength: Infinity,
           url: `${process.env.REACT_APP_BASE_API}delete/Emergency/`+ID,
    
      
           headers: {  'Authorization': 'Bearer ' + token,'Content-Type': 'application/json'}
          };
         axios.request(config)
         .then((response) => {
         console.log(JSON.stringify(response.data));
        Swal.fire({
        title: "Deleted!",
        text: "Your file has been deleted.",
        icon: "success"
        });
        datafetch();

     }).catch((error) => {
       console.log(error);
    });
  
             
    }
    });
    }    
      
    function handleOnEdit(){ 
        console.log(editname);
        console.log(editnumber);
        console.log(editformik.values)
        
        // axios.patch(`${process.env.REACT_APP_BASE_API}updateEmergencyContact`, {
        //     _id:IDE,
        //     contact_name:editformik.values.editname,
        //     contact_number:editformik.values.editnumber,
            
        //   })
        //     .then(response => {console.log(response.data)
        //         setIsLoading(false);
        //         setVisible(false);  
        //         datafetch();     
        //     //   window.location.reload(false);
        //     })
        //     .catch(error => {console.error(error)
        //         setIsLoading(false);
        //     });
    // Validate the form
    editformik.validateForm().then((errors) => {
        // Check if there are any validation errors
        if (Object.keys(errors).length === 0) {
            // If no errors, proceed with form submission
            axios.patch(`${process.env.REACT_APP_BASE_API}updateEmergencyContact`, {
                _id: IDE,
                contact_name: editformik.values.editname,
                contact_number: editformik.values.editnumber,
                // Include additional fields in the payload as needed
            })
            .then(response => {
                console.log(response.data);
                setIsLoading(false);
                setVisible(false);  
                datafetch();     
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
            });
        }
    });
    }

    const editPost=(val)=>{
        console.log(val);
        setID(val.id);
        setnumber(val.contact_number)
        setname(val.contact_name)
        editformik.setValues({
            editnumber: val.contact_number,
            editname: val.contact_name,
          
        });
          setVisible(true);
         
    }
// Define Yup schema for edit form validation
const editValidationSchema = Yup.object().shape({
    editname: Yup.string().required('Contact name is required'),
    editnumber: Yup.number().required('Contact number is required'),
    
});

// Initialize Formik for edit form
const editformik = useFormik({
    initialValues: {
        editname: editname,
        editnumber: editnumber,
        
    },
    validationSchema: editValidationSchema,
    onSubmit: handleOnEdit, // assuming handleOnEdit is your submit handler function
});

    if (isLoading) {
        return <Loading />;
      }

    return (
        <>
            <Sidebar className='right-sidebar' visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                <h5>Add New Emergency Contact</h5>
                <div class="sidebar-main">
                    <form>
                        <div class="mb-3">
                            <label for="chargeName" class="form-label">Contact Name</label>
                            <input type="text" class="form-control" id="chargeName" name="contact_name" placeholder="Enter Contact Name"     
                                // className="form-control"
                               className={classNames({
                                    "input-invalid": Boolean(formik.touched.contact_name && formik.errors.contact_name),
                                }, "form-control")}
                                {...formik.getFieldProps("contact_name")}
                                  />

              {Boolean(formik.touched.contact_name && formik.errors.contact_name) && (
                                <small className="p-error">{formik.errors.contact_name}</small>
                            )}
                        </div>
                        <div class="mb-3">
                            <label for="amount" class="form-label">Contact Number</label>
                            <input type="number" class="form-control" id="amount" name="contact_number" placeholder="Enter Contact Number"  
                            //  className="form-control"
                             className={classNames({
                                    "input-invalid": Boolean(formik.touched.contact_number && formik.errors.contact_number),
                                }, "form-control")}
                                {...formik.getFieldProps("contact_number")}
                                  />

              {Boolean(formik.touched.contact_number && formik.errors.contact_number) && (
                                <small className="p-error">{formik.errors.contact_number}</small>
                            )}
                        </div>
                    </form>
                </div>
                <div className='d-flex footer gap-2 align-items-center'>
                    <button type="submit" class="btn btn-primary rounded-4" onClick={handleOnClick}>Submit</button>
                </div>
            </Sidebar>


            <Sidebar className='right-sidebar' visible={visible} position="right" onHide={() => setVisible(false)}>
                <h5>Edit Emergency Contact</h5>
                <div class="sidebar-main">
                <form onSubmit={editformik.handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="chargeName" className="form-label">Title</label>
                            <input
                                type="text"
                                id="chargeName"
                                name="editname"
                                placeholder="Enter Contact Name"
                                className={classNames({
                                    "input-invalid": Boolean(editformik.touched.editname && editformik.errors.editname),
                                }, "form-control")}
                                // className={classNames(
                                //     "form-control",
                                //     { "border-red": editformik.touched.edittitle && editformik.errors.edittitle }
                                // )}
                                 
                                autoComplete="off"
                                value={editformik.values.editname}
                                onChange={editformik.handleChange}
                                onBlur={editformik.handleBlur}
                            />

                       {editformik.touched.editname && editformik.errors.editname && (
                                <small className="p-error">{editformik.errors.editname}</small>
                            )}
                        </div>

                        <div className="mb-3">
                <label htmlFor="editArea" className="form-label">Carpet Area</label>
                <input
                    type="text"
                    id="editArea"
                    name="editnumber"
                    placeholder="Enter carpet area"

                    className={classNames({
                        "input-invalid": Boolean(editformik.touched.editnumber && editformik.errors.editnumber),
                    }, "form-control")}
                    // className="form-control"
                    autoComplete="off"
                    value={editformik.values.editnumber}
                    onChange={editformik.handleChange}
                    onBlur={editformik.handleBlur}
                />
                {editformik.touched.editnumber && editformik.errors.editnumber && (
                    <small className="p-error">{editformik.errors.editnumber}</small>
                )}
            </div>

           
                        {/* Add similar blocks for other form fields */}
                        {/* <div className="d-flex footer gap-2 align-items-center">
                            <button type="submit" className="btn btn-primary rounded-4">Edit</button>
                        </div> */}
                    </form>
                </div>
                <div className='d-flex footer gap-2 align-items-center'>
                    <button type="submit" class="btn btn-primary rounded-4" onClick={handleOnEdit}>Edit</button>
                </div>
            </Sidebar>


            <div className='table_header mb-3'>
                <div className='d-flex flex-wrap align-items-center'>
                    <h4 className='mb-0 fw-semi-bold fs-5'>All Emergency Contact List</h4>
                    <div className='ms-auto d-flex align-items-center gap-2'>
                        <button onClick={() => setVisibleRight(true)} className='btn btn-sm btn-primary rounded-4'>Add New</button>
                    </div>

                </div>
            </div>
            <div className='table-responsive'>
                <table className="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">CONTACT NAME</th>
                            <th scope="col">NUMBER</th>
                            <th scope="col">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                    {/* {data.data && data.data.map((data,idx) => ( */}
                    {/* {currentRecords && currentRecords.map((data, idx) => ( */}
                    
                    {data.data && data.data.map((data, idx) => (
                        <tr>
                            {/* <th scope="row">{idx+1}</th> */}
                            <th scope="row"> {(currentPage - 1) * pageSize + idx + 1}</th>
                           
                            <td>{data.contact_name}</td>
                            <td>{data.contact_number}</td>  
                            <td><div className='action_btn'>
                                <Link onClick={() => editPost(data)}  ><MdOutlineEdit /></Link>
                                <Link onClick={() => deletePost(data._id)}><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                           ))}

{/* setVisible(true) */}
                        {/* <tr>
                            <th scope="row">1</th>
                            <td>Police</td>
                            <td>022 2423 2312</td>
                            <td><div className='action_btn'>

                                <Link onClick={() => setVisibleRight(true)}  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Fire Brigade</td>
                            <td>022 2423 2312</td>
                            <td><div className='action_btn'>

                                <Link onClick={() => setVisibleRight(true)} ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Ambulance</td>
                            <td>108</td>
                            <td><div className='action_btn'>

                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr> */}

                    </tbody>

                </table>
            </div>
            <div className="table-footer d-md-flex justify-content-md-between align-items-center">
                {/* <p className='mb-0'>Showing 1 to 10 of <span id='total-entries'>57</span>  entries</p>
                <ul class="pagination pagination-md ">
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Previous">&laquo;</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">1</Link></li>
                    <li class="page-item active"><Link class="page-link " href="#">2</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">3</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">4</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">5</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">6</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">7</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">8</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">9</Link></li>
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Next">&raquo;</Link></li>
                </ul> */}
            </div>

            {/* pagination */}
            {/* <nav>
            <ul className='pagination justify-content-center'>
                <li className="page-item">
                    <a className="page-link" 
                        onClick={goToPrevPage} 
                        href='#'>
                        
                        Previous
                    </a>
                </li>
                {pageNumbers.map(pgNumber => (
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >

                        <a onClick={() => setCurrentPage(pgNumber)}  
                            className='page-link' 
                            href='#'>
                            
                            {pgNumber}
                        </a>
                    </li>
                ))}
                <li className="page-item">
                    <a className="page-link" 
                        onClick={goToNextPage}
                        href='#'>
                        
                        Next
                    </a>
                </li>
            </ul>
        </nav> */}


<nav>
                <ul className='pagination justify-content-center'>
                    <li className="page-item">
                        <button className="page-link" onClick={prevPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                    </li>
                    {/* {pageNumbers.map(pgNumber => (
                        <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}>
                            <button className='page-link' onClick={() => setCurrentPage(pgNumber)}>
                                {pgNumber}
                            </button>
                        </li>
                    ))} */}
                     {pageNumbers.map((pageNumber) => (
    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
      <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>
        {pageNumber}
      </button>
    </li>
  ))}
                
                    <li className="page-item">
                        <button className="page-link" onClick={nextPage} disabled={currentPage === totalPages} >
                            Next
                        </button>
                    </li>

                    {/* disabled={currentPage === nPages} */}
                </ul>
            </nav>
         

        {/* pagination */}

        </>


    );
};

export default EmergencyContactList;
