import React, { useState } from 'react'
import SocietyHeader from '../../components/societyHeader'
import logo1 from "../../assets/images/hamptom.png"
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { HiPlus } from 'react-icons/hi';
import { RxCross2 } from "react-icons/rx";

export default function AddAnnouncement() {

    const [postOptions, setPostOptions] = useState([]);

    const handleAddPost = () => {
        setPostOptions([...postOptions, `post-${postOptions.length + 1}`]);
    };


    return (
        <div>
            <div className='header'>
                <SocietyHeader
                    imageUrl={logo1}
                    societyName="The Hampton Homes"
                    defaultRouter="/manage-society" />
            </div>

            <div className='card p-4'>
                <div className="col-xl-10 mx-auto">
                    <div className="card border-0 shadow-lg p-2">
                        <div className="card-header bg-white border-0">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <h4 className="mb-0">Add Post</h4>
                                </div>
                                <div className="col-4 text-end">
                                    <a href="#!" className="btn btn-sm btn-primary">
                                        Edit
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <form>
                                <h6 className="heading-small text-muted mb-4">
                                    Post information
                                </h6>
                                <div className="">
                                    <div class="mb-3">
                                        <label for="exampleFormControlTextarea1" class="form-label">Post Description</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                    </div>
                                </div>
                                <hr className="my-4" />
                                {/* Address */}
                                <h6 className="heading-small text-muted mb-3">
                                    Post Options
                                </h6>

                                <div className="row">
                                    <div className="col-md-12">
                                        <button type='button' onClick={handleAddPost} className='btn w-25 mb-4 ms-auto shadow-lg d-block text-muted '>Poll Options <span><HiPlus /></span></button>
                                    </div>
                                </div>
                                <div className='post-options'>
                                    <div className="container">
                                        <div className="row" >
                                            {postOptions.map((option, index) => (
                                                <div className="col-lg-6" key={index}>
                                                    <div className='card border-0 p-3 mb-2 shadow-lg'>
                                                        <div className="form-group focused mb-2">
                                                            <label className="form-control-label mb-2"
                                                                htmlFor={`input-option-${index}`}
                                                            >
                                                                {`Poll Option ${index + 1}`}
                                                            </label>
                                                            <RxCross2 className='close-icon' />
                                                            <input
                                                                type="text"
                                                                id={`input-option-${index}`}
                                                                className="form-control form-control-alternative"
                                                                placeholder={`Enter Post Option ${index + 1} Here`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <hr className="my-4" />

                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                    <Link to="/manage-announcement" className='btn btn-outline-primary'>Back</Link>
                                    <button className='btn btn-primary'>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}


