import React from "react";
import SocietyHeader from "../../components/societyHeader";
import logo1 from "../../assets/images/hamptom.png";
import AllRequest from "../AllRequest";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SocietyHeaderView from "../../components/societyHeaderView";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


export default function ManageVisitors(props) {
  const history = useHistory();
  const { id } = useParams();
  const {society, image} = JSON.parse(localStorage.getItem("society_info"))

  const home = { icon: "pi pi-home" };

  const items = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "View Requests" },
  ];

  return (
    <div>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
      </div>
      <div className="header">
        <SocietyHeaderView
          imageUrl={decodeURIComponent(image)}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>
      <div className="card p-3">
        <div className="request_list mb-4">
          <div className="mb-2 ">
            {/* <Link to="/manage-society" className='btn btn-sm btn-primary rounded-4 '>Back</Link> */}
            <button
              onClick={() => history.goBack()}
              className="btn me-2 btn-sm btn-primary rounded-4 "
            >
              Back 
            </button>
          </div>
          <AllRequest id={id} imageUrl={image} societyName={society} />
        </div>
      </div>
    </div>
  );
}
