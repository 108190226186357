import React from "react";
import { MdComment, MdVerticalShades } from "react-icons/md";
import { FcComments, FcLike, FcShare } from "react-icons/fc";
import MediaCarousel from "./Mediacarousel";

export default function Poll(props) {
  const { pdf,images, avatar, name, apartment, date, time, title, description, likes, comments, meeting, share, ...rest } = props;

  return (
    <div className="card border-0 shadow-lg mb-4">
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={avatar}
              alt="User Avatar"
              className="rounded-circle mr-3"
              style={{ width: '48px', height: '48px' }}
            />
            <div>
              <h6 className=" ms-3 mb-1">{name}</h6>

              <div className=" ms-3 d-flex align-items-center">
                <p className="text-muted mb-0">{apartment}</p>
                <span style={{ display: "block", marginRight: "0.2rem" }} className="text-secondary mr-2">
                  {date}
                </span>
                <span className="text-secondary">
                  {time}
                </span>
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
        <div className="mt-2">
          <p className="text-secondary mb-0">{title}</p>
        </div>
        <div className="mt-2 mb-2">
          <p className="text-secondary mb-0">{description}</p>
        </div>
        {pdf?
        <div >

          <a className="btn btn-sm btn-primary mb-2" href={pdf} target="_blank">       
          <img
            src="/pdf.png"
            style={{ maxWidth: "40px" }}
            alt=""
            className="img-fluid"
          /></a>
        </div>:""}


        {/* <div className="mt-3 d-flex justify-content-center">
          <span className="text-center"> <MdComment />  Responses {likes}</span>

        </div> */}

        <div>
        <MediaCarousel mediaUrls={images}/>
        </div>

        {console.log("meeting", meeting)}
        {
          !meeting ?
            <div class="container mt-3">
              <div class="d-flex justify-content-between flex-wrap">

                <button class="btn shadow-lg" >

                  <FcLike /> Like {likes}
                </button>

                <button class="btn shadow-lg" >
                  <FcComments /> Comment {comments}
                </button>
                {/* <button class="btn shadow-lg" >

                  <FcShare /> Share {share}
              </button> */}
              </div>
            </div>
            :
            ""
        }


      </div>
    </div>
  );
}
