import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function Complaint(props) {

    const { counter, name1,  imageUrl,
    societyName,name2, pending , resolved, name3, value ,id, dynamicRoute } = props;
    const dataToPass = { image: imageUrl,society:societyName,id:id };

    const total = pending + resolved

    function getPercentage(total , number){
        return (number / total) * 100
    }

    return (
        <div className='card border-0 rounded-4 shadow-lg mb-4 p-3'>
            <div className="col-12 p-0">
                <div className='text-center mb-2'>
                    <h2 className="fw-2 text-primary mb-2">
                        {counter}
                    </h2>
                    <span className='fw-semibold text-primary fs-6'>Active General Complaints</span>
                    <div className="d-flex align-items-center justify-content-start gap-2  p-2  ">
                        <span className="text-muted">{name1}</span>
                        <div className="progress mt-1" style={{ height: '8px', width: '100px' }}>
                            <div className="progress-bar " role="progressbar" style={{ width: `${getPercentage(total , pending)}%` }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-start gap-1">
                        <span className="text-muted">{name2}</span>
                        <div className="progress mt-1" style={{ height: '8px', width: '100px' }}>
                            <div className="progress-bar " role="progressbar" style={{ width: `${getPercentage(total , resolved)}%` }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-start gap-2 p-2  ">
                        <span className="text-muted">{name3}</span>
                        <div className="progress mt-1" style={{ height: '8px', width: '100px' }}>
                            <div className="progress-bar " role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div> */}

                </div>
            </div>
            <div className="col-12 p-0">
                <div className='d-grid justify-content-center'>
                    <Link to={ {pathname: dynamicRoute, state: dataToPass} } className="btn btn-primary rounded-5 btn-sm">{value}</Link>
                </div>
            </div>
        </div>
    )
}
