import React from "react";
import Header from "./header";
import SideBar from "./sidebar";
import Footer from "./footer";

export default function LayoutPage(props) {
  return (
    <div className="layout-wrapper d-flex " style={{ height: "100vh" }}>
      <SideBar />
      <div className="grid-container w-100 h-100 overflow-hidden">
        <main className="dashboardMain fullpage noScroll h-100 p-grid flex-column">
          <Header />
          <section className="sectionContent w-100 p-3 p-col h-100">
            {props.children}
          </section>
          <Footer />
        </main>
      </div>
    </div>
  );
}
