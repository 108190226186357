import React, { useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import No_image_available from "../assets/images/avatar.jpg";

export default function TenantList(props) {

    const [isBankDetailsVisible, setIsBankDetailsVisible] = useState(false);

    const handleOnClick = () => {
        setIsBankDetailsVisible(!isBankDetailsVisible);
    };

    const { name, number, apartmentnumber, image, BN, AHN, AC, IFSC } = props;
    return (
        <div className="card w-100 border-0 shadow-sm mb-1" style={{ padding: "5px 10px" }}>
            <div className="d-flex align-items-center flex-column flex-sm-row">
                {/* <img
                    src={image}
                    alt="Document"
                    className="rounded mr-md-4"
                    style={{ width: "60px", height: "60px" }} />*/}
                
                <LazyLoadImage
            // src={image}
            src={image ? image : No_image_available}
            alt="Document"
            className="rounded mr-md-4"
            style={{ width: "60px", height: "60px" }}
          />
                <div className="ms-3 mt-2">
                    <h6 className="font-weight-bold mb-1">{name}</h6>
                    <p className="font-weight-bold mb-0">{number}</p>
                    {/* <p className="fw-semi-bold text-secondary mb-1">
                        Apartment No #{apartmentnumber} {" "}
                        <Link
                            to="#"
                            onclick={handleOnClick}
                            className="font-weight-bold text-primary text-decoration-none ms-auto"
                        >
                            See Bank Details
                        </Link>
                        <div className={`bank-details ${isBankDetailsVisible ? 'visible' : 'hidden'}`} id='bankDetail'>
                            <h6 className="">Bank Details</h6>
                            <div className="card p-2 shadow-lg border-0">
                                <div className="d-flex align-items-center gap-2">
                                    <p className="text-primary font-size-1xl mb-2 font-weight-700">Bank Name : </p>
                                    <p className="text-secondary mb-2 font-weight-400">{BN}</p>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <p className="text-primary  mb-2 font-weight-700">Account Holder Name : </p>
                                    <p className="text-secondary mb-2 font-weight-400">{AHN}</p>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <p className="text-primary mb-2 font-weight-700">Account Number : </p>
                                    <p className="text-secondary mb-2 font-weight-400">{AC}</p>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <p className="text-primary mb-2 font-weight-700">IFSC Code : </p>
                                    <p className="text-secondary mb-2 font-weight-400">{IFSC}</p>
                                </div>

                            </div>
                        </div>
                    </p> */}
                </div>
            </div>
        </div>
    )
}
