import Post from "./components/Post";
import team1 from "../../assets/images/broken-robot.png";
import { FcClock } from "react-icons/fc";
import React, { useEffect, useRef, useState } from "react";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { HiDotsVertical } from "react-icons/hi";
import { BreadCrumb } from "primereact/breadcrumb";
import SocietyHeader from "../../components/societyHeader";
import SocietyHeaderView from '../../components/societyHeaderView'
import { Link } from "react-router-dom/cjs/react-router-dom";
import logo1 from "../../assets/images/hamptom.png";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { deleteCommentAndReply, detailOfPost } from "../../utils/asyncFun";
import moment from "moment";
import { getTimeDifference } from "../../utils/globalFun";

export default function ViewPost() {
  const home = { icon: "pi pi-home" };
  const [payload, setPayload] = useState({ _id: "", type: "" })

  const itemss = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "View Post" },
  ];

  const avatar = "https://source.unsplash.com/random/200x200?sig=1";

  const menu = useRef(null);
  const toast = useRef(null);
  const items = [
    {
      label: "Options",
      items: [
        {
          label: "Delete",
          icon: "pi pi-times",
          command: () => {
            handleClick(payload)
            toast.current.show({
              severity: "warn",
              summary: "Delete",
              detail: "Data Deleted",
              life: 3000,
            });
          },
        },
      ],
    },
  ];

  // Development-
  const { id } = useParams();
  const { society, image } = JSON.parse(localStorage.getItem("society_info"))

  const dispatch = useDispatch();
  const data = useSelector((store) => store.data.detailOfPost);

  useEffect(() => {
    dispatch(
      detailOfPost({
        method: "GET",
        url: id?.includes("report") ? `detailOfReportedPostAndComment?_id=${id?.split("_")[1]}` : `detailOfPost?_id=${id}`,
        data: "",
      })
    );
  }, []);

  const handleClick = (data) => {
    dispatch(
      deleteCommentAndReply({
        method: "POST",
        url: `deleteCommentAndReply`,
        data,
      })
    )
      .then(() => {
        dispatch(
          detailOfPost({
            method: "GET",
            url: `detailOfPost?_id=${id}`,
            data: "",
          })
        )
      })
  }

  return (
    <div>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={itemss} home={home} />
        <Link
          onClick={() => window.history.back()}
          className="btn ms-auto me-2 btn-sm btn-primary rounded-4 "
        >
          Back
        </Link>
      </div>

      <div className="header">
        <SocietyHeaderView
          imageUrl={decodeURIComponent(image)}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>

      <div className="card p-4 shadow-lg border-0">
        <div className="row mb-4">
          <div className="col">
            <h4 className="mb-0">Post</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-lg-5">
            {/* Assuming Poll is a custom component */}
            <div className="position-sticky top-0 mb-4 mb-md-0">
              <Post
                avatar={data?.user_id?.profile_image ? data?.user_id?.profile_image : data?.societyAdmin_id?.profile_image ? data?.societyAdmin_id?.profile_image : avatar}
                name={data?.user_id ? `${data?.user_id?.first_name} ${data?.user_id?.last_name}` : `${data?.societyAdmin_id?.first_name} ${data?.societyAdmin_id?.last_name}`}
                // apartment="D 005"
                date={moment(data?.createdAt).format("MMMM Do YYYY")}
                time={moment(data?.createdAt).format("hh:mm A")}
                description={data?.description}
                // VotingList="200"
                comments={data?.comment?.length || 0}
                // share="20"
                likes={data?.likes?.count || 0}
                images={data?.post_image}
              />
            </div>
          </div>
          <div className="col-lg-7 col-md-12">
            <div className="comments_container">
              <ul className="comment-list list-unstyled">
                {/* <li className="p-2">
                  <div className="d-flex justify-content-start align-items-center">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="me-3 rounded-circle"
                      style={{
                        height: "48px",
                        width: "48px",
                        marginRight: "20px",
                      }}
                    />
                    <div className="d-flex flex-column align-items-start">
                      <h6 className="text-dark font-weight-bold mb-0">
                        John Doe{" "}
                        <span className="badge rounded-pill bg-danger ms-2">
                          Reported
                        </span>{" "}
                      </h6>
                      <div className="d-flex align-items-center gap-2">
                        <p className="text-secondary m-0 me-3 fs-6">D 0005</p>
                        <FcClock color="#6c757d" size="15" />
                        <span className="text-secondary m-0">2 weeks ago</span>
                      </div>
                    </div>
                    <div className="ms-auto">
                      <Toast ref={toast}></Toast>

                      <Menu model={items} popup ref={menu} id="popup_menu" />
                      <button
                        className="btn shadow-sm"
                        label="Show"
                        icon="pi pi-bars"
                        onClick={(event) => menu.current.toggle(event)}
                        aria-controls="popup_menu"
                        aria-haspopup
                      >
                        <HiDotsVertical />
                      </button>
                    </div>
                  </div>
                  <div className="my-2">
                    <p className="text-dark font-weight-bold mt-2">
                      A Special General Body Meeting of the Society may be
                      called at any time
                      <a href="!#" className="text-dark ms-2">
                        Read More
                      </a>
                    </p>
                  </div>
                </li> */}

                {data?.comment?.map((el) => (
                  <li className="p-2">
                    {console.log("el",el.is_societyAdmin)}
                    <div className="d-flex justify-content-start align-items-center">
                      <img
                        src={el?.user_id?.profile_image ? el?.user_id?.profile_image : el?.societyAdmin_id?.profile_image ? el?.societyAdmin_id?.profile_image : avatar}
                        alt="Avatar"
                        className="me-3 rounded-circle"
                        style={{
                          height: "48px",
                          width: "48px",
                          marginRight: "20px",
                        }}
                      />
                      <div className="d-flex flex-column align-items-start">
                        <h6 className="text-dark font-weight-bold mb-0">
                          {el?.user_id  ? `${el?.user_id?.first_name} ${el?.user_id?.last_name}` : `${el?.societyAdmin_id?.first_name} ${el?.societyAdmin_id?.last_name} - (Admin) `}
                        </h6>

                        <div className="d-flex align-items-center gap-2">
                          <p className="text-secondary m-0 me-3">
                            {el?.apartment_id?.appartmentNumber}
                          </p>
                          <FcClock color="#6c757d" size="15" />
                          <p className="text-secondary m-0">
                            {getTimeDifference(el?.createdAt)} ago
                          </p>
                        </div>
                      </div>

                      {
                        el.visible ?
                          <div className="ms-auto">
                            <Toast ref={toast}></Toast>

                            <Menu model={items} popup ref={menu} id="popup_menu" />
                            <button
                              className="btn shadow-sm"
                              label="Show"
                              icon="pi pi-bars"
                              onClick={(event) => {
                                menu.current.toggle(event);
                                setPayload({ _id: el._id, type: "comment" })
                              }}
                              aria-controls="popup_menu"
                              aria-haspopup
                            >
                              <HiDotsVertical />
                            </button>
                          </div>
                          :
                          <div className="ms-auto">
                            <button class="btn shadow-lg text-danger" >
                              Deleted
                            </button>
                          </div>
                      }
                    </div>
                    <div className="my-2">
                      <p className="text-dark font-weight-bold mt-2">
                        {el?.comment}
                        {/* <a href="!#" className="text-dark ms-2">
                          Read More
                        </a> */}
                      </p>
                    </div>
                    {el?.comment_reply?.map((el2) => (
                      <div className="ms-4 p-2">
                        <div className="d-flex justify-content-start align-items-center">
                          <img
                            src={el?.user_id ? el?.user_id?.profile_image : el?.societyAdmin_id?.profile_image}
                            alt="Avatar"
                            className="me-3 rounded-circle"
                            style={{
                              height: "48px",
                              width: "48px",
                              marginRight: "20px",
                            }}
                          />
                          <div className="d-flex flex-column align-items-start">
                            <h6 className="text-dark font-weight-bold mb-0">
                            {el?.user_id ? `${el?.user_id?.first_name} ${el?.user_id?.last_name}` : `${el?.societyAdmin_id?.first_name} ${el?.societyAdmin_id?.last_name} - (Admin) `}
                            </h6>

                            <div className="d-flex align-items-center gap-2">
                              <p className="text-secondary m-0 me-3">{el2?.apartment_id?.appartmentNumber}</p>
                              <FcClock color="#6c757d" size="15" />
                              <p className="text-secondary m-0">{getTimeDifference(el2?.createdAt)} ago</p>
                            </div>
                          </div>
                          {
                            el.visible ?
                              <div className="ms-auto">
                                <Toast ref={toast}></Toast>

                                <Menu
                                  model={items}
                                  popup
                                  ref={menu}
                                  id="popup_menu"
                                />
                                <button
                                  className="btn shadow-sm"
                                  label="Show"
                                  icon="pi pi-bars"
                                  onClick={(event) => {
                                    menu.current.toggle(event);
                                    setPayload({ _id: el2._id, type: "reply" })
                                  }}
                                  aria-controls="popup_menu"
                                  aria-haspopup
                                >
                                  <HiDotsVertical />
                                </button>
                              </div>
                              :
                              <div className="ms-auto">
                                <button class="btn shadow-lg text-danger" >
                                  Deleted
                                </button>
                              </div>
                          }
                        </div>
                        <div className="my-2">
                          <p className="text-dark font-weight-bold mt-2">
                            {el2?.reply}
                            {/* <a href="!#" className="text-dark ms-2">
                              Read More
                            </a> */}
                          </p>
                        </div>
                      </div>
                    ))}
                  </li>
                ))}

                {/* <li className="p-2">
                  <div className="d-flex justify-content-start align-items-center">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="me-3 rounded-circle"
                      style={{
                        height: "48px",
                        width: "48px",
                        marginRight: "20px",
                      }}
                    />
                    <div className="d-flex flex-column align-items-start">
                      <h6 className="text-dark font-weight-bold mb-0">
                        John Doe{" "}
                        <span className="badge rounded-pill bg-danger ms-2">
                          Reported
                        </span>
                      </h6>

                      <div className="d-flex align-items-center gap-2">
                        <p className="text-secondary m-0 me-3 fs-6">D 0005</p>
                        <FcClock color="#6c757d" size="15" />
                        <span className="text-secondary m-0">2 weeks ago</span>
                      </div>
                    </div>
                    <div className="ms-auto">
                      <Toast ref={toast}></Toast>

                      <Menu model={items} popup ref={menu} id="popup_menu" />
                      <button
                        className="btn shadow-sm"
                        label="Show"
                        icon="pi pi-bars"
                        onClick={(event) => menu.current.toggle(event)}
                        aria-controls="popup_menu"
                        aria-haspopup
                      >
                        <HiDotsVertical />
                      </button>
                    </div>
                  </div>
                  <div className="my-2">
                    <p className="text-dark font-weight-bold mt-2">
                      A Special General Body Meeting of the Society may be
                      called at any time
                    </p>
                  </div>
                </li>

                <li className="p-2">
                  <div className="d-flex justify-content-start align-items-center">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="me-3 rounded-circle"
                      style={{
                        height: "48px",
                        width: "48px",
                        marginRight: "20px",
                      }}
                    />
                    <div className="d-flex flex-column align-items-start">
                      <h6 className="text-dark font-weight-bold mb-0">
                        John Doe
                      </h6>

                      <div className="d-flex align-items-center gap-2">
                        <p className="text-secondary m-0 me-3 fs-6">D 0005</p>
                        <FcClock color="#6c757d" size="15" />
                        <span className="text-secondary m-0">2 weeks ago</span>
                      </div>
                    </div>
                    <div className="ms-auto">
                      <Toast ref={toast}></Toast>

                      <Menu model={items} popup ref={menu} id="popup_menu" />
                      <button
                        className="btn shadow-sm"
                        label="Show"
                        icon="pi pi-bars"
                        onClick={(event) => menu.current.toggle(event)}
                        aria-controls="popup_menu"
                        aria-haspopup
                      >
                        <HiDotsVertical />
                      </button>
                    </div>
                  </div>
                  <div className="my-2">
                    <p className="text-dark font-weight-bold mt-2">
                      A Special General Body Meeting of the Society may be
                      called at any time
                      <a href="#" className="text-dark ms-2">
                        Read More
                      </a>
                    </p>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
