import { Dropdown } from 'primereact/dropdown';
import React, { useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { RadioButton } from "primereact/radiobutton";

export default function PaymentsRemainList() {

  const [selectedMonth, setSelectedMonth] = useState(null);

  const months = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const handleMonthChange = (event) => {
    setSelectedMonth(event.value);
  };


  return (

    <div className='container'>
      <div className="row">
        <div className="col-md-3">
          <div className='card p-3 shadow-lg border-0'>
            <div className="form-group mb-3">
              <label
                className="form-control-label mb-1"
                htmlFor="block-name"
              >
                Enter Year
              </label>
              <input
                type="number"
                id="block-name"
                className="form-control form-control-alternative"
                placeholder="2015"
              />
            </div>
            <h6>Select a Month:</h6>
            <div className="p-grid p-dir-col">
              {months.map((month) => (
                <div key={month.value} className="p-col ">
                  <RadioButton
                    inputId={`month_${month.value}`}
                    name="month"
                    value={month.value}
                    onChange={handleMonthChange}
                    checked={selectedMonth === month.value}
                  />
                  <label htmlFor={`month_${month.value}`} className="p-radiobutton-label ">
                    {month.label}
                  </label>
                </div>
              ))}
            </div>
            <div>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className='main_table'>
            <div className='table_header mb-3'>
              <div className='d-flex flex-wrap align-items-center'>
                <h5 className='fw-semi-bold fs-6 mb-0'> Payment Remaining </h5>
                <div className='ms-auto d-flex align-items-center gap-2'>
                  {selectedMonth && <p className='mb-0 '>Select Month : {months[selectedMonth - 1].label} </p>}
                  {/* <div class="search_box ">
                    <input placeholder="Search" class="chakra-input " />
                  </div> */}
                  <button className='btn btn-outline-primary rounded-5 btn-sm'>
                    Download/Report
                  </button>

                </div>

              </div>
            </div>
            <div className='table-responsive'>
              <table className="table table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">RESIDENT ID</th>
                    <th scope="col">RESIDENT NAME</th>
                    <th scope="col">PAY</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>RES123</td>
                    <td>Mahesh</td>
                    <td><button href="#" className='btn btn-primary btn-sm'>Pay</button></td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>RES123</td>
                    <td>Mahesh</td>
                    <td><button href="#" className='btn btn-primary btn-sm'>Pay</button></td>

                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>RES123</td>
                    <td>Mahesh</td>
                    <td><button href="#" className='btn btn-primary btn-sm'>Pay</button></td>

                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>RES123</td>
                    <td>Mahesh</td>
                    <td><button href="#" className='btn btn-primary btn-sm'>Pay</button></td>

                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>RES123</td>
                    <td>Mahesh</td>
                    <td><button href="#" className='btn btn-primary btn-sm'>Pay</button></td>

                  </tr>

                  <tr>
                    <th scope="row">6</th>
                    <td>RES123</td>
                    <td>Mahesh</td>
                    <td><button href="#" className='btn btn-primary btn-sm'>Pay</button></td>

                  </tr>

                  <tr>
                    <th scope="row">7</th>
                    <td>RES123</td>
                    <td>Mahesh</td>
                    <td><button href="#" className='btn btn-primary btn-sm'>Pay</button></td>

                  </tr>

                  <tr>
                    <th scope="row">8</th>
                    <td>RES123</td>
                    <td>Mahesh</td>
                    <td><button href="#" className='btn btn-primary btn-sm'>Pay</button></td>

                  </tr>
                  <tr>
                    <th scope="row">9</th>
                    <td>RES123</td>
                    <td>Mahesh</td>
                    <td><button href="#" className='btn btn-primary btn-sm'>Pay</button></td>

                  </tr>
                  <tr>
                    <th scope="row">10</th>
                    <td>RES123</td>
                    <td>Mahesh</td>
                    <td><button href="#" className='btn btn-primary btn-sm'>Pay</button></td>

                  </tr>
                </tbody>

              </table>
            </div>
            <div className="table-footer d-md-flex align-items-center justify-content-md-between ">
              <p className='mb-0'>Showing 1 to 10 of <span id='total-entries'>57</span>  entries</p>
              <ul className="pagination  pagination-md">
                <li class="page-item"><Link className="page-link" aria-label="Previous">&laquo;</Link></li>
                <li className="page-item"><Link className="page-link" >1</Link></li>
                <li className="page-item active"><Link className="page-link " href="#">2</Link></li>
                <li className="page-item"><Link className="page-link" >3</Link></li>
                <li className="page-item"><Link className="page-link" >4</Link></li>
                <li className="page-item"><Link className="page-link" >5</Link></li>
                <li className="page-item"><Link className="page-link" >6</Link></li>
                <li className="page-item"><Link className="page-link" >7</Link></li>
                <li className="page-item"><Link className="page-link" >8</Link></li>
                <li className="page-item"><Link className="page-link" >9</Link></li>
                <li className="page-item"><Link className="page-link" aria-label="Next">&raquo;</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>


    </div>

  )
}
