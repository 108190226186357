import { BreadCrumb } from 'primereact/breadcrumb';
import React from 'react'
import SocietyHeader from '../../components/societyHeader';
import logo1 from "../../assets/images/hamptom.png"
import ComplaintsList from './data/ComplaintsList';
import SocietyHeaderView from '../../components/societyHeaderView'
import { useEffect,useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function ManageComplaints(props) {
    const { id } = useParams();
    const {society, image} = JSON.parse(localStorage.getItem("society_info"))

    const home = { icon: 'pi pi-home' }
  
    const items = [
        { label: 'Dashboard' },
        { label: 'Manage Society' },
        { label: 'View Active Complaints' }
    ];


 
    
    return (
        <div>

            <div className='d-flex align-items-center bg-white rounded-2'>
                <BreadCrumb model={items} home={home} />
            </div>

            <div className='header'>
                {/* <SocietyHeader
                    imageUrl={logo1}
                    societyName="The Hampton Homes"
                    defaultRouter="/dashboard" /> */}
                     <SocietyHeaderView
                    imageUrl={decodeURIComponent(image)}
                    societyName={society}
                    defaultRouter="/dashboard" />
            </div>
            <div className='card p-3'>

                <div className='table-body'>
                    <ComplaintsList id={id} />
                </div>

            </div>

        </div>
    )
}
