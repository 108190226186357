import React from "react";
import {
  MdApartment,
  MdDeleteOutline,
  MdOutlineAdd,
  MdOutlineEdit,
} from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Loading from "../../ManageApp/data/Loading";
import { Sidebar } from "primereact/sidebar";
import { makeApiCall } from "../../../utils/api";

const moment = require("moment");

const Bills = (props) => {
  
  const history = useHistory();
  const [data, setdata] = useState([]);
  const [billData, setbill] = useState([]);
  const [visible, setVisible] = useState(false);
  const { id } = props;

  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(true);
  // const [currentPage, setCurrentPage] = useState(1);

  const [selectedCity1, setSelectedCity1] = useState("");

  //const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);
  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleRight2, setVisibleRight2] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);
  const [offsetentry, setoffsetentry] = useState(0);

  const [uploadfile, setUpload] = useState(null);
  const [error, setError] = useState(false);
  const [errmsg, setErrorMsg] = useState("");
  const [societyid, setsocietyid] = useState("");
  const paramsdata = useParams();
  //console.log("paramss", paramsdata);

  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const uploadCsv = () => {
    setVisibleRight(true);
  };

  async function billapiCall() {
    const params = {
      society_id: paramsdata.id,
    };

    const apiBillData = await makeApiCall(
      "showCSVBySociety",
      "GET",
      null,
      params,
      true
    );
    if (apiBillData) {
      console.log(apiBillData.count);

      setTotalItems(apiBillData?.count);
      setbill(apiBillData?.data ?? []);
      setIsLoading(false);
    }
  }

  
  useEffect(() => {
    billapiCall();
  }, []);

  async function getAmenityBooking(currentPage) {
    console.log(currentPage)
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);
     console.log(limit,offset)
    const params = {
      society_id: paramsdata.id,
      offset: offset,
      limit: limit,
    };

    console.log(params)


    const response = await makeApiCall(
      "showCSVBySociety",
      "GET",
      null,
      params,
      true
    );
    console.log(response)
    if (response) {
      console.log(response.data);
      setTotalItems(response?.count);
      setbill(response?.data ?? []);
      //   setBookedAmenities(response?.data ?? [])
      //   setTotalItems(response.count);
      // setentry(response.data.length + offset);
    }
  }

  useEffect(() => {
    // console.log(currentPage)
    getAmenityBooking(currentPage);
    // setbill(response?.data ?? []);
    setIsLoading(false);
  }, [currentPage]);



  //   useEffect(() => {
  //     datafetch();
  //   }, [currentPage]); // Fetch data whenever currentPage changes

  //   const datafetch = () => {
  //     // setIsLoading(true);
  //     const offset = (currentPage - 1) * pageSize;
  //     const limit = pageSize;
  //     setoffsetentry(offset);

  //     let config = {
  //       method: "get",
  //       maxBodyLength: Infinity,
  //       url: `${process.env.REACT_APP_BASE_API}getappartment`,
  //       headers: { Authorization: "Bearer " + token },
  //       params: { limit, offset, society_id: id },
  //     };

  //     axios
  //       .request(config)
  //       .then((response) => {
  //         setTotalItems(response.data.count);
  //         console.log(response.data);
  //         setdata(response.data);
  //         setIsLoading(false);
  //         console.log(id);
  //         console.log(response.data.data.length);
  //         setentry(response.data.data.length + offset);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: "Something went wrong!",
  //         });
  //       });
  //   };

  //   if (isLoading) {
  //     return <Loading />;
  //   }

  //   const deletePost = () => {
  //     console.log("delete");

  //     Swal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, delete it!",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         let config = {
  //           method: "delete",
  //           maxBodyLength: Infinity,
  //         };
  //         axios
  //           .request(config)
  //           .then((response) => {
  //             console.log(JSON.stringify(response.data));
  //             Swal.fire({
  //               title: "Deleted!",
  //               text: "Your file has been deleted.",
  //               icon: "success",
  //             });
  //             // datafetch();
  //           })
  //           .catch((error) => {
  //             console.log(error);
  //           });
  //       }
  //     });
  //   };

  const handleCsvfile = (e) => {
    const file = e.target.files[0];
    // const name = e.target.name;
    //console.log(e.target.files[0]);
    setErrorMsg("");
    console.log(file)
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop();
       console.log(fileExtension)
      if (fileExtension == "csv") {
        setUpload(file);
        setError(false);
      } else {
        setError(true);
      }
    } else {
      setError(true); // Handle case where there's no file selected
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (uploadfile?.name) {
      const form = new FormData();
      form.append("bill_csv", uploadfile);
      form.append("society_id", paramsdata?.id);
      // form.append("user_id",uploadfile)

      const uploadImage = await makeApiCall(
        "uploadMaintenanceBill",
        "POST",
        form,
        {},
        true,
        false,
        "File Uploaded",
        true
      );

      // if(uploadImage && uploadImage.code === 200){
      //   setUpload("")
      // }
    } else {
      if (uploadfile?.name) {
        setErrorMsg("");
      }
      setErrorMsg("Choose file first");
    }
  };
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Sidebar
        className="right-sidebar"
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h5>Upload Bill</h5>
        <div class="sidebar-main mt-3">
          <form>
            <div className="mb-3 text-center">
              <div className="mb-2 uplod-mda">
                {uploadfile?.name ? (
                  <img
                    src="/csv.png"
                    style={{ maxWidth: "80px" }}
                    alt=""
                    className="img-fluid"
                  />
                ) : (
                  <img
                    src=""
                    style={{ maxWidth: "80px" }}
                    alt=""
                    className="img-fluid"
                  />
                )}
                <input
                  name="uploadcsvfile"
                  type="file"
                  accept=".csv"
                  //value={uploadfile}
                  onChange={(e) => handleCsvfile(e)}
                />
              </div>
              {console.log(uploadfile)}
              {error ? (
                <p className="text-danger">
                  File type should be csv like file123.csv
                </p>
              ) : (
                uploadfile?.name
              )}
              {errmsg ? <p className="text-danger">{errmsg}</p> : " "}
            </div>
          </form>
        </div>
        <div className="d-flex footer gap-2 align-items-center">
          <button
            onClick={handleSubmit}
            type="submit"
            class="btn btn-primary rounded-4"
          >
            Upload
          </button>
        </div>
      </Sidebar>

      <div className="table_header d-flex mb-3">
        <div className="d-flex flex-wrap align-items-center">
          {/* <Link to= ""
                    // "/manage-society" 
                    className='btn me-2 btn-sm btn-primary rounded-4 '>Back</Link> */}
          <button
            onClick={() => history.goBack()}
            className="btn me-2 btn-sm btn-primary rounded-4 "
          >
            Back
          </button>
          <h5 className="mb-0 fs-6 d-flex align-item-center gap-2">
            <MdApartment />
            Maintenance Bills
          </h5>
        </div>

        <button
          className="btn rounded-5 ms-auto btn-primary"
          onClick={() => {
            uploadCsv();
          }}
        >
          Upload
        </button>
      </div>

      <div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">DATE</th>
                <th scope="col">BILLS</th>
                <th scope="col">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {billData.map((ele, index) => (
                <>
                  <tr key={index}>
                    <th scope="row">{(currentPage - 1) * pageSize + index + 1}</th>
                    <td>{moment(ele.createdAt).format("YYYY-MM-DD")}</td>
                    <td>{ele?.bill_file.split("/").pop()}</td>
                    <td>
                      <div className="action_btn">
                        <div className="d-flex justify-content-center">
                          <a
                            href={ele?.bill_file}
                            className="btn me-2 btn-sm btn-primary rounded-4"
                            download
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>

        {console.log("totalItems", totalItems)}
        
        <div className="table-footer d-md-flex justify-content-md-between align-items-center">
          <p className="mb-0">
            Showing {offsetentry + 1} to {offsetentry + billData.length} of{" "}
            <span id="total-entries">{totalItems}</span> entries
          </p>
          <nav>
            <ul className="pagination pagination-md">
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={prevPage}
                  disabled={currentPage === 1}
                >
                  &laquo;
                </button>
              </li>

              {pageNumbers.map((pageNumber) => {
                // Define the minimum and maximum page numbers to display
                const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                if (pageNumber >= minPage && pageNumber <= maxPage) {
                  return (
                    <li
                      key={pageNumber}
                      className={`page-item ${
                        currentPage === pageNumber ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    </li>
                  );
                }
                return null;
              })}
              <li className="page-item">
                <button className="page-link" onClick={nextPage}>
                  &raquo;
                </button>
              </li>
            </ul>
          </nav>
        </div>

      </div>
    </>
  );
};

export default Bills;

{
  /* <tr>
                                <th scope="row">1</th>
                                <td>12 April 2024</td>
                                <td>CSV_918378965412.csv</td>
                                <td><div className='action_btn'>
                                    <div className="d-flex justify-content-center">
                                        <button className='btn me-2 btn-sm btn-primary rounded-4'>Download</button>
                                    </div>
                                </div></td>
                            </tr> */
}
{
  /* 
                            <tr>
                                <th scope="row">2</th>
                                <td>12 April 2024</td>
                                <td>CSV_918378965412.csv</td>
                                <td><div className='action_btn'>
                                    <div className="d-flex justify-content-center">
                                        <button className='btn me-2 btn-sm btn-primary rounded-4'>Download</button>
                                    </div>
                                </div></td>
                            </tr> */
}
{
  /* <tr>
                                <th scope="row">3</th>
                                <td>12 April 2024</td>
                                <td>CSV_918378965412.csv</td>
                                <td><div className='action_btn'>
                                    <div className="d-flex justify-content-center">
                                        <button className='btn me-2 btn-sm btn-primary rounded-4' >Download</button>
                                    </div>
                                </div></td>
                            </tr>
                            <tr>
                                <th scope="row">4</th>
                                <td>12 April 2024</td>
                                <td>CSV_918378965412.csv</td>
                                <td><div className='action_btn'>
                                    <div className="d-flex justify-content-center">
                                        <button className='btn me-2 btn-sm btn-primary rounded-4' >Download</button>
                                    </div>
                                </div></td>
                            </tr>
                            <tr>
                                <th scope="row">5</th>
                                <td>12 April 2024</td>
                                <td>CSV_918378965412.csv</td>
                                <td><div className='action_btn'>
                                    <div className="d-flex justify-content-center">
                                        <button className='btn me-2 btn-sm btn-primary rounded-4' >Download</button>
                                    </div>
                                </div></td>
                            </tr>
                            <tr>
                                <th scope="row">6</th>
                                <td>12 April 2024</td>
                                <td>CSV_918378965412.csv</td>
                                <td><div className='action_btn'>
                                    <div className="d-flex justify-content-center">
                                        <button className='btn me-2 btn-sm btn-primary rounded-4' >Download</button>
                                    </div>
                                </div></td>
                            </tr> */
}
