import React, { useState,useEffect } from 'react'
import axios from 'axios';
import { Editor } from 'primereact/editor';
import Loading from '../ManageApp/data/Loading';
import Swal from 'sweetalert2';

export default function TermsCondition() {
  const [title, setTitle] = useState('');
  const [text1, setText1] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  // const [text1, setText1] = useState('<div>Hello World!</div><div>PrimeReact <b>Editor</b> Rocks</div><div><br></div>');

  const [data, setdata] = useState([]);

  const [renderdata, setrenderdata] = useState([]);
  const token = localStorage.getItem('token');

  const [IDE, setID] = useState("");
  const [type, settype] = useState("");
  // const [title, setitle] = useState("");
  const [descp, setdescp] = useState("");
  const[typefor,setType]=useState("societyAdmin");

  useEffect(() => {
    setIsLoading(true);
    datafetch();
   }, [typefor]);

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
      </span>
    );
  }

  const header = renderHeader();
function  datafetch(){
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_API}getCms/terms`,
    headers: { 'Authorization': 'Bearer ' + token},
    params: {for:typefor}

  };    
  axios.request(config)
  .then((response) => {
    // console.log(JSON.stringify(response.data));
    // console.log(response.data);
    setdata(response.data); 
    // const value = response.data.data;
    setIsLoading(false);

  console.log(response.data);
  const value=response.data;
  // data.item ? data.item.data : [];
  console.log(value.data);
   setrenderdata(value.data);
   setTitle(value.data.title);
  setText1(value.data.content);
//  setIsLoading(false);
//  console.log(ID)
  })
  .catch((error) => {
    console.log(error);
    Swal.fire("Error", "Failed to get data", "error");
  });

 
}

function edit(typefor){
  console.log(typefor);
    
  let data = new FormData();
data.append('type', 'terms');
data.append('for',typefor);
data.append('title', title);
data.append('content', text1);

// let config = {
// method: 'put',
// maxBodyLength: Infinity,
// url: `${process.env.REACT_APP_BASE_API}updateCms`,
// data : data
// };

// axios.request(config)
// .then((response) => {
// console.log(JSON.stringify(response.data));
// datafetch();
// })
// .catch((error) => {
// console.log(error);
// });

// Prompt the user for confirmation
Swal.fire({
  title: "Do you want to save the changes?",
  showDenyButton: true,
  showCancelButton: true,
  confirmButtonText: "Save",
  denyButtonText: `Don't save`
}).then((result) => {
  if (result.isConfirmed) {
    // User confirmed, proceed with the edit action
    
let config = {
  method: 'put',
  maxBodyLength: Infinity,
  url: `${process.env.REACT_APP_BASE_API}updateCms`,
  data : data
};

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        datafetch(); // Assuming datafetch() is a function defined elsewhere
        Swal.fire("Saved!", "", "success");
      })
      .catch((error) => {
        console.log(error);
        datafetch();
        Swal.fire("Error", "Failed to save changes", "error");
       
      });
  } else if (result.isDenied) {
    // User denied, do nothing or provide feedback
    datafetch();
    Swal.fire("Changes are not saved", "", "info");
  }
});


}

if (isLoading) {
  return <Loading />;
}


  

  return (
   
    <div>
      <div className="card p-4">
        <h4 className='mb-4 fw-semi-bold fs-5'>Term and Condition</h4>

        <form>
        <div class="mb-3">
            <label for="chargeName" class="form-label">Type</label>
            <select class="form-select" id="chargeType" value={typefor} onChange={(e) => setType(e.target.value)}>
         {/* <option value="">Select Type</option> */}
    <option value="societyAdmin">Society</option>
    <option value="resident">Resident</option>

  </select>
          </div>

        {/* <div class="mb-3">
        <label for="chargeName" name="type" value class="form-label"> Type
         <select name="type"  class="form-control">
                                    <option value="user">user</option>
                                   <option value="management">Management</option>
                                  
                        </select>
                        </label>

          </div> */}
          {/* <div class="mb-3">
            <label for="chargeName" class="form-label">Type</label>
            <input type="text" class="form-control" id="chargeName" placeholder="Enter type"  value={renderdata.type} />
          </div> */}
          <div class="mb-3">
            <label for="chargeName" class="form-label">Title</label>
            <input type="text" class="form-control" id="chargeName" placeholder="Enter title" value={title} onChange={(e)=>setTitle(e.target.value)} autoComplete='off' />
          </div>
          <div class="mb-3">
            <label for="chargeName" class="form-label">Description</label>
            <Editor style={{ height: '320px' }} onTextChange={(e) => setText1(e.htmlValue)}   value={text1} /> 
          </div>
        </form>
       
        <div className='d-flex footer gap-2 align-items-center'>
                    <button type="submit" class="btn btn-primary rounded-4" onClick={() => edit(typefor)}>Submit</button>
                </div> 

      </div>
    </div>
  )
}


