
import React from 'react';
import user from "../../../assets/images/avatar.jpg"
import { MdDeleteOutline } from "react-icons/md";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { MdOutlineEdit } from "react-icons/md";


const SubAdmintable = () => {
    return (
        <div className='card p-4 border-0 shadow-lg'>
            <div className='table_header mb-3'>
                <div className='d-flex flex-wrap '>
                    <h4 className='fw-semi-bold fs-5'>Sub Admin List</h4>
                    <div className='ms-auto d-flex align-items-center gap-2'>
                       
                        <div class="search_box ">
                            <input placeholder="Search" class="chakra-input " />
                            <div class="search_icon "></div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='table-responsive'>
                <table className="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">USER INFO</th>
                            <th scope="col">NUMBER</th>
                            <th scope="col">ROLE</th>
                            <th scope="col">STATUS</th>
                            <th scope="col">DOC</th>
                            <th scope="col">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td><div className='user_info d-flex  align-items-center'>

                                <img src={user} alt="user" className='avatar' />
                                <div className='ms-3'>
                                    <strong><span className='txt-head'>John Sons</span></strong>
                                    <p className='txt-bottom mb-0'>JohnSons13@gmail.com</p>
                                </div>

                            </div></td>
                            <td>987654310</td>
                            <td>Manager</td>
                      
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i></div></td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i></div></td>
                            <td><div className='action_btn'>
                                <Link to="/view-society" ><MdOutlineRemoveRedEye /></Link>
                                <Link ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td><div className='user_info d-flex  align-items-center'>

                                <img src={user} alt="user" className='avatar' />
                                <div className='ms-3'>
                                    <strong><span className='txt-head'>John Sons</span></strong>
                                    <p className='txt-bottom mb-0'>JohnSons13@gmail.com</p>
                                </div>

                            </div></td>
                            <td>987654310</td>
                            <td>Manager</td>
                          
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i></div></td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i></div></td>
                            <td><div className='action_btn'>
                                <Link ><MdOutlineRemoveRedEye /></Link>
                                <Link ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>

                        <tr>
                            <th scope="row">3</th>
                            <td><div className='user_info d-flex  align-items-center'>

                                <img src={user} alt="user" className='avatar' />
                                <div className='ms-3'>
                                    <strong><span className='txt-head'>John Sons</span></strong>
                                    <p className='txt-bottom mb-0'>JohnSons13@gmail.com</p>
                                </div>

                            </div></td>
                            <td>987654310</td>
                            <td>Manager</td>
                       
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i></div></td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i></div></td>
                            <td><div className='action_btn'>
                                <Link ><MdOutlineRemoveRedEye /></Link>
                                <Link ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                      
                    </tbody>

                </table>
            </div>
            <div className="table-footer">
                <ul class="pagination m-sm-auto pagination-md justify-content-end">
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Previous">&laquo;</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">1</Link></li>
                    <li class="page-item active"><Link class="page-link " href="#">2</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">3</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">4</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">5</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">6</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">7</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">8</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">9</Link></li>
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Next">&raquo;</Link></li>
                </ul>
            </div>

        </div>


    );
};

export default SubAdmintable;

