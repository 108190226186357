import React, { useEffect, useState } from "react";
import axios from "axios";

import Alldocuments from "../../components/AllFamily";
import UserProfile from "../../components/userProfile";
import avatar from "../../assets/images/avatar.jpg";
import banner from "../../assets/images/Debit.png";
import doc from "../../assets/images/Debit.png";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SocietyHeader from "../../components/societyHeader";
import logo1 from "../../assets/images/hamptom.png";
import AllFamily from "../../components/AllFamily";
import AllTenants from "../../components/AllTenants";
import Allparking from "../../components/Allparking";
import SocietyHeaderView from "../../components/societyHeaderView";
import { useParams, useHistory } from "react-router-dom";
import AllDocs from "../../components/AllDocs";
import Parking from "./data/Parking";
import ParkingDetails from "./components/ParkingDetails";

export default function ViewResident(props) {
  const [data, setdata] = useState({});

  const history = useHistory();
  const { id } = useParams();
  const { society, image } = JSON.parse(localStorage.getItem("society_info"))
  const home = { icon: "pi pi-home" };

  const items = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "View Resident Profile" },
  ];
  useEffect(() => {
    datafetch();
    // console.log(user);
  }, [id]); // Fetch data whenever currentPage changes

  // const handleBack = () => {
  //   // Handle going back to the previous page
  //   console.log("back");
  //   history.goBack();
  // };
  const datafetch = () => {
    // setIsLoading(true);
    // const offset = (currentPage - 1) * pageSize;
    // const limit = pageSize;

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getResidentDetails?user_id=${id}`,
      // headers: { 'Authorization': 'Bearer ' + token },
      // params: { limit, offset,society_id:id }
      // params: {user_id:id }
    };

    axios
      .request(config)
      .then((response) => {
        // setTotalItems(response.data.count);
        setdata(response.data.data[0]);
        console.log("mydata "+data);
        // setuserId(response.data.resident_details._id)
        // setdata(response.data.data);
        // setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };





  return (
    <div>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
        <button
          onClick={() => history.goBack()}
          className="btn ms-auto me-2 btn-sm btn-primary rounded-4"
        >
          Back
        </button>
      </div>
      <div className="header">
        {/* <SocietyHeader
          imageUrl={logo1}
          societyName="The Hampton Homes"
          defaultRouter="/dashboard" /> */}
        <SocietyHeaderView
          imageUrl={decodeURIComponent(image)}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>

      <div className="card p-4">
        <div className="row mb-12">
          <div className="col-12">

            {/* <button className="btn ms-auto me-2 btn-sm btn-primary rounded-4" onClick={() => history.push('/manage-residents')}>back</button> */}
            {/* <UserProfile
              // banner={banner}
              // avatar={avatar}
              // name="Akshay Khanna"
              // email="Akshay@example.com"
              // number="9876543210"
              // apartmentnumber="D-123"
              // landnumber="91-11-1234 5678"
              // address="Fort Rd, Khejer Para, Manak Chowk, Amar Sagar Pol, "
              // image={doc}
              // ranking='2'
              // link='#'
              // name2="Judith A. Hayes"
              // title='Tenant'
              // BN="Kotak Mahindra Bank"
              // AHN="Akshay Khanna"
              // AC="98567840103417"
              // IFSC='KKBK0007865'
              // userType="Admin"

              banner={banner}
              avatar={avatar}
              name="Akshay Khanna"
              email="Akshay@example.com"
              number="9876543210"
              apartmentnumber="D-123"
              landnumber="91-11-1234 5678"
              address="Fort Rd, Khejer Para, Manak Chowk, Amar Sagar Pol, "
              image={doc}
              ranking='2'
              link='#'
              name2="Judith A. Hayes"
              title='Tenant'
              BN="Kotak Mahindra Bank"
              AHN="Akshay Khanna"
              AC="98567840103417"
              IFSC='KKBK0007865'
              userType="Admin"
            /> */}
            {/*             
 <UserProfile
              data={data}
              banner={banner}
              avatar={avatar}
              firstName={data.first_name}
              lastName={data.last_name}
              email={data.email}
              number={data.number}
              apartmentnumber={data.apartmentnumber}
              landnumber={data.landnumber}
              address={data.address}
              image={doc}
              ranking={data.ranking}
              link='#'
              name2={data.name2}
              title={data.title}
              BN={data.BN}
              AHN={data.AHN}
              AC={data.AC}
              IFSC={data.IFSC}
              userType={data.userType}
            /> */}

            {/* {data && (
              <UserProfile
              id={id}
                banner={banner}
                avatar={avatar}
                firstName={data.first_name}
                lastName={data.last_name}
                // email={data.email}
                // number={data.number}
                // apartmentnumber={data.apartmentnumber}
                // landnumber={data.landnumber}
                // address={data.address}
                // image={doc}
                // ranking={data.ranking}
                // link='#'
                // name2={data.name2}
                // title={data.title}
                // BN={data.BN}
                // AHN={data.AHN}
                // AC={data.AC}
                // IFSC={data.IFSC}
                // userType={data.userType}
              />
            )} */}

            <UserProfile
              id={id}
              banner={banner}
              // -new-
              type="owner"
              avatar={data.profile_image}
              firstName={data.first_name}
              lastName={data.last_name}
              email={data.email}
              phone_primary={data.phone_primary}
              role={data?.role}
              apartmentnumber={<div>
                {data.apartment_details && data.apartment_details.map((apartment, index) => (
                  <span key={apartment._id}>
                    {apartment.appartmentNumber} {data.wing_details.find(wing => wing._id === apartment.wing_id)?.name}
                    {index !== data.apartment_details.length - 1 && ', '}
                  </span>
                ))}
              </div>
              }
            // apartmentnumber={data.apartment_details && data.apartment_details.map((apartment) => (
            //   <div>
            //   {data.apartment_details && data.apartment_details.map((apartment, index) => (
            //     <span key={apartment._id}>
            //       {apartment.appartmentNumber} {data.wing_details.find(wing => wing._id === apartment.wing_id)?.name}
            //       {index !== data.apartment_details.length - 1 && ', '}
            //     </span>
            //   ))}
            // </div>


            // ))}

            // number="9876543210"
            // apartmentnumber="D-123"
            // landnumber="91-11-1234 5678"
            // address="Fort Rd, Khejer Para, Manak Chowk, Amar Sagar Pol, "
            // image={doc}
            // ranking='2'
            // link='#'
            // name2="Judith A. Hayes"
            // title='Tenant'
            // BN="Kotak Mahindra Bank"
            // AHN="Akshay Khanna"
            // AC="98567840103417"
            // IFSC='KKBK0007865'
            // userType="Admin"
            />
          </div>
      </div>
      
        <div className="row">
          <div className="col-xl-6 col-md-6 col-sm-12">

            <AllFamily family={data.familywithtenant} />

          </div>
          {/* <div className="col-xl-6 col-md-6 col-sm-12">
          {data.familywithtenant && data.familywithtenant.length > 0 ? (
    <AllFamily family={data.familywithtenant} />
  ) : (
    <span className="error-message" style={{ color: "red" }}>
      No Family Added
    </span>
  )}     
          </div> */}
          {/* <div className="col-12 col-md-6 col-xl-6 ">
          {data.familywithtenant && data.familywithtenant.length > 0 ? (
   <AllTenants tenant={data.familywithtenant} />
  ) : (
    <span className="error-message" style={{ color: "red" }}>
    No Tenant Added
    </span>
  )}     */}

          <div className="col-12 col-md-6 col-xl-6 mb-3">
            <AllTenants tenant={data.familywithtenant} />
          </div>

          <div className="col-12 col-md-6 col-xl-6 ">
            <AllDocs data={data} />
          </div>

          <div className="col-12 col-md-6 col-xl-6 mb-3">

            <Allparking
             parking={data.parking_details} 
             />

          </div>

        </div>
      </div>
    </div>
  );
}
