import React, { useState, useEffect } from "react";
import user from "../../../assets/images/avatar.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Sidebar } from "primereact/sidebar";
import axios from "axios";

import { LazyLoadImage } from "react-lazy-load-image-component";
import No_image_available from "../../../../src/assets/images/avatar.jpg";
import Loading from "../../ManageApp/data/Loading";
import { Dropdown } from "primereact/dropdown";
import { useDispatch } from "react-redux";
import { resolveMaintenanceRequest } from "../../../utils/asyncFun";

const TableMaintenance = (props) => {
  const { id } = props;
  // console.log(id);
  const token = localStorage.getItem("token");
  const [visibleRight, setVisibleRight] = useState(false);
  const [data, setdata] = useState([]);

  const [selectedCity1, setSelectedCity1] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  //

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const [totalItems, setTotalItems] = useState(0);
  // const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(10);
  // currentPage, searchQuery
  useEffect(() => {
    datafetch();
  }, [searchQuery, currentPage, selectedRole]);

  const dispatch = useDispatch();

  const datafetch = () => {
    // setIsLoading(true);
    let offset = 0;
    if (totalItems > 0 && totalItems < 10) {
      offset = 0;
    } else {
      offset = (currentPage - 1) * pageSize;
    }
    // const offset = (currentPage - 1) * pageSize;

    const limit = pageSize;
    setoffsetentry(offset);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}showAllMaintenance`,
      headers: { Authorization: "Bearer " + token },
      params: {
        soceityId: id,
        searchQuery: searchQuery,
        limit,
        offset,
        type: selectedRole,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.totalCount);
        if (response.data.totalCount > 0 && response.data.totalCount < 10) {
          offset = 0;
        } else {
          offset = (currentPage - 1) * pageSize;
        }
        console.log(response.data);
        setdata(response.data.maintenanceRequests);
        setentry(response.data.maintenanceRequests.length + offset);
        setIsLoading(false);
        console.log(selectedRole);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
    // datafetch();
  };

  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // let totalPages;
  // if (totalItems > 0 && totalItems < 10) {
  //   totalPages = Math.ceil(totalItems / pageSize);
  // } else {
  const totalPages = Math.ceil(totalItems / pageSize);
  // }
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  if (isLoading && searchQuery === "") {
    return <Loading />;
  }

  const handleChangeFun = (index, newValue, _id) => {
    dispatch(resolveMaintenanceRequest({ method: "GET", url: `resolveMaintenanceRequest?_id=${_id}&status=${newValue.code}`, data: "" }))
    setdata(prevData => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], status: newValue.code };
      return newData;
    });
  };


  const options = [
    { name: "Approved", code: "approved" },
    { name: "Pending", code: "pending" },
    { name: "Disapproved", code: "disapproved" },
    { name: "Resolved", code: "resolved" },
  ];

  // console.log(data)
  return (
    <>
      <Sidebar
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h3>Right Sidebar</h3>
      </Sidebar>

      <div className="table_header mb-3">
        <div className="d-flex flex-wrap align-items-center">
          <h4 className="mb-0 fw-semi-bold fs-5">Maintenance Request List</h4>
          <div className="ms-auto d-flex align-items-center gap-2">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={handleRoleChange}
              value={selectedRole}
            >
              <option value="">All Maintenance List</option>
              <option value="Water Leakage">Water Leakage</option>
              <option value="Internet/Wifi">Internet / Wifi</option>
              <option value="Painting">Painting</option>
              <option value="Electricity">Electricity</option>
              <option value="Drainage">Drainage</option>
              <option value="Plumbing">Plumbing</option>
              {/* <option value="4">Others</option> */}
            </select>
            <div className="search_box ">
              {/* <input placeholder="Search" className="chakra-input " value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value) /> */}
              <input
                placeholder="Search"
                className="chakra-input "
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {data && data.length > 0 ? (
        <div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">USER INFO</th>
                  <th scope="col">PHONE</th>
                  <th scope="col">REQUEST TYPE</th>
                  <th scope="col">REQUESTED DATE</th>
                  <th scope="col">RESOLVED DATE</th>
                  <th scope="col">STATUS</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((data, idx) => (
                    <tr>
                      <th scope="row">
                        {(currentPage - 1) * pageSize + idx + 1}
                      </th>
                      <td>
                        <div className="user_info d-flex  align-items-center">
                          {/* 
                <img src={data.user_id.profile_image
} alt="user" className='avatar' /> */}
                          {data.user_id.profile_image ? (
                            <LazyLoadImage
                              src={data.user_id.profile_image}
                              alt="Profile"
                            />
                          ) : (
                            <LazyLoadImage
                              src={No_image_available}
                              alt="No rofile image"
                            />
                          )}
                          <div className="ms-3">
                            <strong>
                              <span className="txt-head">
                                {data.user_id.first_name +
                                  " " +
                                  data.user_id.last_name}
                              </span>
                            </strong>
                            <p className="txt-bottom mb-0">
                              {data.user_id.email}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>{data.user_id.phone_primary}</td>
                      <td>{data.issue}</td>
                      <td>{data.request_date}</td>
                      <td>
                        {data.resolved_date
                          ? new Date(data.resolved_date)
                              .toISOString()
                              .split("T")[0]
                          : "-"}
                      </td>
                      {/* <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i>Resolved</div></td> */}

                      {/* <td>{data.status}</td> */}
                      <td>
                        {" "}
                        <Dropdown
                          className="small-dropdown"
                          value={options.find((el) => el.code == data?.status)} // Use status from data state for each row
                          options={options}
                          onChange={(e) => handleChangeFun(idx, e.value, data?._id)} // Pass index and new value
                          optionLabel="name"
                          placeholder="Change status"
                        />
                      </td>
                    </tr>
                  ))}

                {/* <tr>
              <th scope="row">1</th>
              <td><div className='user_info d-flex  align-items-center'>

                <img src={user} alt="user" className='avatar' />
                <div className='ms-3'>
                  <strong><span className='txt-head'>John Sons</span></strong>
                  <p className='txt-bottom mb-0'>JohnSons13@gmail.com</p>
                </div>
              </div></td>
              <td>987654310</td>
              <td>Water Leakage</td>
              <td>05-08-2024</td>
              <td>05-08-2024</td>
              <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i>Resolved</div></td>

            </tr> */}
                {/* <tr>
              <th scope="row">1</th>
              <td><div className='user_info d-flex  align-items-center'>

                <img src={user} alt="user" className='avatar' />
                <div className='ms-3'>
                  <strong><span className='txt-head'>John Sons</span></strong>
                  <p className='txt-bottom mb-0'>JohnSons13@gmail.com</p>
                </div>
              </div></td>
              <td>987654310</td>
              <td>Water Leakage</td>
              <td>05-08-2024</td>
              <td>05-08-2024</td>
              <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i>Resolved</div></td>

            </tr>
            <tr>
              <th scope="row">1</th>
              <td><div className='user_info d-flex  align-items-center'>

                <img src={user} alt="user" className='avatar' />
                <div className='ms-3'>
                  <strong><span className='txt-head'>John Sons</span></strong>
                  <p className='txt-bottom mb-0'>JohnSons13@gmail.com</p>
                </div>

              </div></td>
              <td>987654310</td>
              <td>Painting</td>
              <td>05-08-2024</td>
              <td>05-08-2024</td>
              <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i>Pending</div></td>

            </tr>
            <tr>
              <th scope="row">1</th>
              <td><div className='user_info d-flex  align-items-center'>

                <img src={user} alt="user" className='avatar' />
                <div className='ms-3'>
                  <strong><span className='txt-head'>John Sons</span></strong>
                  <p className='txt-bottom mb-0'>JohnSons13@gmail.com</p>
                </div>

              </div></td>
              <td>987654310</td>
              <td>Electricity</td>
              <td>05-08-2024</td>
              <td>05-08-2024</td>
              <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i>Resolved</div></td>

            </tr> */}
              </tbody>
            </table>
          </div>
          <div className="table-footer d-md-flex justify-content-md-between align-items-center">
            <p className="mb-0">
              Showing {offsetentry + 1} to {entry} of{" "}
              <span id="total-entries">{totalItems}</span> entries
            </p>
            <nav>
              <ul className="pagination pagination-md">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>

                {pageNumbers.map((pageNumber) => {
                  // Define the minimum and maximum page numbers to display
                  const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                  const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                  if (pageNumber >= minPage && pageNumber <= maxPage) {
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                <li className="page-item">
                  <button className="page-link" onClick={nextPage}>
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <h4>No data available</h4>
      )}
      {/* <div className="table-footer d-md-flex justify-content-md-between align-items-center">
        <p className='mb-0'>Showing 1 to 10 of <span id='total-entries'>57</span>  entries</p>
        <ul className="pagination  pagination-md">
          <li className="page-item"><Link className="page-link" href="#" aria-label="Previous">&laquo;</Link></li>
          <li className="page-item"><Link className="page-link" href="#">1</Link></li>
          <li className="page-item active"><Link className="page-link " href="#">2</Link></li>
          <li className="page-item"><Link className="page-link" href="#">3</Link></li>
          <li className="page-item"><Link className="page-link" href="#">4</Link></li>
          <li className="page-item"><Link className="page-link" href="#">5</Link></li>
          <li className="page-item"><Link className="page-link" href="#">6</Link></li>
          <li className="page-item"><Link className="page-link" href="#">7</Link></li>
          <li className="page-item"><Link className="page-link" href="#">8</Link></li>
          <li className="page-item"><Link className="page-link" href="#">9</Link></li>
          <li className="page-item"><Link className="page-link" href="#" aria-label="Next">&raquo;</Link></li>
        </ul>
      </div> */}
    </>
  );
};

export default TableMaintenance;
