import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import logo1 from "../../assets/images/hamptom.png";
import SocietyHeader from "../../components/societyHeader";
import { BreadCrumb } from "primereact/breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { addManagements, editManagment, getManagment, uploadMediaToServer } from "../../utils/asyncFun";
import Swal from "sweetalert2";
import { mutateDetail } from "../../library/store/slice";
import SocietyHeaderView from "../../components/societyHeaderView";
export default function UpdateManagement(props) {
  const home = { icon: "pi pi-home" };
  
  const items = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "Add Management" },
  ];

  // Development-
  const { id } = useParams();
  const {society, image} = JSON.parse(localStorage.getItem("society_info"))

  const [data, setData] = useState({
    profile_image: "",
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    designation: "",
    society_id: "",
    _id: id
  })

  const dispatch = useDispatch();
  const stores = useSelector((store) => store.data.detail);

  function handleChangeImage(event){
    const formData = new FormData();
    formData.append('image', event.currentTarget.files[0]);
    formData.append('path', "profile_image");
    dispatch(uploadMediaToServer({method: "POST", url: "uploadMediaToServer", data: formData}))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(stores)
    dispatch(editManagment({method: "POST", url: "editManagment", data: {...stores, society_id: stores.society_id._id}}))
    .then(() => {
        // Swal.fire("Saved!", "", "success")
        window.history.back();
    })
  }

  const handleChange = (e) => {
    const {name, value} = e.target;
    setData({...data, [name]: value})
  }

  useEffect(() => {
    dispatch(getManagment({method: "GET", url: `getManagment?managementId=${id}`, data: ""}))
  }, [])

  console.log(stores)

  return (
    <>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
      </div>
      <div className="header">
        {/* <SocietyHeader
          imageUrl={logo1}
          societyName="The Hampton Homes"
          defaultRouter="/manage-society"
        /> */}
         <SocietyHeaderView
          imageUrl={decodeURIComponent(image)}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>
      <div className="card p-4">
        <div className="row">
          <div className="col-xl-10 mx-auto">
            <div className="card border-0 shadow-lg p-2">
              <div className="card-header bg-white border-0">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h4 className="mb-0">Update Management</h4>
                  </div>
                  {/* <div className="col-4 text-end">
                    <a href="#!" className="btn btn-sm btn-primary">
                      Edit
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={(e) => handleSubmit(e)}>
                  <h6 className="heading-small text-muted mb-4">
                  Management information
                  </h6>
                  <div className="col-12 mb-3">
                    <div className="avatar d-flex justify-content-center align-items-center flex-column m-auto">
                      <img
                        className="rounded-circle"
                        src={stores.profile_image}
                        alt=""
                        style={{ height: "150px", width: "150px" }}
                      />
                      <input
                        type="file"
                        id="upload-img"
                        style={{ display: "none" }}
                        onChange={handleChangeImage}
                        required
                      />
                      <label htmlFor="upload-img" className="upload-img">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z"></path>
                        </svg>
                      </label>
                    </div>
                  </div>
                  <div className="pl-lg-4">
                    <div className="row">
                      <div className="col-lg-4">
                        <label className="form-control-label" htmlFor="title">
                          Select From Existing Residents
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Select a member</option>
                          <option value="1">Member one</option>
                          <option value="2">Member two</option>
                        </select>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group focused mb-2">
                          <label
                            className="form-control-label"
                            htmlFor="input-firstName"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            id="input-firstName"
                            className="form-control form-control-alternative"
                            placeholder="Lucky"
                            name="first_name"
                            onChange={(e) => {dispatch(mutateDetail({field: "first_name", value: e.target.value}))}}
                            value={stores.first_name}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mb-2">
                          <label
                            className="form-control-label"
                            htmlFor="input-lastName"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="input-lastName"
                            className="form-control form-control-alternative"
                            placeholder="jesse"
                            name="last_name"
                            onChange={(e) => {dispatch(mutateDetail({field: "last_name", value: e.target.value}))}}
                            value={stores.last_name}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group focused">
                          <label
                            className="form-control-label"
                            htmlFor="mobile-number"
                          >
                            Mobile Number
                          </label>
                          <input
                            type="text"
                            id="mobile-number"
                            className="form-control form-control-alternative"
                            placeholder="+91-98647-98765"
                            name="mobile_number"
                            onChange={(e) => {dispatch(mutateDetail({field: "mobile_number", value: e.target.value}))}}
                            value={stores.mobile_number}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group focused mb-2">
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email ID
                          </label>
                          <input
                            type="email"
                            id="input-email"
                            className="form-control form-control-alternative"
                            placeholder="Lucky@gmail.com"
                            name="email"
                            onChange={(e) => {dispatch(mutateDetail({field: "email", value: e.target.value}))}}
                            value={stores.email}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group focused mb-2">
                          <label
                            className="form-control-label"
                            htmlFor="input-designation"
                          >
                            Designation
                          </label>
                          <input
                            type="text"
                            id="input-designation"
                            className="form-control form-control-alternative"
                            placeholder="software developer"
                            name="designation"
                            onChange={(e) => {dispatch(mutateDetail({field: "designation", value: e.target.value}))}}
                            value={stores.designation}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
             
                  <hr className="my-4" />

                  <div className="d-flex justify-content-center align-items-center gap-2">
                    <Link onClick={() => window.history.back()} className="btn btn-outline-primary">
                      Back
                    </Link>
                    <button className="btn btn-primary" type="submit" disabled={stores.isLoading} >Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
