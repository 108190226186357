import { uniFun } from "./globalFun";

// Handle async function-
export const getsocietydetail = uniFun("r_community/getsocietydetail");
export const updatesocietydetail = uniFun("r_community/updatesocietydetail");
export const uploadMediaToServer = uniFun("r_community/uploadMediaToServer");
export const listAllPost = uniFun("r_community/listAllPost");
export const detailOfPost = uniFun("r_community/detailOfPost");
export const deletePost = uniFun("r_community/deletePost");
export const deleteCommentAndReply = uniFun("r_community/deleteCommentAndReply");
export const addWings = uniFun("r_community/addWings");
export const deleteWings = uniFun("r_community/deleteWings");
export const editWings = uniFun("r_community/editWings");
export const addManagment = uniFun("r_community/addManagment");
export const updateStatusOfSocietyManagment = uniFun("r_community/updateStatusOfSocietyManagment");
export const addUSers = uniFun("r_community/addUSers");
export const updateUser = uniFun("r_community/updateUser");
export const addManagements = uniFun("r_community/addManagements");
export const getManagment = uniFun("r_community/getManagment");
export const editManagment = uniFun("r_community/editManagment");
export const showAllAmenityList = uniFun("r_community/showAllAmenityList");
export const showAllAmenityTimingDetail = uniFun("r_community/showAllAmenityTimingDetail");
export const deleteAmenity = uniFun("r_community/deleteAmenity");
export const reportedPostReasonList = uniFun("r_community/reportedPostReasonList");
export const resolveMaintenanceRequest = uniFun("r_community/resolveMaintenanceRequest");
export const resolveComplainRequest = uniFun("r_community/resolveComplainRequest");
export const getApartmentDetail = uniFun("r_community/getApartmentDetail");
export const updateApartmentDetail = uniFun("r_community/updateApartmentDetail");
export const listAllTrash = uniFun("r_community/listAllTrash");
export const undoFromTrash = uniFun("r_community/undoFromTrash");
export const deleteFromTrash = uniFun("r_community/deleteFromTrash");