import React from 'react'
import avatar from "../assets/images/avatar.jpg"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { classNames } from "primereact/utils";
import { useState,useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

export default function Userprofile() {


 
  const [isReadonly, setIsReadonly] = useState(true);
  const history = useHistory();
  const [rows, setRows] = useState([]);
const [name, setname] = useState("");
const [first_name, setfirst_name] = useState("");
const [last_name, setlast_name] = useState("");
const [email, setemail] = useState("");
const [image, setimage] = useState("");
const [showPassword, setShowPassword] = useState(false); 
const [showOldPassword, setShowOldPassword] = useState(false); 
const [showConfirmPassword, setShowConfirmPassword] = useState(false); 


 
const token=localStorage.getItem('token');


  useEffect(() => {
  
let config = {
  method: 'get',
  maxBodyLength: Infinity,
  url:`${process.env.REACT_APP_BASE_API}getProfile`,

  headers: { 
        'Authorization': 'Bearer '+ token
  }
};

axios.request(config)
.then((response) => {
  // console.log(JSON.stringify(response.data));
  // console.log(response.data);
  const results=response.data;
  // setRows(results.data.data);
  // console.log(rows);

  const value=response.data.data;

  console.log(value);

  setname(value.data.name)
  setfirst_name(value.data.first_name)
  setlast_name(value.data.last_name)
  setemail(value.data.email)
  setimage(value.data.image)
  console.log(first_name);
  console.log(image);


})
.catch((error) => {
  console.log(error);
});

  }, []);



  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const validationSchema = Yup.object().shape({

    // name: Yup.string().required("name is required"),
    // email: Yup.string().email('invalid email address') .required('email is required'),

    // first_name: Yup.string().required("First Name is required"),
    // last_name: Yup.string().required("Last Name is required"),
   oldpassword:Yup.string().required("Required"),
    password: Yup.string().matches(passwordRules, { message: "Please create a stronger password" }).required("Required"),
  confirmpassword: Yup
    .string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
    // aboutme: Yup.string().required("About me is required"),
    // image : Yup.mixed()
    // .required('please select an image')  
    // .test('fileType', 'invalid file type', (value) => {
    // if (value) {
    //   const allowedFileTypes = ['image/jpeg', 'image/png','image/jpg']; 
    //   return allowedFileTypes.includes(value.type);
    // }
    // return true; // Allow empty files
    // })
    // .test('fileSize', 'file size is too large', (value) => {
    // if (value) {
    //   const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    //   return value.size <= maxSizeInBytes;
    // }
    // return true; // Allow empty files
    // }),
     

  
});




const formik = useFormik({
    initialValues: {
        // name:"",
        // email:"",
        // first_name:"",
        // last_name:"",
        password:"",
        confirmpassword:"",
        oldpassword:"",
        // aboutme:"",
        // image :"", 


    },
    validationSchema : validationSchema
});

// const handleOnClick = () => {
//   console.log("working")       
//   formik.setTouched(
//       Object.keys(formik.values).reduce((acc , fieldName) => {
//           acc[fieldName] = true
//           return acc;
//       },
//       {}
//       )
//   )
//   formik.validateForm().then((errors) => {
//     // Check if there are any validation errors
//     console.log("errors" , errors)
//     if (Object.keys(errors).length === 0) {
//       // If no errors, proceed to the next step
//       console.log("values" , formik.values)
//       const payload=formik.values;
//       console.log(payload)
   
//       let data = new FormData();
// // data.append('_id', '658bc9f068b695042ab18219');
// // data.append('name',data.name );
// // data.append('email', 'DEFG');
// // data.append('last_name', 'DEFG');


// data.append('password', payload.password);
// // data.append('password', payload.confirmpassword);


// let config = {
//   method: 'post',
//   maxBodyLength: Infinity,
//   url: `${process.env.REACT_APP_BASE_API}changePassword`,
//   headers: { 
//     'Authorization': 'Bearer '+ token
//   },
//   data : data
// };

// axios.request(config)
// .then((response) => {
//   console.log(JSON.stringify(response.data));
  
// })
// .catch((error) => {
//   console.log(error);
//   Swal.fire({
//     icon: "error",
//     title: "Oops...",
//     text: "Something went wrong!",

//   });
// });

  
//     }       
  
  
// })




// };

const handleOnClick = () => {
  Swal.fire({
    title: "Do you want to change the password?",
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: "Save",
    denyButtonText: `Don't save`
  }).then((result) => {
    if (result.isConfirmed) {
      // User confirmed to save changes
      formik.setTouched(
        Object.keys(formik.values).reduce((acc, fieldName) => {
          acc[fieldName] = true;
          return acc;
        }, {})
      );

      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          const payload = {
            oldPassword:formik.values.oldpassword,
            newPassword: formik.values.password
          };

          console.log("payload",payload);

          axios
            .post(`${process.env.REACT_APP_BASE_API}changePassword`, payload, {
              headers: {
                Authorization: 'Bearer ' + token
              }
            })
            .then((response) => {
              console.log(response.data);
              Swal.fire("Changed!", "", "success");
              // history.push('/dashboard');
              localStorage.clear();
            history.push("/login");
            })
            .catch((error) => {
              console.log(error);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong! or Check old password"
              });
            });
        }
      });
    } else if (result.isDenied) {
      // User denied to save changes
      Swal.fire("Changes are not saved", "", "info");
    }
  });
};





function handleChangeImage(event){
  formik.setFieldTouched('image', true);
  formik.setFieldValue('image', event.currentTarget.files[0]);
}

// const handleonEdit = () => {
//   console.log("working")  
//       setIsReadonly(prevState => !prevState)
  
// };




  return (
    <div className='card p-4 ' >

      <div className="main-content">

        <div className="container-fluid mt-7">
          <div className="row">
            <div className="col-xl-4 order-xl-2 mb-5 mb-xl-0">
              <div className="card card-profile border-0 mt-5 shadow-lg">
                <div className="row ">
                  <div className="card-profile-image d-flex justify-content-center">
                       <div>
                    {/* <img
                      src={formik.values.image ? URL.createObjectURL(formik.values.image) : avatar} style={{ width: '100px', height: '100px', marginTop: '-50px' }} alt='user'
                      className="rounded-circle img-fluid"
                    />
                    </div>
                    <br/>
                  
                    {Boolean(formik.touched.image && formik.errors.image) && (
                                    <small className="p-error">{formik.errors.image}</small>
                                )}
                            
                                <div> */}
                            {/*                                 
                                 <label className='btn btn-sm btn-primary  rounded-4' htmlFor="upload-image">upload</label>
                            <input type="file" id='upload-image' style={{ display: 'none' }} onChange={handleChangeImage} /> */}
                            </div>

                  </div>
                </div>
                {/* <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <button className='btn btn-primary' onClick={handleOnClickimage}>upload</button>
                </div> */}
                 {/* <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  
                 <label className='btn btn-sm btn-primary  rounded-4' htmlFor="upload-image">upload</label>
                            <input type="file" id='upload-image' style={{ display: 'none' }} onChange={handleChangeImage} />
                </div> */}


                {/* <div className='card-body'>
                  <div className='card-title text-center text-muted'>
                    "I'm Jane Hong, and I recently graduated with an advanced diploma from Smith secondary school. I'm seeking an internship where I can apply my skills in content creation and increase my experience in digital marketing."
                  </div>
                </div> */}


              </div>
            </div>
            <div className="col-xl-8 order-xl-1">
              <div className="card border-0 shadow-lg p-2">
                <div className="card-header bg-white border-0">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h3 className="mb-0">My account</h3>
                    </div>
                    <div className="col-4 text-end">
                      {/* <a href="#!" className="btn btn-sm btn-primary">
                        Edit
                      </a> */}
                      {/* <button type="submit" className="btn btn-primary rounded-4" onClick={handleonEdit} >Edit</button> */}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form>
                  {/* <hr className="my-4" /> */}
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                    Change Password
                    </h6>
                    <div className="col-lg-6">
                          <div className="form-group focused">
                            <label
                              className="form-control-label"
                              htmlFor="input-password"
                            >
                             Old Password
                            </label>
                            <div className="input-group">
                            <input
                             type={showOldPassword ? "text" : "password"}
                              // type="password"
                              id="input-password"
                              name="oldpassword"
                              // className="form-control form-control-alternative"
                              placeholder="Enter old password"
                              autocomplete="off"
                            // defaultValue=""
                        
                            className={classNames({
                              "input-invalid": Boolean(formik.touched.oldpassword && formik.errors.oldpassword),
                          }, "form-control form-control-alternative")}
                          {...formik.getFieldProps("oldpassword")}
                        //   readOnly={isReadonly}
                           />

<div className="input-group-append">
            <span className="view-pass"
                type="button"
                onClick={() => setShowOldPassword(!showOldPassword)}
            >
                <i className={`fa ${showOldPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
            </span>
        </div>
</div>

                  {Boolean(formik.touched.oldpassword && formik.errors.oldpassword) && (
                              <small className="p-error">{formik.errors.oldpassword}</small>
                          )}

                          </div>
                        </div>
                        <br/>

                    <div className="pl-lg-4">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group focused">
                            <label
                              className="form-control-label"
                              htmlFor="input-password"
                            >
                              New Password
                            </label>
                            <div className="input-group">
                            <input
                              // type="password"
                              type={showPassword ? "text" : "password"}
                              id="input-password"
                              name="password"
                              autocomplete="off"
                              // className="form-control form-control-alternative"
                              placeholder="Enter new password"
                            // defaultValue=""
                            className={classNames({
                              "input-invalid": Boolean(formik.touched.password && formik.errors.password),
                          }, "form-control form-control-alternative")}
                          {...formik.getFieldProps("password")}
                        //   readOnly={isReadonly}
                           />

<div className="input-group-append">
            <span className="view-pass"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
            >
                <i className={`fa ${showOldPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
            </span>
        </div>

</div>
                  {Boolean(formik.touched.password && formik.errors.password) && (
                              <small className="p-error">{formik.errors.password}</small>
                          )}

                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="confirm-password"
                            >
                              Confirm New Password
                            </label>
                            <div className="input-group">
                            <input
                              // type="password"
                              type={showConfirmPassword ? "text" : "password"}
                              id="confirm-password"
                              name="confirmpassword"
                              autocomplete="off"
                              // className="form-control form-control-alternative"
                              placeholder="Confirm new password"
                              className={classNames({
                                "input-invalid": Boolean(formik.touched.confirmpassword && formik.errors.confirmpassword),
                            }, "form-control form-control-alternative")}
                            {...formik.getFieldProps("confirmpassword")}
                            // readOnly={isReadonly}
                              />


<div className="input-group-append">
            <span className="view-pass"
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
                <i className={`fa ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
            </span>
        </div>

</div>
                    {Boolean(formik.touched.confirmpassword && formik.errors.confirmpassword) && (
                                <small className="p-error">{formik.errors.confirmpassword}</small>
                            )}

                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <hr className="my-4" /> */}
             
                    {/* Description */}


                {/*  */}
                  </form>
                  <br/>
                  <div>
                  <button type="submit" className="btn btn-primary rounded-4"  onClick={handleOnClick} >Change Password</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




    </div>





     




  )
}
