import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import user from "../../../assets/images/avatar.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { MdApartment, MdHeight } from "react-icons/md";

import flipkart from "../../../assets/images/flipkart.svg";
import amazon from "../../../assets/images/amazon1.svg";
import bigBasket from "../../../assets/images/bigbasket.svg";
import Zomato from "../../../assets/images/Zomato.svg";
import UberEats from "../../../assets/images/UberEats.png";
import Blinkit from "../../../assets/images/blinkit.svg";
import connect from "../../../assets/images/connect.png";
import Dominoz from "../../../assets/images/Dominoz.svg";
import Netplus from "../../../assets/images/Netplus.jpeg";
import Other from "../../../assets/images/Other.png";
import Swiggy from "../../../assets/images/Swiggy.svg";
import Walmart from "../../../assets/images/Walmart.png";
import Dunzo from "../../../assets/images/Dunzo.png";
import Zepto from "../../../assets/images/Zepto.svg";
import No_image_avaliable from "../../../assets/images/avatar.jpg";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Loading from "../../ManageApp/data/Loading";
const apIcon = {
  fontSize: "20px",
};

const companyLogos = {
  Zomato: Zomato,
  Ubereats: UberEats,
  Swiggy: Swiggy,
  Flipkart: flipkart,
  Amazon: amazon,
  "Big Basket": bigBasket,
  Zepto: Zepto,
  Blinkit: Blinkit,
  Connect: connect,
  "Domino's": Dominoz,
  Netplus: Netplus,
  Other: Other,

  Walmart: Walmart,
  Dunzo: Dunzo,

  // Add more company names and corresponding logos as needed
};

export default function TablePreAprvDelievery(props) {
  console.log(props);
  const { id } = props;
  const token = localStorage.getItem("token");

  const [data, setdata] = useState([]);
  const [activeTab, setActiveTab] = useState("upcoming");
  const [totalItems, setTotalItems] = useState(0);
  //    const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    setdata([]);
    datafetch(activeTab);
  }, [currentPage, searchQuery, startDate, endDate, activeTab]);

  useEffect(() => {
    // Open the "upcoming" tab when the component mounts
    setActiveTab(0);
  }, []);

  const type = "Pre_Approve_Deliveries";
  // const filter_type = "upcoming";
  const datafetch = (tabIndex) => {
    // setIsLoading(true);
    // const offset = (currentPage - 1) * pageSize;
    // const limit = pageSize;
    // setoffsetentry((currentPage - 1) * pageSize);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);

    let filterType;
    switch (tabIndex) {
      case 0:
        filterType = "upcoming";
        break;
      case 1:
        filterType = "past";
        break;
      case 2:
        filterType = "past";
        break;
      default:
        filterType = "upcoming";
        break;
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}list/preApproved`,
      headers: { Authorization: "Bearer " + token },
      params: {
        society_id: id,
        type: type,
        filter_type: filterType,
        limit,
        offset,
        search: searchQuery,
        start_date: startDate,
        end_date: endDate,
        // type: selectedRole,
        // limit,
        // offset:(currentPage - 1) * pageSize < totalItems ?(currentPage - 1) * pageSize : 0,
        // search: searchQuery,
      },

      // params: { society_id:id,limit,offset,searchQuery: searchQuery},
      // params: { soceityId: id,searchQuery: searchQuery ,limit,offset},
    };

    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.count);
        console.log(response.data);
        // setdata(response.data.data.allData);
        setdata(response.data.data);
        // setentry(response.data.data.allData.length + (currentPage - 1) * pageSize);
        setentry(response.data.data.length + offset);
        setIsLoading(false);
        // console.log(selectedRole);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(data);

  function reset() {
    setStartDate(null);
    setEndDate(null);
  }

  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setStartDate(null);
    setEndDate(null);
  };

  if (isLoading && searchQuery === "") {
    return <Loading />;
  }
  return (
    <div className="card border-0 shadow-lg">
      <TabView
        activeIndex={activeTab}
        onTabChange={(e) => handleTabChange(e.index)}
      >
        <TabPanel header="Scheduled">
          <div className="table_cntner">
            <div className="table_header mb-3">
              <div className="d-flex flex-wrap align-items-center">
                <h4 className="fw-semi-bold fs-5 mb-0">Scheduled List</h4>
                <div className="ms-auto d-flex align-items-center gap-2">
                  <div className="search_box ">
                    <input
                      placeholder="Search"
                      className="chakra-input "
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="d-flex flex-wrap align-items-center">
                <div className="fw-semi-bold fs-5 mb-0"></div>
                <div className="ms-auto d-flex align-items-center gap-2">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) =>
                      setStartDate(new Date(date).toISOString().split("T")[0])
                    }
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Start Date"
                    className="form-select"
                    aria-label="Default select example"
                  />

                  <DatePicker
                    selected={endDate}
                    onChange={(date) =>
                      setEndDate(new Date(date).toISOString().split("T")[0])
                    }
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="End Date"
                    className="form-select"
                    aria-label="Default select example"
                  />

                  <button
                    onClick={reset}
                    className="btn me-2 btn-sm btn-primary rounded-4 "
                  >
                    Reset{" "}
                  </button>
                </div>
              </div>
            </div>
            {data && data.length > 0 ? (
              <div>
                <div className="table-responsive">
                  <table className="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">DELIVERY PARTNER</th>
                        <th scope="col">DELIVER TO</th>
                        {/* <th scope="col">PHONE</th> */}
                        <th scope="col">APARTMENT NO</th>
                        <th scope="col">DATE OF DELIVERY</th>
                        <th scope="col">STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.map((data, idx) => (
                          <tr>
                            <th scope="row">
                              {(currentPage - 1) * pageSize + idx + 1}
                            </th>
                            <td>
                              {/*                         
                        <img src={Zomato} alt="icon" />
                      <span className="ms-2">{data.delivery_company_name}</span> */}
                              <img
                                src={
                                  companyLogos[data.delivery_company_name]
                                    ? companyLogos[data.delivery_company_name]
                                    : Other
                                }
                                alt="icon"
                                style={{ height: "25px", width: "25px" }}
                              />
                              <span className="ms-2">
                                {companyLogos[data.delivery_company_name]
                                  ? data.delivery_company_name
                                  : data.delivery_company_name}
                              </span>
                            </td>
                            {/* <td>-</td> */}
                            {data.user_details && (
                              <td>
                                <div className="user_info d-flex align-items-center">
                                  <img
                                    src={
                                      data.user_details[0].profile_image
                                        ? data.user_details[0].profile_image
                                        : ""
                                    }
                                    alt="user"
                                    className="avatar"
                                  />
                                  <div className="ms-3">
                                    <strong>
                                      <span className="txt-head">
                                        {data.user_details[0].first_name +
                                          " " +
                                          data.user_details[0].last_name}
                                      </span>
                                    </strong>
                                    <p className="txt-bottom mb-0">
                                      {data.user_details[0].email}
                                    </p>
                                    <p>{data.user_details[0].phone_primary}</p>
                                  </div>
                                </div>
                              </td>
                            )}

                            <td>
                              {data.apartment_details && (
                                <div>
                                  <strong>
                                    <span className="txt-head">
                                      <MdApartment style={apIcon} />{" "}
                                      {data.apartment_details[0]
                                        .appartmentNumber +
                                        "-" +
                                        data.wing_details[0].name}
                                    </span>
                                  </strong>
                                </div>
                              )}
                            </td>

                            <td>{data.approved_date}</td>
                            {/* <td>-</td>
                        <td>-</td> */}
                            <td>{data.status}</td>
                          </tr>
                        ))}

                      {/* <tr>
                    <th scope="row">1</th>
                    <td>
                      <div className="user_info d-flex  align-items-center">
                        <img src={user} alt="user" className="avatar" />
                        <div className="ms-3">
                          <strong>
                            <span className="txt-head">John Sons</span>
                          </strong>
                          <p className="txt-bottom mb-0">
                            JohnSons13@gmail.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>987654310</td>
                    <td>
                      <MdApartment style={apIcon} /> 109-A
                    </td>
                    <td>
                      <img src={flipkart} alt="icon" />
                      <span className="ms-2">flipkart</span>
                    </td>
                    <td>06-03-2024</td>
                    <td>Approved</td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>
                      <div className="user_info d-flex  align-items-center">
                        <img src={user} alt="user" className="avatar" />
                        <div className="ms-3">
                          <strong>
                            <span className="txt-head">John Sons</span>
                          </strong>
                          <p className="txt-bottom mb-0">
                            JohnSons13@gmail.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>987654310</td>
                    <td>
                      <MdApartment style={apIcon} /> 109-A
                    </td>
                    <td>
                      <img src={bigBasket} alt="icon" />
                      <span className="ms-2">Bigbasket</span>
                    </td>
                    <td>06-03-2024</td>
                    <td>Approved</td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>
                      <div className="user_info d-flex  align-items-center">
                        <img src={user} alt="user" className="avatar" />
                        <div className="ms-3">
                          <strong>
                            <span className="txt-head">John Sons</span>
                          </strong>
                          <p className="txt-bottom mb-0">
                            JohnSons13@gmail.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>987654310</td>
                    <td>
                      <MdApartment style={apIcon} /> 109-A
                    </td>
                    <td>
                      <img src={amazon} alt="icon" />
                      <span className="ms-2">Amazon</span>
                    </td>
                    <td>06-03-2024</td>
                    <td>Approved</td>
                  </tr> */}
                    </tbody>
                  </table>
                </div>
                <div className="table-footer d-md-flex justify-content-md-between align-items-center">
                  <p className="mb-0">
                    Showing {offsetentry + 1} to {entry} of{" "}
                    <span id="total-entries">{totalItems}</span> entries
                  </p>
                  <nav>
                    <ul className="pagination pagination-md">
                      <li className="page-item">
                        <button
                          className="page-link"
                          onClick={prevPage}
                          disabled={currentPage === 1}
                        >
                          &laquo;
                        </button>
                      </li>

                      {pageNumbers.map((pageNumber) => {
                        // Define the minimum and maximum page numbers to display
                        const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                        const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                        if (pageNumber >= minPage && pageNumber <= maxPage) {
                          return (
                            <li
                              key={pageNumber}
                              className={`page-item ${
                                currentPage === pageNumber ? "active" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() => setCurrentPage(pageNumber)}
                              >
                                {pageNumber}
                              </button>
                            </li>
                          );
                        }
                        return null;
                      })}
                      <li className="page-item">
                        <button className="page-link" onClick={nextPage}>
                          &raquo;
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            ) : (
              <h4>No Data Avaliable</h4>
            )}
            {/* <div className="table-footer d-md-flex justify-content-md-between align-items-center">
              <p className="mb-0">
                Showing 1 to 10 of <span id="total-entries">57</span> entries
              </p>
              <ul className="pagination pagination-md ">
                <li className="page-item">
                  <Link className="page-link" href="#" aria-label="Previous">
                    &laquo;
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    1
                  </Link>
                </li>
                <li className="page-item active">
                  <Link className="page-link " href="#">
                    2
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    3
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    4
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    5
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    6
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    7
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    8
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    9
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#" aria-label="Next">
                    &raquo;
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </TabPanel>

        <TabPanel header="Past">
          <div className="table_cntner">
            <div className="table_header mb-3">
              <div className="d-flex flex-wrap align-items-center">
                <h4 className="fw-semi-bold fs-5 mb-0">Past List</h4>
                <div className="ms-auto d-flex align-items-center gap-2">
                  <div className="search_box ">
                    <input
                      placeholder="Search"
                      className="chakra-input "
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="d-flex flex-wrap align-items-center">
                <div className="fw-semi-bold fs-5 mb-0"></div>
                <div className="ms-auto d-flex align-items-center gap-2">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) =>
                      setStartDate(new Date(date).toISOString().split("T")[0])
                    }
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Start Date"
                    className="form-select"
                    aria-label="Default select example"
                  />

                  <DatePicker
                    selected={endDate}
                    onChange={(date) =>
                      setEndDate(new Date(date).toISOString().split("T")[0])
                    }
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="End Date"
                    className="form-select"
                    aria-label="Default select example"
                  />

                  <button
                    onClick={reset}
                    className="btn me-2 btn-sm btn-primary rounded-4 "
                  >
                    Reset{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">DELIVERY PARTNER</th>
                    <th scope="col">DELIVER TO</th>
                    {/* <th scope="col">PHONE</th> */}
                    <th scope="col">APARTMENT NO</th>
                    <th scope="col">DATE OF DELIVERY</th>
                    <th scope="col">STATUS</th>
                  </tr>
                </thead>

                <tbody>
                  {data &&
                    data.map((data, idx) => (
                      <tr>
                        <th scope="row">
                          {(currentPage - 1) * pageSize + idx + 1}
                        </th>
                        {/* <td>{data.delivery_company_name} </td> */}
                        <td>
                          <img
                            src={
                              companyLogos[data.delivery_company_name]
                                ? companyLogos[data.delivery_company_name]
                                : Other
                            }
                            alt="icon"
                            style={{ height: "25px", width: "25px" }}
                          />
                          <span className="ms-2">
                            {companyLogos[data.delivery_company_name]
                              ? data.delivery_company_name
                              : data.delivery_company_name}
                          </span>
                        </td>
                        {/* <td>-</td> */}
                        {data.user_details && (
                          <td>
                            <div className="user_info d-flex align-items-center">
                              <img
                                src={data.user_details[0].profile_image}
                                alt="user"
                                className="avatar"
                              />
                              <div className="ms-3">
                                <strong>
                                  <span className="txt-head">
                                    {data.user_details[0].first_name +
                                      " " +
                                      data.user_details[0].last_name}
                                  </span>
                                </strong>
                                <p className="txt-bottom mb-0">
                                  {data.user_details[0].email}
                                </p>
                                <p>{data.user_details[0].phone_primary}</p>
                              </div>
                            </div>
                          </td>
                        )}

                        <td>
                          {data.apartment_details && (
                            <div>
                              <strong>
                                <span className="txt-head">
                                  <MdApartment style={apIcon} />{" "}
                                  {data.apartment_details[0].appartmentNumber +
                                    "-" +
                                    data.wing_details[0].name}
                                </span>
                              </strong>
                            </div>
                          )}
                        </td>

                        <td>{data.approved_date}</td>
                        {/* <td>-</td>
                        <td>-</td> */}
                        <td>{data.status}</td>
                      </tr>
                    ))}

                  {/* <tr>
                    <th scope="row">1</th>
                    <td>
                      <div className="user_info d-flex  align-items-center">
                        <img src={user} alt="user" className="avatar" />
                        <div className="ms-3">
                          <strong>
                            <span className="txt-head">John Sons</span>
                          </strong>
                          <p className="txt-bottom mb-0">
                            JohnSons13@gmail.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>987654310</td>
                    <td>
                      <MdApartment style={apIcon} /> 109-A
                    </td>
                    <td>
                      <img src={flipkart} alt="icon" />
                      <span className="ms-2">flipkart</span>
                    </td>
                    <td>06-03-2024</td>
                    <td>Approved</td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>
                      <div className="user_info d-flex  align-items-center">
                        <img src={user} alt="user" className="avatar" />
                        <div className="ms-3">
                          <strong>
                            <span className="txt-head">John Sons</span>
                          </strong>
                          <p className="txt-bottom mb-0">
                            JohnSons13@gmail.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>987654310</td>
                    <td>
                      <MdApartment style={apIcon} /> 109-A
                    </td>
                    <td>
                      <img src={bigBasket} alt="icon" />
                      <span className="ms-2">Bigbasket</span>
                    </td>
                    <td>06-03-2024</td>
                    <td>Approved</td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>
                      <div className="user_info d-flex  align-items-center">
                        <img src={user} alt="user" className="avatar" />
                        <div className="ms-3">
                          <strong>
                            <span className="txt-head">John Sons</span>
                          </strong>
                          <p className="txt-bottom mb-0">
                            JohnSons13@gmail.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>987654310</td>
                    <td>
                      <MdApartment style={apIcon} /> 109-A
                    </td>
                    <td>
                      <img src={amazon} alt="icon" />
                      <span className="ms-2">Amazon</span>
                    </td>
                    <td>06-03-2024</td>
                    <td>Approved</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
            <div className="table-footer d-md-flex justify-content-md-between align-items-center">
              <p className="mb-0">
                Showing {offsetentry + 1} to {entry} of{" "}
                <span id="total-entries">{totalItems}</span> entries
              </p>
              <nav>
                <ul className="pagination pagination-md">
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={prevPage}
                      disabled={currentPage === 1}
                    >
                      &laquo;
                    </button>
                  </li>

                  {pageNumbers.map((pageNumber) => {
                    // Define the minimum and maximum page numbers to display
                    const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                    const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                    if (pageNumber >= minPage && pageNumber <= maxPage) {
                      return (
                        <li
                          key={pageNumber}
                          className={`page-item ${
                            currentPage === pageNumber ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => setCurrentPage(pageNumber)}
                          >
                            {pageNumber}
                          </button>
                        </li>
                      );
                    }
                    return null;
                  })}
                  <li className="page-item">
                    <button className="page-link" onClick={nextPage}>
                      &raquo;
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
            {/* <div className="table-footer d-md-flex justify-content-md-between align-items-center">
              <p className="mb-0">
                Showing 1 to 10 of <span id="total-entries">57</span> entries
              </p>
              <ul className="pagination pagination-md ">
                <li className="page-item">
                  <Link className="page-link" href="#" aria-label="Previous">
                    &laquo;
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    1
                  </Link>
                </li>
                <li className="page-item active">
                  <Link className="page-link " href="#">
                    2
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    3
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    4
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    5
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    6
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    7
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    8
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#">
                    9
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" href="#" aria-label="Next">
                    &raquo;
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
