import React from "react";
import PaymentsRemainList from "./data/PaymentList";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SocietyHeader from "../../components/societyHeader";
import logo1 from "../../assets/images/hamptom.png";
import SocietyHeaderView from "../../components/societyHeaderView";
import { useHistory } from "react-router-dom";

export default function Payments(props) {
  const { state } = props.location;
  const { image, society, id } = state;
  const history = useHistory();
  const home = { icon: "pi pi-home" };

  const items = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "Manage Payments" },
  ];

  return (
    <>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
      </div>
      <div className="header">
        {/* <SocietyHeader
                    imageUrl={logo1}
                    societyName="The Hampton Homes"
                    defaultRouter="/dashboard" /> */}

        <SocietyHeaderView
          imageUrl={image}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>
      <div className="card p-3 rounded-4 ">
        <div className="mb-2 px-3">
          {/* <Link to="/manage-society" className='btn mb-2 btn-sm btn-primary rounded-4 '>Back</Link> */}
          <button
            onClick={() => history.goBack()}
            className="btn me-2 btn-sm btn-primary rounded-4 "
          >
            Back
          </button>
        </div>
        <PaymentsRemainList />
      </div>
    </>
  );
}
