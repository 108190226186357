import { useState,useEffect,memo} from "react";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AllDocsList = (props) => {
  const [userData,setData]=useState({})
  const { image, Download, Document, UserDocument } = props;

  useEffect(()=>{
  setData({image, Download, Document,UserDocument})
  // console.log(Document)


},[props])

const handleDownlod=(e)=>{
  e.preventDefault();
  // window.location.href = userData?.UserDocument;
  window.open(userData?.UserDocument, '_blank');
}

console.log(userData?.fileName)

  return (
    <>
    {Object.keys(userData).length>=1?
    <>
      <li className="doc-list shadow">
        <div className="d-flex">
          <img className="avatar img-fluid" src={userData?.image} />
          <div>
            <p className="mb-2">{userData?.Document}</p>
            <button className="btn btn-sm btn-primary" onClick={handleDownlod}>{userData?.Download}</button>
           
          </div>
        </div>
      </li>
      </> : ""}
    </>
  );
};

export default memo(AllDocsList);


// if(Document=="Noc"){
//   setData({image, Download,UserDocument, ["fileName"]:"Noc"})
  
// }

// if(Document=="Police Verification"){
//  setData({image, Download,UserDocument, ["fileName"]:"Police Verification"})
 
// }

// if(Document=="rental_agreement"){
//   setData({image, Download,UserDocument, ["fileName"]:"Rental Agreement"})
  
// }

// if(Document=="id_proof"){
//   setData({image, Download,UserDocument, ["fileName"]:"id_proof"})

// }
  {/* <a href={userData?.UserDocument} className="btn btn-sm btn-primary" download>
              {userData?.Download}
            </a> */}


