import React, { useState, useEffect } from "react";
import user from "../../../assets/images/avatar.jpg";
import { MdDeleteOutline, MdLocalParking, MdPerson2 } from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { MdOutlineEdit } from "react-icons/md";
import { Sidebar } from "primereact/sidebar";
import logo from "../../../assets/images/amazon1.svg";
import { FaCar } from "react-icons/fa";
import { MdApartment } from "react-icons/md";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import No_image_available from "../../../assets/images/avatar.jpg";
import Loading from "../../ManageApp/data/Loading";

const apIcon = {
  fontSize: "20px",
};

function TableSOS(props) {
  const { id } = props;
  console.log(id);
  const token = localStorage.getItem("token");

  const [visibleRight, setVisibleRight] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [data, setdata] = useState("");

  const [totalItems, setTotalItems] = useState(0);
  //    const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);

  useEffect(() => {
    datafetch();
  }, [selectedRole, currentPage, searchQuery]);

  const datafetch = () => {
    // setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}SosRequest`,
      headers: { Authorization: "Bearer " + token },
      params: {
        society_id: id,
        type: selectedRole,
        limit,
        offset,
        search: searchQuery,
      },

      // params: { society_id:id,limit,offset,searchQuery: searchQuery},
      // params: { soceityId: id,searchQuery: searchQuery ,limit,offset},
    };

    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.count);
        console.log(response.data);
        //   setdata(response.data.data.allData);
        setdata(response.data.data);
        setentry(response.data.data.length + offset);
        setIsLoading(false);
        console.log(selectedRole);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(entry);
  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
    datafetch();
  };

  console.log(data);

  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // console.log(data);
  if (isLoading && searchQuery === "") {
    return <Loading />;
  }
  return (
    <>
      <Sidebar
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h3>Right Sidebar</h3>
      </Sidebar>

      <div className="table_header mb-3">
        <div className="d-flex flex-wrap align-items-center">
          <h4 className="mb-0 fw-semi-bold fs-5">All SOS Requests List</h4>
          <div className="ms-auto d-flex align-items-center gap-2">
            {/* <select className="form-select" aria-label="Default select example"> */}
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={handleRoleChange}
              value={selectedRole}
            >
              {/* <option selected value="">All Request Types</option> */}
              <option value="">All Alert Types</option>
              <option value="Fire_Emergency">Fire Emergency</option>
              <option value="Visitor_Threat">Visitor Threat</option>
              <option value="Animal_Threat">Animal Threat</option>
              <option value="Lift_Emergency">Lift Emergency</option>
            </select>

            <div className="search_box ">
              <input
                placeholder="Search"
                className="chakra-input "
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

{data && data.length>0 ?(
      <div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">USER INFO</th>
              <th scope="col">PHONE</th>
              <th scope="col">APARTMENT NO</th>
              <th scope="col">EMERGENCY</th>
              <th scope="col">ALERT DATE</th>

              {/* <th scope="col">IN TIME</th>
              <th scope="col">OUT TIME</th> */}
              {/* <th scope="col">STATUS</th> */}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((data, idx) => (
                <tr>
                  {/* (currentPage - 1) * pageSize + idx + 1 */}
                  <th scope="row">{(currentPage - 1) * pageSize + idx + 1}</th>
                  <td>
                    <div className="user_info d-flex  align-items-center">
                      {data.user_id.profile_image ? (
                        <LazyLoadImage
                          src={data.user_id.profile_image}
                          alt="Profile"
                        />
                      ) : (
                        <LazyLoadImage
                          src={No_image_available}
                          alt="No rofile image"
                        />
                      )}
                      <div className="ms-3">
                        <strong>
                          <span className="txt-head">
                            {data.user_id.first_name +
                              " " +
                              data.user_id.last_name}
                          </span>
                        </strong>
                      </div>
                    </div>
                  </td>
                  <td>{data.user_id.phone_primary}</td>
                  <td>
                    {" "}
                    {data.apartment_id ? (
                      <>
                        <MdApartment style={apIcon} />
                        {data.apartment_id.appartmentNumber+"-"+data.wing_id.name} 
                      </>
                    ) : (
                      "-"
                    )}
                  </td>

                  <td>{data.emergency === "Animal_Threat" ? "Animal Threat" :
     data.emergency === "Fire_Emergency" ? "Fire Emergency" :
     data.emergency === "Lift_Emergency" ? "Lift Emergency" :
     data.emergency === "Visitor_Threat" ? "Visitor Threat" :
   
     "-"}</td>
                  <td>
                    {data.createdAt
                      ? new Date(data.createdAt).toISOString().split("T")[0]
                      : "-"}
                  </td>

                  {/* <td>{data.status?(data.status):"-"}</td> */}
                </tr>
              ))}
            {/* {data &&
              data.map((data, idx) => (
                <tr>
               <th scope="row">{(currentPage - 1) * pageSize + idx + 1}</th>
               <td><div className='user_info d-flex  align-items-center'>
               {data.user_details.profile_image ? <LazyLoadImage src={data.user_details.profile_image} alt="Profile" /> : <LazyLoadImage src={No_image_available} alt="No rofile image" />}
                <div className='ms-3'>
                  <strong><span className='txt-head'>{data.user_details.first_name +" "+data.user_details.last_name}</span></strong>
                </div>
              </div></td>
              <td>{data.user_details.phone_primary}</td>
              <td>{data.type?(data.type):"-"}</td>
              <td>{data.request_date?(data.request_date):"-"}</td>
              <td><MdApartment style={apIcon} />{data.apartment_details.appartmentNumber} </td>
              <td>{data.checkin_time?(convertTimeToAMPM(data.checkin_time)):"-"}</td>
              <td>{data.checkout_time?(convertTimeToAMPM(data.checkout_time)):"-"}</td>
              <td>{data.status?(data.status):"-"}</td>
             
                </tr>
              ))} */}

            {/* <tr>
              <th scope="row">1</th>
              <td><div className='user_info d-flex  align-items-center'>
                <img src={user} alt="user" className='avatar'/>
                <div className='ms-3'>
                  <strong><span className='txt-head'>John Sons</span></strong>
                </div>
              </div></td>
              <td>987654310</td>
              <td>Auto-approve Deliveries <p className='delivery-partner'>(<img src={logo} alt="amazon" />)</p></td>
              <td><div className=''>05-08-2024</div></td>
              <td><MdApartment style={apIcon} /> A 150 </td>
              <td>11:00 AM</td>
              <td>12:00 AM</td>
              <td><h6> <span className="badge bg-secondary">Awaiting</span></h6></td>

            </tr> */}
            {/* <tr>
              <th scope="row">1</th>
              <td><div className='user_info d-flex  align-items-center'>
                <img src={user} alt="user" className='avatar'/>
                <div className='ms-3'>
                  <strong><span className='txt-head'>John Sons</span></strong>
                </div>
              </div></td>
              <td>987654310</td>
              <td>Auto-approve Deliveries <p className='delivery-partner'>(<img src={logo} alt="amazon" />)</p></td>
              <td><div className=''>05-08-2024</div></td>
              <td><MdApartment style={apIcon} /> A 150 </td>
              <td>11:00 AM</td>
              <td>12:00 AM</td>
              <td><h6> <span className="badge bg-secondary">Awaiting</span></h6></td>

            </tr>
            <tr>
              <th scope="row">2</th>
              <td><div className='user_info d-flex  align-items-center'>

                <img src={user} alt="user" className='avatar' />
                <div className='ms-3'>
                  <strong><span className='txt-head'>John Sons</span></strong>
                </div>

              </div></td>
              <td>987654310</td>
              <td>Pre-approve Guest <p className='guest fw-bold d-flex gap-1 align-items-center'>(<MdPerson2 />Guest)</p> </td>
              <td><div className=''>05-08-2024</div></td>
              <td><MdApartment style={apIcon} /> A 190</td>
              <td>11:00 AM</td>
              <td>01:00 PM</td>
              <td><h6> <span className="badge bg-danger">Disapproved</span></h6></td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td><div className='user_info d-flex  align-items-center'>

                <img src={user} alt="user" className='avatar' />
                <div className='ms-3'>
                  <strong><span className='txt-head'>John Sons</span></strong>
                </div>

              </div></td>
              <td>987654310</td>
              <td>Pre-approve Visiting Help<p className='guest fw-bold d-flex gap-1 align-items-center'>(<MdPerson2 />Doctor)</p></td>
              <td><div className=''>05-08-2024</div></td>
              <td> <MdApartment style={apIcon} /> A 150</td>
              <td>10:00 AM</td>
              <td>11:00 AM</td>
              <td><h6> <span className="badge bg-success">Approved</span></h6></td>

            </tr>
            <tr>
              <th scope="row">2</th>
              <td><div className='user_info d-flex  align-items-center'>

                <img src={user} alt="user" className='avatar' />
                <div className='ms-3'>
                  <strong><span className='txt-head'>John Sons</span></strong>
                </div>

              </div></td>
              <td>987654310</td>
              <td>Book Guest Parking <p className='guest fw-bold d-flex gap-1 align-items-center'>(<FaCar />Guest)</p> </td>
              <td><div className=''>05-08-2024</div></td>
              <td><MdApartment style={apIcon} /> A 190</td>
              <td>11:00 AM</td>
              <td>12:00 AM</td>
              <td><h6> <span className="badge bg-danger">Disapproved</span></h6></td>

            </tr> */}
          </tbody>
        </table>
      </div>
      {/* {offsetentry+1}  */}
      <div className="table-footer d-md-flex justify-content-md-between align-items-center">
        <p className="mb-0">
          Showing {offsetentry + 1} to {entry} of{" "}
          <span id="total-entries">{totalItems}</span> entries
        </p>
        <nav>
          <ul className="pagination pagination-md">
            <li className="page-item">
              <button
                className="page-link"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                &laquo;
              </button>
            </li>

            {pageNumbers.map((pageNumber) => {
              // Define the minimum and maximum page numbers to display
              const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
              const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

              if (pageNumber >= minPage && pageNumber <= maxPage) {
                return (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      currentPage === pageNumber ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                );
              }
              return null;
            })}
            <li className="page-item">
              <button className="page-link" onClick={nextPage}>
                &raquo;
              </button>
            </li>
          </ul>
        </nav>
      </div>
      </div>
     ):(<h4>No data available</h4> )}
      {/* <div className="table-footer d-md-flex justify-content-md-between align-items-center">
        <p className='mb-0'>Showing 1 to 10 of <span id='total-entries'>57</span>  entries</p>
        <ul className="pagination pagination-md ">
          <li className="page-item"><Link className="page-link" href="#" aria-label="Previous">&laquo;</Link></li>
          <li className="page-item"><Link className="page-link" href="#">1</Link></li>
          <li className="page-item active"><Link className="page-link " href="#">2</Link></li>
          <li className="page-item"><Link className="page-link" href="#">3</Link></li>
          <li className="page-item"><Link className="page-link" href="#">4</Link></li>
          <li className="page-item"><Link className="page-link" href="#">5</Link></li>
          <li className="page-item"><Link className="page-link" href="#">6</Link></li>
          <li className="page-item"><Link className="page-link" href="#">7</Link></li>
          <li className="page-item"><Link className="page-link" href="#">8</Link></li>
          <li className="page-item"><Link className="page-link" href="#">9</Link></li>
          <li className="page-item"><Link className="page-link" href="#" aria-label="Next">&raquo;</Link></li>
        </ul>
      </div> */}
    </>
  );
}

export default TableSOS;
