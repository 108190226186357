import { Menu } from "primereact/menu";
import React, { useRef, useState, useEffect, createRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { saveAs } from "file-saver";

import {
  MdOutlineRemoveRedEye,
  MdPerson,
  MdDeleteOutline,
  MdOutlineAdd,
  MdOutlineEdit,
} from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { CgSearch } from "react-icons/cg";
import Loading from "../../ManageApp/data/Loading";
import Swal from "sweetalert2";
import { Sidebar } from "primereact/sidebar";
import { useDispatch } from "react-redux";
import { addUSers, getwings, updateUser } from "../../../utils/asyncFun";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeApiCall } from "../../../utils/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AddResidents from "../../../components/Dialog/AddResidents";

export default function ResidentsList({ id, image, society }) {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const token = localStorage.getItem("token");
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(10);
  const [ID, setIDE] = useState("");
  const [residentData, setResidentData] = useState({
    first_name: "",
    last_name: "",
    phone_primary: "",
    email: "",
    status: "",
    type: "",
    society_id: id,
  });
  const dispatch = useDispatch();

  const [userID, setuserId] = useState("");

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);
  const paramData = useParams();
  console.log(paramData);

  //add csv state

  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [file2, setFile2] = useState();
  // State to control the visibility of the button
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  // const { id, image, society } = props;

  const dataToPass = { image: image, society: society, id: id };

  let resid;

  // const handleRemoveRedEyeClick = (residentId) => {
  //   // Your logic here, for example, you can log the ID or perform some other action
  //   console.log("Remove eye icon clicked with resident ID:", residentId);
  //   resid = residentId;
  //   // Navigate to the next page with the resident ID
  //   history.push(`/view-resident/${residentId}`);
  // };
  const menuRefs = useRef(data.map(() => createRef()));

  const validationSchema = Yup.object().shape({
    society_id: Yup.string(),
    wing_id: Yup.string().required("Wing is required"),
    appartment_id: Yup.string().required("Appartment is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    phone_primary: Yup.string(),
    email: Yup.string().email("invalid email address"),
    status: Yup.string().email("invalid email address"),
    type: Yup.string().email("invalid email address"),
  });

  const formik = useFormik({
    initialValues: {
      society_id: "",
      wing_id: "",
      appartment_id: "",
      first_name: "",
      last_name: "",
      phone_primary: "",
      email: "",
      status: "",
      type: "",
    },
    validationSchema: validationSchema,
  });

  const handleSave = async () => {
    formik.setTouched(
      Object.keys(formik.values).reduce((acc, fieldName) => {
        acc[fieldName] = true;
        return acc;
      }, {})
    );

    formik.validateForm().then(async (errors) => {
      // Check if there are any validation errors
      console.log("errors", errors);
      console.log("values", formik.values);
      if (Object.keys(errors).length === 0) {
      }
    });
  };

  const items = [
    {
      label: "2 More Apartments",
      items: [
        {
          label: "A 151",
          icon: "pi pi-home",
        },
        {
          label: "A 160",
          icon: "pi pi-home",
        },
      ],
    },
  ];
  async function getWingsandAppartment() {
    const params = {
      society_id: id,
    };

    const wingResponse = await makeApiCall(
      "getwings",
      "GET",
      null,
      params,
      true
    );

    if (wingResponse) {
      console.log("wingResponse", wingResponse);
    }
  }

  useEffect(() => {
    getWingsandAppartment();
  }, []);

  useEffect(() => {
    datafetch();
    // menuRefs.current = data.map(() => createRef());
  }, [currentPage, searchQuery]); // Fetch data whenever currentPage changes

  useEffect(() => {
    menuRefs.current = data.map(() => createRef());
  }, [data]);

  const datafetch = () => {
    setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getresidentAccordindSociety`,
      headers: { Authorization: "Bearer " + token },
      params: { search: searchQuery, limit, offset, society_id: id },
    };

    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.count);
        setdata(response.data.data);
        setIsLoading(false);
        setIDE(response.data.resident_id);
        setentry(response.data.data.length + offset);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleRight2, setVisibleRight2] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const addResidents = () => {
    setVisibleRight(true);
  };
  const editResidents = () => {
    setVisibleRight2(true);
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  function userVal(data) {
    console.log(data);
    setuserId(data);
  }

  function apartmentCount(count) {
    console.log("check");
    console.log(count);
  }
  function generateMenuItems(apartmentDetails, index) {
    if (apartmentDetails && Array.isArray(apartmentDetails)) {
      return apartmentDetails.map((apartment) => ({
        label: apartment.wing_details.name + " " + apartment.appartmentNumber,
        icon: "pi pi-home",
        index: index, // Add the index of the row
      }));
    } else {
      return []; // Return an empty array if apartmentDetails is not valid or empty
    }
  }

  const deleteconfirm = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let config = {
          method: "patch",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_API}deleteTenant/` + ID,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        };
        axios
          .request(config)
          .then((response) => {
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            datafetch();
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    });
  };

  // if (isLoading) {
  //   return <Loading />;
  // }

  if (isLoading && searchQuery === "") {
    return <Loading />;
  }

  const handleSubmit = async (e, field, value) => {
    e.preventDefault();
    if (field == "ADD_RESIDENT") {
      if (
        residentData.first_name &&
        residentData.last_name &&
        residentData.phone_primary
      ) {
        dispatch(
          addUSers({ method: "POST", url: "addUSers", data: residentData })
        ).then(() => {
          setResidentData({
            first_name: "",
            last_name: "",
            phone_primary: "",
            email: "",
            status: "",
            type: "",
            society_id: id,
          });
          datafetch();
          setVisibleRight(false);
        });
      }
    } else {
      dispatch(
        updateUser({ method: "PATCH", url: "updateUser", data: residentData })
      ).then(() => {
        setResidentData({
          first_name: "",
          last_name: "",
          phone_primary: "",
          email: "",
          status: "",
          type: "",
          society_id: id,
        });
        datafetch();
        setVisibleRight2(false);
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResidentData({ ...residentData, [name]: value });
  };

  // --Dialog--

  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="Close"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
        {/* <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
          <Button label="Yes" icon="pi pi-check" onClick={() => onHide(name)} autoFocus /> */}
      </div>
    );
  };

  // async function handleDownloadSampleCSV() {
  //   await axios
  //     .get("https://api.rcommunity.app/a1/users/templateDownload?type=resident")
  //     .then((res) =>{ console.log("csv", res.data.data);
  // });
  // }

  // async function handleDownloadSampleCSV() {
  //   try {
  //     const res = await axios.get("https://api.rcommunity.app/a1/users/templateDownload?type=resident");
  //     console.log("csv", res.data.data);

  //     // Create a Blob from the CSV data
  //     const blob = new Blob([res.data.data], { type: 'text/csv' });

  //     // Create a link element
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = 'sample.csv';

  //     // Append the link to the document body and trigger the download
  //     document.body.appendChild(a);
  //     a.click();

  //     // Clean up by removing the link and revoking the Blob URL
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error('Error downloading the CSV file', error);
  //   }
  // }

  // async function handleDownloadSampleCSV() {
  //   try {
  //     const res = await axios.get("https://api.rcommunity.app/a1/users/templateDownload?type=resident");
  //     const csvUrl = res.data.data; // Assuming this is the URL to the CSV file

  //     // Create a link element
  //     const a = document.createElement('a');
  //     a.href = csvUrl;
  //     a.download = 'sample.csv';

  //     // Append the link to the document body and trigger the download
  //     document.body.appendChild(a);
  //     a.click();

  //     // Clean up by removing the link
  //     document.body.removeChild(a);
  //   } catch (error) {
  //     console.error('Error downloading the CSV file', error);
  //   }
  // }

  async function handleDownloadSampleCSV() {
    try {
      const res = await axios.get(
        "https://api.rcommunity.app/a1/users/templateDownload?type=resident",
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([res.data], { type: "text/csv" });
      saveAs(blob, "sample.csv");
    } catch (error) {
      console.error("Error downloading the CSV file", error);
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile2(event.target.files[0]);
    // Get the first file from the selected files array
    setSelectedFile(file); // Update the state with the selected file
    setIsDisabled(!isDisabled);
    setIsButtonVisible(true);
  };

  
  const clearSelectedFile = () => {
    console.log(file2);
    setSelectedFile(null); // Reset the state to null
    setIsDisabled(!isDisabled);
    setIsButtonVisible(false);
  };

  function handleCsvSubmit() {
    // event.preventDefault();
    const url = `${process.env.REACT_APP_BASE_API}uploadresident`;
    const formData = new FormData();
    // formData.append("society_id", id);
    formData.append("doc", file2);
    formData.append("type", "doc");

    // formData.append('fileName', file.name);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response.data);
        // alert("file Uploaded success!");
        setSelectedFile(null); // Reset the state to null
        setIsDisabled(!isDisabled);
        setIsButtonVisible(false);
        datafetch();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "CSV Upload Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        onHide("displayResponsive");
      })
      .catch((error) => {
        //handle error
        console.log(error.message);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
        onHide("displayResponsive");
      });
  }
  return (
    <div>
      <AddResidents
        header="Add Resident"
        content="Custom Content goes here"
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        society_id={id}
        datafetch={datafetch}
      />
      <Sidebar
        className="right-sidebar"
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h5>Add Resident</h5>

        <div className="sidebar-main mt-3">
          <form>
            <div className="mb-3">
              <label for="chargeName" className="form-label">
                Wing
              </label>
              <select
                className="form-control"
                onChange={(e) => handleChange(e)}
                name="wing"
                value={residentData?.type || ""}
              >
                <option value="">Select Wing</option>
                <option value="resident">Resident</option>
                <option value="managememt">Management</option>
              </select>
            </div>
            <div className="mb-3">
              <label for="chargeName" className="form-label">
                Apartment
              </label>
              <select
                className="form-control"
                onChange={(e) => handleChange(e)}
                name="apartment"
                value={residentData?.type || ""}
              >
                <option value="">Select Apartment</option>
                <option value="resident">Resident</option>
                <option value="managememt">Management</option>
              </select>
            </div>
            <div className="mb-3">
              <label for="chargeName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                name="first_name"
                {...formik.getFieldProps("first_name")}
              />
            </div>

            <div className="mb-3">
              <label for="chargeName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                name="last_name"
                {...formik.getFieldProps("last_name")}
              />
            </div>

            {/* <div className="mb-3">
              <label for="chargeName" className="form-label">Apartment Number</label>
              <input type="text" className='form-control' value='211' />
            </div> */}

            <div className="mb-3">
              <label for="chargeName" className="form-label">
                Mobile Number
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleChange(e)}
                name="phone_primary"
                value={residentData?.phone_primary || ""}
              />
            </div>

            <div className="mb-3">
              <label for="chargeName" className="form-label">
                Email
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleChange(e)}
                name="email"
                value={residentData?.email || ""}
              />
            </div>

            {/* <div className="mb-3">
              <label for="chargeName" className="form-label">Maintenance Amount</label>
              <input type="text" className='form-control' value='15000' />
            </div> */}

            <div className="mb-3">
              <label for="chargeName" className="form-label">
                Resident Type
              </label>
              <select
                className="form-control"
                onChange={(e) => handleChange(e)}
                name="type"
                value={residentData?.type || ""}
              >
                <option value="">Select Type</option>
                <option value="resident">Resident</option>
                <option value="managememt">Management</option>
              </select>
            </div>

            {/* <div className="mb-3">
              <label for="chargeName" className="form-label">Resident Since</label>
              <input type="date" className='form-control' value='20-12-2024' />
            </div> */}

            <div className="mb-3">
              <label for="chargeName" className="form-label">
                Resident Status
              </label>
              <select
                className="form-control"
                onChange={(e) => handleChange(e)}
                name="status"
                value={residentData?.status || ""}
              >
                <option value="">Select Status</option>
                <option value="pending">Pending</option>
                <option value="approve">Approve</option>
                <option value="disapprove">Disapprove</option>
              </select>
            </div>
          </form>
        </div>
        <div className="d-flex footer gap-2 align-items-center">
          <button
            onClick={(e) => handleSubmit(e, "ADD_RESIDENT", "")}
            className="btn btn-primary rounded-4"
          >
            Add
          </button>
        </div>
      </Sidebar>

      <Sidebar
        className="right-sidebar"
        visible={visibleRight2}
        position="right"
        onHide={() => setVisibleRight2(false)}
      >
        <h5>Edit Resident</h5>
        <div className="sidebar-main mt-3">
          <form onSubmit={(e) => handleSubmit(e, "UPDATE_RESIDENT", "")}>
            <div className="mb-3">
              <label for="chargeName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleChange(e)}
                name="first_name"
                value={residentData?.first_name || ""}
                required
              />
            </div>

            <div className="mb-3">
              <label for="chargeName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleChange(e)}
                name="last_name"
                value={residentData?.last_name || ""}
                required
              />
            </div>

            {/* <div className="mb-3">
              <label for="chargeName" className="form-label">Apartment Number</label>
              <input type="text" className='form-control' value='211' />
            </div> */}

            <div className="mb-3">
              <label for="chargeName" className="form-label">
                Mobile Number
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleChange(e)}
                name="phone_primary"
                value={residentData?.phone_primary || ""}
                required
              />
            </div>

            <div className="mb-3">
              <label for="chargeName" className="form-label">
                Email
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleChange(e)}
                name="email"
                value={residentData?.email || ""}
                required
              />
            </div>

            {/* <div className="mb-3">
              <label for="chargeName" className="form-label">Maintenance Amount</label>
              <input type="text" className='form-control' value='15000' />
            </div> */}

            <div className="mb-3">
              <label for="chargeName" className="form-label">
                Resident Type
              </label>
              <select
                className="form-control"
                onChange={(e) => handleChange(e)}
                name="type"
                value={residentData?.type || ""}
                required
              >
                <option value="">Select Type</option>
                <option value="resident">Resident</option>
                <option value="managememt">Management</option>
              </select>
            </div>

            {/* <div className="mb-3">
              <label for="chargeName" className="form-label">Resident Since</label>
              <input type="date" className='form-control' value='20-12-2024' />
            </div> */}

            <div className="mb-3">
              <label for="chargeName" className="form-label">
                Resident Status
              </label>
              <select
                className="form-control"
                onChange={(e) => handleChange(e)}
                name="status"
                value={residentData?.status || ""}
                required
              >
                <option value="">Select Status</option>
                <option value="pending">Pending</option>
                <option value="approve">Approve</option>
                <option value="disapprove">Disapprove</option>
              </select>
            </div>

            <div className="d-flex footer gap-2 align-items-center">
              <button
                type="submit"
                // onClick={(e) => handleSubmit(e, "UPDATE_RESIDENT", "")}
                className="btn btn-primary rounded-4"
              >
                Update
              </button>
            </div>
          </form>
        </div>
        {/* <div className="d-flex footer gap-2 align-items-center">
          <button type="submit"
           
            className="btn btn-primary rounded-4"
          >
            Update
          </button>
        </div> */}
      </Sidebar>

      <div className="table_header mb-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {/* <Link to="/" className='btn me-2 btn-sm btn-primary rounded-4 '>Back</Link> */}
          <button
            onClick={() => history.goBack()}
            className="btn me-2 btn-sm btn-primary rounded-4"
          >
            Back
          </button>
          <h5 className="mb-0 fs-6 d-flex align-items-center gap-2">
            <MdPerson /> Residents
          </h5>
        </div>
        {/* <div className="search_box position-relative align-items-center">
    <input placeholder="Search" className="form-control rounded-5" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/> */}
        {/* <span className="search-icon" >
      <CgSearch/>
    </span> */}
        {/* </div> */}
        <div className="ms-auto d-flex align-items-center gap-2">
          <div className="search_box ">
            <input
              placeholder="Search"
              className="chakra-input"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="search_icon "></div>
          </div>

          <button
            label="Show Dialog"
            onClick={() => setShowDialog(true)}
            className="btn rounded-5 btn-primary"
          >
            Add New
          </button>

          <div>
            <Button
              label="Upload CSV"
              className="p-button-sm p-button-rounded"
              icon="pi pi-external-link"
              onClick={() => onClick("displayResponsive")}
              style={{ marginRight: "10px" }}
            />
            {/* <Toast visible={toastVisible} severity={toastSeverity} summary={toastSummary} detail={toastDetail} /> */}
            <Dialog
              header="Upload Society"
              visible={displayResponsive}
              onHide={() => onHide("displayResponsive")}
              breakpoints={{ "960px": "75vw" }}
              style={{ width: "50vw" }}
              footer={renderFooter("displayResponsive")}
            >
              <div>
                {/* <a href="" className='btn btn-sm btn-primary d-block ms-auto mb-2' style={{width: '30%'}} download>Download Society Import Sample CSV</a> */}
                {/* <FileUpload
                name="doc"
                url={`${process.env.REACT_APP_BASE_API}uploadappartment`}
                onUpload={onUpload}
                onClick={fileHandler}
                accept="pdf/*"
                maxFileSize={1000000}  // 1 MB
              />        
                <div>
                {uploadedFiles.length > 0 && (
                  <div>
                    <ul>
                      {uploadedFiles.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div> */}

                {/* <form onSubmit={handleSubmit}> */}
                {/* <h3> File Upload</h3> */}

                <a
                  onClick={() => handleDownloadSampleCSV()}
                  className="btn btn-sm btn-primary d-block ms-auto mb-2"
                  style={{ width: "50%" }}
                >
                  Download Resident Template Sample CSV
                </a>

                <div className="p-fileupload p-fileupload-advanced p-component">
                  <div className="p-fileupload-buttonbar">
                    <label
                      for="upload-apartment"
                      className="p-button p-fileupload-choose p-component "
                      tabIndex={0}
                    >
                      {!selectedFile && ( // Render the file input if no file is selected
                        <input
                          className="upload-apartment-csv"
                          type="file"
                          name="doc"
                          accept=".csv"
                          // className='btn btn-primary rounded-5 text-capitalize'
                          // onChange={handleChange}
                          id="upload-apartment"
                          onChange={handleFileChange}
                        />
                      )}

                      <span className="p-button-icon p-button-icon-left p-clickable pi pi-fw pi-plus"></span>
                      <span className="p-button-label p-clickable">choose</span>
                    </label>
                    <button
                      // className="p-button p-component p-disabled"
                      className="p-button p-component"
                      disabled={isDisabled}
                      onClick={handleCsvSubmit}
                    >
                      <span className="p-button-icon p-c p-button-icon-left pi pi-upload"></span>
                      <span className="p-button-label p-c">Upload</span>
                    </button>
                    <button
                      type="button"
                      // className="p-button p-component p-disabled"
                      className="p-button p-component"
                      disabled={isDisabled}
                      onClick={clearSelectedFile}
                    >
                      <span className="p-button-icon p-c p-button-icon-left pi pi-times"></span>
                      <span className="p-button-label p-c">Cancel</span>
                    </button>
                  </div>
                  <div className="p-fileupload-content">
                    <div className="p-fileupload-files">
                      <div className="p-fileupload-row">
                        {/* <div className="p-fileupload-filename">appartment2.csv</div>
                        <div>154 B</div> */}
                        <div className="p-fileupload-filename">
                          {selectedFile && <p> {selectedFile.name}</p>}
                        </div>

                        <div>
                          {isButtonVisible && (
                            <button
                              type="button"
                              className="p-button p-component p-button-icon-only"
                              onClick={clearSelectedFile}
                            >
                              <span className="p-button-icon p-c pi pi-times"></span>
                              <span className="p-button-label p-c">&nbsp;</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </form> */}
              </div>
            </Dialog>
          </div>
        </div>
      </div>

      {/* <div className="table_header mb-3">
        <div className="d-flex flex-wrap align-items-center"> */}
      {/* <Link to="/" className='btn me-2 btn-sm btn-primary rounded-4 '>Back</Link> */}
      {/* <button
            onClick={() => history.goBack()}
            className="btn me-2 btn-sm btn-primary rounded-4 "
          >
            Back
          </button>
          <h5 className="mb-0 fs-6 d-flex align-items-center gap-2">
            <MdPerson /> Residents{" "}
          </h5> */}

      {/* <div className="search_box position-relative align-items-center">
            <input placeholder="Search" className="form-control rounded-5"/>
            <span className="search-icon">
              <CgSearch/>
            </span>
          </div>  */}

      {/* <div className="search_box position-relative align-items-center">
            <input placeholder="Search" className="form-control rounded-5"  value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}/>
            <span className="search-icon">
              <CgSearch  onClick={handleSearch}/>
            </span>
          </div> */}
      {/* </div>
      </div> */}
      {data && data.length > 0 ? (
        <div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  {/* <th scope="col">TITLE</th> */}
                  <th scope="col">FIRST NAME</th>
                  <th scope="col">LAST NAME</th>
                  {/* <th scope="col">RESIDENT ID</th> */}
                  {/* <th scope="col">WING NAME</th> */}
                  <th scope="col">APARTMENT NUMBER</th>
                  <th scope="col">MOBILE NO.</th>
                  <th scope="col">MAINTENANCE AMOUNT/RENT DUE AMOUNT</th>
                  <th scope="col">RESIDENT TYPE</th>
                  <th scope="col">RESIDENT SINCE</th>
                  <th scope="col">STATUS</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((data, idx) => (
                    <tr>
                      {/* <th scope="row">1</th> */}
                      <th scope="row">
                        {(currentPage - 1) * pageSize + idx + 1}
                      </th>

                      {/* <td ></td> */}
                      <td>
                        {/* { data.forEach(
                                resident_details
                                 => {resident_details.first_name});} */}
                        {data.resident_details.first_name}
                      </td>

                      <td> {data.resident_details.last_name}</td>
                      {/* <td><div className='text-uppercase'></div></td> */}
                      {/* <td> { data.resident_details.wing_name} */}

                      {/* {data &&
                      data.appartment_details &&
                      data.appartment_details.map((apartment) => (
                        <span key={apartment.id}>
                          {apartment && apartment.wing_details.name}
                        </span>
                      ))} */}
                      {/* <td>
  {data && data.apartment_details && data.apartment_details.map((apartment, index) => (
    <span key={apartment._id}>
      {apartment && apartment.wing_details && apartment.wing_details.name}
      {index < data.apartment_details.length - 1 && ', '}
    </span>
  ))}
</td> */}

                      <td>
                        {/* {data.appartment_details[0].appartmentNumber?appartment_details.appartmentNumber:0} */}
                        {/* {data?.appartment_details? data.appartment_details[0].appartmentNumber : ''} */}

                        {/* {data &&
                      data.appartment_details &&
                      data.appartment_details.map((apartment) => (
                        // <span key={apartment.id}>{apartment && apartment.appartmentNumber}</span>
                        <div className="bagde-container">
                          <Menu
                            model={items}
                            popup
                            ref={menu}
                            id="popup_menu"
                          />
                          <button
                            className="btn btn-sm p-shadow-2"
                            label="Show"
                            icon="pi pi-bars"
                            // onClick={(event) => menu.current.toggle(event)}
                            onClick={() => apartmentCount(data.appartment_details)}
                            aria-controls="popup_menu"
                            aria-haspopup
                          >
                            <span className="counter">
                              {apartment && apartment.appartmentNumber}
                            </span>{" "}
                            {data.appartment_details.length > 1
                              ? data.appartment_details.length.toString() +
                                " More"
                              : ""}
                          </button>
                        </div>
                      ))} */}

                        {/* <div className='bagde-container'>
                                    <Menu model={items} popup ref={menu} id="popup_menu" />
                                    <button className='btn btn-sm p-shadow-2' label="Show" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup >
                                        + <span className='counter'>2</span> More
                                    </button>
                                </div> */}
                        {/* {data && data.appartment_details && data.appartment_details.length > 1 ? (
  <div className='bagde-container'>
    <Menu model={items} popup ref={menu} id="popup_menu" />
    <button className='btn btn-sm p-shadow-2' label="Show" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup >
      + <span className='counter'>2</span> More
    </button>
  </div>
) : (
  data && data.appartment_details && data.appartment_details.map(apartment => (
    <span key={apartment.id}>{apartment && apartment.appartmentNumber}</span>
  ))
)} */}
                        <td>
                          {data &&
                          data.apartment_details &&
                          data.apartment_details.length > 0 ? (
                            <div>
                              <div className="bagde-container">
                                {/* Render the details of the first apartment */}
                                <span className="counter">
                                  {data.apartment_details[0] &&
                                    data.apartment_details[0].wing_details &&
                                    data.apartment_details[0].wing_details
                                      .name &&
                                    `${data.apartment_details[0].wing_details.name} ${data.apartment_details[0].appartmentNumber}`}
                                </span>
                              </div>
                              {/* Render menu button only if there are more than one apartment details */}
                              {data.apartment_details.length > 1 && (
                                <div className="bagde-container">
                                  <Menu
                                    model={generateMenuItems(
                                      data.apartment_details.slice(1),
                                      idx
                                    )}
                                    popup
                                    ref={menuRefs.current[idx]}
                                    id={`popup_menu_${idx}`}
                                  />
                                  <button
                                    className="btn btn-sm p-shadow-2"
                                    label="Show"
                                    icon="pi pi-bars"
                                    onClick={(event) =>
                                      menuRefs.current[idx].current.toggle(
                                        event
                                      )
                                    }
                                    aria-controls="popup_menu"
                                    aria-haspopup="true" // add aria-haspopup attribute
                                  >
                                    <span className="counter">
                                      {data.apartment_details.length - 1} +More
                                    </span>{" "}
                                  </button>
                                </div>
                              )}
                            </div>
                          ) : (
                            <span>
                              <i
                                className="pi pi-times"
                                style={{ color: "red" }}
                              ></i>
                            </span>
                          )}
                        </td>
                      </td>

                      <td>{data.resident_details.phone_primary}</td>
                      <td>
                        {(
                          data?.resident_details?.role === "owner"
                            ? data?.resident_details?.maintenance_bill_amount
                            : data?.resident_details?.rent_due_amount
                        )
                          ? `₹ ${
                              data?.resident_details?.role === "owner"
                                ? data?.resident_details
                                    ?.maintenance_bill_amount
                                : data?.resident_details?.rent_amount
                            }`
                          : ""}
                      </td>
                      <td>{data?.resident_details?.role}</td>

                      <td>{data?.resident_details.createdAt?.split("T")[0]}</td>

                      {/* 
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i>Active</div></td> */}
                      <td>
                        <div className="">
                          {/* <i className="pi pi-check" style={{ color: 'slateblue' }}></i> */}

                          {data.resident_details.status}
                        </div>
                      </td>
                      {/* <td><div className='action_btn'>
                                <MdOutlineRemoveRedEye />
                            </div></td> */}
                      <td>
                        {console.log(data)}
                        <div className="action_btn d-none">
                          <div>
                            <Link
                              to={{
                                pathname: `/view-resident/${data.resident_id}`,
                                state: dataToPass,
                              }}
                              // onClick={() =>
                              //   handleRemoveRedEyeClick(data.resident_id)
                              // }
                            >
                              <MdOutlineRemoveRedEye />
                            </Link>
                          </div>
                          {/* <div>
                            <button onClick={() => deleteconfirm(data._id)} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>

                              <MdDeleteOutline /></button></div> */}
                        </div>

                        <div className="action_btn cstm-actns-bttns">
                          <span onClick={() => addResidents()}>
                            {" "}
                            {data.resident_details.role == "owner" ? (
                              <>
                                <Link
                                  to={{
                                    pathname: `/view-resident/${data.resident_id}`,
                                    state: dataToPass,
                                  }}
                                >
                                  <MdOutlineRemoveRedEye />{" "}
                                </Link>{" "}
                              </>
                            ) : (
                              <>
                                <Link
                                  to={{
                                    pathname: `/view-tenant/${data.resident_id}/${paramData.id}`,
                                    state: {
                                      ...dataToPass,
                                      uploadDocumentLink: data.upload_document, // Include the upload document link in the state
                                    },
                                  }}
                                >
                                  <MdOutlineRemoveRedEye />{" "}
                                </Link>{" "}
                              </>
                            )}
                          </span>
                          <span
                            onClick={() => {
                              editResidents();
                              setResidentData({ ...data, user_id: data._id });
                            }}
                          >
                            <MdOutlineEdit />
                          </span>
                          <span
                            onClick={() => deleteconfirm(data._id)}
                            style={{
                              border: "none",
                              background: "none",
                              cursor: "pointer",
                            }}
                          >
                            <MdDeleteOutline />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}

                {/* <tr> 
                            <th scope="row">1</th>
                            <td >Mr.</td>
                            <td>Amar</td>
                            <td>Singh</td>
                            <td><div className='text-uppercase'>Res123</div></td>
                            <td>A</td>
                            <td>102
                                <div className='bagde-container'>
                                    <Menu model={items} popup ref={menu} id="popup_menu" />
                                    <button className='btn btn-sm p-shadow-2' label="Show" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup >
                                        + <span className='counter'>2</span> More
                                    </button>

                                </div>
                            </td>
                            <td>+91-87652-87654</td>
                            <td>2350</td>
                            <td>Owner</td>
                            <td>10/02/2021</td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i>Active</div></td>
                            <td><div className='action_btn'>
                                <Link to="/view-resident"><MdOutlineRemoveRedEye /></Link>
                            </div></td>    </tr>*/}

                {/* <tr>
                            <th scope="row">2</th>
                            <td >Mr.</td>
                            <td>Amar</td>
                            <td>Singh</td>
                            <td><div className='text-uppercase'>Res123</div></td>
                            <td>B</td>
                            <td>102</td>
                            <td>+91-87652-87654</td>
                            <td>2350</td>
                            <td>Tenant</td>
                            <td>10/02/2021</td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i>Active</div></td>
                            <td><div className='action_btn'>
                                <Link to="/view-tenant"><MdOutlineRemoveRedEye /></Link>
                            </div></td>

                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td >Mr.</td>
                            <td>Amar</td>
                            <td>Singh</td>
                            <td><div className='text-uppercase'>Res123</div></td>
                            <td>C</td>
                            <td>102</td>
                            <td>+91-87652-87654</td>
                            <td>2350</td>
                            <td>Owner</td>
                            <td>10/02/2021</td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i>Active</div></td>
                            <td><div className='action_btn'>
                                <Link to="/view-owner" ><MdOutlineRemoveRedEye /></Link>
                            </div></td>

                        </tr> */}
              </tbody>
            </table>
          </div>
          <div className="table-footer d-md-flex justify-content-md-between align-items-center">
            <p className="mb-0">
              Showing {offsetentry + 1} to {entry} of{" "}
              <span id="total-entries">{totalItems}</span> entries
            </p>
            <nav>
              <ul className="pagination pagination-md">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>

                {pageNumbers.map((pageNumber) => {
                  // Define the minimum and maximum page numbers to display
                  const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                  const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                  if (pageNumber >= minPage && pageNumber <= maxPage) {
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                <li className="page-item">
                  <button className="page-link" onClick={nextPage}>
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <h4>No data available</h4>
      )}

      {/* <div className="table-footer d-md-flex align-items-center justify-content-md-between "> */}
      {/* <p className='mb-0'>Showing 1 to 5 of <span id='total-entries'>57</span>  entries</p>
                <ul className="pagination  pagination-md">
                    <li className="page-item"><Link className="page-link" aria-label="Previous">&laquo;</Link></li>
                    <li className="page-item"><Link className="page-link" >1</Link></li>
                    <li className="page-item active"><Link className="page-link " href="#">2</Link></li>
                    <li className="page-item"><Link className="page-link" >3</Link></li>
                    <li className="page-item"><Link className="page-link" >4</Link></li>
                    <li className="page-item"><Link className="page-link" >5</Link></li>
                    <li className="page-item"><Link className="page-link" >6</Link></li>
                    <li className="page-item"><Link className="page-link" >7</Link></li>
                    <li className="page-item"><Link className="page-link" >8</Link></li>
                    <li className="page-item"><Link className="page-link" >9</Link></li>
                    <li className="page-item"><Link className="page-link" aria-label="Next">&raquo;</Link></li>
                </ul> */}

      {/* <nav>
        <ul className="pagination justify-content-center">
          <li className="page-item">
            <button
              className="page-link"
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          </li>
          {pageNumbers.map((pageNumber) => {
            // Define the minimum and maximum page numbers to display
            const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
            const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

            if (pageNumber >= minPage && pageNumber <= maxPage) {
                return (
                    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                        <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>
                            {pageNumber}
                        </button>
                    </li>
                );
            }
            return null;
        })}

          <li className="page-item">
            <button className="page-link" onClick={nextPage}>
              Next
            </button>
          </li>
          </ul>
      </nav> */}
      {/* {pageNumbers.map(pgNumber => (
                        <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}>
                            <button className='page-link' onClick={() => setCurrentPage(pgNumber)}>
                                {pgNumber}
                            </button>
                        </li>
                    ))} */}
      {/* {pageNumbers.map((pageNumber) => (
            <li
              key={pageNumber}
              className={`page-item ${
                currentPage === pageNumber ? "active" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(pageNumber)}
              >
                {pageNumber}
              </button>
            </li>
          ))} */}

      {/* disabled={currentPage === nPages} */}
    </div>
    // </div>
  );
}
