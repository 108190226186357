import React from 'react'
import AddAmenitiesList from './data/AddAmenityList'


export default function AddAmenities() {
  return (
    <div>
      <div className='card p-3'>

        <div className='table-body'>
          <AddAmenitiesList />
        </div>

      </div>

    </div>
  )
}
