import React from "react";
import SocietyHeader from "../../components/societyHeader";
import logo1 from "../../assets/images/hamptom.png";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom/cjs/react-router-dom";
import ManagementList from "./component/Table";
import SocietyHeaderView from "../../components/societyHeaderView";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function AllManagement(props) {
  const { id } = useParams();
  const {society, image} = JSON.parse(localStorage.getItem("society_info"))

  const home = { icon: "pi pi-home" };

  const items = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "Management List" },
  ];

  return (
    <>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
      </div>
      <div className="header">
        <SocietyHeaderView
          imageUrl={decodeURIComponent(image)}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>
      <div className="card p-3">
        <div className="table-body">
          <ManagementList id={id} image={image} society={society} />
        </div>
      </div>
    </>
  );
}
