import React, { useState } from "react";
import user from "../../../assets/images/avatar.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Sidebar } from "primereact/sidebar";
import { MdDeleteOutline, MdOutlineRemoveRedEye } from "react-icons/md";
import moment from "moment";

const TableReportedComments = ({ post, id, onPageChange, onSearch, society, image }) => {
  const [visibleRight, setVisibleRight] = useState(false);
  const [page, setPage] = useState(1);
  const totalCount = post?.totalCount ?? 0;
  const totalPages = Math.ceil(Number(totalCount) / 5);

  console.log("count" + totalCount)

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber)
    onPageChange(pageNumber);
  };

  const handleSearch = (e) => {
    onSearch(e.target.value);
  };


  return (
    <>
      <Sidebar
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h3>Right Sidebar</h3>
      </Sidebar>

      <div className="table_header mb-3">
        <div className="d-flex flex-wrap align-items-center">
          <h4 className="mb-0 fw-semi-bold fs-5"> Reported Comments List</h4>
          <div className="ms-auto d-flex align-items-center gap-2">
            {/* <select class="form-select" aria-label="Default select example">
              <option selected>All Post</option>
              <option value="1">Active</option>
              <option value="2">Removed</option>
            </select> */}
            <div class="search_box ">
              <input placeholder="Search" class="chakra-input" onChange={(e) => handleSearch(e)} />
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">COMMENT TEXT</th>
              <th scope="col">AUTHOR</th>
              <th scope="col">COMMENT CREATED</th>
              <th scope="col">STATUS</th>
              <th scope="col">REPORTERS</th>
              <th scope="col">REMOVAL REASON</th>
              <th scope="col">DATE REPORTED</th>
              <th scope="col">REMOVED BY</th>
              <th scope="col">DATE REMOVAL</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {post?.data?.map((el, i) => (
              <tr key={i}>
                <td>{(page - 1) * 5 + i + 1}</td>
                <td>{el?.comment_id?.comment}</td>
                <td>{`${el?.comment_id?.user_id?.first_name} ${el?.comment_id?.user_id?.last_name}`}</td>
                <td>{moment(el?.comment_id?.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                <td>{el?.status}</td>
                <td>
                  {/* <a href=" " className="text-decoration-none">
                    No of User <span className="badge bg-secondary">10</span>
                  </a> */}
                  No of User <span className="badge bg-secondary">{el?.reportCount ?? 0}</span>
                </td>
                <td>{el?.reason}</td>
                <td>{moment(el?.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                <td>-</td>
                <td>{moment(el?.updatedAt).format('YYYY-MM-DD HH:mm')}</td>
                <td>
                  <div className="action_btn">
                    <Link to={`/view-post/reported_${el?.comment_id?._id}/${id}`}>
                      <MdOutlineRemoveRedEye />
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="table-footer d-md-flex justify-content-md-between align-items-center">
        <p className="mb-0">
          Showing {totalCount > 0 ? 1 : 0} to {Math.min(10, totalCount)}  of{" "} {totalCount} entries
        </p>
        <ul className="pagination pagination-md">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
            <li key={pageNumber} className={`page-item ${pageNumber === page ? "active" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default TableReportedComments;
