import React from "react";
import { Link } from "react-router-dom";
import { CgArrowLeft } from "react-icons/cg";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import "primereact/resources/primereact.min.css";
import { useState, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import { CgSearch } from "react-icons/cg";
import { useReducer } from "react";
import { Toast } from "primereact/toast";
// import 'primereact/resources/themes/saga-green/theme.css';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import BankDetails from "./Dialog/BankDetails";

const SocietyHeader = ({ societyName, imageUrl, id, defaultRouter }) => {
  const myToast = useRef(null);

  const showToast = (severityValue, summaryValue, detailValue) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };
  const [visible, setVisible] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [toastSeverity, setToastSeverity] = useState("");
  const [toastSummary, setToastSummary] = useState("");
  const [toastDetail, setToastDetail] = useState("");
  // --fil-Upload-
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const token = localStorage.getItem("token");
  const [file, setFile] = useState();
  const [file2, setFile2] = useState();

  const [selectedFile, setSelectedFile] = useState(null);

  // State to control the visibility of the button
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  // Function to hide the button when the other button is clicked
  const handleHideButtonClick = () => {
    setIsButtonVisible(false);
  };
  // const showToast = (severity, summary, detail) => {
  //   setToastSeverity(severity);
  //   setToastSummary(summary);
  //   setToastDetail(detail);
  //   setToastVisible(true);
  // };
  const handleButton = () => {
    // showToast('success', 'Operation Successful', 'Data has been saved successfully.');
    showToast(
      "success",
      "Success Message",
      "The task was executed successfully."
    );
  };

  // Function to handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile2(event.target.files[0]);
    // Get the first file from the selected files array
    setSelectedFile(file); // Update the state with the selected file
    setIsDisabled(!isDisabled);
    setIsButtonVisible(true);
  };
  const clearSelectedFile = () => {
    console.log(file2);
    setSelectedFile(null); // Reset the state to null
    setIsDisabled(!isDisabled);
    setIsButtonVisible(false);
  };
  const [isDisabled, setIsDisabled] = useState(true); // Initially disabled

  const handleButtonClick = () => {
    // Enable/disable the button
    setIsDisabled(!isDisabled);
  };

  const onUpload = (event) => {
    setUploadedFiles(event.files);
  };

  // --Dialog--

  const [displayResponsive, setDisplayResponsive] = useState(false);

  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  // const showSuccessToast = (summary, detail) => {
  //   toast.current.show({ severity: 'success', summary: summary, detail: detail });
  // };
  // const renderFooter = (name) => {
  //   return (
  //     <div>
  //       <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
  //       <Button label="Yes" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
  //     </div>
  //   );
  // }

  const [selectedOption, setSelectedOption] = useState(null);
  const [posts, setPosts] = useState([]);
  const fileHandler = (event) => {
    console.log(token);
    console.log(id);
    const formData = new FormData();
    formData.append("_id", id);
    formData.append("csvFile", event.target.files[0]);

    // const config = {
    //   headers: {
    //     "content-type": "multipart/form-data",
    //     'Authorization': 'Bearer '+ token
    //   },
    // };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_API}uploadappartment`,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
      data: formData,
    })
      .then(function (response) {
        //handle success
        console.log(response);
        // toast.current.show({ severity: 'success', summary: 'Upload Successful', detail: 'CSV file uploaded successfully' });
        //  showSuccessToast('Upload Successful', 'CSV file uploaded successfully');
        handleButton();
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  // const [file, setFile] = useState()

  function handleChange(event) {
    setFile2(event.target.files[0]);
    setUploadedFiles(event.files);
  }

  function handleSubmit() {
    // event.preventDefault();
    const url = `${process.env.REACT_APP_BASE_API}uploadappartment`;
    const formData = new FormData();
    formData.append("society_id", id);
    formData.append("apartment_csv", file2);
    // formData.append('fileName', file.name);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response.data);
        // alert("file Uploaded success!");
        setSelectedFile(null); // Reset the state to null
        setIsDisabled(!isDisabled);
        setIsButtonVisible(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "CSV Upload Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        onHide("displayResponsive");
      })
      .catch((error) => {
        //handle error
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Duplicate CSV data or wings not exist or check Apartment Type or network error",
        });
        onHide("displayResponsive");
      });
  }

  const renderFooter = (name) => {
    return (
      <div>
        {/* <Button
          label="No"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => onHide(name)}
          autoFocus
        /> */}
        <Button
          label="Close"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          autoFocus
          className="p-button-text"
        />
      </div>
    );
  };

  function show() {
    console.log("working");
  }

  return (
    <div className="card p-3 mb-3">
      <div className="row align-items-center">
        <div className="col-md-2">
          <div className="card-images society_logo-img d-flex justify-content-md-start justify-content-center">
            <img className="img-fluid" src={imageUrl} alt="society logo" />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-md-flex align-items-center justify-content-center gap-5">
            <div className="card-title my-3 my-md-0 fs-4 text-decoration-underline fw-semibold text-center">
              {societyName}
            </div>
          </div>
        </div>
        {/* <div className="col-md-2">
          <BankDetails />
        </div> */}

        <div className="col-md-3">
          <Button
            label="Upload Apartment"
            className="p-button-sm p-button-rounded"
            icon="pi pi-external-link"
            onClick={() => onClick("displayResponsive")}
          />

          {/* <Toast visible={toastVisible} severity={toastSeverity} summary={toastSummary} detail={toastDetail} /> */}
          <Dialog
            header="Upload Apartment"
            visible={displayResponsive}
            onHide={() => onHide("displayResponsive")}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
            footer={renderFooter("displayResponsive")}
          >
            <div>
              {/* <a href="" className='btn btn-sm btn-primary d-block ms-auto mb-2' style={{width: '30%'}} download>Download Society Import Sample CSV</a> */}
              {/* <FileUpload
                name="doc"
                url={`${process.env.REACT_APP_BASE_API}uploadappartment`}
                onUpload={onUpload}
                onClick={fileHandler}
                accept="pdf/*"
                maxFileSize={1000000}  // 1 MB
              />        
                <div>
                {uploadedFiles.length > 0 && (
                  <div>
                    <ul>
                      {uploadedFiles.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div> */}

              {/* <form onSubmit={handleSubmit}> */}
              {/* <h3> File Upload</h3> */}

              <a
                href={`${process.env.REACT_APP_BASE_IMAGE_URL}r-community-rest-apis/public/apartment-sample-csv.csv`}
                className="btn btn-sm btn-primary d-block ms-auto mb-2"
                style={{ width: "50%" }}
                download
              >
                Download Apartment Import Sample CSV
              </a>

              <div className="p-fileupload p-fileupload-advanced p-component">
                <div className="p-fileupload-buttonbar">
                  <label
                    for="upload-apartment"
                    className="p-button p-fileupload-choose p-component "
                    tabIndex={0}
                  >
                    {!selectedFile && ( // Render the file input if no file is selected
                      <input
                        className="upload-apartment-csv"
                        type="file"
                        name="apartment_csv"
                        accept=".csv"
                        // className='btn btn-primary rounded-5 text-capitalize'
                        // onChange={handleChange}
                        id="upload-apartment"
                        onChange={handleFileChange}
                      />
                    )}

                    <span className="p-button-icon p-button-icon-left p-clickable pi pi-fw pi-plus"></span>
                    <span className="p-button-label p-clickable">choose</span>
                  </label>
                  <button
                    // className="p-button p-component p-disabled"
                    className="p-button p-component"
                    disabled={isDisabled}
                    onClick={handleSubmit}
                  >
                    <span className="p-button-icon p-c p-button-icon-left pi pi-upload"></span>
                    <span className="p-button-label p-c">Upload</span>
                  </button>
                  <button
                    type="button"
                    // className="p-button p-component p-disabled"
                    className="p-button p-component"
                    disabled={isDisabled}
                    onClick={clearSelectedFile}
                  >
                    <span className="p-button-icon p-c p-button-icon-left pi pi-times"></span>
                    <span className="p-button-label p-c">Cancel</span>
                  </button>
                </div>
                <div className="p-fileupload-content">
                  <div className="p-fileupload-files">
                    <div className="p-fileupload-row">
                      {/* <div className="p-fileupload-filename">appartment2.csv</div>
                        <div>154 B</div> */}
                      <div className="p-fileupload-filename">
                        {selectedFile && <p> {selectedFile.name}</p>}
                      </div>

                      <div>
                        {isButtonVisible && (
                          <button
                            type="button"
                            className="p-button p-component p-button-icon-only"
                            onClick={clearSelectedFile}
                          >
                            <span className="p-button-icon p-c pi pi-times"></span>
                            <span className="p-button-label p-c">&nbsp;</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </form> */}
            </div>
          </Dialog>
        </div>

        <div className="col-md-3">
          <div className="text-md-end text-center">
            <Link
              to={defaultRouter}
              className="btn btn-primary rounded-5 text-capitalize"
            >
              <CgArrowLeft /> Back to all Societies
            </Link>
          </div>
        </div>
      </div>
      {/* <Toast ref={toast} /> */}
      <Toast ref={myToast} />
    </div>
  );
};

export default SocietyHeader;
