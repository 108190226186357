import React, { useState, useEffect } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { MdOutlineEdit } from "react-icons/md";
import { Sidebar } from "primereact/sidebar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { classNames } from "primereact/utils";
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "./Loading";
import { LazyLoadImage } from "react-lazy-load-image-component";

// ==Assets==

import logo1 from "../../../assets/images/gym.jpg";
import logo2 from "../../../assets/images/pool.jpg";
import logo3 from "../../../assets/images/gym.jpg";
import logo4 from "../../../assets/images/park.jpg";
import logo5 from "../../../assets/images/tennis-court.jpg";

const imgPreview = {
  height: "100px",
  display: "flex",
  justifyContent: "center",
  border: "1px dashed #b5b5b5",
  width: "100%",
  padding: "5px",
};

const AddAmenitiesList = () => {
  const [visibleRight, setVisibleRight] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setdata] = useState([]);

  const [IDE, setID] = useState("");
  const [editname, setname] = useState("");

  const [editimage, setimage] = useState(null);

  const [showimage, setshowimage] = useState("");
  const [check, setCheck] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState(true);
  const [newImagePreview, setNewImagePreview] = useState(null);

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  //

  const [isLoading, setIsLoading] = useState(true);

  const token = localStorage.getItem("token");
  const [totalItems, setTotalItems] = useState(0);
  //    const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(10);

  //     function datafetch(){
  //                 // data fetching here
  //                 let config = {
  //                     method: 'get',
  //                     maxBodyLength: Infinity,
  //                     url: `${process.env.REACT_APP_BASE_API}getAmenty/`,

  //                     headers: { }
  //                   };
  //                   axios.request(config)
  //                   .then((response) => {
  //                     // console.log(JSON.stringify(response.data));
  //                     console.log(response.data);
  //                     setdata(response.data);
  //                     setIsLoading(false);
  //                   })
  //                   .catch((error) => {
  //                     console.log(error);
  //                   });
  //     }

  //     useEffect(() => {
  //          datafetch();
  //     }, []);

  //     //pagination
  //     // const array= Object.keys(data);
  //     // console.log(array)
  //     const val=data.data;
  //     // console.log(val);

  //     const array=[];
  //     for(let key in val) {
  //         array.push(val[key]);
  //     }
  //     console.log(array);
  //     const indexOfLastRecord = currentPage * recordsPerPage;
  //     const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  //     const currentRecords = array.slice(indexOfFirstRecord, indexOfLastRecord);
  //     const nPages = Math.ceil(array.length / recordsPerPage)

  //     const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

  //     const goToNextPage = () => {
  //             if(currentPage !== nPages) setCurrentPage(currentPage + 1)
  //     }
  //     const goToPrevPage = () => {
  //         if(currentPage !== 1) setCurrentPage(currentPage - 1)
  //     }
  // //
  useEffect(() => {
    datafetch();
  }, [currentPage]); // Fetch data whenever currentPage changes

  const datafetch = () => {
    setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getAmenty`,
      headers: { Authorization: "Bearer " + token },
      params: { limit, offset },
    };

    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.count);
        console.log(response.data);
        setdata(response.data);
        setIsLoading(false);
        console.log(response.data.data.length);
        setentry(response.data.data.length + offset);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred while fetching data from the server!",
        });
      });
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // //Addcharge validation
  const validationSchema = Yup.object().shape({
    image: Yup.mixed()
      .required("please select an image")
      .test("fileType", "invalid file type", (value) => {
        if (value) {
          const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"];
          return allowedFileTypes.includes(value.type);
        }
        return true; // Allow empty files
      })
      .test("fileSize", "file size is too large", (value) => {
        if (value) {
          const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
          return value.size <= maxSizeInBytes;
        }
        return true; // Allow empty files
      }),
    amenity: Yup.string().required("Amenity name is required"),
  });

  const formik = useFormik({
    initialValues: {
      image: "",
      amenity: "",
    },
    validationSchema: validationSchema,
  });

  const handleOnClick = () => {
    console.log("working");
    formik.setTouched(
      Object.keys(formik.values).reduce((acc, fieldName) => {
        acc[fieldName] = true;
        return acc;
      }, {})
    );
    formik.validateForm().then((errors) => {
      // Check if there are any validation errors
      console.log("errors", errors);
      if (Object.keys(errors).length === 0) {
        // If no errors, proceed to the next step
      }
      console.log("values", formik.values);
      const payload = formik.values;

      console.log("payload", payload);
      let formData = new FormData();

      // console.log(" Object.keys(formik.values)" , )
      // Object.keys(payload).forEach((element => {
      //     formData.append("element.image", payload[element].image);
      //     console.log("element" , element)
      //     console.log("payload[element]" , payload[element])
      // }))

      formData.append("image", payload.image);
      formData.append("amenity", payload.amenity);

      console.log("formData", formData);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      axios
        .post(`${process.env.REACT_APP_BASE_API}addAmenty`, formData, config)
        .then((response) => {
          console.log(response);
          formik.setValues({
            image: "",
            amenity: "",
          });
          setVisibleRight(false);
          datafetch();
          Swal.fire({
            position: "Center",
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          console.log(error);
          setVisibleRight(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "An error occurred while saving data to the server!",
          });
        });
    });
  };

  const handleUploadButtonClick = () => {
    // Logic to handle upload button click
    setShowImagePreview(false); // Hide the image preview when upload button is clicked
  };

  function handleChangeImage(event) {
    formik.setFieldTouched("image", true);
    formik.setFieldValue("image", event.currentTarget.files[0]);
  }

  function handleChangeEditImage(event) {
    handleUploadButtonClick();
    editformik.setFieldTouched("editimage", true);
    editformik.setFieldValue("editimage", event.currentTarget.files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      setNewImagePreview(reader.result);
    };
    reader.readAsDataURL(event.currentTarget.files[0]);

    setCheck(true);
  }
  function handleEditImage(e) {
    console.log(e.target.files);
    setimage(e.target.files[0]);
  }

  const deleteconfirm = (ID) => {
    console.log("delete");
    console.log(ID);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_API}delete/Amenity/` + ID,

          headers: { "Content-Type": "application/json" },
        };
        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            datafetch();
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "An error occurred while saving data to the server!",
            });
          });
      }
    });
  };

  // function handleOnEdit(){
  //     console.log(editname);
  //     console.log(editimage);

  //     console.log(editformik.values)
  // editformik.validateForm().then((errors) => {
  //     // Check if there are any validation errors
  //     if (Object.keys(errors).length === 0) {
  //     let formData = new FormData();

  //     formData.append('_id', IDE,);
  //     formData.append('image', editimage);
  //     formData.append('amenity', editformik.values.editname);

  //     console.log("formData", formData)

  //     axios.patch(`${process.env.REACT_APP_BASE_API}/updateAmenity`, formData
  //     // {
  //     //     _id:IDE,
  //     // amenity:editname,
  //     //     image:editimage,
  //     //   }
  //       )
  //         .then(response => {console.log(response.data)
  //         //   window.location.reload(false);
  //             setVisible(false);
  //             datafetch();
  //         })
  //         .catch(error => console.error(error));
  //     }
  // })
  // }
  function handleOnEdit() {
    console.log(editname);
    console.log(editimage);

    // console.log(editformik.values);
    console.log(editformik.errors);

    editformik.validateForm().then((errors) => {
      // Check if there are any validation errors
      if (Object.keys(errors).length === 0) {
        setVisible(false);
        // Use SweetAlert for confirmation

        Swal.fire({
          title: "Do you want to save the changes?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Save",
          denyButtonText: "Don't save",
        }).then((result) => {
          // Handle user choice
          if (result.isConfirmed) {
            // User confirmed, proceed with the update
            let formData = new FormData();
            formData.append("_id", IDE);
            formData.append("image", editformik.values.editimage);
            formData.append("amenity", editformik.values.editname);

            console.log("formData", formData);

            axios
              .patch(
                `${process.env.REACT_APP_BASE_API}/updateAmenity`,
                formData
              )
              .then((response) => {
                console.log(response.data);
                setVisible(false);
                editformik.resetForm();
                setNewImagePreview(null);
                datafetch();
                Swal.fire("Saved!", "", "success");
              })
              .catch((error) => {
                console.error(error);
                Swal.fire("Error", "Failed to save changes", "error");
              });
          } else if (result.isDenied) {
            // User denied, do nothing or show a message
            datafetch();
            Swal.fire("Changes are not saved", "", "info");
          }
        });
      }
    });
  }

  const editPost = (val) => {
    console.log(val);
    console.log(val.image);
    setID(val.id);
    setimage(val.image);
    setname(val.amenity);
    setshowimage(val.image);
    editformik.setValues({
      editname: val.amenity,
      editimage: val.image,
    });
    setVisible(true);
  };

  const handleChange = (e) => {
    setimage(e.target.files[0]);
  };

  //Define Yup schema for edit form validation
  const editValidationSchema = Yup.object().shape({
    editname: Yup.string().required("Amenity name is required"),
    editimage: Yup.mixed()
      .required("please select an image"),
      // .test("fileType", "invalid file type", (value) => {
      //   if (value) {
      //     const allowedFileTypes = ["image/jpeg", "image/png"];
      //     return allowedFileTypes.includes(value.type);
      //   }
     
      //   return true; 
      // })
      // .test("fileSize", "file size is too large", (value) => {
      //   if (value) {
      //     const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
      //     return value.size <= maxSizeInBytes;
      //   }

      
      //   return true;
      // }),
  });

  // Initialize Formik for edit form
  const editformik = useFormik({
    initialValues: {
      editname: editname,
      editimage: editimage,
    },
    validationSchema: editValidationSchema,
    onSubmit: handleOnEdit,
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Sidebar
        className="right-sidebar"
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h5>Add New Amenity</h5>
        <div className="sidebar-main">
          <form>
            <div className="mb-3 text-center">
              <div className="mb-2" style={imgPreview}>
                <img
                  src={
                    formik.values.image
                      ? URL.createObjectURL(formik.values.image)
                      : logo1
                  }
                  style={{ backgroundSize: "cover" }}
                  alt="delivery partner logo"
                  className="img-fluid"
                />
              </div>
              {Boolean(formik.touched.image && formik.errors.image) && (
                <small className="p-error">{formik.errors.image}</small>
              )}
              <br />
              <label
                className="btn btn-sm btn-primary  rounded-4"
                htmlFor="upload-image"
              >
                upload
              </label>
              <input
                type="file"
                id="upload-image"
                style={{ display: "none" }}
                onChange={handleChangeImage}
              />
            </div>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Amenity Name
              </label>
              <input
                type="text"
                autoComplete="off"
                class="form-control"
                id="chargeName"
                name="amenity"
                placeholder="Enter Amenity name"
                className={classNames(
                  {
                    "input-invalid": Boolean(
                      formik.touched.amenity && formik.errors.amenity
                    ),
                  },
                  "form-control"
                )}
                {...formik.getFieldProps("amenity")}
              />

              {Boolean(formik.touched.amenity && formik.errors.amenity) && (
                <small className="p-error">{formik.errors.amenity}</small>
              )}
            </div>
          </form>
        </div>

        <div className="d-flex footer gap-2 align-items-center">
          <button
            type="submit"
            class="btn btn-primary rounded-4"
            onClick={handleOnClick}
          >
            Submit
          </button>
        </div>
      </Sidebar>

      <Sidebar
        className="right-sidebar"
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
      >
        <h5>Edit Amenity</h5>
        <div className="sidebar-main">
          <form onSubmit={editformik.handleSubmit}>
            <div className="mb-3 text-center">
              <div className="mb-2" style={imgPreview}>
                {/* <img src={editformik.values.editimage ? URL.createObjectURL(editformik.values.editimage) :logo2 } style={{ backgroundSize: 'cover' }} alt="delivery partner logo" className='img-fluid' /> */}

                {/* <img src={editformik.values.editimage } style={{ backgroundSize: 'cover' }} alt="delivery partner logo" className='img-fluid'   /> */}

                {newImagePreview ? (
                  <img
                    src={newImagePreview}
                    style={{ backgroundSize: "cover" }}
                    alt="Preview"
                    className="img-fluid"
                  />
                ) : (
                  <img
                    src={editformik.values.editimage}
                    style={{ backgroundSize: "cover" }}
                    alt="delivery partner logo"
                    className="img-fluid"
                  />
                )}
              </div>

              {Boolean(
                editformik.touched.editimage && editformik.errors.editimage
              ) && (
                <small className="p-error">{editformik.errors.editimage}</small>
              )}

              <br />
              <label
                className="btn btn-sm btn-primary  rounded-4"
                htmlFor="upload-image"
              >
                upload
              </label>
              <input
                type="file"
                id="upload-image"
                style={{ display: "none" }}
                onChange={handleChangeEditImage}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="chargeName" className="form-label">
                Name
              </label>
              <input
                type="text"
                id="chargeName"
                name="editname"
                placeholder="Enter Amenity name"
                className={classNames(
                  {
                    "input-invalid": Boolean(
                      editformik.touched.editname && editformik.errors.editname
                    ),
                  },
                  "form-control"
                )}
                // className={classNames(
                //     "form-control",
                //     { "border-red": editformik.touched.edittitle && editformik.errors.edittitle }
                // )}

                autoComplete="off"
                value={editformik.values.editname}
                onChange={editformik.handleChange}
                onBlur={editformik.handleBlur}
              />

              {editformik.touched.editname && editformik.errors.editname && (
                <small className="p-error">{editformik.errors.editname}</small>
              )}
            </div>
          </form>
        </div>

        <div className="d-flex footer gap-2 align-items-center">
          <button
            type="submit"
            class="btn btn-primary rounded-4"
            onClick={handleOnEdit}
          >
            Save
          </button>
        </div>
      </Sidebar>

      <div className="table_header mb-3">
        <div className="d-flex flex-wrap align-items-center">
          <h4 className="mb-0 fw-semi-bold fs-5">All Amenity List</h4>
          <div className="ms-auto d-flex align-items-center gap-2">
            <button
              onClick={() => setVisibleRight(true)}
              className="btn btn-sm btn-primary rounded-4"
            >
              Add New
            </button>
          </div>
        </div>
      </div>
      {data.data && data.data.length > 0 ? (
        <div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">IMAGE</th>
                  <th scope="col">NAME</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {/* {data.data && data.data.map((data, idx) => ( */}
                {/* {currentRecords && currentRecords.map((data, idx) => ( */}

                {data.data &&
                  data.data.map((data, idx) => (
                    <tr>
                      {/* <th scope="row">{idx + 1}</th> */}
                      <th scope="row">
                        {(currentPage - 1) * pageSize + idx + 1}
                      </th>

                      <td>
                        {/* <LazyLoadImage src={'https://betazone.promaticstechnologies.com/r-community-rest-apis/public/uploadcsv/'+data.image} style={{ height: 25, width: 25 }} /> */}
                        <LazyLoadImage
                          src={data.image}
                          style={{ height: 25, width: 25 }}
                        />
                        {/* <img src={'https://betazone.promaticstechnologies.com/r-community-rest-apis/public/uploadcsv/'+data.image} style={{ height: 25, width: 25 }} /> */}
                      </td>
                      <td>{data.amenity}</td>

                      <td>
                        <div className="action_btn">
                          <Link onClick={() => editPost(data)}>
                            <MdOutlineEdit />
                          </Link>
                          <Link onClick={() => deleteconfirm(data._id)}>
                            <MdDeleteOutline />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}

                {/* <tr>
                            <th scope="row">1</th>
                            <td><img src={logo1} alt="Partner 1 Logo" class="img-fluid" style={{maxWidth: '50px'}}/></td>
                            <td>Amazon</td>
                            <td><div className='action_btn'>

                                <Link onClick={() => setVisibleRight(true)}  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td><img src={logo2} alt="Partner 1 Logo" class="img-fluid" style={{ maxWidth: '50px' }} /></td>
                            <td>Flipkart</td>
                            <td><div className='action_btn'>

                                <Link onClick={() => setVisibleRight(true)} ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td><img src={logo3} alt="Partner 1 Logo" class="img-fluid" style={{ maxWidth: '50px' }} /></td>
                            <td>Swiggy</td>
                            <td><div className='action_btn'>

                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td><img src={logo4} alt="Partner 1 Logo" class="img-fluid" style={{ maxWidth: '50px' }} /></td>
                            <td>Zomato</td>
                            <td><div className='action_btn'>

                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td><img src={logo5} alt="Partner 1 Logo" class="img-fluid" style={{ maxWidth: '50px' }} /></td>
                            <td>Zepto</td>
                            <td><div className='action_btn'>

                                <Link  ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr> */}
              </tbody>
            </table>
          </div>

          <div className="table-footer d-md-flex justify-content-md-between align-items-center">
            <p className="mb-0">
              Showing {offsetentry + 1} to {entry} of{" "}
              <span id="total-entries">{totalItems}</span> entries
            </p>
            <nav>
              <ul className="pagination pagination-md">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>

                {pageNumbers.map((pageNumber) => {
                  // Define the minimum and maximum page numbers to display
                  const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                  const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                  if (pageNumber >= minPage && pageNumber <= maxPage) {
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                <li className="page-item">
                  <button className="page-link" onClick={nextPage}>
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <h4>No data available</h4>
      )}
      {/* <div className="table-footer d-md-flex justify-content-md-between align-items-center"> */}
      {/* <p className='mb-0'>Showing 1 to 10 of <span id='total-entries'>57</span>  entries</p>
                <ul class="pagination pagination-md ">
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Previous">&laquo;</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">1</Link></li>
                    <li class="page-item active"><Link class="page-link " href="#">2</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">3</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">4</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">5</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">6</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">7</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">8</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">9</Link></li>
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Next">&raquo;</Link></li>
                </ul> */}
      {/* </div> */}
      {/* pagination */}
      {/* <nav>
            <ul className='pagination justify-content-center'>
                <li className="page-item">
                    <a className="page-link" 
                        onClick={goToPrevPage} 
                        href='#'>
                        
                        Previous
                    </a>
                </li>
                {pageNumbers.map(pgNumber => (
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >

                        <a onClick={() => setCurrentPage(pgNumber)}  
                            className='page-link' 
                            href='#'>
                            
                            {pgNumber}
                        </a>
                    </li>
                ))}
                <li className="page-item">
                    <a className="page-link" 
                        onClick={goToNextPage}
                        href='#'>
                        
                        Next
                    </a>
                </li>
            </ul>
        </nav> */}

      {/* <nav>
                <ul className='pagination justify-content-center'>
                    <li className="page-item">
                        <button className="page-link" onClick={prevPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                    </li>
                    
                    {pageNumbers.map((pageNumber) => (
    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
      <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>
        {pageNumber}
      </button>
    </li>
  ))}
                
                    <li className="page-item">
                        <button className="page-link" onClick={nextPage}  disabled={currentPage === totalPages}>
                            Next
                        </button>
                    </li>

                   
                </ul>
            </nav> */}

      {/* {pageNumbers.map(pgNumber => (
                        <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}>
                            <button className='page-link' onClick={() => setCurrentPage(pgNumber)}>
                                {pgNumber}
                            </button>
                        </li>
                    ))} */}
      {/* pagination */}
      {/* disabled={currentPage === nPages} */}
    </>
  );
};

export default AddAmenitiesList;
