import React, { useState } from 'react';
import { MdDeleteOutline, MdRemoveRedEye, MdReply, } from "react-icons/md";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Sidebar } from 'primereact/sidebar';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { classNames } from "primereact/utils";


const ApartmentList = () => {
    const [visibleRight, setVisibleRight] = useState(false);


       // //Addcharge validation
       const validationSchema = Yup.object().shape({
        
        title: Yup.string().required("Title is required"),
        CarpetArea:Yup.string().required("Carpet area is required"),
        SuperBuiltup:Yup.number().required("Super built area is required"),
        Floor:Yup.string().required("Floor is required"),
        Maintenance:Yup.string().required("Maintenance charges is required"),
        GST:Yup.string().required("GST is required"),
        Property:Yup.string().required("Property tax is required"),

    });

    const formik = useFormik({
        initialValues: {
            title:"",
            CarpetArea: "",
            SuperBuiltup:"",
            Floor:"",
            Maintenance:"",
            GST:"",
            Property:"",
           
        },
        validationSchema : validationSchema
    });
    
    const handleOnClick = () => {
        console.log("working")
        formik.setTouched(
            Object.keys(formik.values).reduce((acc , fieldName) => {
                acc[fieldName] = true
                return acc
            },
            {}
            )
        )
        formik.validateForm().then((errors) => {
          // Check if there are any validation errors
          console.log("errors" , errors)
          if (Object.keys(errors).length === 0) {
            // If no errors, proceed to the next step
          }       
        console.log("valuesger" , formik.values)
        });
        
        
    };

    return (
        <>
            <Sidebar className='right-sidebar' visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                <h5>Add Apartment </h5>
                <div className="sidebar-main">
                    <form>
                        <div className="mb-3">
                            <label for="title" className="form-label">Title</label>
                            <input type="text" placeholder='Add Flat Name' name="title" id="title" className={classNames({
                                    "input-invalid": Boolean(formik.touched.title && formik.errors.title),
                                }, "form-control")}
                                {...formik.getFieldProps("title")}  />

              {Boolean(formik.touched.title && formik.errors.title) && (
                                <small className="p-error">{formik.errors.title}</small>
                            )}

                        </div>
                        <div className="mb-3">
                            <label for="CarpetArea" className="form-label">Carpet Area</label>
                            <input type="text" placeholder='Enter Carpet Area  1200 sq. ft. ' name="CarpetArea" id="CarpetArea" className={classNames({
                                    "input-invalid": Boolean(formik.touched.CarpetArea && formik.errors.CarpetArea),
                                }, "form-control")}
                                {...formik.getFieldProps("CarpetArea")}  />

              {Boolean(formik.touched.CarpetArea && formik.errors.CarpetArea) && (
                                <small className="p-error">{formik.errors.CarpetArea}</small>
                            )}
                        </div>
                        <div className="mb-3">
                            <label for="SuperBuiltup" className="form-label">Super Builtup Area</label>
                            <input type="text" placeholder='Super Builtup Area  1500 sq. ft. ' name="SuperBuiltup" id="SuperBuiltup" className={classNames({
                                    "input-invalid": Boolean(formik.touched.SuperBuiltup && formik.errors.SuperBuiltup),
                                }, "form-control")}
                                {...formik.getFieldProps("SuperBuiltup")}  />

              {Boolean(formik.touched.SuperBuiltup && formik.errors.SuperBuiltup) && (
                                <small className="p-error">{formik.errors.SuperBuiltup}</small>
                            )}
                        </div>
                        <div className="mb-3">
                            <label for="Floor" className="form-label">Floor</label>
                            <input type="text" placeholder='Floor ' name="Floor" id="Floor" className={classNames({
                                    "input-invalid": Boolean(formik.touched.Floor && formik.errors.Floor),
                                }, "form-control")}
                                {...formik.getFieldProps("Floor")}  />

              {Boolean(formik.touched.Floor && formik.errors.Floor) && (
                                <small className="p-error">{formik.errors.Floor}</small>
                            )}
                        </div>
                        <div className="mb-3">
                            <label for="Maintenance" className="form-label">Maintenance Charges</label>
                            <input type="text"  placeholder='Maintenance Charges' name="Maintenance" id="Maintenance"className={classNames({
                                    "input-invalid": Boolean(formik.touched.Maintenance && formik.errors.Maintenance),
                                }, "form-control")}
                                {...formik.getFieldProps("Maintenance")}  />

              {Boolean(formik.touched.Maintenance && formik.errors.Maintenance) && (
                                <small className="p-error">{formik.errors.Maintenance}</small>
                            )}
                        </div>
                        <div className="form-check form-switch mb-3">
                            <label className="form-check-label" for="flexSwitchCheckDefault">Include GST</label>
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                        </div>
                        <div className="mb-3">
                            <label for="GST" className="form-label">Enter GST </label>
                            <input type="text" placeholder='12% ' name="GST" id="GST" className={classNames({
                                    "input-invalid": Boolean(formik.touched.GST && formik.errors.GST),
                                }, "form-control")}
                                {...formik.getFieldProps("GST")}  />

              {Boolean(formik.touched.GST && formik.errors.GST) && (
                                <small className="p-error">{formik.errors.GST}</small>
                            )}
                        </div>

                        <div className="mb-3">
                            <label for="Property" className="form-label">Property Tax </label>
                            <input type="text" placeholder='500 ' name="Property" id="Property" className={classNames({
                                    "input-invalid": Boolean(formik.touched.Property && formik.errors.Property),
                                }, "form-control")}
                                {...formik.getFieldProps("Property")}  />

              {Boolean(formik.touched.Property && formik.errors.Property) && (
                                <small className="p-error">{formik.errors.Property}</small>
                            )}
                        </div>

                        
                    </form>
                </div>
                <div className='d-flex footer gap-2 align-items-center'>
                    <button type="submit" className="btn btn-primary rounded-4"   onClick={handleOnClick}>Submit</button>
                </div>
            </Sidebar>

            <div className='table_header mb-3'>
                <div className='d-flex flex-wrap align-items-center'>
                    <h4 className='mb-0 fw-semi-bold fs-5'>All Apartments List</h4>
                    <button onClick={() => setVisibleRight(true)} className='btn btn-primary rounded-4 ms-auto btn-sm'>Add New</button>
                </div>
            </div>
            <div className='table-responsive'>
                <table className="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">TITLE</th>
                            <th scope="col">CARPET AREA</th>
                            <th scope="col">SUPER BUILTUP AREA</th>
                            <th scope="col">FLOOR</th>
                            <th scope="col">MAINTENANCE CHARGES</th>
                            <th scope="col">GST INCLUDED</th>
                            <th scope="col">PROPERTY TAX</th>
                            <th scope="col">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Penthouse Apartment</td>
                            <td> 1200 sq. ft. </td>
                            <td>1500 sq. ft.</td>
                            <td>5th</td>
                            <td>&#x20B9; 200/Month</td>
                            <td>YES</td>
                            <td>&#x20B9; 500/Year</td>
                            <td><div className='action_btn'>
                                <Link  ><MdRemoveRedEye /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Ground Apartment</td>
                            <td> 1000 sq. ft. </td>
                            <td>1500 sq. ft.</td>
                            <td>2nd</td>
                            <td>&#x20B9; 200/Month</td>
                            <td>YES</td>
                            <td>&#x20B9; 500/Year</td>
                            <td><div className='action_btn'>
                                <Link  ><MdRemoveRedEye /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>

                        <tr>
                            <th scope="row">1</th>
                            <td>High Rise Apartment</td>
                            <td> 1200 sq. ft. </td>
                            <td>1500 sq. ft.</td>
                            <td>2nd</td>
                            <td>&#x20B9; 200/Month</td>
                            <td>YES</td>
                            <td>&#x20B9; 500/Year</td>
                            <td><div className='action_btn'>
                                <Link  ><MdRemoveRedEye /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>

                        

                    </tbody>

                </table>
            </div>
            <div className="table-footer d-md-flex justify-content-md-between align-items-center">
                <p className='mb-0'>Showing 1 to 10 of <span id='total-entries'>57</span>  entries</p>
                <ul className="pagination pagination-md ">
                    <li className="page-item"><Link className="page-link" href="#" aria-label="Previous">&laquo;</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">1</Link></li>
                    <li className="page-item active"><Link className="page-link " href="#">2</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">3</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">4</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">5</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">6</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">7</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">8</Link></li>
                    <li className="page-item"><Link className="page-link" href="#">9</Link></li>
                    <li className="page-item"><Link className="page-link" href="#" aria-label="Next">&raquo;</Link></li>
                </ul>
            </div>

        </>


    );
};

export default ApartmentList;
