import React, { useState, useEffect } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { MdOutlineEdit } from "react-icons/md";
import { Sidebar } from "primereact/sidebar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { classNames } from "primereact/utils";
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "./Loading";

import { Toast } from "primereact/toast";

function ManageApartmentType() {
  const [visibleRight, setVisibleRight] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setdata] = useState([]);

  const [IDE, setID] = useState("");
  const [edittitle, settitle] = useState("");
  const [editarea, setarea] = useState("");
  const [editsuper, setsuper] = useState("");
  const [editconfig, setconfig] = useState("");
  const [editfloor, setfloor] = useState("");

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  //

  const token = localStorage.getItem("token");
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(10);
  //   useEffect(() => {
  //     datafetch();
  // }, [currentPage]);

  // const datafetch = async () => {
  //     setIsLoading(true);
  //     const offset = (currentPage - 1) * recordsPerPage;
  //     try {
  //         const response = await axios.get(`${process.env.REACT_APP_BASE_API}getTypeOfAppartment?offset=${offset}&limit=${recordsPerPage}`);
  //         setdata(response.data);
  //         setIsLoading(false);
  //     } catch (error) {
  //         console.error('Error fetching data:', error);
  //         setIsLoading(false);
  //     }
  // };

  useEffect(() => {
    datafetch();
  }, [currentPage]); // Fetch data whenever currentPage changes

  const datafetch = () => {
    setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getTypeOfAppartment`,
      headers: { Authorization: "Bearer " + token },
      params: { limit, offset },
    };

    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.count);
        console.log(response.data);
        setdata(response.data);
        setIsLoading(false);
        console.log(response.data.data.length);
        setentry(response.data.data.length + offset);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred while fetching data from the server!",
        });
      });
  };

  //     function datafetch(){
  //                 // data fetching here
  //                 let config = {
  //                     method: 'post',
  //                     maxBodyLength: Infinity,
  //                     url: `${process.env.REACT_APP_BASE_API}getTypeOfAppartment/`,
  //                     headers: { }
  //                   };
  //                   axios.request(config)
  //                   .then((response) => {
  //                     // console.log(JSON.stringify(response.data));
  //                     console.log(response.data);
  //                     setdata(response.data);
  //                     setIsLoading(false);
  //                   })
  //                   .catch((error) => {
  //                     console.log(error);
  //                   });
  //     }

  //     useEffect(() => {
  //          datafetch();
  //     }, []);

  //pagination
  // const array= Object.keys(data);
  // console.log(array)
  // const val=data.data;
  // // console.log(val);

  // const array=[];
  // for(let key in val) {
  //     array.push(val[key]);
  // }

  // console.log(array);
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = array.slice(indexOfFirstRecord, indexOfLastRecord);
  // const nPages = Math.ceil(array.length / recordsPerPage)

  // const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

  // const goToNextPage = () => {
  //         if(currentPage !== nPages) setCurrentPage(currentPage + 1)
  // }
  // const goToPrevPage = () => {
  //     if(currentPage !== 1) setCurrentPage(currentPage - 1)
  // }
  //

  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  //

  // validation
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    carpet_area: Yup.number().required("carpet area is required"),
    super_builtup_area: Yup.number().required("super built area is required"),
    configuration: Yup.string().required("Type is required"),
    floor: Yup.string().required("floor is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      carpet_area: "",
      super_builtup_area: "",
      configuration: "",
      floor: "",
    },
    validationSchema: validationSchema,
  });

  const handleOnClick = () => {
    console.log("working");
    formik.setTouched(
      Object.keys(formik.values).reduce((acc, fieldName) => {
        acc[fieldName] = true;
        return acc;
      }, {})
    );
    formik.validateForm().then((errors) => {
      // Check if there are any validation errors
      console.log("errors", errors);
      if (Object.keys(errors).length === 0) {
        // If no errors, proceed to the next step
        console.log("values", formik.values);
        const payload = formik.values;
        axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_API}Appartment/Type`,
          data: payload, // you are sending body instead
          headers: {
            //  'Authorization': `bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            console.log(response);

            formik.setValues({
              title: "",
              carpet_area: "",
              super_builtup_area: "",
              configuration: "",
              floor: "",
            });
            setVisibleRight(false);
            Swal.fire({
              position: "Center",
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });
            datafetch();
          })
          .catch((error) => {
            console.log("error", error);
            setVisibleRight(false);
            formik.setValues({
              title: "",
              carpet_area: "",
              super_builtup_area: "",
              configuration: "",
              floor: "",
            });
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    });
  };

  const deletePost = (ID) => {
    console.log("delete");
    console.log(ID);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_API}delete/TypeOfAppartment/` + ID,
          headers: { "Content-Type": "application/json" },
        };
        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            datafetch();
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    });
  };

  // function handleOnEdit(){
  //     // console.log(edittitle);
  //     // console.log(editarea);
  //     // console.log(editsuper);
  //     // console.log(editconfig);
  //     // console.log(editfloor);
  //     console.log(editformik.values.edittitle)
  //     editformik.validateForm().then((errors) => {
  //         // Check if there are any validation errors
  //         if (Object.keys(errors).length === 0) {
  //     axios.patch(`${process.env.REACT_APP_BASE_API}editTypeOfAppartment`, {
  //         _id:IDE,
  //         title:editformik.values.edittitle,
  //         carpet_area:editformik.values.editarea,
  //         super_builtup_area:editformik.values.editsuper,
  //         configuration:editformik.values.editconfig,
  //         floor:editformik.values.editfloor,
  //       })
  //         .then(response => {console.log(response.data)
  //             setIsLoading(false);
  //             setVisible(false);
  //             datafetch();
  //         })
  //         .catch(error => {console.error(error)
  //             setIsLoading(false);
  //         });

  //     }
  // });
  // }
  function handleOnEdit() {
    editformik.validateForm().then((errors) => {
      // Check if there are any validation errors
      if (Object.keys(errors).length === 0) {
        setVisible(false);
        Swal.fire({
          title: "Do you want to save the changes?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Save",
          denyButtonText: `Don't save`,
        }).then((result) => {
          if (result.isConfirmed) {
            axios
              .patch(`${process.env.REACT_APP_BASE_API}editTypeOfAppartment`, {
                _id: IDE,
                title: editformik.values.edittitle,
                carpet_area: editformik.values.editarea,
                super_builtup_area: editformik.values.editsuper,
                configuration: editformik.values.editconfig,
                floor: editformik.values.editfloor,
              })
              .then((response) => {
                console.log(response.data);
                setIsLoading(false);
                setVisible(false);
                Swal.fire("Saved!", "", "success");
                datafetch();
              })
              .catch((error) => {
                console.error(error);
                setIsLoading(true);
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                });
              });
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
            datafetch();
            setVisible(false);
          }
        });
      }
    });
  }

  const editPost = (val) => {
    console.log(val);
    setID(val.id);
    settitle(val.title);
    setarea(val.carpet_area);
    setsuper(val.super_builtup_area);
    setconfig(val.configuration);
    setfloor(val.floor);
    // setVisible(true);
    editformik.setValues({
      edittitle: val.title,
      editarea: val.carpet_area,
      editsuper: val.super_builtup_area,
      editconfig: val.configuration,
      editfloor: val.floor,
    });
    setVisible(true);
  };

  // Define Yup schema for edit form validation
  const editValidationSchema = Yup.object().shape({
    edittitle: Yup.string().required("Title is required"),
    editarea: Yup.number().required("Carpet area is required"),
    editsuper: Yup.number().required("Super built area is required"),
    editconfig: Yup.string().required("Type is required"),
    editfloor: Yup.string().required("Floor is required"),
  });

  // Initialize Formik for edit form
  const editformik = useFormik({
    initialValues: {
      edittitle: edittitle,
      editarea: editarea,
      editsuper: editsuper,
      editconfig: editconfig,
      editfloor: editfloor,
    },
    validationSchema: editValidationSchema,
    onSubmit: handleOnEdit, // assuming handleOnEdit is your submit handler function
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Sidebar
        className="right-sidebar"
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h5>Add Apartment Type</h5>
        <div class="sidebar-main">
          <form>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Title
              </label>
              <input
                type="text"
                id="chargeName"
                name="title"
                placeholder="Add flat name"
                className={classNames(
                  {
                    "input-invalid": Boolean(
                      formik.touched.title && formik.errors.title
                    ),
                  },
                  "form-control"
                )}
                {...formik.getFieldProps("title")}
              />

              {Boolean(formik.touched.title && formik.errors.title) && (
                <small className="p-error">{formik.errors.title}</small>
              )}
            </div>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Carpet area
              </label>
              <input
                type="number"
                id="chargeName"
                name="carpet_area"
                placeholder="Add carpet area"
                className={classNames(
                  {
                    "input-invalid": Boolean(
                      formik.touched.carpet_area && formik.errors.carpet_area
                    ),
                  },
                  "form-control"
                )}
                {...formik.getFieldProps("carpet_area")}
              />

              {Boolean(
                formik.touched.carpet_area && formik.errors.carpet_area
              ) && (
                <small className="p-error">{formik.errors.carpet_area}</small>
              )}
            </div>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Super built area
              </label>
              <input
                type="number"
                class="form-control"
                id="chargeName"
                name="super_builtup_area"
                placeholder="Add super built area"
                className={classNames(
                  {
                    "input-invalid": Boolean(
                      formik.touched.super_builtup_area &&
                        formik.errors.super_builtup_area
                    ),
                  },
                  "form-control"
                )}
                {...formik.getFieldProps("super_builtup_area")}
              />

              {Boolean(
                formik.touched.super_builtup_area &&
                  formik.errors.super_builtup_area
              ) && (
                <small className="p-error">
                  {formik.errors.super_builtup_area}
                </small>
              )}
            </div>

            <label for="chargeName" name="configuration" class="form-label">
              {" "}
              Configuration
              <select
                name="configuration"
                class="form-control"
                className={classNames(
                  {
                    "input-invalid": Boolean(
                      formik.touched.configuration &&
                        formik.errors.configuration
                    ),
                  },
                  "form-control"
                )}
                {...formik.getFieldProps("configuration")}
              >
                <option value="1BHk">1BHK</option>
                <option value="2BHK">2BHK</option>
                <option value="3BHK">3BHK</option>
              </select>
              {Boolean(
                formik.touched.configuration && formik.errors.configuration
              ) && (
                <small className="p-error">{formik.errors.configuration}</small>
              )}
            </label>

            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Floor
              </label>
              <input
                type="text"
                class="form-control"
                id="chargeName"
                name="floor"
                placeholder="Enter floor"
                className={classNames(
                  {
                    "input-invalid": Boolean(
                      formik.touched.floor && formik.errors.floor
                    ),
                  },
                  "form-control"
                )}
                {...formik.getFieldProps("floor")}
              />

              {Boolean(formik.touched.floor && formik.errors.floor) && (
                <small className="p-error">{formik.errors.floor}</small>
              )}
            </div>
          </form>
          <div className="d-flex footer gap-2 align-items-center">
            <button
              type="submit"
              class="btn btn-primary rounded-4"
              onClick={handleOnClick}
            >
              Submit
            </button>
          </div>
        </div>
      </Sidebar>
      <Sidebar
        className="right-sidebar"
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
      >
        <h5>Edit Apartment Type</h5>
        <div className="sidebar-main">
          <form onSubmit={editformik.handleSubmit}>
            <div className="mb-3">
              <label htmlFor="chargeName" className="form-label">
                Title
              </label>
              <input
                type="text"
                id="chargeName"
                name="edittitle"
                placeholder="Enter Apartment Type"
                className={classNames(
                  {
                    "input-invalid": Boolean(
                      editformik.touched.edittitle &&
                        editformik.errors.edittitle
                    ),
                  },
                  "form-control"
                )}
                // className={classNames(
                //     "form-control",
                //     { "border-red": editformik.touched.edittitle && editformik.errors.edittitle }
                // )}

                autoComplete="off"
                value={editformik.values.edittitle}
                onChange={editformik.handleChange}
                onBlur={editformik.handleBlur}
              />

              {editformik.touched.edittitle && editformik.errors.edittitle && (
                <small className="p-error">{editformik.errors.edittitle}</small>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="editArea" className="form-label">
                Carpet Area
              </label>
              <input
                type="text"
                id="editArea"
                name="editarea"
                placeholder="Enter carpet area"
                className={classNames(
                  {
                    "input-invalid": Boolean(
                      editformik.touched.editarea && editformik.errors.editarea
                    ),
                  },
                  "form-control"
                )}
                // className="form-control"
                autoComplete="off"
                value={editformik.values.editarea}
                onChange={editformik.handleChange}
                onBlur={editformik.handleBlur}
              />
              {editformik.touched.editarea && editformik.errors.editarea && (
                <small className="p-error">{editformik.errors.editarea}</small>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="editSuper" className="form-label">
                Super Builtup Area
              </label>
              <input
                type="text"
                id="editSuper"
                name="editsuper"
                placeholder="Enter super builtup area"
                // className="form-control"
                className={classNames(
                  {
                    "input-invalid": Boolean(
                      editformik.touched.editsuper &&
                        editformik.errors.editsuper
                    ),
                  },
                  "form-control"
                )}
                autoComplete="off"
                value={editformik.values.editsuper}
                onChange={editformik.handleChange}
                onBlur={editformik.handleBlur}
              />
              {editformik.touched.editsuper && editformik.errors.editsuper && (
                <small className="p-error">{editformik.errors.editsuper}</small>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="editConfiguration" className="form-label">
                Configuration
              </label>
              <select
                id="editConfiguration"
                name="editconfig"
                // className="form-control"
                className={classNames(
                  {
                    "input-invalid": Boolean(
                      editformik.touched.editconfig &&
                        editformik.errors.editconfig
                    ),
                  },
                  "form-control"
                )}
                value={editformik.values.editconfig}
                onChange={editformik.handleChange}
                onBlur={editformik.handleBlur}
              >
                <option value="">Select Configuration</option>
                <option value="1BHK">1BHK</option>
                <option value="2BHK">2BHK</option>
                <option value="3BHK">3BHK</option>
              </select>
              {editformik.touched.editconfig &&
                editformik.errors.editconfig && (
                  <small className="p-error">
                    {editformik.errors.editconfig}
                  </small>
                )}
            </div>

            <div className="mb-3">
              <label htmlFor="editFloor" className="form-label">
                Floor
              </label>
              <input
                type="text"
                id="editFloor"
                name="editfloor"
                placeholder="Enter floor"
                // className="form-control"
                className={classNames(
                  {
                    "input-invalid": Boolean(
                      editformik.touched.editfloor &&
                        editformik.errors.editfloor
                    ),
                  },
                  "form-control"
                )}
                autoComplete="off"
                value={editformik.values.editfloor}
                onChange={editformik.handleChange}
                onBlur={editformik.handleBlur}
              />
              {editformik.touched.editfloor && editformik.errors.editfloor && (
                <small className="p-error">{editformik.errors.editfloor}</small>
              )}
            </div>
            {/* Add similar blocks for other form fields */}
            <div className="d-flex footer gap-2 align-items-center">
              <button type="submit" className="btn btn-primary rounded-4">
                Edit
              </button>
            </div>
          </form>
        </div>
      </Sidebar>

      <div className="table_header mb-3">
        <div className="d-flex flex-wrap align-items-center">
          <h4 className="mb-0 fw-semi-bold fs-5">Apartment Type</h4>
          <div className="ms-auto d-flex align-items-center gap-2">
            <button
              onClick={() => setVisibleRight(true)}
              className="btn btn-sm btn-primary rounded-4"
            >
              Create
            </button>
          </div>
        </div>
      </div>

      {data.data && data.data.length > 0 ? (
        <div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">APARTMENT TYPE</th>
                  <th scope="col">CARPET AREA</th>
                  <th scope="col">SUPER BUILTUP AREA</th>
                  <th scope="col">CONFIGURATION</th>
                  <th scope="col">FLOOR</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {/* {data.data && data.data.map((data,idx) => ( */}
                {data.data &&
                  data.data.map((data, idx) => (
                    <tr>
                      {/* <th scope="row">{idx+1}</th> */}
                      <th scope="row">
                        {(currentPage - 1) * pageSize + idx + 1}
                      </th>
                      <td>{data.title}</td>
                      <td>{data.carpet_area}</td>
                      <td>{data.super_builtup_area}</td>
                      <td>{data.configuration}</td>
                      <td>{data.floor}</td>
                      <td>
                        <div className="action_btn">
                          <Link onClick={() => editPost(data)}>
                            <MdOutlineEdit />
                          </Link>
                          <Link onClick={() => deletePost(data._id)}>
                            <MdDeleteOutline />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="table-footer d-md-flex justify-content-md-between align-items-center">
            <p className="mb-0">
              Showing {offsetentry + 1} to {entry} of{" "}
              <span id="total-entries">{totalItems}</span> entries
            </p>
            <nav>
              <ul className="pagination pagination-md">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>

                {pageNumbers.map((pageNumber) => {
                  // Define the minimum and maximum page numbers to display
                  const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                  const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                  if (pageNumber >= minPage && pageNumber <= maxPage) {
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                <li className="page-item">
                  <button className="page-link" onClick={nextPage}>
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <h4>No data available</h4>
      )}
      {/* <div className="table-footer d-md-flex justify-content-md-between align-items-center"> */}
      {/* <p className='mb-0'>Showing 1 to 10 of <span id='total-entries'>57</span>  entries</p>
                <ul class="pagination pagination-md ">
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Previous">&laquo;</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">1</Link></li>
                    <li class="page-item active"><Link class="page-link " href="#">2</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">3</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">4</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">5</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">6</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">7</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">8</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">9</Link></li>
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Next">&raquo;</Link></li>
                </ul> */}
      {/* </div> */}
      {/* pagination */}
      {/* <nav>
            <ul className='pagination justify-content-center'>
                <li className="page-item">
                    <a className="page-link" 
                        onClick={goToPrevPage} 
                        href='#'>
                        
                        Previous
                    </a>
                </li>
                {pageNumbers.map(pgNumber => (
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >

                        <a onClick={() => setCurrentPage(pgNumber)}  
                            className='page-link' 
                            href='#'>
                            
                            {pgNumber}
                        </a>
                    </li>
                ))}
                <li className="page-item">
                    <a className="page-link" 
                        onClick={goToNextPage}
                        href='#'>
                        
                        Next
                    </a>
                </li>
            </ul>
        </nav> */}

      {/* <nav>
                <ul className='pagination justify-content-center'>
                    <li className="page-item">
                        <button className="page-link" onClick={prevPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                    </li>
                    {pageNumbers.map((pageNumber) => (
    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
      <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>
        {pageNumber}
      </button>
    </li>
  ))}
                    
                    <li className="page-item">
                        <button className="page-link" onClick={nextPage}  disabled={currentPage === totalPages} >
                            Next
                        </button>
                    </li>

                    
                </ul>
            </nav> */}

      {/* {pageNumbers.map(pgNumber => (
                        <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}>
                            <button className='page-link' onClick={() => setCurrentPage(pgNumber)}>
                                {pgNumber}
                            </button>
                        </li>
                    ))} */}
      {/* disabled={currentPage === nPages} */}

      {/* pagination */}
    </div>
  );
}

export default ManageApartmentType;
