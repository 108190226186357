import React from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import TableSecurity from './components/TableSecurity';
import TableMaintenance from './components/TableMaintenance';
import TableComplaints from './components/TableComplaints';
import TableAmenities from './components/TableAmenities';
import TableSOS from './components/TableSOS';
import { Link } from 'react-router-dom/cjs/react-router-dom';


export default function AllRequest(props) {
    const {id,imageUrl,
        societyName
        } = props;
    console.log(props);
    // console.log(id);
    return (
        <div className='card border-0 shadow-lg'>
            <TabView>
                {/* <TabPanel header="Visiting">
                    <TableSecurity id={id}  imageUrl={imageUrl} societyName={societyName}/>
                </TabPanel> */}
                <TabPanel header="Maintenance Request">
                    <TableMaintenance id={id} />
                </TabPanel>

                <TabPanel header="Complaints">
                    <TableComplaints id={id}/>
                </TabPanel>


                <TabPanel header="SOS">
                    <TableSOS id={id}/>
                </TabPanel>

                {/* <TabPanel header="Amenities">
                    <TableAmenities id={id} />
                </TabPanel> */}

            </TabView>

        </div>
    )
}
