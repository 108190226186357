import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import user from "../../../assets/images/avatar.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { MdApartment, MdSportsGymnastics } from "react-icons/md";
import axios from "axios";
import Loading from "../../ManageApp/data/Loading";

import Appliances from "../../../assets/images/appliance-repair.svg";
import Beautician from "../../../assets/images/Beutician.png";
import Doctor from "../../../assets/images/Doctor.png";
import Gardner from "../../../assets/images/Gardner.png";
import HomeRepair from "../../../assets/images/home-repair.svg";
import Internet from "../../../assets/images/internet.png";
import Maid from "../../../assets/images/maid2.svg";
import Nanny from "../../../assets/images/Nanny.png";
import Tutor from "../../../assets/images/tutoring.svg";
import Yoga from "../../../assets/images/Yogainstructor.png";
import Milkman from "../../../assets/images/Milkman.png";
import Driver from "../../../assets/images/driver.png";
import Newspaper from "../../../assets/images/newspaper.png";
import Laundry from "../../../assets/images/laundry.png";
import CarCleaner from "../../../assets/images/carcleaner.png";
import GymInructor from "../../../assets/images/gyminstructor.png";
import Housekeeping from "../../../assets/images/housekeeping.png";
import Noimage from "../../../assets/images/avatar.jpg";

import Others from "../../../assets/images/Other.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const apIcon = {
  fontSize: "20px",
};
const companyLogos = {
  "Appliances Repair": Appliances,
  Beautician: Beautician,
  Doctor: Doctor,
  Gardner: Gardner,
  "Home Repair": HomeRepair,
  "Internet Repair": Internet,
  Maid: Maid,
  Nanny: Nanny,
  "Tuition Teacher": Tutor,
  "Yoga Instructor": Yoga,
  Milkman: Milkman,
  Noimage: Noimage,
  Driver: Driver,
  Newspaper: Newspaper,
  Laundry: Laundry,
  "Car Cleaner": CarCleaner,
  "Gym Instructor": GymInructor,
  HouseKeeping: Housekeeping,
  Others: Others,

  // Add more company names and corresponding logos as needed
};
export default function TablePreAprvVisiting(props) {
  console.log(props);
  const { id } = props;
  const token = localStorage.getItem("token");

  const [data, setdata] = useState([]);
  const [activeTab, setActiveTab] = useState("upcoming");
  const [totalItems, setTotalItems] = useState(0);
  //    const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setdata([]);
    datafetch(activeTab);
  }, [searchQuery, currentPage, startDate, endDate, activeTab]);

  useEffect(() => {
    // Open the "upcoming" tab when the component mounts
    setActiveTab(0);
  }, []);

  const type = "Pre_Approve_Visiting_Help";
  // const filter_type = "upcoming";
  const datafetch = (tabIndex) => {
    // setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry((currentPage - 1) * pageSize);

    let filterType;
    switch (tabIndex) {
      case 0:
        filterType = "upcoming";
        break;
      case 1:
        filterType = "scheduled";
        break;
      case 2:
        filterType = "past";
        break;
      default:
        filterType = "upcoming";
        break;
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}list/preApproved`,
      headers: { Authorization: "Bearer " + token },
      params: {
        society_id: id,
        type: type,
        filter_type: filterType,
        // type: selectedRole,

        limit,
        offset,
        start_date: startDate,
        end_date: endDate,
        // offset:(currentPage - 1) * pageSize < totalItems ?(currentPage - 1) * pageSize : 0,
        search: searchQuery,
      },

      // params: { society_id:id,limit,offset,searchQuery: searchQuery},
      // params: { soceityId: id,searchQuery: searchQuery ,limit,offset},
    };

    axios
      .request(config)
      .then((response) => {
        // setTotalItems(response.data.data.total_count);
        setTotalItems(response.data.count);
        console.log(response.data);
        // setdata(response.data.data.allData);
        setdata(response.data.data);
        // setentry(response.data.data.allData.length + (currentPage - 1) * pageSize);
        setentry(response.data.data.length + offset);
        setIsLoading(false);
        // setIsLoading(false);
        // console.log(selectedRole);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(data);
  function reset() {
    setStartDate(null);
    setEndDate(null);
  }
  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  function convertToAMPM(time) {
    if (typeof time !== 'string') {
      return 'Invalid time format';
    }

    // Split the time string into hours and minutes
    const [hours, minutes] = time.split(':').map(Number);

    if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      return 'Invalid time format';
    }

    // Determine AM or PM based on hours
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12;

    // Construct the AM/PM time string
    const formattedTime = `${hours12}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;

    return formattedTime;
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setStartDate(null);
    setEndDate(null);
  };

  if (isLoading && searchQuery === "") {
    return <Loading />;
  }
  return (
    <div className="card border-0 shadow-lg">
      <TabView
        activeIndex={activeTab}
        onTabChange={(e) => handleTabChange(e.index)}
      >
        <TabPanel header="Upcoming">
          <div className="table_cntner">
            <div className="table_header mb-3">
              <div className="d-flex flex-wrap align-items-center">
                <h4 className="fw-semi-bold fs-5 mb-0">Upcoming List</h4>
                <div className="ms-auto d-flex align-items-center gap-2">
                  <div class="search_box ">
                    <input
                      placeholder="Search"
                      class="chakra-input "
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <br />
              <div className="d-flex flex-wrap align-items-center">
                <div className="fw-semi-bold fs-5 mb-0"></div>
                <div className="ms-auto d-flex align-items-center gap-2">


                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(new Date(date)
                      .toISOString()
                      .split("T")[0])}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Start Date"
                    className="form-select"
                    aria-label="Default select example"
                  />



                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(new Date(date)
                      .toISOString()
                      .split("T")[0])}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="End Date"
                    className="form-select"
                    aria-label="Default select example"
                  />

                  <button onClick={reset} className="btn me-2 btn-sm btn-primary rounded-4 ">Reset </button>

                </div>
              </div>
            </div>
            {data && data.length > 0 ? (
              <div>
                <div className="table-responsive">
                  <table className="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        {/* <th scope="col">VISITOR TYPE</th> */}
                        <th scope="col">VISITOR INFO</th>
                        <th scope="col">RESIDENT INFO</th>
                        {/* <th scope="col">PHONE</th> */}
                        <th scope="col">APARTMENT NO</th>
                        <th scope="col">ALLOW ENTRY</th>
                        <th scope="col">APPROVED/VALIDITY DATE</th>
                        <th scope="col">APPROVED DAYS</th>
                        <th scope="col">EXPECTED TIME</th>
                        {/* <th scope="col">STATUS</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.map((data, idx) => (
                          <tr>
                            <th scope="row">
                              {(currentPage - 1) * pageSize + idx + 1}
                            </th>
                            {/* <td>{data.helper_name} </td> */}

                            <td>
                              <img
                                src={
                                  companyLogos[data.helper_name]
                                    ? companyLogos[data.helper_name]
                                    : Noimage
                                }
                                alt="icon"
                                style={{ height: "25px", width: "25px" }}
                              />
                              <span className="ms-2">
                                {companyLogos[data.helper_name]
                                  ? data.helper_name
                                  : data.helper_name}
                              </span>
                              <p></p>
                              <p className="txt-bottom mb-0">
                                {data.visitor_name}
                              </p>
                            </td>

                            {data.user_details && (
                              <td>
                                <div className="user_info d-flex align-items-center">
                                  <img
                                    src={data.user_details[0].profile_image}
                                    alt="user"
                                    className="avatar"
                                  />
                                  <div className="ms-3">
                                    <strong>
                                      <span className="txt-head">
                                        {data.user_details[0].first_name +
                                          " " +
                                          data.user_details[0].last_name}
                                      </span>
                                    </strong>
                                    <p className="txt-bottom mb-0">
                                      {data.user_details[0].email}
                                    </p>
                                    <p>{data.user_details[0].phone_primary}</p>
                                  </div>
                                </div>
                              </td>
                            )}

                            {data.apartment_details &&
                              data.apartment_details.length > 0 ? (
                              <td>
                                <div>
                                  <strong>
                                    <span className="txt-head">
                                      <MdApartment style={apIcon} />
                                      {data.apartment_details[0]
                                        .appartmentNumber || "-"}
                                      -{data.wing_details[0].name || "="}
                                    </span>
                                  </strong>
                                </div>
                              </td>
                            ) : (
                              <td>-</td>
                            )}
                            {/* <td> */}
                            {/* {data.apartment_details && (
                            <div>
                              <strong>
                                <span className="txt-head">
                                  <MdApartment style={apIcon} />
                                  {data.apartment_details[0].appartmentNumber?(data.apartment_details[0].appartmentNumber):("") +
                                    "-" +
                                    data.wing_details[0].name}
                                </span>
                              </strong>
                            </div>
                          )} */}
                            {/* </td> */}
                            <td>
                              {data.allow_entry_type === "Allow_Once"
                                ? "Allow Once"
                                : data.allow_entry_type}
                            </td>

                            {/* <td>
                          {
                            new Date(data.approved_date)
                              .toISOString()
                             
                          } */}

                            {/*                              
                                {new Date(data.
                                validity
                                )
                              .toISOString()
                              .split("T")[0]} */}
                            {/* </td> */}
                            <td>
                              <p>
                                {
                                  new Date(data.approved_date)
                                    .toISOString()
                                    .split("T")[0]
                                }
                              </p>
                              {data.validity ? (
                                <div>
                                  <p>/</p>
                                  <p className="txt-bottom mb-0">
                                    {data.validity && data.validity ? (
                                      new Date(data.validity)
                                        .toISOString()
                                        .split("T")[0]) : ("-")
                                    }
                                  </p>
                                </div>
                              ) : ("")}
                              <p></p>
                              {/* <p className="txt-bottom mb-0">{ new Date(data.
validity
)
                              .toISOString().split("T")[0]}</p> */}
                            </td>
                            <td>
                              {data.approved_days &&
                                data.approved_days.length > 0
                                ? data.approved_days
                                  .map((day) => day.toUpperCase())
                                  .join(", ")
                                : "N/A"}
                            </td>

                            <td>{data.expected_time ? (
                              convertToAMPM(data.expected_time)) : ("N/A")
                            }</td>
                            {/* <td>-</td>
                        <td>-</td> */}
                            {/* <td>-</td> */}
                          </tr>
                        ))}
                      {/* <tr>
                    <th scope="row">1</th>
                    <td>
                      <div className="user_info d-flex  align-items-center">
                        <img src={user} alt="user" className="avatar" />
                        <div className="ms-3">
                          <strong>
                            <span className="txt-head">John Sons</span>
                          </strong>
                          <p className="txt-bottom mb-0">
                            JohnSons13@gmail.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>987654310</td>
                    <td>
                      <MdApartment style={apIcon} /> 109-A
                    </td>
                    <td>
                       Newspaper
                    </td>
                    <td>06-03-2024</td>
                    <td>Approved</td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>
                      <div className="user_info d-flex  align-items-center">
                        <img src={user} alt="user" className="avatar" />
                        <div className="ms-3">
                          <strong>
                            <span className="txt-head">John Sons</span>
                          </strong>
                          <p className="txt-bottom mb-0">
                            JohnSons13@gmail.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>987654310</td>
                    <td>
                      <MdApartment style={apIcon} /> 109-A
                    </td>
                    <td>Milkman</td>
                    <td>06-03-2024</td>
                    <td>Approved</td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>
                      <div className="user_info d-flex  align-items-center">
                        <img src={user} alt="user" className="avatar" />
                        <div className="ms-3">
                          <strong>
                            <span className="txt-head">John Sons</span>
                          </strong>
                          <p className="txt-bottom mb-0">
                            JohnSons13@gmail.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>987654310</td>
                    <td>
                      <MdApartment style={apIcon} /> 109-A
                    </td>
                    <td>
                      Nanny
                    </td>
                    <td>06-03-2024</td>
                    <td>Approved</td>
                  </tr> */}
                    </tbody>
                  </table>
                </div>
                <div className="table-footer d-md-flex justify-content-md-between align-items-center">
                  <p className="mb-0">
                    Showing {offsetentry + 1} to {entry} of{" "}
                    <span id="total-entries">{totalItems}</span> entries
                  </p>
                  <nav>
                    <ul className="pagination pagination-md">
                      <li className="page-item">
                        <button
                          className="page-link"
                          onClick={prevPage}
                          disabled={currentPage === 1}
                        >
                          &laquo;
                        </button>
                      </li>

                      {pageNumbers.map((pageNumber) => {
                        // Define the minimum and maximum page numbers to display
                        const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                        const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                        if (pageNumber >= minPage && pageNumber <= maxPage) {
                          return (
                            <li
                              key={pageNumber}
                              className={`page-item ${currentPage === pageNumber ? "active" : ""
                                }`}
                            >
                              <button
                                className="page-link"
                                onClick={() => setCurrentPage(pageNumber)}
                              >
                                {pageNumber}
                              </button>
                            </li>
                          );
                        }
                        return null;
                      })}
                      <li className="page-item">
                        <button className="page-link" onClick={nextPage}>
                          &raquo;
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            ) : (
              <h4>No data available</h4>
            )}
            {/* <div className="table-footer d-md-flex justify-content-md-between align-items-center">
              <p className="mb-0">
                Showing 1 to 10 of <span id="total-entries">57</span> entries
              </p>
              <ul class="pagination pagination-md ">
                <li class="page-item">
                  <Link class="page-link" href="#" aria-label="Previous">
                    &laquo;
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    1
                  </Link>
                </li>
                <li class="page-item active">
                  <Link class="page-link " href="#">
                    2
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    3
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    4
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    5
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    6
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    7
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    8
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    9
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#" aria-label="Next">
                    &raquo;
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </TabPanel>
        <TabPanel header="Scheduled">
          <div className="table_cntner">
            <div className="table_header mb-3">
              <div className="d-flex flex-wrap align-items-center">
                <h4 className="fw-semi-bold fs-5 mb-0">Scheduled List</h4>
                <div className="ms-auto d-flex align-items-center gap-2">
                  <div class="search_box ">
                    <input
                      placeholder="Search"
                      class="chakra-input "
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <br />
              <div className="d-flex flex-wrap align-items-center">
                <div className="fw-semi-bold fs-5 mb-0"></div>
                <div className="ms-auto d-flex align-items-center gap-2">


                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(new Date(date)
                      .toISOString()
                      .split("T")[0])}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Start Date"
                    className="form-select"
                    aria-label="Default select example"
                  />



                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(new Date(date)
                      .toISOString()
                      .split("T")[0])}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="End Date"
                    className="form-select"
                    aria-label="Default select example"
                  />

                  <button onClick={reset} className="btn me-2 btn-sm btn-primary rounded-4 ">Reset </button>

                </div>
              </div>
            </div>
          </div>
          {data && data.length > 0 ? (
            <div>
              <div className="table-responsive">
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      {/* <th scope="col">VISITOR TYPE</th> */}
                      <th scope="col">VISITOR INFO</th>
                      <th scope="col">RESIDENT INFO</th>
                      {/* <th scope="col">PHONE</th> */}
                      <th scope="col">APARTMENT NO</th>
                      <th scope="col">ALLOW ENTRY</th>
                      <th scope="col">APPROVED/VALIDITY DATE</th>
                      <th scope="col">APPROVED DAYS</th>
                      <th scope="col">EXPECTED TIME</th>
                      {/* <th scope="col">STATUS</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((data, idx) => (
                        <tr>
                          <th scope="row">
                            {(currentPage - 1) * pageSize + idx + 1}
                          </th>
                          {/* <td>{data.helper_name} </td> */}

                          <td>
                            <img
                              src={
                                companyLogos[data.helper_name]
                                  ? companyLogos[data.helper_name]
                                  : Noimage
                              }
                              alt="icon"
                              style={{ height: "25px", width: "25px" }}
                            />
                            <span className="ms-2">
                              {companyLogos[data.helper_name]
                                ? data.helper_name
                                : data.helper_name}
                            </span>
                            <p></p>
                            <p className="txt-bottom mb-0">{data.visitor_name}</p>
                          </td>

                          {data.user_details && (
                            <td>
                              <div className="user_info d-flex align-items-center">
                                <img
                                  src={data.user_details[0].profile_image}
                                  alt="user"
                                  className="avatar"
                                />
                                <div className="ms-3">
                                  <strong>
                                    <span className="txt-head">
                                      {data.user_details[0].first_name +
                                        " " +
                                        data.user_details[0].last_name}
                                    </span>
                                  </strong>
                                  <p className="txt-bottom mb-0">
                                    {data.user_details[0].email}
                                  </p>
                                  <p>{data.user_details[0].phone_primary}</p>
                                </div>
                              </div>
                            </td>
                          )}

                          {data.apartment_details &&
                            data.apartment_details.length > 0 ? (
                            <td>
                              <div>
                                <strong>
                                  <span className="txt-head">
                                    <MdApartment style={apIcon} />
                                    {data.apartment_details[0].appartmentNumber ||
                                      "-"}
                                    -{data.wing_details[0].name || "="}
                                  </span>
                                </strong>
                              </div>
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                          {/* <td> */}
                          {/* {data.apartment_details && (
                            <div>
                              <strong>
                                <span className="txt-head">
                                  <MdApartment style={apIcon} />
                                  {data.apartment_details[0].appartmentNumber?(data.apartment_details[0].appartmentNumber):("") +
                                    "-" +
                                    data.wing_details[0].name}
                                </span>
                              </strong>
                            </div>
                          )} */}
                          {/* </td> */}
                          <td>
                            {data.allow_entry_type === "Allow_Once"
                              ? "Allow Once"
                              : data.allow_entry_type}
                          </td>

                          {/* <td>
                          {
                            new Date(data.approved_date)
                              .toISOString()
                             
                          } */}

                          {/*                              
                                {new Date(data.
                                validity
                                )
                              .toISOString()
                              .split("T")[0]} */}
                          {/* </td> */}
                          <td>
                            <p>
                              {
                                new Date(data.approved_date)
                                  .toISOString()
                                  .split("T")[0]
                              }
                            </p>
                            {data.validity ? (
                              <div>
                                <p>/</p>
                                <p className="txt-bottom mb-0">
                                  {data.validity && data.validity ? (
                                    new Date(data.validity)
                                      .toISOString()
                                      .split("T")[0]) : ("-")
                                  }
                                </p>
                              </div>
                            ) : ("")}
                          </td>
                          <td>
                            {data.approved_days && data.approved_days.length > 0
                              ? data.approved_days
                                .map((day) => day.toUpperCase())
                                .join(", ")
                              : "N/A"}
                          </td>
                          <td>{data.expected_time ? (
                            convertToAMPM(data.expected_time)) : ("N/A")
                          }</td>
                          {/* <td>-</td>
                        <td>-</td> */}
                          {/* <td>-</td> */}
                        </tr>
                      ))}
                    {/* <tr>
                    <th scope="row">1</th>
                    <td>
                      <div className="user_info d-flex  align-items-center">
                        <img src={user} alt="user" className="avatar" />
                        <div className="ms-3">
                          <strong>
                            <span className="txt-head">John Sons</span>
                          </strong>
                          <p className="txt-bottom mb-0">
                            JohnSons13@gmail.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>987654310</td>
                    <td>
                      <MdApartment style={apIcon} /> 109-A
                    </td>
                    <td>
                       Newspaper
                    </td>
                    <td>06-03-2024</td>
                    <td>Approved</td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>
                      <div className="user_info d-flex  align-items-center">
                        <img src={user} alt="user" className="avatar" />
                        <div className="ms-3">
                          <strong>
                            <span className="txt-head">John Sons</span>
                          </strong>
                          <p className="txt-bottom mb-0">
                            JohnSons13@gmail.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>987654310</td>
                    <td>
                      <MdApartment style={apIcon} /> 109-A
                    </td>
                    <td>Milkman</td>
                    <td>06-03-2024</td>
                    <td>Approved</td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>
                      <div className="user_info d-flex  align-items-center">
                        <img src={user} alt="user" className="avatar" />
                        <div className="ms-3">
                          <strong>
                            <span className="txt-head">John Sons</span>
                          </strong>
                          <p className="txt-bottom mb-0">
                            JohnSons13@gmail.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>987654310</td>
                    <td>
                      <MdApartment style={apIcon} /> 109-A
                    </td>
                    <td>
                      Nanny
                    </td>
                    <td>06-03-2024</td>
                    <td>Approved</td>
                  </tr> */}
                  </tbody>
                </table>
              </div>
              {/* </div> */}
              <div className="table-footer d-md-flex justify-content-md-between align-items-center">
                <p className="mb-0">
                  Showing {offsetentry + 1} to {entry} of{" "}
                  <span id="total-entries">{totalItems}</span> entries
                </p>
                <nav>
                  <ul className="pagination pagination-md">
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={prevPage}
                        disabled={currentPage === 1}
                      >
                        &laquo;
                      </button>
                    </li>

                    {pageNumbers.map((pageNumber) => {
                      // Define the minimum and maximum page numbers to display
                      const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                      const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                      if (pageNumber >= minPage && pageNumber <= maxPage) {
                        return (
                          <li
                            key={pageNumber}
                            className={`page-item ${currentPage === pageNumber ? "active" : ""
                              }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => setCurrentPage(pageNumber)}
                            >
                              {pageNumber}
                            </button>
                          </li>
                        );
                      }
                      return null;
                    })}
                    <li className="page-item">
                      <button className="page-link" onClick={nextPage}>
                        &raquo;
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>

          ) : (<h4>No Data Avaliable</h4>)}
          {/* <div className="table-footer d-md-flex justify-content-md-between align-items-center">
              <p className="mb-0">
                Showing 1 to 10 of <span id="total-entries">57</span> entries
              </p>
              <ul class="pagination pagination-md ">
                <li class="page-item">
                  <Link class="page-link" href="#" aria-label="Previous">
                    &laquo;
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    1
                  </Link>
                </li>
                <li class="page-item active">
                  <Link class="page-link " href="#">
                    2
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    3
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    4
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    5
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    6
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    7
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    8
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    9
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#" aria-label="Next">
                    &raquo;
                  </Link>
                </li>
              </ul>
            </div> */}
        </TabPanel>

        <TabPanel header="Past">
          <div className="table_cntner">
            <div className="table_header mb-3">
              <div className="d-flex flex-wrap align-items-center">
                <h4 className="fw-semi-bold fs-5 mb-0">Past List</h4>
                <div className="ms-auto d-flex align-items-center gap-2">
                  <div class="search_box ">
                    <input
                      placeholder="Search"
                      class="chakra-input "
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="d-flex flex-wrap align-items-center">
                <div className="fw-semi-bold fs-5 mb-0"></div>
                <div className="ms-auto d-flex align-items-center gap-2">


                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(new Date(date)
                      .toISOString()
                      .split("T")[0])}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Start Date"
                    className="form-select"
                    aria-label="Default select example"
                  />



                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(new Date(date)
                      .toISOString()
                      .split("T")[0])}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="End Date"
                    className="form-select"
                    aria-label="Default select example"
                  />

                  <button onClick={reset} className="btn me-2 btn-sm btn-primary rounded-4 ">Reset </button>

                </div>
              </div>
            </div>
            {data && data.length > 0 ? (
              <div>
                <div className="table-responsive">
                  <table className="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        {/* <th scope="col">VISITOR TYPE</th> */}
                        <th scope="col">VISITOR INFO</th>
                        <th scope="col">RESIDENT INFO</th>
                        {/* <th scope="col">PHONE</th> */}
                        <th scope="col">APARTMENT NO</th>
                        <th scope="col">ALLOW ENTRY</th>
                        <th scope="col">APPROVED/VALIDITY DATE</th>
                        <th scope="col">APPROVED DAYS</th>
                        <th scope="col">EXPECTED TIME</th>
                        {/* <th scope="col">STATUS</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.map((data, idx) => (
                          <tr>
                            <th scope="row">
                              {(currentPage - 1) * pageSize + idx + 1}
                            </th>
                            {/* <td>{data.helper_name} </td> */}

                            <td>
                              <img
                                src={
                                  companyLogos[data.helper_name]
                                    ? companyLogos[data.helper_name]
                                    : user
                                }
                                alt="icon"
                                style={{ height: "25px", width: "25px" }}
                              />
                              <span className="ms-2">
                                {companyLogos[data.helper_name]
                                  ? data.helper_name
                                  : data.helper_name}
                              </span>
                              <p></p>
                              <p className="txt-bottom mb-0">
                                {data.visitor_name}
                              </p>
                            </td>

                            {data.user_details && (
                              <td>
                                <div className="user_info d-flex align-items-center">
                                  <img
                                    src={data.user_details[0].profile_image || user}
                                    alt="user"
                                    className="avatar"
                                  />
                                  <div className="ms-3">
                                    <strong>
                                      <span className="txt-head">
                                        {data.user_details[0].first_name +
                                          " " +
                                          data.user_details[0].last_name}
                                      </span>
                                    </strong>
                                    <p className="txt-bottom mb-0">
                                      {data.user_details[0].email}
                                    </p>
                                    <p>{data.user_details[0].phone_primary}</p>
                                  </div>
                                </div>
                              </td>
                            )}

                            {data.apartment_details &&
                              data.apartment_details.length > 0 ? (
                              <td>
                                <div>
                                  <strong>
                                    <span className="txt-head">
                                      <MdApartment style={apIcon} />
                                      {data.apartment_details[0]
                                        .appartmentNumber || "-"}
                                      -{data.wing_details[0].name || "="}
                                    </span>
                                  </strong>
                                </div>
                              </td>
                            ) : (
                              <td>-</td>
                            )}
                            {/* <td> */}
                            {/* {data.apartment_details && (
                            <div>
                              <strong>
                                <span className="txt-head">
                                  <MdApartment style={apIcon} />
                                  {data.apartment_details[0].appartmentNumber?(data.apartment_details[0].appartmentNumber):("") +
                                    "-" +
                                    data.wing_details[0].name}
                                </span>
                              </strong>
                            </div>
                          )} */}
                            {/* </td> */}
                            <td>
                              {data.allow_entry_type === "Allow_Once"
                                ? "Allow Once"
                                : data.allow_entry_type}
                            </td>

                            {/* <td>
                          {
                            new Date(data.approved_date)
                              .toISOString()
                             
                          } */}

                            {/*                              
                                {new Date(data.
                                validity
                                )
                              .toISOString()
                              .split("T")[0]} */}
                            {/* </td> */}
                            <td>
                              <p>
                                {
                                  new Date(data.approved_date)
                                    .toISOString()
                                    .split("T")[0]
                                }
                              </p>

                              {data.validity ? (
                                <div>
                                  <p>/</p>
                                  <p className="txt-bottom mb-0">
                                    {data.validity && data.validity ? (
                                      new Date(data.validity)
                                        .toISOString()
                                        .split("T")[0]) : ("-")
                                    }
                                  </p>
                                </div>
                              ) : ("")}
                              <p></p>
                              {/* <p className="txt-bottom mb-0">{ new Date(data.
validity
)
                              .toISOString().split("T")[0]}</p> */}
                            </td>
                            <td>
                              {data.approved_days &&
                                data.approved_days.length > 0
                                ? data.approved_days
                                  .map((day) => day.toUpperCase())
                                  .join(", ")
                                : "N/A"}
                            </td>
                            <td>{data.expected_time ? (
                              convertToAMPM(data.expected_time)) : ("N/A")
                            }</td>
                            {/* <td>-</td>
                        <td>-</td> */}
                            {/* <td>-</td> */}
                          </tr>
                        ))}
                      {/* <tr>
                    <th scope="row">1</th>
                    <td>
                      <div className="user_info d-flex  align-items-center">
                        <img src={user} alt="user" className="avatar" />
                        <div className="ms-3">
                          <strong>
                            <span className="txt-head">John Sons</span>
                          </strong>
                          <p className="txt-bottom mb-0">
                            JohnSons13@gmail.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>987654310</td>
                    <td>
                      <MdApartment style={apIcon} /> 109-A
                    </td>
                    <td>
                       Newspaper
                    </td>
                    <td>06-03-2024</td>
                    <td>Approved</td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>
                      <div className="user_info d-flex  align-items-center">
                        <img src={user} alt="user" className="avatar" />
                        <div className="ms-3">
                          <strong>
                            <span className="txt-head">John Sons</span>
                          </strong>
                          <p className="txt-bottom mb-0">
                            JohnSons13@gmail.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>987654310</td>
                    <td>
                      <MdApartment style={apIcon} /> 109-A
                    </td>
                    <td>Milkman</td>
                    <td>06-03-2024</td>
                    <td>Approved</td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>
                      <div className="user_info d-flex  align-items-center">
                        <img src={user} alt="user" className="avatar" />
                        <div className="ms-3">
                          <strong>
                            <span className="txt-head">John Sons</span>
                          </strong>
                          <p className="txt-bottom mb-0">
                            JohnSons13@gmail.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>987654310</td>
                    <td>
                      <MdApartment style={apIcon} /> 109-A
                    </td>
                    <td>
                      Nanny
                    </td>
                    <td>06-03-2024</td>
                    <td>Approved</td>
                  </tr> */}
                    </tbody>
                  </table>
                </div>
                <div className="table-footer d-md-flex justify-content-md-between align-items-center">
                  <p className="mb-0">
                    Showing {offsetentry + 1} to {entry} of{" "}
                    <span id="total-entries">{totalItems}</span> entries
                  </p>
                  <nav>
                    <ul className="pagination pagination-md">
                      <li className="page-item">
                        <button
                          className="page-link"
                          onClick={prevPage}
                          disabled={currentPage === 1}
                        >
                          &laquo;
                        </button>
                      </li>

                      {pageNumbers.map((pageNumber) => {
                        // Define the minimum and maximum page numbers to display
                        const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                        const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                        if (pageNumber >= minPage && pageNumber <= maxPage) {
                          return (
                            <li
                              key={pageNumber}
                              className={`page-item ${currentPage === pageNumber ? "active" : ""
                                }`}
                            >
                              <button
                                className="page-link"
                                onClick={() => setCurrentPage(pageNumber)}
                              >
                                {pageNumber}
                              </button>
                            </li>
                          );
                        }
                        return null;
                      })}
                      <li className="page-item">
                        <button className="page-link" onClick={nextPage}>
                          &raquo;
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            ) : (
              <h4>No Data Avaliable</h4>
            )}
            {/* <div className="table-footer d-md-flex justify-content-md-between align-items-center">
              <p className="mb-0">
                Showing 1 to 10 of <span id="total-entries">57</span> entries
              </p>
              <ul class="pagination pagination-md ">
                <li class="page-item">
                  <Link class="page-link" href="#" aria-label="Previous">
                    &laquo;
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    1
                  </Link>
                </li>
                <li class="page-item active">
                  <Link class="page-link " href="#">
                    2
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    3
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    4
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    5
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    6
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    7
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    8
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#">
                    9
                  </Link>
                </li>
                <li class="page-item">
                  <Link class="page-link" href="#" aria-label="Next">
                    &raquo;
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
