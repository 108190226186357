import React, { useState } from "react";
import {
  MdAnnouncement,
  MdDeleteOutline,
  MdOutlineEdit,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import user from "../../../assets/images/avatar.jpg";
import moment from "moment";
import { deletePost, listAllPost } from "../../../utils/asyncFun";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

export default function PostList({ post, id, onPageChange, society, societyId, image, offset }) {
  const dispatch = useDispatch();


  const [page, setPage] = useState(1);
  const  totalCount = post?.totalCount ?? 0;
  const totalPages = Math.ceil(totalCount / 5);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    onPageChange(pageNumber);
  };


  // const handleClick = (e, _id) => {

  // };


  const handleClick = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deletePost({ method: "POST", url: "deletePost", data: { _id } })
        ).then(() => {
          dispatch(
            listAllPost({
              method: "GET",
              url: `listAllPost?societyId=${id}&type=post`,
              data: "",
            })
          );
        });
        // let config = {
        //   method: "patch",
        //   maxBodyLength: Infinity,
        //   url: `${process.env.REACT_APP_BASE_API}deleteTenant/` + ID,
        //   headers: {
        //     Authorization: "Bearer " + token,
        //     "Content-Type": "application/json",
        //   },
        // };
        // axios
        //   .request(config)
        //   .then((response) => {
        //     Swal.fire({
        //       title: "Deleted!",
        //       text: "Your file has been deleted.",
        //       icon: "success",
        //     });
        //     datafetch();
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //     Swal.fire({
        //       icon: "error",
        //       title: "Oops...",
        //       text: "Something went wrong!",
        //     });
        //   });
      }
    });
  };


  return (
    <div>
      <div className="table_header mb-3">
        <div className="d-flex flex-wrap ">
          <h4 className="fw-semi-bold fs-5 mb-0">Post List</h4>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">USER INFO</th>
              <th scope="col">Description</th>
              <th scope="col">DATE</th>
              <th scope="col">TIME</th>
              <th scope="col">LIKES</th>
              <th scope="col">COMMENTS</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {post?.data && post?.data.length !== 0 && post?.data?.map((el, index) => (
              <tr key={index}>
                <th scope="row">{(page - 1) * 5 + index + 1}</th>
                <td>
                  <div className="user_info d-flex  align-items-center">
                    <img src={el?.user_id?.profile_image ? el?.user_id?.profile_image : el?.societyAdmin_id?.profile_image ? el?.societyAdmin_id?.profile_image : user} alt="user" className="avatar" />
                    <div className="ms-3">
                      <strong>
                        <span className="txt-head">{el?.user_id ? `${el?.user_id?.first_name} ${el?.user_id?.last_name}` : `${el?.societyAdmin_id?.first_name} ${el?.societyAdmin_id?.last_name}`}</span>{" "}
                        {/* <span className="badge rounded-pill bg-danger ms-2">
                          Reported
                        </span> */}
                      </strong>
                      <p className="txt-bottom mb-0">{el?.user_id ? `${el?.user_id?.email}` : `${el?.societyAdmin_id?.email}`}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p className="text-truncate" style={{ maxWidth: "200px" }}>
                    {el?.description}
                  </p>
                </td>
                <td>{el?.createdAt?.split("T")[0]}</td>
                <td>{moment(el?.createdAt).format("hh:mm A")}</td>
                <td>{el?.likes?.count || 0}</td>
                <td>{el?.comment?.length || 0}</td>
                <td>
                  <div className="action_btn">
                    <Link to={`/view-post/${el?._id}/${societyId}`}>
                      <MdOutlineRemoveRedEye />
                    </Link>
                    <span>
                      <MdDeleteOutline
                        onClick={(e) => handleClick(el?._id)}
                      />
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="table-footer d-md-flex justify-content-md-between align-items-center">
        <p className="mb-0">
          Showing {offset + 1} to {offset + post?.data?.length} of{" "}
          {totalCount} entries
        </p>
        <ul className="pagination pagination-md">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(
            (pageNumber) => (
              <li
                key={pageNumber}
                className={`page-item ${pageNumber === page ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
}
