import React from "react";
import {
  MdSecurity,
  MdDeleteOutline,
  MdOutlineAdd,
  MdOutlineEdit,
} from "react-icons/md";
// import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import Loading from "../../ManageApp/data/Loading";
import { CgSearch } from "react-icons/cg";
import Swal from "sweetalert2";
import { Sidebar } from "primereact/sidebar";
import {
  addManagment,
  updateStatusOfSocietyManagment,
} from "../../../utils/asyncFun";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeApiCall } from "../../../utils/api";

const SecurityGuardList = (props) => {
  const { id } = props;
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [data, setdata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [securityData, setSecurityData] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    status: "",
    role: "security",
    society_id: id,
  });
  const dispatch = useDispatch();

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  //

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);

  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(10);
  //   useEffect(() => {
  useEffect(() => {
    datafetch();
  }, [currentPage, searchQuery]);

  const datafetch = () => {
    // setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getsecurityaccordingSocity`,
      headers: { Authorization: "Bearer " + token },
      // params: { limit, offset }
      params: { search: searchQuery, limit, offset, society_id: id },
    };

    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.count);
        console.log(response.data);
        setdata(response.data);
        setIsLoading(false);
        console.log(response.data.data.length);
        setentry(response.data.data.length + offset);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleRight2, setVisibleRight2] = useState(false);

  const addSecurity = () => {
    setVisibleRight(true);
  };

  const editSecurity = (data) => {
    setVisibleRight2(true);

    // society_id: id,
    //   profile_image: "",
    //   first_name: "",
    //   last_name: "",
    //   email: "",
    //   designation: "",
    //   mobile_number: "",
    //   phone_number: "",

    console.log("data", data);
    const formikIntialValue = {
      _id: data?._id,
      email: data?.email,
      designation: data?.designation,
      first_name: data?.first_name,
      last_name: data?.last_name,
      mobile_number: data?.mobile_number,
      status: data?.status,
      society_id: data?.society_id,
      phone_number: data?.phone_number,
    };

    formikEdit.setValues(formikIntialValue);
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleSearch = () => {
    setCurrentPage(1); // Reset to the first page when performing a new search
    datafetch(); // Fetch data based on the new search query
  };

  const deleteconfirm = (ID) => {
    console.log("delete");
    console.log(ID);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_API}deleteManagement/` + ID,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        };
        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            datafetch();
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    });
  };

  const handleSubmit = async (e, field, value) => {
    e.preventDefault();
    if (field == "ADD_SECURITY") {
      dispatch(
        addManagment({
          method: "POST",
          url: "addManagment",
          data: securityData,
        })
      ).then(() => {
        setSecurityData({
          first_name: "",
          last_name: "",
          mobile_number: "",
          status: "",
          role: "security",
          society_id: id,
        });
        datafetch();
        setVisibleRight(false);
        Swal.fire("Saved!", "", "success");
      });
    } else {
      dispatch(
        updateStatusOfSocietyManagment({
          method: "PATCH",
          url: "updateStatusOfSocietyManagment",
          data: securityData,
        })
      ).then(() => {
        setSecurityData({
          first_name: "",
          last_name: "",
          mobile_number: "",
          status: "",
          role: "security",
          society_id: id,
        });
        datafetch();
        setVisibleRight2(false);
        Swal.fire("Saved!", "", "success");
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSecurityData({ ...securityData, [name]: value });
  };

  const validationSchema = Yup.object().shape({
    society_id: Yup.string(),
    profile_image: Yup.string(),
    first_name: Yup.string().required("First Name is required"),
    email: Yup.string().required("Email is required"),
    last_name: Yup.string().required("Last Name is required"),
    designation: Yup.string(),
    mobile_number: Yup.string()
      .required("Mobile number is required")
      .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
    phone_number: Yup.string().matches(
      /^[0-9]{10}$/,
      "Mobile number must be 10 digits"
    ),
    role: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      society_id: id,
      profile_image: "",
      first_name: "",
      last_name: "",
      email: "",
      designation: "",
      mobile_number: "",
      phone_number: "",
      role: "security",
    },
    validationSchema: validationSchema,
  });

  const handleSave = async () => {
    formik.setTouched(
      Object.keys(formik.values).reduce((acc, fieldName) => {
        acc[fieldName] = true;
        return acc;
      }, {})
    );

    formik.validateForm().then(async (errors) => {
      if (Object.keys(errors).length === 0) {
        const data = {
          society_id: formik.values.society_id,
          profile_image: formik.values.profile_image,
          first_name: formik.values.first_name,
          last_name: formik.values.last_name,
          email: formik.values.email,
          designation: formik.values.designation,
          mobile_number: formik.values.mobile_number,
          phone_number: formik.values.phone_number,
          role: "security",
        };

        const response = await makeApiCall(
          "addManagment",
          "POST",
          data,
          {},
          true
        );

        if (response.code === 200) {
          hideMe();
          datafetch();
        }
      }
    });
  };

  async function handleUploadImage(event) {
    if (!event?.currentTarget?.files[0]) return;
    const formData = new FormData();
    formData.append("profile_image", event.currentTarget.files[0]);

    const uploadImage = await makeApiCall(
      "uploadImageToAWS",
      "POST",
      formData,
      {},
      true,
      false,
      "",
      false
    );

    if (uploadImage) {
      formik.setFieldTouched("profile_image", true);
      formik.setFieldValue("profile_image", uploadImage?.data);
    }
  }

  function hideMe() {
    setVisibleRight(false);
    formik.resetForm();
  }

  const validationEditSchema = Yup.object().shape({
    _id: Yup.string(),
    first_name: Yup.string().required("First Name is required"),

    last_name: Yup.string().required("Last Name is required"),
    // mobile_number: Yup.string().required("Mobile number is required").matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits'),
    status: Yup.string().required("Status is required"),
    email: Yup.string().required("Email is required"),
    designation: Yup.string(),
    mobile_number: Yup.string()
      .required("Mobile number is required")
      .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
    phone_number: Yup.string().matches(
      /^[0-9]{10}$/,
      "Mobile number must be 10 digits"
    ),
  });

  const formikEdit = useFormik({
    initialValues: {
      _id: "",
      first_name: "",
      last_name: "",
      email: "",
      designation: "",
      mobile_number: "",
      phone_number: "",
      status: "",
    },
    validationSchema: validationEditSchema,
  });

  const handleEdit = async () => {
    formikEdit.setTouched(
      Object.keys(formikEdit.values).reduce((acc, fieldName) => {
        acc[fieldName] = true;
        return acc;
      }, {})
    );

    formikEdit.validateForm().then(async (errors) => {
      if (Object.keys(errors).length === 0) {
        const data = formikEdit.values;
        const response = await makeApiCall(
          "updateStatusOfSocietyManagment",
          "PATCH",
          data,
          {},
          true
        );

        if (response.success) {
          hideMe();
          datafetch();
        }
      }
    });
  };

  async function handleUploadImage(event) {
    if (!event?.currentTarget?.files[0]) return;
    const formData = new FormData();
    formData.append("profile_image", event.currentTarget.files[0]);

    const uploadImage = await makeApiCall(
      "uploadImageToAWS",
      "POST",
      formData,
      {},
      true,
      false,
      "",
      false
    );

    if (uploadImage) {
      formik.setFieldTouched("profile_image", true);
      formik.setFieldValue("profile_image", uploadImage?.data);
    }
  }

  function onHideEdit() {
    setVisibleRight2(false);
    formikEdit.resetForm();
  }

  return (
    <>
      <Sidebar
        className="right-sidebar"
        visible={visibleRight}
        position="right"
        onHide={hideMe}
      >
        <h5>Add Security Guard</h5>
        <div class="sidebar-main mt-3">
          <form>
            <div className="mb-3">
              <div className="avatar d-flex justify-content-center align-items-center flex-column m-auto">
                {formik?.values?.profile_image ? (
                  <img
                    className="rounded-circle"
                    alt=""
                    src={formik?.values?.profile_image}
                    style={{ height: 100, width: 100, objectFit: "contain" }}
                  />
                ) : (
                  <img
                    className="rounded-circle"
                    alt=""
                    src=""
                    style={{ height: 100, width: 100 }}
                  />
                )}

                <input
                  type="file"
                  id="upload-img"
                  onChange={handleUploadImage}
                  style={{ display: "none" }}
                />
                <label htmlFor="upload-img" className="upload-img">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z" />
                  </svg>
                </label>
              </div>
            </div>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                First name
              </label>
              <input
                type="text"
                placeholder="Enter first name here"
                name="first_name"
                className={
                  Boolean(formik.touched.first_name && formik.errors.first_name)
                    ? "border-danger form-control"
                    : "form-control"
                }
                {...formik.getFieldProps("first_name")}
              />
              {Boolean(
                formik.touched.first_name && formik.errors.first_name
              ) && (
                <small className="text-danger">
                  {formik.errors.first_name}
                </small>
              )}
            </div>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Last Name
              </label>
              <input
                type="text"
                placeholder="Enter last name here"
                name="last_name"
                className={
                  Boolean(formik.touched.last_name && formik.errors.last_name)
                    ? "border-danger form-control"
                    : "form-control"
                }
                {...formik.getFieldProps("last_name")}
              />
              {Boolean(formik.touched.last_name && formik.errors.last_name) && (
                <small className="text-danger">{formik.errors.last_name}</small>
              )}
            </div>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Email
              </label>
              <input
                type="email"
                placeholder="Enter email here"
                name="email"
                className={
                  Boolean(formik.touched.email && formik.errors.email)
                    ? "border-danger form-control"
                    : "form-control"
                }
                {...formik.getFieldProps("email")}
              />
              {Boolean(formik.touched.email && formik.errors.email) && (
                <small className="text-danger">{formik.errors.email}</small>
              )}
            </div>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Designation
              </label>
              <input
                type="text"
                placeholder="Enter designation here"
                name="designation"
                className={
                  Boolean(
                    formik.touched.designation && formik.errors.designation
                  )
                    ? "border-danger form-control"
                    : "form-control"
                }
                {...formik.getFieldProps("designation")}
              />
              {Boolean(
                formik.touched.designation && formik.errors.designation
              ) && (
                <small className="text-danger">
                  {formik.errors.designation}
                </small>
              )}
            </div>

            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Mobile Number 1
              </label>
              <input
                type="text"
                placeholder="Enter mobile number"
                name="mobile_number"
                className={
                  Boolean(
                    formik.touched.mobile_number && formik.errors.mobile_number
                  )
                    ? "border-danger form-control"
                    : "form-control"
                }
                {...formik.getFieldProps("mobile_number")}
              />
              {Boolean(
                formik.touched.mobile_number && formik.errors.mobile_number
              ) && (
                <small className="text-danger">
                  {formik.errors.mobile_number}
                </small>
              )}
            </div>

            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Mobile Number 2 (Optional)
              </label>
              <input
                type="text"
                placeholder="Enter mobile number"
                name="phone_number"
                className={
                  Boolean(
                    formik.touched.phone_number && formik.errors.phone_number
                  )
                    ? "border-danger form-control"
                    : "form-control"
                }
                {...formik.getFieldProps("phone_number")}
              />
              {Boolean(
                formik.touched.phone_number && formik.errors.phone_number
              ) && (
                <small className="text-danger">
                  {formik.errors.phone_number}
                </small>
              )}
            </div>
          </form>
        </div>
        <div className="d-flex footer gap-2 align-items-center">
          <button onClick={handleSave} class="btn btn-primary rounded-4">
            Add
          </button>
        </div>
      </Sidebar>

      <Sidebar
        className="right-sidebar"
        visible={visibleRight2}
        position="right"
        onHide={onHideEdit}
      >
        <h5>Edit Security Guard</h5>
        <div class="sidebar-main mt-3">
          <form>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                First name
              </label>
              <input
                type="text"
                placeholder="Enter first name here"
                name="first_name"
                className={
                  Boolean(
                    formikEdit.touched.first_name &&
                      formikEdit.errors.first_name
                  )
                    ? "border-danger form-control"
                    : "form-control"
                }
                {...formikEdit.getFieldProps("first_name")}
              />
              {Boolean(
                formikEdit.touched.first_name && formikEdit.errors.first_name
              ) && (
                <small className="text-danger">
                  {formikEdit.errors.first_name}
                </small>
              )}
            </div>

            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Last Name
              </label>
              <input
                type="text"
                placeholder="Enter last name here"
                name="last_name"
                className={
                  Boolean(
                    formikEdit.touched.last_name && formikEdit.errors.last_name
                  )
                    ? "border-danger form-control"
                    : "form-control"
                }
                {...formikEdit.getFieldProps("last_name")}
              />
              {Boolean(
                formikEdit.touched.last_name && formikEdit.errors.last_name
              ) && (
                <small className="text-danger">
                  {formikEdit.errors.last_name}
                </small>
              )}
            </div>

            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Email
              </label>
              <input
                type="email"
                placeholder="Enter email here"
                name="email"
                className={
                  Boolean(formikEdit.touched.email && formikEdit.errors.email)
                    ? "border-danger form-control"
                    : "form-control"
                }
                {...formik.getFieldProps("email")}
              />
              {Boolean(formikEdit.touched.email && formikEdit.errors.email) && (
                <small className="text-danger">{formikEdit.errors.email}</small>
              )}
            </div>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Designation
              </label>
              <input
                type="text"
                placeholder="Enter designation here"
                name="designation"
                className={
                  Boolean(
                    formikEdit.touched.designation &&
                      formikEdit.errors.designation
                  )
                    ? "border-danger form-control"
                    : "form-control"
                }
                {...formikEdit.getFieldProps("designation")}
              />
              {Boolean(
                formikEdit.touched.designation && formikEdit.errors.designation
              ) && (
                <small className="text-danger">
                  {formikEdit.errors.designation}
                </small>
              )}
            </div>

            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Mobile Number
              </label>
              <input
                type="text"
                name="mobile_number"
                className={
                  Boolean(
                    formikEdit.touched.mobile_number &&
                      formikEdit.errors.mobile_number
                  )
                    ? "border-danger form-control"
                    : "form-control"
                }
                {...formikEdit.getFieldProps("mobile_number")}
              />
              {Boolean(
                formikEdit.touched.mobile_number &&
                  formikEdit.errors.mobile_number
              ) && (
                <small className="text-danger">
                  {formikEdit.errors.mobile_number}
                </small>
              )}
            </div>

            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Mobile Number 2 (Optional)
              </label>
              <input
                type="text"
                placeholder="Enter mobile number"
                name="phone_number"
                className={
                  Boolean(
                    formikEdit.touched.phone_number &&
                      formikEdit.errors.phone_number
                  )
                    ? "border-danger form-control"
                    : "form-control"
                }
                {...formik.getFieldProps("phone_number")}
              />
              {Boolean(
                formikEdit.touched.phone_number &&
                  formikEdit.errors.phone_number
              ) && (
                <small className="text-danger">
                  {formikEdit.errors.phone_number}
                </small>
              )}
            </div>

            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Status
              </label>
              <select
                name="status"
                className={
                  Boolean(formikEdit.touched.status && formikEdit.errors.status)
                    ? "border-danger form-control"
                    : "form-control"
                }
                {...formikEdit.getFieldProps("status")}
              >
                <option value="">Select Status</option>
                <option value="Block">Block</option>
                <option value="Unblock">Unblock</option>
              </select>
              {Boolean(
                formikEdit.touched.status && formikEdit.errors.status
              ) && (
                <small className="text-danger">
                  {formikEdit.errors.status}
                </small>
              )}
            </div>
          </form>
        </div>
        <div className="d-flex footer gap-2 align-items-center">
          <button onClick={handleEdit} class="btn btn-primary rounded-4">
            Update
          </button>
        </div>
      </Sidebar>

      {/* <div className='table_header mb-3'>
                <div className='d-flex flex-wrap align-items-center'> */}
      {/* <Link to="/manage-society" className='btn me-2 btn-sm btn-primary rounded-4 '>Back</Link> */}
      {/* <button onClick={() => history.goBack()} className='btn me-2 btn-sm btn-primary rounded-4 '>Back</button>
                    <h5 className='fw-semi-bold fs-6 mb-0 '><MdSecurity /> Manage Security Guards</h5> */}
      {/* 
                    <div className="search_box position-relative ">
        <input placeholder="Search" className="form-control rounded-5" />
        <span className='search-icon'><CgSearch /></span>
    </div>
    </div> */}

      {/* </div> */}

      <div className="table_header mb-3">
        <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <button
              onClick={() => history.goBack()}
              className="btn me-2 btn-sm btn-primary rounded-4"
            >
              Back
            </button>
            <h5 className="fw-semi-bold fs-6 mb-0">
              <MdSecurity /> Manage Security Guards
            </h5>
          </div>
          {/* <div className="ms-auto d-flex align-items-center gap-2">
          <div className="search_box">
            <input placeholder="Search" className="form-control rounded-5"  value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}/> */}
          {/* <span className="search-icon">
              <CgSearch  onClick={handleSearch}/>
            </span> */}
          {/* </div>
          </div> */}

          <div className="ms-auto d-flex align-items-center gap-2">
            <div class="search_box ">
              <input
                placeholder="Search"
                class="chakra-input"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div class="search_icon "></div>
            </div>
            <button
              onClick={() => addSecurity()}
              className="btn rounded-5 btn-primary"
            >
              Add New
            </button>
          </div>
        </div>
      </div>
      {data.data && data.data.length > 0 ? (
        <div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  {/* <th scope="col">SECURITY ID</th> */}
                  <th scope="col">GUARD NAME</th>
                  <th scope="col">EMAIL</th>
                  <th scope="col">MOBILE NO.</th>
                  <th scope="col">STATUS</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {data.data &&
                  data.data.map((data, idx) => (
                    <tr>
                      <th scope="row">
                        {(currentPage - 1) * pageSize + idx + 1}
                      </th>
                      {/* <td>
                    <div className="text-uppercase">-</div>
                  </td> */}
                      <td>{data.first_name + " " + data.last_name}</td>
                      <td>{data.email}</td>
                      <td>{data.mobile_number}</td>
                      {/* <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i>Active</div></td> */}
                      <td>
                        <div className="">{data.status}</div>
                      </td>
                      <td>
                        {/* <div>
                          <button onClick={() => deleteconfirm(data._id)} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>

                            <MdDeleteOutline /></button></div> */}

                        <div className="action_btn cstm-actns-bttns">
                          <span
                            onClick={() => {
                              editSecurity(data);
                            }}
                          >
                            <MdOutlineEdit />
                          </span>
                          <span
                            onClick={() => deleteconfirm(data._id)}
                            style={{
                              border: "none",
                              background: "none",
                              cursor: "pointer",
                            }}
                          >
                            <MdDeleteOutline />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                {/* <tr>
                            <th scope="row">2</th>
                            <td ><div className='text-uppercase'>sec01</div></td>
                            <td>Mahesh</td>
                            <td>01 (Main Gate)</td>
                            <td>+91-87652-87654</td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i>Active</div></td>

                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td ><div className='text-uppercase'>sec01</div></td>
                            <td>Suresh</td>
                            <td>01 (Main Gate)</td>
                            <td>+91-87652-87654</td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i>Active</div></td>

                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td ><div className='text-uppercase'>sec01</div></td>
                            <td>Mahesh</td>
                            <td>01 (Main Gate)</td>
                            <td>+91-87652-87654</td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i>Active</div></td>

                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td ><div className='text-uppercase'>sec01</div></td>
                            <td>Mahesh</td>
                            <td>01 (Main Gate)</td>
                            <td>+91-87652-87654</td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i>Active</div></td>

                        </tr> */}
              </tbody>
            </table>
          </div>
          {/* <div className="table-footer d-md-flex align-items-center justify-content-md-between "> */}
          {/* <p className='mb-0'>Showing 1 to 5 of <span id='total-entries'>57</span>  entries</p>
                <ul className="pagination  pagination-md">
                    <li class="page-item"><Link className="page-link" aria-label="Previous">&laquo;</Link></li>
                    <li className="page-item"><Link className="page-link" >1</Link></li>
                    <li className="page-item active"><Link className="page-link " href="#">2</Link></li>
                    <li className="page-item"><Link className="page-link" >3</Link></li>
                    <li className="page-item"><Link className="page-link" >4</Link></li>
                    <li className="page-item"><Link className="page-link" >5</Link></li>
                    <li className="page-item"><Link className="page-link" >6</Link></li>
                    <li className="page-item"><Link className="page-link" >7</Link></li>
                    <li className="page-item"><Link className="page-link" >8</Link></li>
                    <li className="page-item"><Link className="page-link" >9</Link></li>
                    <li className="page-item"><Link className="page-link" aria-label="Next">&raquo;</Link></li>
                </ul> */}
          {/* </div> */}

          <div className="table-footer d-md-flex justify-content-md-between align-items-center">
            <p className="mb-0">
              Showing {offsetentry + 1} to {entry} of{" "}
              <span id="total-entries">{totalItems}</span> entries
            </p>
            <nav>
              <ul className="pagination pagination-md">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>

                {pageNumbers.map((pageNumber) => {
                  // Define the minimum and maximum page numbers to display
                  const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                  const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                  if (pageNumber >= minPage && pageNumber <= maxPage) {
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                <li className="page-item">
                  <button className="page-link" onClick={nextPage}>
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <h4>No data available</h4>
      )}

      {/* <nav>
        <ul className="pagination justify-content-center">
          <li className="page-item">
            <button
              className="page-link"
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          </li>
          {pageNumbers.map((pageNumber) => (
            <li
              key={pageNumber}
              className={`page-item ${
                currentPage === pageNumber ? "active" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(pageNumber)}
              >
                {pageNumber}
              </button>
            </li>
          ))}
          <li className="page-item">
            <button
              className="page-link"
              onClick={nextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </li>
          </ul>
      </nav> */}
      {/* {pageNumbers.map(pgNumber => (
                        <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}>
                            <button className='page-link' onClick={() => setCurrentPage(pgNumber)}>
                                {pgNumber}
                            </button>
                        </li>
                    ))} */}

      {/* disabled={currentPage === nPages} */}
    </>
  );
};

export default SecurityGuardList;
