// import React, { useState } from "react";
// import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
// import logo1 from "../../assets/images/hamptom.png";
// import SocietyHeader from "../../components/societyHeader";
// import SocietyHeaderView from "../../components/societyHeaderView";
// import { BreadCrumb } from "primereact/breadcrumb";
// import { useDispatch, useSelector } from "react-redux";
// import { addManagements, uploadMediaToServer } from "../../utils/asyncFun";
// import Swal from "sweetalert2";
// // import { useHistory } from 'react-router-dom';

// export default function AddManagement(props) {
//   const home = { icon: "pi pi-home" };
//   const state = props?.location?.state ?? {}
//   // console.log("props.location.state",props?.location?.state)
//   const items = [
//     { label: "Dashboard" },
//     { label: "Manage Society" },
//     { label: "Add Management" },
//   ];

//   // Development-
//   const { id } = useParams();
//   const {society, image} = JSON.parse(localStorage.getItem("society_info"))

//   const [data, setData] = useState({
//     profile_image: "",
//     first_name: "",
//     last_name: "",
//     mobile_number: "",
//     email: "",
//     designation: "",
//     society_id: id
//   })

//   const dispatch = useDispatch();
//   const stores = useSelector((store) => store.data);

//   function handleChangeImage(event){
//     const formData = new FormData();
//     formData.append('image', event.currentTarget.files[0]);
//     formData.append('path', "profile_image");
//     dispatch(uploadMediaToServer({method: "POST", url: "uploadMediaToServer", data: formData}))
//   }

//   const handleSubmit = async (e) => {
//     e.preventDefault()
//     dispatch(addManagements({method: "POST", url: "addManagements", data: {...data, profile_image: stores.getsocietydetaildata.profile_image}}))
//     .then(() => {
//         // Swal.fire("Saved!", "", "success")
//         window.history.back();
//     })
//   }

//   const handleChange = (e) => {
//     const {name, value} = e.target;
//     setData({...data, [name]: value})
//   }

//   // const history = useHistory();

//   function handleBack() {
//     window.history.back();
//   }

//   console.log(stores)

//   return (
//     <>
//       <div className="d-flex align-items-center bg-white rounded-2">
//         <BreadCrumb model={items} home={home} />
//       </div>
//       <div className="header">
//         {/* <SocietyHeader
//           imageUrl={logo1}
//           societyName="The Hampton Homes"
//           defaultRouter="/manage-society"
//         /> */}
//         <SocietyHeaderView
//           imageUrl={decodeURIComponent(image)}
//           societyName={society}
//           defaultRouter="/dashboard"
//         />
//       </div>
//       <div className="card p-4">
//         <div className="row">
//           <div className="col-xl-10 mx-auto">
//             <div className="card border-0 shadow-lg p-2">
//               <div className="card-header bg-white border-0">
//                 <div className="row align-items-center">
//                   <div className="col-8">
//                     <h4 className="mb-0">Add Management</h4>
//                   </div>
//                   {/* <div className="col-4 text-end">
//                     <a href="#!" className="btn btn-sm btn-primary">
//                       Edit
//                     </a>
//                   </div> */}
//                 </div>
//               </div>
//               <div className="card-body">
//                 <form onSubmit={(e) => handleSubmit(e)}>
//                   <h6 className="heading-small text-muted mb-4">
//                   Management information
//                   </h6>
//                   <div className="col-12 mb-3">
//                     <div className="avatar d-flex justify-content-center align-items-center flex-column m-auto">
//                       <img
//                         className="rounded-circle"
//                         src={stores.getsocietydetaildata.profile_image}
//                         alt=""
//                         style={{ height: "150px", width: "150px" }}
//                       />
//                       <input
//                         type="file"
//                         id="upload-img"
//                         style={{ display: "none" }}
//                         onChange={handleChangeImage}
//                       />
//                       <label htmlFor="upload-img" className="upload-img">
//                         <svg
//                           stroke="currentColor"
//                           fill="currentColor"
//                           strokeWidth="0"
//                           viewBox="0 0 24 24"
//                           height="1em"
//                           width="1em"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path fill="none" d="M0 0h24v24H0z"></path>
//                           <path d="M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z"></path>
//                         </svg>
//                       </label>
//                     </div>
//                   </div>
//                   <div className="pl-lg-4">
//                     <div className="row">
//                       <div className="col-lg-4">
//                         <label className="form-control-label" htmlFor="title">
//                           Select From Existing Residents
//                         </label>
//                         <select
//                           class="form-select"
//                           aria-label="Default select example"
//                         >
//                           <option selected>Select a member</option>
//                           <option value="1">Member one</option>
//                           <option value="2">Member two</option>
//                         </select>
//                       </div>
//                       <div className="col-lg-4">
//                         <div className="form-group focused mb-2">
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-firstName"
//                           >
//                             First Name
//                           </label>
//                           <input
//                             type="text"
//                             id="input-firstName"
//                             className="form-control form-control-alternative"
//                             placeholder="Lucky"
//                             name="first_name"
//                             onChange={(e) => handleChange(e)}
//                             value={data.first_name}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-4">
//                         <div className="form-group mb-2">
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-lastName"
//                           >
//                             Last Name
//                           </label>
//                           <input
//                             type="text"
//                             id="input-lastName"
//                             className="form-control form-control-alternative"
//                             placeholder="jesse"
//                             name="last_name"
//                             onChange={(e) => handleChange(e)}
//                             value={data.last_name}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="row">
//                       <div className="col-lg-4">
//                         <div className="form-group focused">
//                           <label
//                             className="form-control-label"
//                             htmlFor="mobile-number"
//                           >
//                             Mobile Number
//                           </label>
//                           <input
//                             type="text"
//                             id="mobile-number"
//                             className="form-control form-control-alternative"
//                             placeholder="+91-98647-98765"
//                             name="mobile_number"
//                             onChange={(e) => handleChange(e)}
//                             value={data.mobile_number}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-4">
//                         <div className="form-group focused mb-2">
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-email"
//                           >
//                             Email ID
//                           </label>
//                           <input
//                             type="email"
//                             id="input-email"
//                             className="form-control form-control-alternative"
//                             placeholder="Lucky@gmail.com"
//                             name="email"
//                             onChange={(e) => handleChange(e)}
//                             value={data.email}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-4">
//                         <div className="form-group focused mb-2">
//                           <label
//                             className="form-control-label"
//                             htmlFor="input-designation"
//                           >
//                             Designation
//                           </label>
//                           <input
//                             type="text"
//                             id="input-designation"
//                             className="form-control form-control-alternative"
//                             placeholder="software developer"
//                             name="designation"
//                             onChange={(e) => handleChange(e)}
//                             value={data.designation}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <hr className="my-4" />
//                   {/* Address */}

//                   <hr className="my-4" />

//                   <div className="d-flex justify-content-center align-items-center gap-2">
//                     {/* <Link to="" className="btn btn-outline-primary"> */}
//                      <button className="btn btn-outline-primary" onClick={handleBack}>
//                       Back
//                      </button>
//                     {/* </Link> */}
//                     <button className="btn btn-primary" type="submit" disabled={stores.isLoading} >Save</button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SocietyHeaderView from "../../components/societyHeaderView";
import { BreadCrumb } from "primereact/breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { addManagements, uploadMediaToServer } from "../../utils/asyncFun";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeApiCall } from "../../utils/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import Swal from 'sweetalert2';
import axios from 'axios';
export default function AddManagement(props) {
  const [listUser, setUserlist] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const home = { icon: "pi pi-home" };
  const state = props?.location?.state ?? {};
  const items = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "Add Management" },
  ];
  const history = useHistory();
  const { id } = useParams();
  const { society, image } = JSON.parse(localStorage.getItem("society_info"));

  const dispatch = useDispatch();
  const stores = useSelector((store) => store.data);

  const validationSchema = Yup.object().shape({
    total_member: Yup.string(),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    mobile_number: Yup.string().required("Mobile number is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    designation: Yup.string().required("Designation is required"),
    profile_image: Yup.mixed(),
    // rentalAgreement: Yup.mixed().required("Rental agreement is required"),

    user_role: Yup.string().required("Role is required"),
    phone_number: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      total_member: "",
      profile_image: "",
      first_name: "",
      last_name: "",
      mobile_number: "",
      email: "",
      designation: "",
      // rentalAgreement: null,
      society_id: id,
      user_role: "",
      phone_number: "",
    },

    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      dispatch(
        addManagements({
          method: "POST",
          url: "addManagements",
          data: {
            ...values,
            profile_image: stores.getsocietydetaildata.profile_image,
          },
        })
      ).then(() => {
        Swal.fire("Saved!", "", "success");
        window.history.back();
      });
    },
  });

  const rentalAgreementRef = useRef();

  const handleChangeImage = async (event) => {
    const formData = new FormData();
    //formData.append("image", event.currentTarget.files[0]);

    formData.append("profile_image", event.currentTarget.files[0]);

    const uploadImage = await makeApiCall(
      "uploadImageToAWS",
      "POST",
      formData,
      {},
      true,
      false,
      "",
      false
    );
    if (uploadImage) {
      console.log("profile_image", uploadImage.data);
      formik.setFieldValue("profile_image", uploadImage.data);
    }

    // formData.append("path", "profile_image");
    // dispatch(uploadMediaToServer({ method: "POST", url: "uploadMediaToServer", data: formData }));
    // if( stores.getsocietydetaildata.profile_image){
    //   formik.setFieldValue("profile_image",stores.getsocietydetaildata.profile_image)
    // }
  };

  const handleBack = () => {
    // window.history.back();
    history.push(`/manage-society/${id}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    formik.setTouched(
      Object.keys(formik.values).reduce((acc, fieldName) => {
        acc[fieldName] = true;
        return acc;
      }, {})
    );

    formik.validateForm().then(async (errors) => {
      console.log("errors", errors);

      if (Object.keys(errors).length === 0) {
        //const formData = new FormData();
        const values = formik.values;
        if (formik.values) {
          const response = await makeApiCall(
            "addManagements",
            "POST",
            values,
            {},
            true,
            false,
            "",
            false
          );

          if (response?.is_trashed) {
            Swal.fire({
              title: "Are you sure?",
              text: "Do you want to restore this item?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Restore",
              cancelButtonText: "Cancel",
            }).then((result) => {
              if (result.isConfirmed) {
                axios({
                  method: "PATCH",
                  url: "https://api.rcommunity.app/a1/admin/restoreFromRecycleBin",
                  data: {
                    userId: response?.userId,
                  },
                })
                  .then((response) => {
                    Swal.fire(
                      response?.message
                    );
                  })
                  .catch((error) => {
                    Swal.fire(
                      "Error!",
                      "There was a problem restoring the item.",
                      "error"
                    );
                  });
              }
            });
          }
          if (response.code == 200) {
            formik.resetForm();
            Swal.fire("Response", response.message);
          }
          if (response.message == "User Already exists.") {
            Swal.fire("Response", response.message);
          }
          console.log(values);

          // dispatch(addManagements({method: "POST", url: "addManagements", data: {...formik.values}}))
          // .then(() => {
          //     // Swal.fire("Saved!", "", "success")
          //     formik.resetForm()
          //     window.history.back();
          // })
        }

        console.log("Form data to be submitted:", values);
      } else {
        console.log("Validation errors", errors);
      }
    });
  };

  const handleUserRole = async (e) => {
    console.log(e.target.value);
  };
  async function handleApiUser() {
    const params = {
      society_id: id,
    };
    const response = await makeApiCall(
      "listAlluser",
      "GET",
      {},
      params,
      true,
      false,
      "",
      false,
      false
    );
    if (response) {
      console.log(response?.data);
      setUserlist(response?.data);
    }
  }

  const handleExistingUser = (event) => {
    let id = event.target.value;
    console.log("od", id);
    if (!id) {
      return;
    }
    let data = listUser.filter((ele, index) => ele._id == id);
    console.log(data);
    // const mapData = (entry) => {
    //   return

    const mappedData = {
      first_name: data[0].first_name,
      last_name: data[0].last_name,
      mobile_number: data[0].phone_primary,
      phone_number: data[0].phone_primary,
      email: data[0].email,

      user_role: "",
      designation: "",
      society_id: id,

      resident_id: data[0].id,
      profile_image: "",
    };

    formik.setValues(mappedData);

    // console.log(mappedData);
  };

  useEffect(() => {
    handleApiUser();
  }, []);

  useEffect(() => {}, [selectedUser]);

  return (
    <>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
      </div>
      <div className="header">
        <SocietyHeaderView
          imageUrl={decodeURIComponent(image)}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>
      <div className="card p-4">
        <div className="row">
          <div className="col-xl-10 mx-auto">
            <div className="card border-0 shadow-lg p-2">
              <div className="card-header bg-white border-0">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h4 className="mb-0">Add Management</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form>
                  <h6 className="heading-small text-muted mb-4">
                    Management information
                  </h6>
                  <div className="col-12 mb-3">
                    <div className="avatar d-flex justify-content-center align-items-center flex-column m-auto">
                      <img
                        className="rounded-circle"
                        src={formik.values.profile_image}
                        // src={}
                        alt=""
                        style={{ height: "150px", width: "150px" }}
                      />
                      {Boolean(
                        formik.touched.profile_image &&
                          formik.errors.profile_image
                      ) && (
                        <small className="text-danger">
                          {formik.errors.profile_image}
                        </small>
                      )}
                      <input
                        type="file"
                        id="upload-img"
                        style={{ display: "none" }}
                        onChange={handleChangeImage}
                      />
                      <label htmlFor="upload-img" className="upload-img">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z"></path>
                        </svg>
                      </label>
                    </div>
                  </div>
                  <div className="pl-lg-4">
                    <div className="row">
                      <div className="col-lg-4">
                        <label className="form-control-label" htmlFor="title">
                          Select From Existing Residents
                        </label>
                        <select
                          name="total_member"
                          className="form-select"
                          aria-label="Default select example"
                          onClick={handleExistingUser}
                          // onChange={handleApiUser}
                          {...formik.getFieldProps("total_member")}
                        >
                          <option value="">Select a member</option>
                          {console.log("list", listUser)}

                          {listUser.length > 0 &&
                            listUser?.map((ele, index) => (
                              <>
                                <option
                                  value={ele._id}
                                >{`${ele?.first_name} ${ele?.last_name} `}</option>
                              </>
                            ))}

                          {/* <option value="1">Member one</option> */}
                        </select>
                        {formik.touched.total_member &&
                          formik.errors.total_member && (
                            <small className="text-danger">
                              {formik.errors.total_member}
                            </small>
                          )}

                        {formik.touched.total_member &&
                          formik.errors.total_member && (
                            <small className="text-danger">
                              {formik.errors.total_member}
                            </small>
                          )}
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group focused mb-2">
                          <label
                            className="form-control-label"
                            htmlFor="input-firstName"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-alternative"
                            placeholder="Lucky"
                            name="first_name"
                            {...formik.getFieldProps("first_name")}
                          />
                          {formik.touched.first_name &&
                            formik.errors.first_name && (
                              <small className="text-danger">
                                {formik.errors.first_name}
                              </small>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mb-2">
                          <label
                            className="form-control-label"
                            htmlFor="input-lastName"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-alternative"
                            placeholder="Jesse"
                            name="last_name"
                            {...formik.getFieldProps("last_name")}
                          />
                          {formik.touched.last_name &&
                            formik.errors.last_name && (
                              <small className="text-danger">
                                {formik.errors.last_name}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group focused">
                          <label
                            className="form-control-label"
                            htmlFor="mobile-number"
                          >
                            Mobile Number
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-alternative"
                            placeholder="+91-98647-98765"
                            name="mobile_number"
                            {...formik.getFieldProps("mobile_number")}
                          />
                          {formik.touched.mobile_number &&
                            formik.errors.mobile_number && (
                              <small className="text-danger">
                                {formik.errors.mobile_number}
                              </small>
                            )}
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-group focused">
                          <label
                            className="form-control-label"
                            htmlFor="mobile-number"
                          >
                            Phone Number
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-alternative"
                            placeholder="98647-98765"
                            name="phone_number"
                            {...formik.getFieldProps("phone_number")}
                          />
                          {formik.touched.phone_number &&
                            formik.errors.phone_number && (
                              <small className="text-danger">
                                {formik.errors.phone_number}
                              </small>
                            )}
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-group focused">
                          <label
                            className="form-control-label"
                            htmlFor="user-role"
                          >
                            Role
                          </label>

                          <select
                            id="user-role"
                            name="user_role"
                            className="form-control form-control-alternative"
                            onClick={handleUserRole}
                            {...formik.getFieldProps("user_role")}
                          >
                            <option value="" label="Select role" />
                            <option value="admin" label="Admin" />
                            {/* <option value="security" label="Security" /> */}
                            <option value="management" label="Management" />
                          </select>

                          {formik.touched.user_role &&
                            formik.errors.user_role && (
                              <small className="text-danger">
                                {formik.errors.user_role}
                              </small>
                            )}
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-group focused mb-2">
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email ID
                          </label>
                          <input
                            type="email"
                            className="form-control form-control-alternative"
                            placeholder="Lucky@gmail.com"
                            name="email"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <small className="text-danger">
                              {formik.errors.email}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group focused mb-2">
                          <label
                            className="form-control-label"
                            htmlFor="input-designation"
                          >
                            Designation
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-alternative"
                            placeholder="Software Developer"
                            name="designation"
                            {...formik.getFieldProps("designation")}
                          />
                          {formik.touched.designation &&
                            formik.errors.designation && (
                              <small className="text-danger">
                                {formik.errors.designation}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4"></div>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <div className="d-flex justify-content-center align-items-center gap-2">
                    <button
                      className="btn btn-outline-primary"
                      onClick={handleBack}
                    >
                      Back
                    </button>
                    <button
                      onClick={handleSubmit}
                      className="btn btn-primary"
                      type="submit"
                      disabled={stores.isLoading}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
