import React from "react";
import {
  MdApartment,
  MdDeleteOutline,
  MdOutlineAdd,
  MdOutlineEdit,
} from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Loading from "../../ManageApp/data/Loading";
import { Sidebar } from "primereact/sidebar";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { addWings, deleteWings, editWings } from "../../../utils/asyncFun";

const WingList = (props) => {
  const history = useHistory();
  const [data, setdata] = useState([]);
  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleRight2, setVisibleRight2] = useState(false);
  // const [visible, setVisible] = useState(false);
  const { id } = props;

  const token = localStorage.getItem("token");
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);
  const [wingData, setWingData] = useState(null);
  const [datas, setDatas] = useState({
    name: "",
    contact_number: "",
    society_id: id,
  });
  const dispatch = useDispatch();

  const addWing = () => {
    setVisibleRight(true);
  };
  const editWing = () => {
    setVisibleRight2(true);
  };

  useEffect(() => {
    datafetch();
  }, [currentPage]); // Fetch data whenever currentPage changes

  const datafetch = () => {
    // setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getwings`,
      headers: { Authorization: "Bearer " + token },
      params: { limit, offset, society_id: id },
    };

    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.count);
        console.log(response.data);
        setdata(response.data);
        setIsLoading(false);
        console.log(id);
        console.log(response.data.data.length);
        setentry(response.data.data.length + offset);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  if (isLoading) {
    return <Loading />;
  }

  const deletePost = () => {
    console.log("delete");

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
        };
        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            datafetch();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const handleSubmit = async (e, field, value) => {
    e.preventDefault();
    if (field == "ADD_WINGS") {
      if (datas.name) {
        dispatch(
          addWings({ method: "POST", url: "addWings", data: datas })
        ).then(() => {
          setDatas({ name: "", contact_number: "", society_id: id });
          datafetch();
          setVisibleRight(false);
          Swal.fire("Saved!", "", "success");
        });
      }
    } else if (field == "DELETE_WING") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(
            deleteWings({
              method: "POST",
              url: "deleteWings",
              data: { _id: value },
            })
          ).then(() => {
            datafetch();
            Swal.fire("Deleted!", "", "success");
            setVisibleRight(false);
          });
        }
      });
    } else {
      dispatch(
        editWings({ method: "POST", url: "editWings", data: wingData })
      ).then(() => {
        setWingData(null);
        datafetch();
        setVisibleRight2(false);
        Swal.fire("Saved!", "", "success");
      });
    }
  };

  return (
    <>
      <Sidebar
        className="right-sidebar"
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        {/* <h5>Add Wing</h5> */}
        <div class="sidebar-main mt-3">
          <form>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Wing Name
              </label>
              <input
                type="text"
                onChange={(e) => setDatas({ ...datas, name: e.target.value })}
                className="form-control"
                placeholder="Enter name here"
                required
              />
            </div>

            <div class="mb-3">
              {/* <label for="mobileNumber" class="form-label">
                Add Contact
              </label> */}
              {/* <input
                type="text"
                onChange={(e) =>
                  setDatas({ ...datas, contact_number: e.target.value })
                }
                className="form-control"
                id="mobileNumber"
                placeholder="Enter mobile number"
                required
              /> */}
            </div>
          </form>
        </div>
        <div className="d-flex footer gap-2 align-items-center">
          <button
            onClick={(e) => handleSubmit(e, "ADD_WINGS", "")}
            class="btn btn-primary rounded-4"
          >
            Add
          </button>
        </div>
      </Sidebar>

      <Sidebar
        className="right-sidebar"
        visible={visibleRight2}
        position="right"
        onHide={() => setVisibleRight2(false)}
      >
        <h5>Edit Wing</h5>
        <div class="sidebar-main mt-3">
          <form>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Wing Name
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) =>
                  setWingData({ ...wingData, name: e.target.value })
                }
                value={wingData?.name || ""}
              />
            </div>

            <div class="mb-3">
              <label for="mobilenm" class="form-label">
                Edit Contact
              </label>
              <input
                type="text"
                id="mobilenm"
                className="form-control"
                onChange={(e) =>
                  setWingData({ ...wingData, contact_number: e.target.value })
                }
                value={wingData?.contact_number || ""}
              />
            </div>
          </form>
        </div>
        <div className="d-flex footer gap-2 align-items-center">
          <button
            onClick={(e) => handleSubmit(e, "UPDATE_WINGS", "")}
            class="btn btn-primary rounded-4"
          >
            Update
          </button>
        </div>
      </Sidebar>

      <div className="table_header d-flex mb-3">
        <div className="d-flex flex-wrap align-items-center">
          <button
            onClick={() => history.goBack()}
            className="btn me-2 btn-sm btn-primary rounded-4 "
          >
            Back
          </button>
          <h5 className="mb-0 fs-6 d-flex align-item-center gap-2">
            <MdApartment /> All Wings List
          </h5>
        </div>
        <button
          onClick={() => addWing()}
          className="btn rounded-5 ms-auto btn-primary"
        >
          Add New
        </button>
      </div>
      {data.data && data.data.length > 0 ? (
        <div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">WING NAME</th>
                  <th scope="col">TOTAL APARTMENT</th>
                  <th scope="col">TOTAL RESIDENT</th>
                  {/* <th scope="col">CONTACT NO</th> */}
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {data.data &&
                  data.data.map((data, idx) => (
                    <tr>
                      {/* <th scope="row">{idx + 1}</th> */}
                      <th scope="row">
                        {(currentPage - 1) * pageSize + idx + 1}
                      </th>

                      <td>
                        <div className="text-uppercase">{data.name}</div>
                      </td>
                      <td>
                        <div className="text-uppercase">
                          {data.apartment_count}
                        </div>
                      </td>
                      <td>
                        <div className="text-uppercase ">
                          <button className="Rsdnt-btn">
                            <Link
                              // href={{
                              //   pathname: `/manage-wing-resident/${data._id}`,
                              //   query: { id: data._id },

                              // }}
                              to={{
                                pathname: `/manage-wing-resident/${data.society_id}/${data?._id}`,
                                state: { ...props.dataToPass, data },
                              }}
                            >
                              {data.resident_count}
                            </Link>
                          </button>
                        </div>
                      </td>
                      {/* <td></td> */}
                      <td>
                        <div className="action_btn cstm-actns-bttns">
                          <span
                            onClick={() => {
                              editWing();
                              setWingData(data);
                            }}
                          >
                            <MdOutlineEdit />
                          </span>
                          <span
                            onClick={(e) =>
                              handleSubmit(e, "DELETE_WING", data._id)
                            }
                          >
                            <MdDeleteOutline />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="table-footer d-md-flex justify-content-md-between align-items-center">
            <p className="mb-0">
              Showing {offsetentry + 1} to {entry} of{" "}
              <span id="total-entries">{totalItems}</span> entries
            </p>
            <nav>
              <ul className="pagination pagination-md">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>

                {pageNumbers.map((pageNumber) => {
                  // Define the minimum and maximum page numbers to display
                  const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                  const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                  if (pageNumber >= minPage && pageNumber <= maxPage) {
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                <li className="page-item">
                  <button className="page-link" onClick={nextPage}>
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <h4>No data available</h4>
      )}
    </>
  );
};

export default WingList;
