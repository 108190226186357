import React from "react";
import user from "../../../assets/images/avatar.jpg";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { MdOutlineEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import No_image_available from "../../../assets/images/avatar.jpg";
import Swal from "sweetalert2";
import Loading from "../../ManageApp/data/Loading";

const MyTable = ({ id, imageUrl, societyName }) => {
  const dataToPass = { image: imageUrl, society: societyName, society_id: id };

  const history = useHistory();
  const token = localStorage.getItem("token");
  const [data, setdata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [image, setimage] = useState("");
  const [society, setsociety] = useState("");

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);

  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    datafetch();
  }, [searchQuery, currentPage]);

  const datafetch = () => {
    setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getTenant`,
      headers: { Authorization: "Bearer " + token },
      // params: { limit, offset }
      params: { search: searchQuery, society_id: id, limit, offset },
    };

    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.count);
        console.log(response.data);
        setdata(response.data);
        setIsLoading(false);
        console.log(response.data.data.length);
        setentry(response.data.data.length + offset);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const deleteconfirm = (ID, imageUrl, societyName) => {
    console.log("delete");
    console.log(ID);
    setimage(imageUrl);
    setsociety(societyName);
    console.log(imageUrl);
    console.log(societyName);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let config = {
          method: "patch",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_API}deleteTenant/` + ID,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        };
        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            datafetch();
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    });
  };

  if (isLoading && searchQuery === "") {
    return <Loading />;
  }

  return (
    <>
      <div className="table_header mb-3">
        <div className="d-flex flex-wrap ">
          <div className="d-flex flex-wrap align-items-center">
            {/* <Link to="/manage-society" className='btn ms-auto me-2 btn-sm btn-primary rounded-4 '>Back</Link> */}
            <button
              onClick={() => window.history.back()}
              className="btn me-2 btn-sm btn-primary rounded-4 "
            >
              Back
            </button>
            <h4 className="mb-0 fs-5">All Tenants</h4>
          </div>
          <div className="ms-auto d-flex align-items-center gap-2">
            <div class="search_box">
              <input
                placeholder="Search"
                class="chakra-input "
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div class="search_icon "></div>
            </div>
            <Link to={{ pathname: `/add-tenant/${id}`, state: { imageUrl, societyName } }} >
              <button className="btn rounded-5 btn-primary">Add New</button>
            </Link>
          </div>
        </div>
      </div>
      {data.data && data.data.length > 0 ? (
        <div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">USER INFO</th>
                  <th scope="col">PHONE</th>
                  <th scope="col">SOCIETY</th>
                  <th scope="col">OWNER</th>
                  <th scope="col">DUE RENT</th>
                  <th scope="col">APARTMENT</th>
                  <th scope="col">STATUS</th>
                  <th scope="col">DOC</th>
                  <th scope="col">START DATE</th>
                  <th scope="col">EXPIRY DATE</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>

              <tbody>
                {data.data &&
                  data.data.map((data, idx) => (
                    <tr>
                      {/* {(currentPage - 1) * pageSize + idx + 1} */}
                      <th scope="row">
                        {(currentPage - 1) * pageSize + idx + 1}
                      </th>
                      <td>
                        <div className="user_info d-flex  align-items-center">
                          {/* <img src={data.profile_image} alt="user" className="avatar" /> */}
                          {data.profile_image ? (
                            <LazyLoadImage
                              src={data.profile_image}
                              alt="Profile"
                            />
                          ) : (
                            <LazyLoadImage
                              src={No_image_available}
                              alt="No profile image"
                            />
                          )}
                          <div className="ms-3">
                            <strong>
                              <span className="txt-head">
                                {data.first_name + " " + data.last_name}
                              </span>
                            </strong>
                            <p className="txt-bottom mb-0">{data.email}</p>
                          </div>
                        </div>
                      </td>
                      <td>{data.phone_primary}</td>
                      <td>
                        {data.socity_details &&
                          data.socity_details.map((societyDetail, idx) => (
                            <div key={idx}>
                              {/* Other elements */}
                              <p>{societyDetail.society_name}</p>
                              {/* Other elements */}
                            </div>
                          ))}
                      </td>
                      <td>{data?.user_details[0]?.first_name + " " + data?.user_details[0]?.last_name}</td>
                      <td>
                        {" "}
                        {data?.rent_due_amount ? (
                          "₹" + data?.rent_due_amount
                        ) : (
                          <i
                            className="pi pi-times"
                            style={{ color: "red" }}
                          ></i>
                        )}
                      </td>
                      <td>
                        {data.wingsAndApartments &&
                          data.wingsAndApartments.length > 0 ? (
                          data.wingsAndApartments.map((wingApartment, idx) => (
                            <tr key={idx}>
                              {/* Other table cells */}
                              <td>
                                {wingApartment.wing.name}{" "}
                                {wingApartment.apartments[0].appartmentNumber}
                              </td>
                              {/* Other table cells */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>
                              <i
                                className="pi pi-times"
                                style={{ color: "red" }}
                              ></i>
                            </td>
                          </tr>
                        )}
                      </td>

                      <td>
                        <div className="">
                          {/* <i className="pi pi-check" style={{ color: 'slateblue' }}></i> */}
                          {data.status}
                        </div>
                      </td>
                      <td>
                        <div className="">
                          {data.upload_document &&
                            data.upload_document.length > 0 ? (
                            <i
                              className="pi pi-check"
                              style={{ color: "slateblue" }}
                            ></i>
                          ) : (
                            <i
                              className="pi pi-times"
                              style={{ color: "red" }}
                            ></i>
                          )}
                          {/* <i className="pi pi-check" style={{ color: 'slateblue' }}></i> */}
                        </div>
                      </td>
                      <td>
                        {data.agreement_start_date ? (
                          new Date(data.agreement_start_date)
                            .toISOString()
                            .split("T")[0]
                        ) : (
                          <i
                            className="pi pi-times"
                            style={{ color: "red" }}
                          ></i>
                        )}
                      </td>
                      <td>
                        {" "}
                        {data.agreement_end_date ? (
                          new Date(data.agreement_end_date)
                            .toISOString()
                            .split("T")[0]
                        ) : (
                          <i
                            className="pi pi-times"
                            style={{ color: "red" }}
                          ></i>
                        )}{" "}
                      </td>

                      <td>
                        <div className="action_btn">
                          {/* <Link to="/view-tenant" ><MdOutlineRemoveRLinkedEye /></Link> */}
                          <div>

                            <Link
                              to={{
                                pathname: `/view-tenant/${data._id}/${id}`,
                                state: {
                                  ...dataToPass,
                                  uploadDocumentLink: data.upload_document, // Include the upload document link in the state
                                },
                              }}
                            >
                              <MdOutlineRemoveRedEye />
                            </Link>
                            
                          </div>
                          <div>
                            <button
                              onClick={() =>
                                deleteconfirm(data._id, imageUrl, societyName)
                              }
                              style={{
                                border: "none",
                                background: "none",
                                cursor: "pointer",
                              }}
                            >
                              <MdDeleteOutline />
                            </button>
                          </div>
                        </div>
                      </td>
                      {/* <td>{data.first_name + " " + data.last_name}</td>
                  <td>-</td>
                  <td>{data.mobile_number}</td> */}
                      {/* <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i>Active</div></td> */}
                      {/* <td>
                    <div className="">{data.status}</div>
                  </td> */}
                    </tr>
                  ))}

                {/*                         
                    <tr>
                            <th scope="row">1</th>
                            <td><div className='user_info d-flex  align-items-center'>

                                <img src={user} alt="user" className='avatar' />
                                <div className='ms-3'>
                                    <strong><span className='txt-head'>John Sons</span></strong>
                                    <p className='txt-bottom mb-0'>JohnSons13@gmail.com</p>
                                </div>

                            </div></td>
                            <td>987654310</td>
                            <td>Hai San Secret Society</td>
                            <td>John Cena</td>
                            <td>$ 50</td>
                            <td>200</td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i></div></td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i></div></td>
                            <td>05-08-2024</td>
                            <td>05-08-2025</td>
                            <td><div className='action_btn'>
                                <Link to="/view-tenant" ><MdOutlineRemoveRedEye /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr> */}
              </tbody>
              {/* <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td><div className='user_info d-flex  align-items-center'>

                                <img src={user} alt="user" className='avatar' />
                                <div className='ms-3'>
                                    <strong><span className='txt-head'>John Sons</span></strong>
                                    <p className='txt-bottom mb-0'>JohnSons13@gmail.com</p>
                                </div>

                            </div></td>
                            <td>987654310</td>
                            <td>Hai San Secret Society</td>
                            <td>John Cena</td>
                            <td>$ 50</td>
                            <td>200</td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i></div></td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i></div></td>
                            <td>05-08-2024</td>
                            <td>05-08-2025</td>
                            <td><div className='action_btn'>
                                <Link to="/view-tenant" ><MdOutlineRemoveRedEye /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td><div className='user_info d-flex  align-items-center'>

                                <img src={user} alt="user" className='avatar' />
                                <div className='ms-3'>
                                    <strong><span className='txt-head'>John Sons</span></strong>
                                    <p className='txt-bottom mb-0'>JohnSons13@gmail.com</p>
                                </div>

                            </div></td>
                            <td>987654310</td>
                            <td>Hai San Secret Society</td>
                            <td>John Cena</td>
                            <td>$ 50</td>
                            <td>200</td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i></div></td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i></div></td>
                            <td>05-08-2024</td>
                            <td>05-08-2025</td>
                            <td><div className='action_btn'>
                                <Link ><MdOutlineRemoveRedEye /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td><div className='user_info d-flex  align-items-center'>

                                <img src={user} alt="user" className='avatar' />
                                <div className='ms-3'>
                                    <strong><span className='txt-head'>John Sons</span></strong>
                                    <p className='txt-bottom mb-0'>JohnSons13@gmail.com</p>
                                </div>

                            </div></td>
                            <td>987654310</td>
                            <td>Hai San Secret Society</td>
                            <td>John Cena</td>
                            <td>$ 50</td>
                            <td>200</td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i></div></td>
                            <td><div className=''><i className="pi pi-check" style={{ color: 'slateblue' }}></i></div></td>
                            <td>05-08-2024</td>
                            <td>05-08-2025</td>
                            <td><div className='action_btn'>
                                <Link ><MdOutlineRemoveRedEye /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>

                    </tbody> */}
            </table>
          </div>
          {/* <div className="table-footer">
                <ul class="pagination m-sm-auto pagination-md justify-content-end">
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Previous">&laquo;</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">1</Link></li>
                    <li class="page-item active"><Link class="page-link " href="#">2</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">3</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">4</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">5</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">6</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">7</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">8</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">9</Link></li>
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Next">&raquo;</Link></li>
                </ul>
            </div> */}

          <div className="table-footer d-md-flex justify-content-md-between align-items-center">
            <p className="mb-0">
              Showing {offsetentry + 1} to {entry} of{" "}
              <span id="total-entries">{totalItems}</span> entries
            </p>
            <nav>
              <ul className="pagination pagination-md">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>

                {pageNumbers.map((pageNumber) => {
                  // Define the minimum and maximum page numbers to display
                  const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
                  const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed

                  if (pageNumber >= minPage && pageNumber <= maxPage) {
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${currentPage === pageNumber ? "active" : ""
                          }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                <li className="page-item">
                  <button className="page-link" onClick={nextPage}>
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <h4>No data available</h4>
      )}
      {/* <nav>
                <ul className='pagination justify-content-center'>
                    <li className="page-item">
                        <button className="page-link" onClick={prevPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                    </li>
                    {pageNumbers.map((pageNumber) => (
    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
      <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>
        {pageNumber}
      </button>
    </li>
  ))}

                
                    <li className="page-item">
                        <button className="page-link" onClick={nextPage}  disabled={currentPage === totalPages} >
                            Next
                        </button>
                    </li>

                   
                </ul>
            </nav> */}
      {/* disabled={currentPage === nPages} */}
      {/* {pageNumbers.map(pgNumber => (
                        <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}>
                            <button className='page-link' onClick={() => setCurrentPage(pgNumber)}>
                                {pgNumber}
                            </button>
                        </li>
                    ))} */}
    </>
  );
};

export default MyTable;
