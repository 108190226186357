import React from "react";
// import "../assets/css/loading.css";

import { ProgressSpinner } from "primereact/progressspinner";

function Loading() {
  return (
    // <div className="loader-container">
    // <div className="loader">Loading...</div>

    // </div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <ProgressSpinner
        style={{ width: "50px", height: "50px" }}
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      />
    </div>

    // <div className="spinner-container">
    //   <div className="loading-spinner"></div>
    // </div>
  );
}

export default Loading;
