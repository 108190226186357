import React from 'react'
import ApartmentList from './data/ApartmentList'

export default function AddApartment() {
    return (
        <div className='card p-3'>
            <div className='table-body'>
                <ApartmentList />
            </div>
        </div>
    )
}
