
import Post from './components/Post'
import team1 from "../../assets/images/broken-robot.png"
import { FcClock } from 'react-icons/fc'
import React, { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { HiDotsVertical } from "react-icons/hi";
import Poll from './components/Poll';
import Discussion from './components/Discussion';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import SocietyHeader from '../../components/societyHeader';
import logo1 from "../../assets/images/hamptom.png"


export default function ViewDiscussions() {

  const home = { icon: 'pi pi-home' }

  const itemss = [
    { label: 'Dashboard' },
    { label: 'Manage Society' },
    { label: 'View Discussion' }
  ];


  const avatar = "https://source.unsplash.com/random/200x200?sig=1"

  const menu = useRef(null);
  const toast = useRef(null);
  const items = [
    {
      label: 'Options',
      items: [
        // {
        //   label: 'Update',
        //   icon: 'pi pi-refresh',
        //   command: () => {
        //     toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
        //   }
        // },
        {
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => {
            toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
          }
        }
      ]
    },

  ]




  return (

    <div>

      <div className='d-flex align-items-center bg-white rounded-2'>
        <BreadCrumb model={itemss} home={home} />
        <Link to="/community-management/society" className='btn ms-auto me-2 btn-sm btn-primary rounded-4 '>Back</Link>
      </div>

      <div className='header'>
        <SocietyHeader
          imageUrl={logo1}
          societyName="The Hampton Homes"
          defaultRouter="/dashboard" />
      </div>

      <div className="card p-4 shadow-lg border-0">
        <div className="row mb-4">
          <div className="col">
            <h4 className="mb-0">Discussion</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-lg-5">
            {/* Assuming Poll is a custom component */}
            <div className='position-sticky top-0 '>
              <Discussion
                avatar={team1}
                name="John mack"
                apartment="D 005"
                date="2 July 2023"
                time="11:00 AM"
                description=" Would you be willing to join a 30 min Appreaz demo for residents of Jalvayu Vihar?"
                VotingList="200"
                comments="22"
                share="20"
              />
            </div>

          </div>
          <div className='col-lg-7 col-md-12'>
            <div className="comments_container">
              <ul className='comment-list list-unstyled' >
                <li className="p-2" >
                  <div className="d-flex justify-content-start align-items-center">
                    <img src={avatar} alt="Avatar" className="me-3 rounded-circle" style={{ height: '48px', width: '48px', marginRight: '20px' }} />
                    <div className="d-flex flex-column align-items-start">
                      <h6 className="text-dark font-weight-bold mb-0">John Doe</h6>
                      <div className="d-flex align-items-center gap-2">
                        <p className="text-secondary m-0 me-3 fs-6">D 0005</p>
                        <FcClock color="#6c757d" size="15" />
                        <span className="text-secondary m-0">2 weeks ago</span>
                      </div>
                    </div>
                    <div className='ms-auto'>
                      <Toast ref={toast}></Toast>

                      <Menu model={items} popup ref={menu} id="popup_menu" />
                      <button className='btn shadow-sm ' label="Show" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup >
                        <HiDotsVertical />
                      </button>

                    </div>
                  </div>
                  <div className="my-2">
                    <p className="text-dark font-weight-bold mt-2">
                      A Special General Body Meeting of the Society may be called at any time
                      <a href="#" className="text-dark ms-2">Read More</a>
                    </p>
                  </div>
                </li>
                <li className="p-2" >
                  <div className="d-flex justify-content-start align-items-center">
                    <img src={avatar} alt="Avatar" className="me-3 rounded-circle" style={{ height: '48px', width: '48px', marginRight: '20px' }} />
                    <div className="d-flex flex-column align-items-start">
                      <h6 className="text-dark font-weight-bold mb-0">John Doe</h6>

                      <div className="d-flex align-items-center gap-2">
                        <p className="text-secondary m-0 me-3">D 0005</p>
                        <FcClock color="#6c757d" size="15" />
                        <p className="text-secondary m-0">2 weeks ago</p>
                      </div>
                    </div>
                    <div className='ms-auto'>
                      <Toast ref={toast}></Toast>

                      <Menu model={items} popup ref={menu} id="popup_menu" />
                      <button className='btn shadow-sm ' label="Show" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup >
                        <HiDotsVertical />
                      </button>

                    </div>
                  </div>
                  <div className="my-2">

                    <p className="text-dark font-weight-bold mt-2">
                      A Special General Body Meeting of the Society may be called at any time
                      <a href="#" className="text-dark ms-2">Read More</a>
                    </p>
                  </div>
                  <div className="ms-4 p-2" >
                    <div className="d-flex justify-content-start align-items-center">
                      <img src={avatar} alt="Avatar" className="me-3 rounded-circle" style={{ height: '48px', width: '48px', marginRight: '20px' }} />
                      <div className="d-flex flex-column align-items-start">
                        <h6 className="text-dark font-weight-bold mb-0">John Doe</h6>

                        <div className="d-flex align-items-center gap-2">
                          <p className="text-secondary m-0 me-3">D 0005</p>
                          <FcClock color="#6c757d" size="15" />
                          <p className="text-secondary m-0">2 weeks ago</p>
                        </div>
                      </div>
                      <div className='ms-auto'>
                        <Toast ref={toast}></Toast>

                        <Menu model={items} popup ref={menu} id="popup_menu" />
                        <button className='btn shadow-sm ' label="Show" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup >
                          <HiDotsVertical />
                        </button>

                      </div>
                    </div>
                    <div className="my-2">
                      <p className="text-dark font-weight-bold mt-2">
                        A Special General Body Meeting of the Society may be called at any time
                        <a href="#" className="text-dark ms-2">Read More</a>
                      </p>
                    </div>

                  </div>

                </li>

                <li className="p-2" >
                  <div className="d-flex justify-content-start align-items-center">
                    <img src={avatar} alt="Avatar" className="me-3 rounded-circle" style={{ height: '48px', width: '48px', marginRight: '20px' }} />
                    <div className="d-flex flex-column align-items-start">
                      <h6 className="text-dark font-weight-bold mb-0">John Doe</h6>

                      <div className="d-flex align-items-center gap-2">
                        <p className="text-secondary m-0 me-3 fs-6">D 0005</p>
                        <FcClock color="#6c757d" size="15" />
                        <span className="text-secondary m-0">2 weeks ago</span>
                      </div>
                    </div>
                    <div className='ms-auto'>
                      <Toast ref={toast}></Toast>

                      <Menu model={items} popup ref={menu} id="popup_menu" />
                      <button className='btn shadow-sm ' label="Show" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup >
                        <HiDotsVertical />
                      </button>

                    </div>
                  </div>
                  <div className="my-2">

                    <p className="text-dark font-weight-bold mt-2">
                      A Special General Body Meeting of the Society may be called at any time
                    </p>
                  </div>
                </li>

                <li className="p-2" >
                  <div className="d-flex justify-content-start align-items-center">
                    <img src={avatar} alt="Avatar" className="me-3 rounded-circle" style={{ height: '48px', width: '48px', marginRight: '20px' }} />
                    <div className="d-flex flex-column align-items-start">
                      <h6 className="text-dark font-weight-bold mb-0">John Doe</h6>

                      <div className="d-flex align-items-center gap-2">
                        <p className="text-secondary m-0 me-3 fs-6">D 0005</p>
                        <FcClock color="#6c757d" size="15" />
                        <span className="text-secondary m-0">2 weeks ago</span>
                      </div>
                    </div>
                    <div className='ms-auto'>
                      <Toast ref={toast}></Toast>

                      <Menu model={items} popup ref={menu} id="popup_menu" />
                      <button className='btn shadow-sm' label="Show" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup >
                        <HiDotsVertical />
                      </button>

                    </div>
                  </div>
                  <div className="my-2">

                    <p className="text-dark font-weight-bold mt-2">
                      A Special General Body Meeting of the Society may be called at any time
                      <a href="#" className="text-dark ms-2">Read More</a>
                    </p>
                  </div>
                </li>
              </ul>

            </div>
          </div>
        </div>

      </div>

    </div>
  )
}
