import React from "react";
import {
  MdApartment,
  MdDeleteOutline,
  MdOutlineAdd,
  MdOutlineEdit,
} from "react-icons/md";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Loading from "../../ManageApp/data/Loading";
import { Sidebar } from "primereact/sidebar";
import {
  getApartmentDetail,
  updateApartmentDetail,
} from "../../../utils/asyncFun";
import { useDispatch, useSelector } from "react-redux";
import { mutateDetail } from "../../../library/store/slice";
import { Button } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";

const Apartment = (props) => {
  const history = useHistory();
  const [data, setdata] = useState([]);
  const [visible, setVisible] = useState(false);
  const { id } = props;
  const [appartmentToggle, setAppartmentToggle] = useState(true);
  const [wingId, setWingId] = useState("");

  const token = localStorage.getItem("token");
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [offsetentry, setoffsetentry] = useState(0);
  const [entry, setentry] = useState(0);
  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleRight2, setVisibleRight2] = useState(false);

  const [filterByWing, setFilterByWing] = useState([]);
  const [filterByApartmentFloor, setFilterByApartmentFloor] = useState([]);
  const [appartmentFloorId,setApartmentFloorId] = useState("");
  const [typeOfApartment,setTypeOfAppartment]=useState([]);

  const dispatch = useDispatch();
  const stores = useSelector((store) => store.data.detail);

  console.log("stores------->", stores);

  const initialValues={
    apartmentNumber: "",
    wingid: "",
    apartmentFloorId: "",
    apartmentType: "",

  };
  const validationSchema = Yup.object({
    apartmentNumber: Yup.string().required("Apartment Number is required"),
    wingid: Yup.string().required("Wing is required"),
    apartmentFloorId: Yup.string().required("Apartment Floor is required"),
    apartmentType: Yup.string().required("Apartment Type is required"),

  });


  const formik = useFormik({
   initialValues:initialValues ,
    validationSchema,

  });

  const addUnits = () => {
    setVisibleRight(true);
  };

  const handleSubmitApartment=()=>{

  
      // e.preventDefault();
  
      formik.setTouched(
        Object.keys(formik.values).reduce((acc, fieldName) => {
          acc[fieldName] = true;
          return acc;
        }, {})
      );
  
      console.log(formik.touched);
  
      formik.validateForm().then(async (errors) => {
        // console.log("formik.values", formik.values);
        console.log("formik.errors", formik.errors);
        if (Object.keys(errors).length === 0) {
          console.log(formik.values)

          
            console.log("floor",id,formik.values.wingid)
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `${process.env.REACT_APP_BASE_API}createAppartment`,
              headers: { Authorization: "Bearer " + token },
              data: {
                society_id: id,
                wing_id: formik.values.wingid,
                floor_number:formik.values. apartmentFloorId,
                appartmentNumber:formik.values.apartmentNumber,
                appartment_type:formik.values.apartmentType,

              },
            };
        
            axios
              .request(config)
              .then((response) => {
                console.log(response.data.data);
                Swal.fire({
                  icon: "success",
                  title: "Success...",
                  text: "successfully submited",
                });
                formik.resetForm();
           setVisibleRight(false);

              
              })
              .catch((error) => {
                console.log(error);
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                });
              });
          
        }
      });
    

  }

  const editUnits = (data) => {
    setVisibleRight2(true);
    dispatch(
      getApartmentDetail({
        method: "GET",
        url: `getApartmentDetail?_id=${data._id}`,
        data: "",
      })
    );
  };

  const filterByWings = () => {

    let config = {
      method: "get",
      // maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}listOfWings`,
      headers: { Authorization: "Bearer " + token },
      params: {
        society_id: id,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data.data);
        setFilterByWing(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const filterByAppartementFloor = () => {
    console.log("floor",id,formik.values.wingid)
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}listOfappartmentFloor`,
      headers: { Authorization: "Bearer " + token },
      data: {
        society_id: id,
        wing_id: formik.values.wingid, // Ensure the key matches Formik's field name
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data.data);
        setFilterByApartmentFloor(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  useEffect(() => {
    datafetch();
   
  }, [currentPage, appartmentToggle, wingId, appartmentFloorId]);

  useEffect(() => {
    filterByWings();
    getTypeOfAppartment();
  
  }, []);
  useEffect(() => {
    if(formik.values.wingid){
    filterByAppartementFloor();
         
    }
    

  }, [formik.values.wingid]);

  console.log(filterByWing);

  const datafetch = () => {
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;
    setoffsetentry(offset);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getappartment`,
      headers: { Authorization: "Bearer " + token },
      params: {
        limit,
        offset,
        society_id: id,
        sort: appartmentToggle ? 1 : -1,
        wing: wingId,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setTotalItems(response.data.count);
        console.log(response.data);
        setdata(response.data);
        setIsLoading(false);
        console.log(id);
        console.log(response.data.data.length);
        setentry(response.data.data.length + offset);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };
  const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const totalPages = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  if (isLoading) {
    return <Loading />;
  }

  const handleSubmit = () => {
    dispatch(
      updateApartmentDetail({
        method: "PATCH",
        url: `updateApartmentDetail`,
        data: stores,
      })
    ).then(() => {
      setVisibleRight2(false);
      datafetch();
    });
  };
 function getTypeOfAppartment(){
    
    let config = {
      method: "get",
      // maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getTypeOfAppartment`,
      headers: { Authorization: "Bearer " + token },
      params: {
        limit:10
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setTypeOfAppartment(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  
 }






  const handleAppartmentToggle = () => {
    setAppartmentToggle((appartmentToggle) => !appartmentToggle);

    // datafetch()
  };

  return (
    <>
      <Sidebar
        className="right-sidebar"
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h5>Add Apartment</h5>
        <div class="sidebar-main mt-3">
          <form>
   

<div className="mb-3">
        <select
          className={
            formik.touched.wingid && formik.errors.wingid
              ? "border-danger form-select"
              : "form-select"
          }
          aria-label="Default select example"
          name="wingid"
          {...formik.getFieldProps("wingid")}
        >
          <option value="" label="Select wing" />
          {filterByWing?.length > 0 &&
            filterByWing.map((ele) => (
              <option key={ele._id} value={ele._id}>
                {ele.name}
              </option>
            ))}
        </select>
        {formik.touched.wingid && formik.errors.wingid && (
          <small className="text-danger">{formik.errors.wingid}</small>
        )}
      </div>

      <div className="mb-3">
        <select
          className={
            formik.touched.apartmentFloorId && formik.errors.apartmentFloorId
              ? "border-danger form-select"
              : "form-select"
          }
          aria-label="Default select example"
          name="apartmentFloorId"
          {...formik.getFieldProps("apartmentFloorId")}
        >
          <option value="" label="Select apartment floor" />
          {filterByApartmentFloor?.length > 0 &&
            filterByApartmentFloor.map((ele) => (
              <option key={ele?.floor_number} value={ele?.floor_number}>
                {ele.floor_number}
              </option>
            ))}
        </select>
        {formik.touched.apartmentFloorId && formik.errors.apartmentFloorId && (
          <small className="text-danger">{formik.errors.apartmentFloorId}</small>
        )}
      </div>

      <div className="mb-3">
        {console.log("typeOfApartment",typeOfApartment)}
        <select
          className={
            formik.touched.apartmentType && formik.errors.apartmentType
              ? "border-danger form-select"
              : "form-select"
          }
          aria-label="Default select example"
          name="apartmentType"
          {...formik.getFieldProps("apartmentType")}
        >
          <option value="" label="Select type of apartment" />
          {typeOfApartment.length>0 && typeOfApartment.map((ele,index)=>(
            <>
          <option value={ele._id}>{ele.title}</option>

            </>
          ))}
          {/* <option value="studio">Studio</option>
          <option value="1BHK">1 BHK</option>
          <option value="2BHK">2 BHK</option>
          <option value="3BHK">3 BHK</option> */}
        </select>
        {formik.touched.apartmentType && formik.errors.apartmentType && (
          <small className="text-danger">{formik.errors.apartmentType}</small>
        )}
      </div>

            {/* <div class="mb-3">
              <label for="chargeName" class="form-label">
                Apartment Floor
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Apartment Floor"
              />
            </div> */}

            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Apartment Number
              </label>
              <input
                type="text"
                name="apartmentNumber"
                className={
                  formik.touched.apartmentNumber && formik.errors.apartmentNumber
                    ? "border-danger form-control"
                    : "form-control"
                }
                // className="form-control"
                placeholder="Enter Apartment Number"
                {...formik.getFieldProps("apartmentNumber")}
              />
                     {formik.touched.apartmentNumber &&
                        formik.errors.apartmentNumber && (
                          <small className="text-danger">
                            {formik.errors.apartmentNumber}
                          </small>
                        )}
            </div>

            {/* <div class="mb-3">
              <label for="chargeName" class="form-label">
                Apartment Status
              </label>
              <select className="form-control">
                <option value="option 1">Select </option>
                <option value="option 2">Vacant</option>
                <option value="option 3">Occupied</option>
              </select>
            </div> */}
          </form>
        </div>
        <div className="d-flex footer gap-2 align-items-center">
          <button type="submit" class="btn btn-primary rounded-4" onClick={handleSubmitApartment}>
            Add
          </button>
        </div>
      </Sidebar>

      <Sidebar
        className="right-sidebar"
        visible={visibleRight2}
        position="right"
        onHide={() => setVisibleRight2(false)}
      >
        <h5>Edit Apartment</h5>
        <div class="sidebar-main mt-3">
          <form>
            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Apartment Floor
              </label>
              <input
                type="text"
                className="form-control"
                value={stores?.floor_number}
                name="floor_number"
                onChange={(e) => {
                  dispatch(
                    mutateDetail({
                      field: "floor_number",
                      value: e.target.value,
                    })
                  );
                }}
              />
            </div>

            <div class="mb-3">
              <label for="chargeName" class="form-label">
                Apartment Number
              </label>
              <input
                type="text"
                className="form-control"
                value={stores?.appartmentNumber}
                name="appartmentNumber"
                onChange={(e) => {
                  dispatch(
                    mutateDetail({
                      field: "appartmentNumber",
                      value: e.target.value,
                    })
                  );
                }}
              />
            </div>
          </form>
        </div>
        <div
          className="d-flex footer gap-2 align-items-center"
          onClick={() => handleSubmit()}
        >
          <button class="btn btn-primary rounded-4">Update</button>
        </div>
      </Sidebar>

      <div className="table_header d-flex mb-3">
        <div
          style={{
            width: "95vw",
          }}
          className="d-flex flex-wrap align-items-center justify-content-between pe-2 "
        >
          <div className="d-flex flex-wrap align-items-center">
            <button
              onClick={() => history.goBack()}
              className="btn me-2 btn-sm btn-primary rounded-4 "
            >
              Back
            </button>
            <h5 className="mb-0 fs-6 d-flex align-item-center gap-2">
              <MdApartment /> All Apartment List
            </h5>
          </div>

          <div className="d-flex justify-content-center align-items-center">
            
            <div class="mb-2 me-2">
              <select
                class="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  console.log(e.target.value);
                  setWingId(e.target.value);
                }}
              >
                <option value="" selected>
                  Select apartment wing
                </option>

                {filterByWing?.length > 0 &&
                  filterByWing?.map((ele, index) => (
                    <>
                      <option value={ele?._id}>{ele.name}</option>
                    </>
                  ))}
              </select>
            </div> 
            <button
              onClick={(e) => addUnits(e, "ADD_WINGS", "")}
              className="btn btn-primary rounded-4 mb-2"
            >
              Add Apartment
            </button>
            {/* <select class="form-select" aria-label="Default select example" onClick={(e)=>{console.log(e.target.value);setWingId(e.target.value)}}>
              <option value="" selected>Select wing</option>

              {filterByWing?.length>0 && filterByWing?.map((ele,index)=>(<>
                <option value={ele?._id}>{ele.name}</option>
              </>))}
            </select> */}
          </div>
        </div>

        {/* <button  onClick={() => addUnits()} className='btn ms-auto rounded-5 btn-primary'>Add New</button> */}
      </div>
      {data.data && data.data.length ? (
        <div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">APARTMENT FLOOR</th>
                  <th scope="col">WING</th>
                  <th
                    scope="col"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <p>APARTMENT NUMBER</p>
                    <Button onClick={() => handleAppartmentToggle()}>
                      {appartmentToggle ? <FaArrowUp /> : <FaArrowDown />}
                    </Button>
                  </th>
                  <th scope="col">VACANT</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {data.data &&
                  data.data.map((data, idx) => (
                    <tr key={idx}>
                      <th scope="row">
                        {(currentPage - 1) * pageSize + idx + 1}
                      </th>
                      <td>
                        <div className="text-uppercase">
                          {data.floor_number}
                        </div>
                      </td>
                      <td>
                        <div className="text-uppercase">
                          {data?.wing_id?.name}
                        </div>
                      </td>
                      <td>
                        <div className="text-uppercase">
                          {data.appartmentNumber}
                        </div>
                      </td>
                      <td>
                        <div>{data.is_vacant ? "Vacant" : "Occupied"}</div>
                      </td>
                      <td>
                        <div className="action_btn cstm-actns-bttns">
                          <span onClick={() => editUnits(data)}>
                            <MdOutlineEdit />
                          </span>
                          {/* <span onClick={() => deletePost()}  ><MdDeleteOutline /></span> */}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="table-footer d-md-flex justify-content-md-between align-items-center">
            <p className="mb-0">
              Showing {offsetentry + 1} to {entry} of{" "}
              <span id="total-entries">{totalItems}</span> entries
            </p>
            <nav>
              <ul className="pagination pagination-md">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>

                {pageNumbers.map((pageNumber) => {
                  const minPage = Math.max(1, currentPage - 4);
                  const maxPage = Math.min(totalPages, currentPage + 4);

                  if (pageNumber >= minPage && pageNumber <= maxPage) {
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                <li className="page-item">
                  <button className="page-link" onClick={nextPage}>
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <h4>No data available</h4>
      )}
    </>
  );
};

export default Apartment;
