// import doc from "../assets/images/Debit.png"
// import React from "react";
// import Family from "./Family";
// import { useState } from "react";


// export default function AllFamily(props) {
//   const {family}=props;

// //   const [data,setdata]=useState([]);

// // // const array=family;
// // for(let i=0;i<family.length;i++){
// //   if(family[i].role==="family"){
// //         setdata(family[i]);
// //   }
// // }

// // function test(){
// //   console.log(data);
// // }

//   return (
//     <div className="card shadow-lg border-0 p-3" mb={{ base: "0px" }}>
//       <div className=" pb-2">
//         <h5>
//           All Family Members
//         </h5>
//       </div>

//       {/* <button onClick={test}>Click</button> */}
//       {family.map((member, index) => {
//         if (member.role === "family") {
//       <Family
//         image={doc}
//         number='6287465534'
//         email='preetisharma@gmail.com'
//         title='Ankur Sharma'
//         relation="Brother"
//       />
//       );
//     } else {
//       return null; // Skip rendering if the role is not family
//     }
//   })}
//       {/* <Family
//         image={doc}
//         number='6287465534'
//         email='preetisharma@gmail.com'
//         title='Ankur Sharma'
//         relation="Brother"
//       />
//       <Family

//         image={doc}
//         number='6287465534'
//         email='preetisharma@gmail.com'
//         title='Ankur Sharma'
//         relation="Brother"
//       /> */}
//     </div>
//   );
// }



// import doc from "../assets/images/Debit.png";
// import React from "react";
// import Family from "./Family";

// export default function AllFamily(props) {
//   const { family } = props;

//   return (
//     <div className="card shadow-lg border-0 p-3" mb={{ base: "0px" }}>
//       <div className="pb-2">
//         <h5>All Family Members</h5>
//       </div>

//       {family.map((member, index) => {
//         if (member.role === "family") {
//           return (
//             <Family
//               key={index}
//               image={doc}
//               number={member.phone_primary}
//               email={member.email}
//               title={member.first_name+" "+member.first_name }
//               relation={member.Relation}
//             />
//           );
//         } else {
//           return null; // Skip rendering if the role is not family
//         }
//       })}
//     </div>
//   );
// }


import doc from "../assets/images/Debit.png";
import React from "react";
import Family from "./Family";

export default function AllFamily(props) {
  const { family } = props;

  return (
    <div className="card shadow-lg border-0 p-3" mb={{ base: "0px" }}>
      <div className="pb-2">
        <h5>All Family Members</h5>
      </div>

      {family && family.length > 0 ? (
    family.map((member, index) => {
      if (member.role === "family") {
        return (
          <Family
            key={index}
            image={member.profile_image}
            number={member.phone_primary}
            email={member.email}
            title={`${member.first_name} ${member.last_name}`}
            relation={member.Relation}
          />
        );
      } else {
        return null; // Skip rendering if the role is not family
      }
    })
  ) : (
    <span className="error-message" style={{color:"red"}}>No Family Members Added</span>
  )}

    </div>
  );
}
