import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { CgClose } from 'react-icons/cg';
import { classNames } from "primereact/utils";

const Wings = (props) => {

  const formik = props.formik;

  // const [numberOfWings, setNumberOfWings] = useState(6);
  // const [wings, setWings] = useState([]);

  // const generateInitialWings = (count) => {
  //   return Array.from({ length: count }, (_, index) => ({
  //     name: `Wing ${index + 1}`,
  //     numberOfApartments: 0,
  //     apartments: [],
  //   }));
  // };

  // useEffect(() => {
  //   setWings(generateInitialWings(numberOfWings));
  // }, [numberOfWings]);


  const handleNumberChange = (event) => {
    
    const newNumber = parseInt(event.target.value, 10) || 0;
    const currentWingCount = formik.values.wings.length;
    formik.setFieldValue("number", newNumber);
    formik.setFieldTouched("number", true);
    // If the new number is greater than the current number of boxes, add boxes
    if (newNumber > currentWingCount) {
      const additionalWings = newNumber - currentWingCount;
      const newWings = [...formik.values.wings];

      for (let i = 0; i < additionalWings; i++) {
        newWings.push({ name: "" , contact_number : ""});
      }

      formik.setFieldValue("wings", newWings);
    } else if (newNumber < currentWingCount) {
      // If the new number is less than the current number of boxes, remove boxes
      const removedWings = currentWingCount - newNumber;
      const newWings = formik.values.wings.slice(0, - removedWings);

      formik.setFieldValue("wings", newWings);
    }

    // Update the number field
    formik.handleChange(event);
  };




  const handleAddWingClose = (index) => {
    const newWings = [...formik.values.wings];
    console.log(index)
    newWings.splice(index, 1);
    formik.setFieldValue("wings", newWings);
    formik.setFieldValue("number", formik.values.number - 1);

    const touchWings = [...formik.touched.wings]
    touchWings.splice(index, 1);

    formik.setFieldTouched(`wings`, touchWings);
    // formik.setFieldTouched(`wings.${index}.name`, false);
    // formik.setFieldTouched(`wings.${index}.contact_number`, false);
    // formik.setFieldError(`wings.${index}.name`, undefined);
    // formik.setFieldError(`wings.${index}.contact_number`, undefined);
  };


  return (
    <div className="p-d-flex p-flex-column">
      <div className="p-fluid w-50 m-auto">
        <div className="p-field">
          <label htmlFor="totalParkingSlots">Total Wing Slots</label>
          <div className="p-inputgroup">
            <InputText
              id="totalParkingSlots"
              type="number"
              onChange={handleNumberChange}
              onBlur={formik.handleBlur}
              value={formik.values.number}
            />
          </div>
            {Boolean(formik.touched.number && formik.errors.number) && (
                <small className="p-error">{formik.errors.number}</small>
            )}
        </div>
      </div>
      <div className="container">
        <div className="row mb-4">
          {formik.values.wings.map((wing, wingIndex) => (
            <div
              className="col-xl-4 col-md-4 col-lg-4 col-sm-6 col-12 mb-0 p-2"
              key={wingIndex}
            >
              <div className="card border-0 shadow-lg p-3">
                <h4 className="fw-semi-bold fs-6 ">{wing.name}</h4>

                <CgClose
                  className="position-absolute p-cursor-pointer p-text-danger"
                  style={{
                    fontSize: '20px',
                    fontWeight: '800',
                    top: '10px',
                    right: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleAddWingClose(wingIndex)}
                />

                <div className="p-grid p-mr-1">
                  <div className="p-col-12">
                    <div className="p-field p-mb-2">
                      <label className="text-muted" htmlFor={`wings.${wingIndex}.name`}>Name of Wing</label>
                      <InputText 
                      id={`wings.${wingIndex}.name`}
                      name={`wings.${wingIndex}.name`}
                      placeholder="Name of Wing"
                      className={classNames({
                        "input-invalid": Boolean(formik.touched?.wings?.[wingIndex]?.name && formik.errors?.wings?.[wingIndex]),
                         }, "w-100")}
                      {...formik.getFieldProps(`wings.${wingIndex}.name`)}
                      />
                       {Boolean(formik.touched?.wings && formik.touched?.wings?.[wingIndex]?.name && formik.errors?.wings?.[wingIndex] && formik.errors?.wings?.[wingIndex]?.name ) && (
                          <small className="p-error">{formik.errors?.wings?.[wingIndex]?.name}</small>
                      )}
                      {console.log(wingIndex, formik.errors?.wings?.[wingIndex]?.name)}
                    </div>
                    <div className="p-field p-mb-0">
                      <label className="text-muted" htmlFor={`wings.${wingIndex}.contact_number`}>Contact No (optional)</label>
                      <InputText 
                      id={`wings.${wingIndex}.contact_number`}
                      name={`wings.${wingIndex}.contact_number`}
                      placeholder="Enter Contact Number" 
                      className="w-100"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={wing.contact_number}
                       />
                      {Boolean(formik.errors?.wings?.[wingIndex]) && (
                          <small className="p-error">{formik.errors?.wings?.[wingIndex]?.contact_number}</small>
                      )}
                       {console.log("touch" , formik.touched)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Wings;
