import React, { useEffect } from "react";
import logo1 from "../../assets/images/hamptom.png";
import SocietyStat from "./components/SocietyStat";
import Complaint from "./components/Complaint";
import SocietyHeader from "../../components/societyHeader";
import { BreadCrumb } from "primereact/breadcrumb";
// import { useParams } from 'react-router-dom/cjs/react-router-dom'

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import "primereact/resources/primereact.min.css";
import { useState } from "react";
import axios from "axios";
import { CgSearch } from "react-icons/cg";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import Loading from "../ManageApp/data/Loading";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function ManageSociety(props) {
  const home = { icon: "pi pi-home" };
  const { state } = props.location;
  // const {resident, management, security, occupy, unoccupy, tenant, occupyparking, unoccupyparking } = state;

  const { id } = useParams();
  const { society, image } = JSON.parse(localStorage.getItem("society_info"));

  const token = localStorage.getItem("token");
  const [data, setdata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const items = [{ label: "Dashboard" }, { label: "Manage Society" }];

  useEffect(() => {
    datafetch();
  }, []); // Fetch data whenever currentPage changes

  const datafetch = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}getsociety`,
      headers: { Authorization: "Bearer " + token },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        console.log(response.data.data);
        const filteredData = response.data.data.find((item) => item._id === id);
        setIsLoading(false);
        // Set the filtered data in the state
        setdata(filteredData);
        // setdata(response.data);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred while fetching data from the server!",
        });
      });
  };

  // console.log(data);
  // console.log(data.OccupiedParkingSlotCount
  //     )
  if (isLoading) {
    return <Loading />;
  }

  const deleteconfirm = (ID) => {
    console.log("delete");
    console.log(ID);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_API}deleteSociety/` + ID,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        };
        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            history.push("/dashboard");
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    });
  };

  return (
    <>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
        <div className="ms-auto">
          <button
            className="btn ms-auto me-2 btn-sm btn-danger rounded-4"
            onClick={() => deleteconfirm(id)}
          >
            Delete
          </button>

          <button
            className="btn ms-auto me-2 btn-sm btn-primary rounded-4"
            onClick={() => history.push("/dashboard")}
          >
            Back
          </button>
        </div>
      </div>

      <div className="header">
        <SocietyHeader
          imageUrl={image}
          societyName={society}
          id={id}
          defaultRouter="/dashboard"
        />
      </div>

      <div className="card p-3">
        <div className="card-body">
          <div className="row">
            <div className="col-md-4 col-lg-3 col-sm-6">
              <SocietyStat
                counter={data.security_Count}
                name="Total Security Guard"
                value="View"
                dynamicRoute={`/manage-security/${id}`}
              />
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
              <SocietyStat
                counter={data.resident_count}
                name="Residents"
                value="View"
                dynamicRoute={`/manage-residents/${id}`}
              />
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
              <SocietyStat
                counter={data.wingCount}
                name="Wings/Unit"
                value="View"
                dynamicRoute={`/manage-wings/${id}`}
              />
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
              <SocietyStat
                counter={data.request_Data}
                name="Request"
                value="View"
                dynamicRoute={`/manage-visitors/${id}`}
              />
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
              <SocietyStat
                counter={data.pre_all_data}
                name="Gate Control"
                value="View"
                dynamicRoute={`/manage-visitors-type/${id}`}
              />
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
              <SocietyStat
                counter={data.management_Count}
                name="Management"
                value="View"
                dynamicRoute={`/all-managements/${id}`}
              />
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
              <SocietyStat
                counter={data.countfortenant}
                name="Tenants"
                value="View"
                dynamicRoute={`/all-tenants/${id}`}
              />
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
              <SocietyStat
                counter={
                  data.UnOccupiedappartmentCount +
                  "/" +
                  data.OccupiedappartmentCount
                }
                name="Vacant Units"
                value="View"
                dynamicRoute={`/manage-apartment/${id}`}
              />
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
              <SocietyStat
                counter={
                  data.UnOccupiedParkingSlotCount +
                  "/" +
                  data.OccupiedParkingSlotCount
                }
                name="Parking Slots"
                value="View"
                dynamicRoute={`/parking-slots/${id}/${encodeURIComponent(
                  data.UnOccupiedParkingSlotCount +
                    `-` +
                    data.OccupiedParkingSlotCount
                )}`}
              />
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
              <SocietyStat
                counter={data?.communityPostsCount}
                name="Community"
                value="Manage"
                dynamicRoute={`/community-management/${id}`}
              />
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
              <Complaint
                counter={data.complaintrequests_Count}
                name1={
                  data.pendingCount ? data.pendingCount.length + " Pending" : ""
                }
                name2={
                  data.resolvedCount
                    ? data.resolvedCount.length + " Resolved"
                    : ""
                }
                pending={data.pendingCount ? data.pendingCount.length : 0}
                resolved={data.resolvedCount ? data.resolvedCount.length : 0}
                value="View"
                dynamicRoute={`/manage-complaints/${id}`}
              />
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
              <SocietyStat
                counter={data?.amenityTimingsCount}
                name="Amenities"
                value="View"
                dynamicRoute={`/manage-amenity/${id}`}
              />
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
              <SocietyStat
                counter={data?.management_Count}
                name="Finance"
                value="View"
                dynamicRoute={`/manage-finance/${id}`}
              />
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
              <SocietyStat
                counter={data?.maintenanceBillCount}
                name="Maintenance Bills"
                value="View"
                imageUrl={image}
                societyName={society}
                dynamicRoute={`/manage-bills/${id}`}
              />
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
              <SocietyStat
                counter={data?.recycleBinCount}
                name="Recycle Bin"
                value="View"
                imageUrl={image}
                societyName={society}
                dynamicRoute={`/recycle-bin/${id}`}
              />
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
              <SocietyStat
                counter={data?.amenitybookingsCount}
                name="Booked Amenities"
                value="View"
                imageUrl={image}
                societyName={society}
                dynamicRoute={`/booked-amenities/${id}`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
