import React, { useState,useEffect } from 'react'
import { MdDeleteOutline, MdOutlineEdit, MdOutlineRemoveRedEye } from 'react-icons/md'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import user from "../../../assets/images/avatar.jpg"
import { Sidebar } from 'primereact/sidebar'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { classNames } from "primereact/utils";
import axios from 'axios';
import Swal from 'sweetalert2';
import Loading from '../../ManageApp/data/Loading'



export default function FaqList() {
    const [visibleRight, setVisibleRight] = useState(false);
    const [visible, setVisible] = useState(false);
    const [data, setdata] = useState([]);
    // const [isLoading, setIsLoading] = useState(true);

    const [IDE, setID] = useState("");
    const [edittype, settype] = useState("");
    const [editques, setques] = useState("");
    const [editanswer, setanswer] = useState("");

    const[offsetentry,setoffsetentry]=useState(0);
  const[entry,setentry]=useState(0);

  


    // const token = localStorage.getItem('token');
    const [totalItems, setTotalItems] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [pageSize] = useState(10);

    // useEffect(() => {
    //     datafetch();
    //    }, []);
    const token = localStorage.getItem('token');
     //Pagination
     const [currentPage, setCurrentPage] = useState(1);
     const [recordsPerPage] = useState(10);
  //


  useEffect(() => {
    datafetch();
}, [currentPage]); // Fetch data whenever currentPage changes

const datafetch = () => {
    // setIsLoading(true);
    const offset = (currentPage - 1) * pageSize;
    const limit = pageSize;

    setoffsetentry(offset);

    
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API}getFaqs`,
        headers: { 'Authorization': 'Bearer ' + token },
        params: { limit, offset }
    };

    axios.request(config)
        .then((response) => {
            setTotalItems(response.data.item.totalRecords); 
            console.log(response.data)
            console.log(response.data.item.data.length)
            setdata(response.data);
            setIsLoading(false);
            // console.log(response.data.data.length)
            setentry(response.data.item.data.length+offset)
        })
        .catch((error) => {
            console.log(error);
            Swal.fire("Error", "Failed to get data", "error");
        });
};


const totalPages = Math.ceil(totalItems / pageSize);
const pageNumbers = [];
for (let i = 1; i <= totalPages; i++) {
  pageNumbers.push(i);
}

const nextPage = () => {
    const totalPages = Math.ceil(totalItems / pageSize); // Calculate the total number of pages

    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
};

const prevPage = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
};


//     function datafetch(){

        
//         // data fetching here
//         let config = {
//             method: 'get',
//             maxBodyLength: Infinity,
//             url: `${process.env.REACT_APP_BASE_API}getFaqs`,
//             headers: { 'Authorization': 'Bearer ' + token}
       
//           };    
//           axios.request(config)
//           .then((response) => {
//             // console.log(JSON.stringify(response.data));
//             // console.log(response.data);
//             setdata(response.data); 
//             // const value = response.data.data;

//           console.log(response.data);
//           const value=response.data.item.data;
//           console.log(value);
       
//         //  setIsLoading(false);
//         //  console.log(ID)
//           })
//           .catch((error) => {
//             console.log(error);
//           });
// }

//pagination
    // const array= Object.keys(data);
    // console.log(array)
    // const val=data.item.data;
    const array=data.item ? data.item.data : [];
    console.log(array);


    // console.log(array);
    // const indexOfLastRecord = currentPage * recordsPerPage;
    // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    // const currentRecords = array.slice(indexOfFirstRecord, indexOfLastRecord);
    // const nPages = Math.ceil(array.length / recordsPerPage)


    // const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    

    // const goToNextPage = () => {
    //         if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    // }
    // const goToPrevPage = () => {
    //     if(currentPage !== 1) setCurrentPage(currentPage - 1)
    // }
//
   



    // //Addcharge validation
    const validationSchema = Yup.object().shape({
        type:Yup.string().required("Type is required"),
        question: Yup.string().required("Title is required"),
        answer:Yup.string().required("Answer is required"),

    });

    const formik = useFormik({
        initialValues: {
            
    
            question:"",
            answer:"",
            type:"",
           
        },
        validationSchema : validationSchema
    });
    
    const handleOnClick = () => {
        console.log("working")
        formik.setTouched(
            Object.keys(formik.values).reduce((acc , fieldName) => {
                acc[fieldName] = true
                return acc
            },
            {}
            )
        )
        formik.validateForm().then((errors) => {
          // Check if there are any validation errors
          console.log("errors" , errors)
          if (Object.keys(errors).length === 0) {
            // If no errors, proceed to the next step
            console.log("values" , formik.values)
            const payload = formik.values 
            console.log(payload);
            axios({
                method: 'post',
                url:`${process.env.REACT_APP_BASE_API}addFaq`,
                data: payload, // you are sending body instead
                headers: {
                    'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
                }, 
              }) .then((response) => {
                console.log(response);
                formik.setValues({
                    
                    question:"",
                    answer:"",
                    type:"",
               })
            
               setVisibleRight(false);  
               datafetch();
               Swal.fire({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500
              });
                
                })
            .catch((error) => {
               console.log("error",error);
               setVisibleRight(false);  
               Swal.fire("Error", "Failed to add data", "error");
               formik.setValues({
                    
                question:"",
                answer:"",
                type:"",
           })
        
              })
          }       
 
        });
        
        
    };
    const deletePost=(ID)=>
    {
            console.log("delete");
            console.log(ID);
    
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
             
              }).then((result) => {
                if (result.isConfirmed) {
             let config = {
               method: 'delete',
               maxBodyLength: Infinity,
               url: `${process.env.REACT_APP_BASE_API}deleteFaq/`+ID,
        
          
               headers: {  'Authorization': 'Bearer ' + token,'Content-Type': 'application/json'}
              };
             axios.request(config)
             .then((response) => {
             console.log(JSON.stringify(response.data));
            Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
            });
            datafetch();
    
         }).catch((error) => {
           console.log(error);
        });
      
                 
        }
        });
        }
        
        
      
        const editPost=(data)=>{
            console.log(data);
            // setID(val.id);
            // settype(val.type);
            // setques(val.question);
            // setanswer(val.answer);
            
            // setVisible(true);      
            if (data && data._id) {
                setID(data._id);
                settype(data.type);
                setques(data.question);
                setanswer(data.answer);
                setVisible(true);
                editformik.setValues({
                    editanswer:data.answer,
                    editques:data.question,
                    edittype:data.type
                });          
            } else {
                console.error("ID not found in data object:", data);
            }    
        }

         // Define Yup schema for edit form validation
const editValidationSchema = Yup.object().shape({
    
    edittype: Yup.string().required('Type is required'),
    editques: Yup.string().required('Question is required'),
    editanswer: Yup.string().required('Answer is required'),
});

// Initialize Formik for edit form
const editformik = useFormik({
    initialValues: {
        edittype:edittype,
        editques:editques,
        editanswer:editanswer,
    },
    validationSchema: editValidationSchema,
    // onSubmit: confirmAndHandleEdit,
    onSubmit: handleOnEdit, // assuming handleOnEdit is your submit handler function
});


        if (isLoading) {
            return <Loading />;
          }
        // function handleOnEdit(){ 
        //     setVisible(false);  
        //     Swal.fire({
        //         title: "Do you want to save the changes?",
        //         showDenyButton: true,
        //         showCancelButton: true,
        //         confirmButtonText: "Save",
        //         denyButtonText: `Don't save`
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             handleOnEdit(); // Call your edit function when user confirms
                  
          
          
        //     axios.patch(`${process.env.REACT_APP_BASE_API} updateFaq/${IDE}`, {
               
        //         question:editformik.values.editques, 
        //         type:editformik.values.edittype,
        //         answer:editformik.values.editanswer,
                           
        //       },{
        //         headers: {
        //             'Authorization': 'Bearer ' + token,
        //             'Content-Type': 'application/json'
        //         }
        //     })
        //         .then(response => {console.log(response.data)
        //             // setIsLoading(false);
        //             setVisible(false);  
        //             datafetch();     
        //             Swal.fire("Saved!", "", "success");
        //         //   window.location.reload(false);
        //         })
        //         .catch(error => {console.error(error)
        //             // setIsLoading(false);
        //             Swal.fire("Error", "Failed to add data", "error");
        //         });
                
        //     } else if (result.isDenied) {
        //         Swal.fire("Changes are not saved", "", "info");
        //     }
        // });
    
        // }


        function handleOnEdit() {
            setVisible(false);
            Swal.fire({
                title: "Do you want to save the changes?",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Save",
                denyButtonText: `Don't save`
            }).then((result) => {
                if (result.isConfirmed) {
                    // Proceed with the edit action
                    executeEdit();
                } else if (result.isDenied) {
                    // Do nothing or provide feedback
                    Swal.fire("Changes are not saved", "", "info");
                }
            });
        }
        
        function executeEdit() {
            axios.patch(`${process.env.REACT_APP_BASE_API}updateFaq/${IDE}`, {
                    question: editformik.values.editques,
                    type: editformik.values.edittype,
                    answer: editformik.values.editanswer,
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    console.log(response.data);
                    setVisible(false);
                    datafetch();
                    Swal.fire("Saved!", "", "success");
                })
                .catch(error => {
                    console.error(error);
                    Swal.fire("Error", "Failed to update", "error");
                });
        }
        function confirmAndHandleEdit() {
            setVisible(false);  
            Swal.fire({
                title: "Do you want to save the changes?",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Save",
                denyButtonText: `Don't save`
            }).then((result) => {
                if (result.isConfirmed) {
                    handleOnEdit(); // Call your edit function when user confirms
                    Swal.fire("Saved!", "", "success");
                } else if (result.isDenied) {
                    Swal.fire("Changes are not saved", "", "info");
                }
            });
        }

    return (
        <div>


            <Sidebar className='right-sidebar' visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                <h5>Add New </h5>
                <div class="sidebar-main">
                    <form>
                        <div class="mb-3">
                       
                        <label for="chargeName" name="type" class="form-label"> Type  
                        <select name="type" class="form-control" className={classNames({
                                    "input-invalid": Boolean(formik.touched.type && formik.errors.type),
                                }, "form-control")}
                                {...formik.getFieldProps("type")} >
                                <option value="">Select Type</option>
                                    <option value="user">user</option>
                                   <option value="managment">management</option>
                        </select>
                        {Boolean(formik.touched.type && formik.errors.type) && (
                                <small className="p-error">{formik.errors.type}</small>
                            )}
                        </label>
                        <br/>

                            <label for="chargeName" class="form-label">Title</label>
                            <input type="text" class="form-control" id="chargeName" name="question" placeholder="Enter Question title"   
                             className={classNames({
                                    "input-invalid": Boolean(formik.touched.question && formik.errors.question),
                                }, "form-control")}
                                {...formik.getFieldProps("question")}  />

              {Boolean(formik.touched.question && formik.errors.question) && (
                                <small className="p-error">{formik.errors.question}</small>
              )}
                        </div>
                        <div class="mb-3">
                            <label for="category" class="form-label">Answer</label>
                            <textarea
                            //  className='form-control' 
                            id='answer' name="answer" rows={5} className={classNames({
                                    "input-invalid": Boolean(formik.touched.answer && formik.errors.answer),
                                }, "form-control")}
                                {...formik.getFieldProps("answer")}  />

              {Boolean(formik.touched.answer && formik.errors.answer) && (
                                <small className="p-error">{formik.errors.answer}</small>
              )}
                        </div>
                    </form>
                </div>
                <div className='d-flex footer gap-2 align-items-center'>
                    <button type="submit" class="btn btn-primary rounded-4"onClick={handleOnClick}>Submit</button>
                </div>
            </Sidebar>


            <Sidebar className='right-sidebar' visible={visible} position="right" onHide={() => setVisible(false)}>
                <h5>Edit</h5>
                <div class="sidebar-main">
    
                    <form onSubmit={editformik.handleSubmit}>
                        <div class="mb-3">
                       
                        <label for="chargeName" name="edittype" class="form-label"> Type  
                        <select name="edittype" class="form-control"
                        //  className={classNames({
                        //             "input-invalid": Boolean(formik.touched.type && formik.errors.type),
                        //         }, "form-control")}
                        //         {...formik.getFieldProps("type")} 
                        className={classNames({
                            "input-invalid": Boolean(editformik.touched.edittype && editformik.errors.edittype),
                        }, "form-control")}
                        autocomplete="off"
                        value={editformik.values.edittype}
                            // value={edittype}
                        // onChange={(e) => settype(e.target.value)}
                        onChange={editformik.handleChange}
                        onBlur={editformik.handleBlur}
                                >     <option value="">Select Type</option>
                                    <option value="user">user</option>
                                   <option value="managment">managment</option>
                        </select>
                        {/* {Boolean(formik.touched.type && formik.errors.type) && (
                                <small className="p-error">{formik.errors.type}</small>
                            )} */}
                             {editformik.touched.edittype && editformik.errors.edittype && (
        <small className="p-error">{editformik.errors.edittype}</small>
    )}
                        </label>
                        <br/>

                            <label for="chargeName" class="form-label">Title</label>
                            <input type="text" class="form-control" id="chargeName" name="editques" placeholder="Enter Question title" 
                             className={classNames({
                                "input-invalid": Boolean(editformik.touched.editques && editformik.errors.editques),
                            }, "form-control")}  
                            //  className={classNames({
                            //         "input-invalid": Boolean(formik.touched.question && formik.errors.question),
                            //     }, "form-control")}
                            //     {...formik.getFieldProps("question")} 
                            autocomplete="off"
                            // value={editques}
                        // onChange={(e) => setques(e.target.value)}
                        // autoComplete="off"
                        value={editformik.values.editques}
                        onChange={editformik.handleChange}
                        onBlur={editformik.handleBlur}
                               />
 {editformik.touched.editques && editformik.errors.editques && (
                                <small className="p-error">{editformik.errors.editques}</small>
                            )}
              {/* {Boolean(formik.touched.question && formik.errors.question) && (
                                <small className="p-error">{formik.errors.question}</small>
              )} */}
                        </div>
                        <div class="mb-3">
                            <label for="category" class="form-label">Answer</label>
                            <textarea
                            //  className='form-control' 
                            id='answer' name="editanswer" rows={5} 
                            className={classNames({
                                "input-invalid": Boolean(editformik.touched.editanswer && editformik.errors.editanswer),
                            }, "form-control")}  
                            // className={classNames({
                            //         "input-invalid": Boolean(formik.touched.answer && formik.errors.answer),
                            //     }, "form-control")}
                            //     {...formik.getFieldProps("answer")}
                            autocomplete="off"
                        //     value={editanswer}
                        // onChange={(e) => setanswer(e.target.value)}
                        value={editformik.values.editanswer}
                        onChange={editformik.handleChange}
                        onBlur={editformik.handleBlur}
                                  />
                                   {editformik.touched.editanswer && editformik.errors.editanswer && (
                                <small className="p-error">{editformik.errors.editanswer}</small>
                            )}
{/* 
              {Boolean(formik.touched.answer && formik.errors.answer) && (
                                <small className="p-error">{formik.errors.answer}</small>
              )} */}
                        </div>
                        <div className='d-flex footer gap-2 align-items-center'>
                    <button type="submit" class="btn btn-primary rounded-4">Edit</button>
                </div>
                    </form>
                </div>
               
            </Sidebar>


            <div className='table_header mb-3'>
                <div className='d-flex flex-wrap justify-content-between align-items-center  '>
                    <h4 className='fw-semi-bold fs-5 mb-0'>Faq List</h4>
                    <button onClick={() => setVisibleRight(true)}  className='btn btn-primary btn-sm rounded-4'>Add New</button>

                </div>
            </div>
            <div className='table-responsive'>
                <table className="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Question</th>
                            <th scope="col">Answer</th>
                            <th scope="col">Type</th>
                            <th scope="col">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                  
                    {/* {currentRecords && currentRecords.map((data, idx) => ( */}
                        {array && array.map((data, idx) => (
                        <tr>
                            {/* <th scope="row">{idx+1}</th> */}
                            <th scope="row"> {(currentPage - 1) * pageSize + idx + 1}</th>
                            <td><p className='text-truncate' style={{ maxWidth: '300px' }}>{data.question}</p></td>
                            <td><p className='text-truncate' style={{ maxWidth: '300px' }}> {data.answer}</p></td>  
                            <td><p className='text-truncate' style={{ maxWidth: '300px' }}> {data.type}</p></td>  
                            <td><div className='action_btn'>
                            <Link onClick={() => editPost(data)}   ><MdOutlineEdit /></Link>
                                <Link onClick={() => deletePost(data._id)}><MdDeleteOutline /></Link>
                                {/* <Link onClick={() => editPost(data)}  ><MdOutlineEdit /></Link>
                                <Link onClick={() => deletePost(data._id)}><MdDeleteOutline /></Link> */}
                            </div></td>
                        </tr>
                           ))}

                       {/* <tr>
                            <th scope="row">1</th>
                            <td><p className='text-truncate' style={{ maxWidth: '300px' }}> Is there a free trial available?</p></td>
                            <td><p className='text-truncate' style={{ maxWidth: '300px' }}> Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.</p></td>
                            <td><div className='action_btn'>
                                <Link  ><MdOutlineRemoveRedEye /></Link>
                                <Link ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr> */}
                        {/* <tr>
                            <th scope="row">1</th>
                            <td><p className='text-truncate' style={{ maxWidth: '300px' }}> Can I change my plan later? </p></td>
                            <td><p className='text-truncate' style={{ maxWidth: '300px' }}> Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.</p></td>
                            <td><div className='action_btn'>
                                <Link to="/view-post" ><MdOutlineRemoveRedEye /></Link>
                                <Link ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>

                        <tr>
                            <th scope="row">1</th>
                            <td><p className='text-truncate' style={{ maxWidth: '300px' }}> What is your cancellation policy?</p></td>
                            <td><p className='text-truncate' style={{ maxWidth: '300px' }}> Would you be willing to join a 30 min Appreaz demo for residents of Jalvayu Vihar?</p></td>
                            <td><div className='action_btn'>
                                <Link ><MdOutlineRemoveRedEye /></Link>
                                <Link ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>

                        <tr>
                            <th scope="row">1</th>
                            <td><p className='text-truncate' style={{ maxWidth: '300px' }}> Can other info be added to an invoice?</p></td>
                            <td><p className='text-truncate' style={{ maxWidth: '300px' }}> Would you be willing to join a 30 min Appreaz demo for residents of Jalvayu Vihar?</p></td>
                            <td><div className='action_btn'>
                                <Link ><MdOutlineRemoveRedEye /></Link>
                                <Link ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr>

                        <tr>
                            <th scope="row">1</th>
                            <td><p className='text-truncate' style={{ maxWidth: '300px' }}> How do I change my account email?</p></td>
                            <td><p className='text-truncate' style={{ maxWidth: '300px' }}> Would you be willing to join a 30 min Appreaz demo for residents of Jalvayu Vihar?</p></td>
                            <td><div className='action_btn'>
                                <Link  ><MdOutlineRemoveRedEye /></Link>
                                <Link ><MdOutlineEdit /></Link>
                                <Link ><MdDeleteOutline /></Link>
                            </div></td>
                        </tr> */}


                    </tbody>

                </table>
            </div>
            <div className="table-footer d-md-flex justify-content-md-between align-items-center">
        <p className='mb-0'>Showing {offsetentry+1} to {entry} of <span id='total-entries'>{totalItems}</span>  entries</p>
        <nav>
              <ul className="pagination pagination-md">
              <li className="page-item">
            <button
              className="page-link"
              onClick={prevPage}
              disabled={currentPage === 1}
            >
               &laquo;
            </button>
          </li>
               
                {pageNumbers.map((pageNumber) => {
            // Define the minimum and maximum page numbers to display
            const minPage = Math.max(1, currentPage - 4); // Adjust this value as needed
            const maxPage = Math.min(totalPages, currentPage + 4); // Adjust this value as needed


            if (pageNumber >= minPage && pageNumber <= maxPage) {
                return (
                    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                        <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>
                            {pageNumber}
                        </button>
                    </li>
                );
            }
            return null;
        })}
 <li className="page-item">
            <button className="page-link" onClick={nextPage}>
            &raquo;
            </button>
          </li>
                
              </ul>
            </nav>
      
          
   
      </div>
            {/* <div className="table-footer d-md-flex justify-content-md-between align-items-center"> */}
                {/* <p className='mb-0'>Showing 1 to 5 of <span id='total-entries'>57</span>  entries</p>
                <ul class="pagination  pagination-md">
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Previous">&laquo;</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">1</Link></li>
                    <li class="page-item active"><Link class="page-link " href="#">2</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">3</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">4</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">5</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">6</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">7</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">8</Link></li>
                    <li class="page-item"><Link class="page-link" href="#">9</Link></li>
                    <li class="page-item"><Link class="page-link" href="#" aria-label="Next">&raquo;</Link></li>
                </ul> */}
            {/* </div> */}
              {/* pagination */}
              {/* <nav>
            <ul className='pagination justify-content-center'>
                <li className="page-item">
                    <a className="page-link" 
                        onClick={goToPrevPage} 
                        href='#'>
                        
                        Previous
                    </a>
                </li>
                {pageNumbers.map(pgNumber => (
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >

                        <a onClick={() => setCurrentPage(pgNumber)}  
                            className='page-link' 
                            href='#'>
                            
                            {pgNumber}
                        </a>
                    </li>
                ))}
                <li className="page-item">
                    <a className="page-link" 
                        onClick={goToNextPage}
                        href='#'>
                        
                        Next
                    </a>
                </li>
            </ul>
        </nav> */}

{/* <nav>
                <ul className='pagination justify-content-center'>
                    <li className="page-item">
                        <button className="page-link" onClick={prevPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                    </li>
                  
                 {pageNumbers.map((pageNumber) => (
    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
      <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>
        {pageNumber}
      </button>
    </li>
  ))}
                    <li className="page-item">
                        <button className="page-link" onClick={nextPage} >
                            Next
                        </button>
                    </li>

                  
                </ul>
            </nav> */}
              {/* {pageNumbers.map(pgNumber => (
                        <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}>
                            <button className='page-link' onClick={() => setCurrentPage(pgNumber)}>
                                {pgNumber}
                            </button>
                        </li>
                    ))} */}
           {/* disabled={currentPage === nPages} */}

        {/* pagination */}

        </div>
    )
}
