import React, {useEffect, useState} from "react";
import AmenitiesList from "./data/AmenitiesList";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom";
import SocietyHeaderView from "../../components/societyHeaderView";
import { useDispatch, useSelector } from "react-redux";
import { showAllAmenityList } from "../../utils/asyncFun";

export default function ManageAmenity(props) {
  const { id } = useParams();
  const {society, image} = JSON.parse(localStorage.getItem("society_info"))

  const home = { icon: "pi pi-home" };

  const items = [
    { label: "Dashboard" },
    { label: "Manage Society" },
    { label: "All Amenites " },
  ];

  const [offset, setOffset] = useState(0);
  const dispatch = useDispatch();
  const stores = useSelector((store) => store.data.amenity);

  useEffect(() => {
    dispatch(showAllAmenityList({method: "GET", url: `showAllAmenityList?society_id=${id}&limit=5&offset=${offset}`, data: ""}))
  }, [offset])

  const handlePageChange = (pageVal) => {
    setOffset((pageVal-1)*5)
  }

  return (
    <div>
      <div className="d-flex align-items-center bg-white rounded-2">
        <BreadCrumb model={items} home={home} />
      </div>

      <div className="header">
        <SocietyHeaderView
          imageUrl={decodeURIComponent(image)}
          societyName={society}
          defaultRouter="/dashboard"
        />
      </div>
      <div className="card p-3">
        <div className="table-body">
          <AmenitiesList imageUrl={decodeURIComponent(image)} societyName={society} id={id} data={stores} onPageChange={handlePageChange} />
        </div>
      </div>
    </div>
  );
}
